import {developement} from 'mobile/utils.js';

var init_page, init_user_multiselect;

$(document).ready(function() {
  $(document).on("hide", "#project-users-modal", function() {
    $('.typeahead').remove();
    $('.project-new-extra a').show();
    $('#new_project_form .project-budget-section').addClass("hide");
    $('#new_project_form .workspace-assign').addClass("hide");
    return $('#new_project_form .project-template').addClass("hide");
  });
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      return init_page();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    return init_page();
  }
});

init_page = function() {
  $('.project-board').on("click", ".project_cog  a[data-target=\\#project-users-modal]", function(e) {
    var url;
		url = "/projects/" + Breeze.project + '/users' + "?auth_token=" + Breeze.current_user.token;
    // if (developement()) {
    //   url = "https://app.breeze.test:3006/projects/" + Breeze.project + '/users' + "?auth_token=" + Breeze.current_user.token;
    // } else {
    //   url = "https://breeze-node-n7.herokuapp.com/projects/" + Breeze.project + '/users' + "?auth_token=" + Breeze.current_user.token;
    // }
    $('#project-users-modal').modal({
      dynamic: true
    });
    $('#project-users-modal .modal-body').html('');
    $('#project-users-modal .modal-body').append(Breeze.global_loader);
    $("#project-users-modal").modal("show");
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        partial: true
      },
      success: function(data) {
        $('#project-users-modal .m-loader').remove();
        $('#project-users-modal .modal-body').html($(data).html());
        init_user_multiselect();
        if (developement()) {
          url = "https://app.breeze.test:3006/teams/users?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n7.herokuapp.com/teams/users?auth_token=" + Breeze.current_user.token;
        }
        return $.ajax({
          type: 'GET',
          url: url,
          data: {
            partial: true
          },
          success: function(data) {
            // $("#project-users-modal").user_autocomplete(data);
             Breeze.all_users = data;
          }
        });
      }
    });
    e.preventDefault();
    $('.project_cog .dropdown-toggle').dropdown("toggle");
    return false;
  });
  $('#project-users-modal').on("click", 'a[data-action="cancel"]', function() {
    var that;
    that = $(this).closest(".controls");
    that.find(".confirm").hide();
    that.find('a[data-action="remove"]').show();
    return false;
  });
  $('#project-users-modal').on("click", 'a[data-action="confirm"]', function() {
    var ele, target, url;
    ele = $(this).closest(".members");
    Breeze.project_users = _.reject(Breeze.project_users, function(el) {
      return el.id === ele.data('user-id');
    });
    ele.fadeOut();
    target = $(this).attr('href');
    if (developement()) {
      url = "https://app.breeze.test:3006" + target + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n7.herokuapp.com" + target + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "GET",
      url: url
    });
    return false;
  });
  $('#project-users-modal').on("click", 'a[data-action="remove"]', function() {
    $(this).parent().find(".confirm").show();
    $(this).hide();
    return false;
  });
  $('#project-users-modal').on("submit", "#user_project_form", function(evt, xhr, settings) {
    var url;
    $('#user_project_form .person-email input:text').each(function(i) {
      if (!$.validate_email($(this).siblings("input:hidden").val())) {
        return $(this).parent().siblings(".person-icon").find("i").attr("class", "icon-minus");
      }
    });
    if ($("#user_project_form .person-icon .icon-minus").length > 0) {
      return false;
    }
    $(this).find('.c-button').prop('disabled', true);
    $(this).find('.c-button .progress-button').show();
		
		url = "/projects/" + Breeze.project + '/people/add' + "?auth_token=" + Breeze.current_user.token;
    // if (developement()) {
    //   url = "https://app.breeze.test:3006/projects/" + Breeze.project + '/people/add' + "?auth_token=" + Breeze.current_user.token;
    // } else {
    //   url = "https://breeze-node-n7.herokuapp.com/projects/" + Breeze.project + '/people/add' + "?auth_token=" + Breeze.current_user.token;
    // }
    $.ajax({
      type: "POST",
      url: url,
      data: $('#user_project_form').serialize(),
      success: function(data) {
        $(this).find('.c-button').prop('disabled', false);
        $(this).find('.c-button .progress-button').hide();
        $('#project-users-modal .modal-body').html($(data).html());
        // $("#project-users-modal").user_autocomplete(Breeze.all_users);
        Breeze.project_users_groups_select = void 0;
        init_user_multiselect();
      }
    });
    return false;
  });
  $('#project-users-modal').on("change", '.project-invite-link input[type="radio"]', function(e) {
    var url;
    if ($('.project-invite-users-link-box').hasClass("hide")) {
      if (developement()) {
        url = "https://app.breeze.test:3006/projects/" + Breeze.project + '/enable_project_invite_link' + "?auth_token=" + Breeze.current_user.token;
      } else {
        url = "https://breeze-node-n7.herokuapp.com/projects/" + Breeze.project + '/enable_project_invite_link' + "?auth_token=" + Breeze.current_user.token;
      }
      $('.project-invite-link').append(Breeze.global_loader);
      return $.ajax({
        type: "GET",
        url: url,
        success: function(data) {
          $('.project-invite-link .m-loader').remove();
          $('.project-invite-link input').val("https://app.breeze.pm/signup/start/p/" + data);
          return $('.project-invite-users-link-box').removeClass('hide');
        }
      });
    } else {
      if (developement()) {
        url = "https://app.breeze.test:3006/projects/" + Breeze.project + '/disable_project_invite_link' + "?auth_token=" + Breeze.current_user.token;
      } else {
        url = "https://breeze-node-n7.herokuapp.com/projects/" + Breeze.project + '/disable_project_invite_link' + "?auth_token=" + Breeze.current_user.token;
      }
      $.ajax({
        type: "GET",
        url: url
      });
      return $('.project-invite-users-link-box').addClass('hide');
    }
  });
  return $('#project-users-modal').on("click", ".project-invite-link button", function() {
    $('.project-invite-link input').select();
    return document.execCommand("copy");
  });
};

init_user_multiselect = function() {
  $('#invite-role-select').multiselect({
    numberDisplayed: 1,
    maxHeight: 600,
    nonSelectedText: "default",
    buttonClass: 'btn-material',
    nSelectedText: 'selected',
    multiple: false
  });
  $('.fields-users-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Users",
    buttonClass: 'btn btn-link1',
    nSelectedText: '',
    nSelectedText1: 'Users',
    enableFiltering: true,
    enableHTML: true,
    enableCaseInsensitiveFiltering: true,
    enableClickableOptGroups: true,
    onInitialized: function(select, container) {
      var selected_values;
      selected_values = $(this.$select).data('selected-values');
      if (selected_values && selected_values.length > 0) {
        $(container).find('.multiselect-badge').text(selected_values.length);
      }
      $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      var select_el, selected_values, url, wrapper_el;
      $(".popover").remove();
      $('.custom-fields-button').removeData("bs.popover");
      select_el = $(this.$select);
      wrapper_el = $(this.$container);
      if (select_el.find('option').length <= 0) {
        selected_values = select_el.attr('data-selected-values');
        wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
        if (developement()) {
          url = "https://app.breeze.test:3006/users/select_field?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n2.herokuapp.com/users/select_field?auth_token=" + Breeze.current_user.token;
        }
        $.ajax({
          type: "POST",
          url: url,
          data: {
            empty: true
          },
          success: function(data) {
            select_el.append($(data).find("select").html());
            select_el.multiselect('rebuild');
            wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
            select_el.parent().find("input[type='text'].multiselect-search").focus();
          }
        });
      }
    }
  });
};

