import {embed_images, unembed_images} from 'mobile/utils/embed.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after} 
				from 'mobile/froala/setup.js';

import {update_task_stripe} from 'mobile/utils/task_stripe.js';
import {dec_wip_total,inc_wip_total} from 'mobile/utils/wip.js'
import {hide_first_swimlane} from 'mobile/utils/swimlane.js'
import {update_calendar} from 'mobile/cards/card-menu/duedate.js'

import {} from 'mobile/utils/gravatar.js';
import {developement,load_card_globals} from 'mobile/utils.js';

import {update_project_list_view_columns} from 'mobile/utils/project_board.js'


require("mobile/utils/jquery.autosize.js")

var get_tasks_page, init_dashboard_page, init_dashboard_select, update_active_filter_pill;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "dashboard") {
      return init_dashboard_page();
    }
  });
  if ($('.workspace.dashboard').length > 0) {
    init_dashboard_page();
    if (window.openBreezeDashboardCard != null) {
      return window.openBreezeDashboardCard();
    }
  }
});

init_dashboard_page = function() {
	
  init_dashboard_select();
  $('.card').show_overdue_tasks();
  if ($(".timer.on").length <= 0) {
    $.init_timer();
  }
  Breeze.init_fileupload();
  update_active_filter_pill();
  // Breeze.init_harvest_tag();
  if ($('#dashboard-filter-type input:checked').val() === "created") {
    $('#tasks_user_id option:contains("not assigned")').hide();
  }
  Breeze.make_dashboard_sortable();
	
	update_project_list_view_columns()
	
  $('.card_cover img').on("error", function() {
    return $(this).parent().remove();
  });
  $('.dashboard').on("click", ".card-footer-container", function() {
    return false;
  });
  $('.dashboard').on("ajax:beforeSend", ".load-more-cards", function(evt, xhr, settings) {
    $(this).hide();
    $(this).parent().append(Breeze.global_loader)
  });
  $('.dashboard').on("ajax:complete", ".load-more-cards", function(evt, xhr, settings) {
    var group;
    group = $(this).closest('.card-block');
    $($(xhr.responseText).html()).insertBefore($(this).parent());
    $(this).parent().remove();
    $('.card').show_overdue_tasks();
    if ($(".timer.on").length <= 0) {
      $.init_timer();
    }
    Breeze.make_dashboard_sortable();
		update_project_list_view_columns()
    // return Breeze.init_harvest_tag();
  });
  $('.dashboard').on("ajax:complete", ".load-more-projects", function(evt, xhr, settings) {
    $($(xhr.responseText).find('.right-menu').html()).insertBefore($(this).parent());
    $(this).parent().remove();
    $('.card').show_overdue_tasks();
    if ($(".timer.on").length <= 0) {
      $.init_timer();
    }
    Breeze.make_dashboard_sortable();
		update_project_list_view_columns()
    // return Breeze.init_harvest_tag();
  });
  $('.dashboard').on("ajax:beforeSend", ".load-more-projects", function(evt, xhr, settings) {
    $(this).hide();
		
		$(this).parent().append(Breeze.global_loader)
				
  });
  $('.dashboard').on("click", ".right-menu .card-block[data-dueblock] .hide-dashboard-group", function() {
    $(this).parent().next().toggleClass("hide");
    $(this).toggleClass("active");
    return $.ajax({
      type: 'POST',
      url: '/profile',
      queue: true,
      data: {
        user: {
          dashboard_group_hide: $(this).parent().attr('data-dueblock')
        }
      }
    });
  });
  $('.dashboard').on("click", ".right-menu.project-sort .card-block .hide-dashboard-group", function() {
    $(this).parent().next().toggleClass("hide");
    $(this).toggleClass("active");
    return $.ajax({
      type: 'POST',
      url: '/profile',
      queue: true,
      data: {
        user: {
          dashboard_project_hide: $(this).parent().attr('data-project-id')
        }
      }
    });
  });
	
  $('.dashboard').on("click", '.task-board-settings .users', function(e) {
		$('.left-menu .fields-users-wrapper button').trigger("click")
		e.preventDefault();
		return false;
	});
	
  $('.dashboard').on("click", '.task-board-settings [data-group] a', function(e) {
		$('.task-board-settings [data-group] a').removeClass('active')
		$(this).addClass("active")
		
    get_tasks_page();
		e.preventDefault()
  });
	
  $('.dashboard').on("click", '.task-board-settings [data-filter] a', function(e) {
    var filter;
		$('.task-board-settings [data-filter] a').removeClass('active')
		
    $(this).addClass("active");
    filter = $(this).attr('data-type');
    if (filter === "created") {
      $('#tasks_user_id option:contains("not assigned")').hide();
      if ($('#tasks_user_id option:selected').html() === "not assigned") {
        $('#tasks_user_id option:contains("all users")').prop('selected', true);
      }
    } else {
      $('#tasks_user_id option:contains("not assigned")').show();
    }
    get_tasks_page();
		e.preventDefault()
  });
  $('.dashboard').on("click", '#dashboard-filter input', function() {
    get_tasks_page();
    return true;
  });
  $('.task-board-settings').on("click", ".save-filters", function() {
    var el;
    el = $('.task-board-settings');
    $('.popover').remove();
    el.removeData("bs.popover");
    el.popover({
      trigger: "manual",
      placement: "bottomleft",
      template: '<div class="popover edit-popover" id="dashboard-board-filters"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
      html: true,
      content: $('.new-bookmark').html()
    });
    el.removeClass("open");
    el.popover("show");
    $('#dashboard-board-filters input[type=text]').focus();
    $('#dashboard-board-filters').on("click", ".cancel", function() {
      $('.popover').remove();
      return false;
    });
    $('#dashboard-board-filters').on("ajax:before", ".new-bookmark-board", function(evt, xhr, settings) {
      var custom_fields, dashboard_style, filter, group, projects, stages, status, tags, users;
      filter = $('.dashboard .dashboard-filter-dropdown li.checked').attr('data-type') || "assigned";
      dashboard_style = $('.left-menu .dashboard-list-dropdown').attr('data-layout');
      group = $('.left-menu [data-action="tasks-sort"]').attr('data-sort');
      tags = _.map($('.dashboard-tag-select option:selected'), function(e) {
        return $(e).val();
      });
      status = _.map($('.dashboard-status-select option:selected'), function(e) {
        return $(e).val();
      });
      stages = _.map($('.dashboard-stage-select option:selected'), function(e) {
        return $(e).val();
      });
      projects = _.map($('.fields-projects-select option:selected'), function(e) {
        return $(e).val();
      });
      users = _.map($('.fields-users-select option:selected'), function(e) {
        return $(e).val();
      });
      custom_fields = $('.hidden-filters .custom-fields-button').attr("data-fields");
      $('.new-bookmark-board input[name="projects[]"]').val(projects);
      $('.new-bookmark-board input[name="stages[]"]').val(stages);
      $('.new-bookmark-board input[name="tags[]"]').val(tags);
      $('.new-bookmark-board input[name="users[]"]').val(users);
      $('.new-bookmark-board input[name="custom_fields"]').val(custom_fields);
      $('.new-bookmark-board input[name="status[]"]').val(status);
      $('.new-bookmark-board input[name="dashboard_group"]').val(group);
      $('.new-bookmark-board input[name="dashboard_filter"]').val(filter);
      return $('.new-bookmark-board input[name="dashboard_layout"]').val(dashboard_style);
    });
    $('#dashboard-board-filters').on("ajax:beforeSend", ".new-bookmark-board", function(evt, xhr, settings) {
      if ($('#dashboard-board-filters input[type="text"]').val().length <= 0) {
        $('#dashboard-board-filters input[type=text]').focus();
        return false;
      }
      $(this).find('.c-button').prop('disabled', true);
      return $(this).find('.c-button .progress-button').show();
    });
    $('#dashboard-board-filters').on("ajax:complete", function(evt, xhr, settings) {
      $('.dashboard-bookmarks').append($(xhr.responseText));
      $('#dashboard-board-filters input[type="text"]').val("");
      $(this).find('.c-button .progress-button').hide();
      $(this).find('.c-button').prop('disabled', false);
      return $('.popover').remove();
    });
    return false;
  });
  $('.dashboard').on("click", ".reset-filter", function(e) {
    $('.fields-custom-wrapper .custom-fields-button').attr('data-fields', '[]');
    $('.fields-custom-wrapper .custom-fields-popover').remove();
    $('.fields-custom-wrapper .multiselect-badge').html('');
    $('.task-board-settings .multiselect-badge').html('');
		
		$('.task-board-settings [data-filter] a').removeClass('active')
		$('.task-board-settings [data-filter] [data-type="assigned"]').addClass('active')
		
		$('.task-board-settings [data-group] a').removeClass('active')
		$('.task-board-settings [data-group] [data-type="duedate"]').addClass('active')
		
		
    $('.left-menu select option').each(function() {
      return $(this).prop('selected', false);
    });
    $('.fields-users-select option[value="' + Breeze.current_user.id + '"]').prop('selected', true);
    $('.dashboard-list-dropdown').attr('data-layout', 'list');
    $('.dashboard .dashboard-list-dropdown .dropdown-toggle span').html("Show as list");
    $('.dashboard .dashboard-group-dropdown li').removeClass("checked");
    $('.dashboard .dashboard-group-dropdown .dropdown-toggle span').html("Sort by due date");
    $('.dashboard .dashboard-group-dropdown').attr("data-sort", "duedate");
    $('.dashboard .dashboard-filter-dropdown li[data-type="assigned"]').addClass("checked");
    $('.dashboard .dashboard-filter-dropdown .dropdown-toggle span').html("Assigned to...");
    $('.dashboard-status-select option').each(function() {
      if ($(this).text() !== "Done") {
        return $(this).prop('selected', true);
      }
    });
    $('.left-menu select').multiselect('refresh');
    $('.dashboard-action .create').trigger("click");
    $('.task-board-settings').removeClass("open");
		
		var selected_users = $('.left-menu .fields-users-wrapper .fields-users-select').data('selected-values').length
    if (selected_users > 0) $('.task-board-settings .multiselect-badge').html(selected_users);
		
    e.preventDefault();
		
  });
  $('.dashboard').on("click", ".dashboard-bookmarks .remove", function(e) {
    return $(this).parent().fadeOut('fast');
  });
  $('.dashboard').on("change", '#tasks_user_id', function() {
    var user;
    user = $('#tasks_user_id option:selected').val();
    if (user === "") {
      if ($('#tasks_user_id option:selected').html() === "all users") {
        user = "all";
      } else {
        user = "none";
      }
    }
    History.pushState({
      workspace: null,
      "static": true,
      url: "/tasks/" + user
    }, $("title").html(), "/tasks/" + user);
    return get_tasks_page();
  });
  $('.dashboard').on("click", ".left-menu .show-filters", function() {
    if ($(this).find('.fa').hasClass("fa-angle-down")) {
      $('.left-menu .hidden-filters').slideDown(200);
      $(this).find("span").text("Show less");
      $(this).find('.fa').removeClass("fa-angle-down").addClass("fa-angle-up");
    } else {
      $('.left-menu .hidden-filters').slideUp(200);
      $(this).find("span").text("Show more");
      $(this).find('.fa').addClass("fa-angle-down").removeClass("fa-angle-up");
    }
    return false;
  });
  $('.dashboard').on("click", ".list-view .item-footer-project", function(e) {
    var url;
    url = $(this).attr("href");
    History.pushState({
      workspace: null,
      url: url,
      card_modal: true,
      todo_link: true
    }, $("title").html(), url);
    return false;
  });
  return $('.dashboard').on("click", ".dashboard-action .create", function() {
    get_tasks_page();
    return false;
  });
};

get_tasks_page = function() {
  var custom_fields, filter, group, page_layout, projects, remove_tags, stages, status, tags, url, users;
  filter = $('.task-board-settings [data-filter] .active').attr('data-type');
  group = $('.task-board-settings [data-group] .active').attr('data-type');


  tags = _.map($('.dashboard-tag-select option:selected'), function(e) {
    return $(e).val();
  });
  status = _.map($('.dashboard-status-select option:selected'), function(e) {
    return $(e).val();
  });
  stages = _.map($('.dashboard-stage-select option:selected'), function(e) {
    return $(e).val();
  });
  projects = _.map($('.fields-projects-select option:selected'), function(e) {
    return $(e).val();
  });
  users = _.map($('.fields-users-select option:selected'), function(e) {
    return $(e).val();
  });
  custom_fields = $('.hidden-filters .custom-fields-button').attr("data-fields");
  if (tags.length <= 0) {
    remove_tags = true;
  }
  if ($('.dashboard-list-dropdown[data-layout="cards"]').length > 0) {
    url = '/tasks/block';
    page_layout = "cards";
  } else {
    url = '/tasks';
    page_layout = "list";
  }
	
  $.ajax({
    type: "GET",
    url: url,
    data: {
      group: group,
      partial: true,
      filter: filter,
      status: status,
      tags: tags,
      stages: stages,
      projects: projects,
      task_page: true,
      custom_fields: custom_fields,
      users: users,
      page_layout: page_layout
    },
    beforeSend: function() {
      $(".workspace .right-menu").remove();
      return $('.contain').append(Breeze.global_loader);
    },
    success: function(data) {
      var el;
      $(".contain .m-loader").remove();
      el = $(data).find('.right-menu').prop('outerHTML');
      $(el).insertAfter('.workspace .left-menu');
      $('.card').show_overdue_tasks();
      if ($(".timer.on").length <= 0) {
        // $.init_timer();
      }
      Breeze.init_fileupload();
      Breeze.make_dashboard_sortable();
      update_active_filter_pill();
			update_project_list_view_columns()
      // Breeze.init_harvest_tag();
      return $(document).trigger("breeze:page", "todos-menu");
    }
  });
};

update_active_filter_pill = function() {
  var cf, custom_fields_count, s;
  custom_fields_count = false;
  cf = $('.custom-fields-button').attr("data-fields");
  if ((cf != null ? cf.length : void 0) > 0) {
    cf = JSON.parse(cf);
    if (cf.length > 0) {
      custom_fields_count = true;
    }
  }
  if ((($('.fields-projects-select').val() != null) && $('.fields-projects-select').val().length > 0) || (($('.dashboard-stage-select').val() != null) && $('.dashboard-stage-select').val().length > 0) || (($('.dashboard-tag-select').val() != null) && $('.dashboard-tag-select').val().length > 0) || custom_fields_count) {
    return $('.show-filters span').addClass("mark-dot");
  } else if (($('.dashboard-status-select').val() != null) && $('.dashboard-status-select').val().length > 0) {
    s = [];
    $('.dashboard-status-select option:not(:selected)').each(function() {
      return s.push($(this).val());
    });
    if (_.difference(s, [Breeze.calendars_filter_status_done]).length > 0) {
      return $('.show-filters span').addClass("mark-dot");
    } else {
      return $('.show-filters span').removeClass("mark-dot");
    }
  } else {
    return $('.show-filters span').removeClass("mark-dot");
  }
};

init_dashboard_select = function() {
  var move_dropdown, reset_dropdown;
  move_dropdown = function() {
    var el, height, new_pos, parent, pos;
    el = $('.hidden-filters .open .dropdown-menu');
    parent = el.closest('.multiselect-native-select');
    if (el.length > 0) {
      height = el.height();
      pos = parent.offset().top + height - $(window).scrollTop();
      if ($('html').height() < pos) {
        new_pos = pos - $('html').height() + 20;
        return el.css('top', -new_pos + "px").css('bottom', '');
      }
    }
  };
  reset_dropdown = function() {
    var el;
    el = $('.hidden-filters .open .dropdown-menu');
    if (el.length > 0) {
      return el.css('bottom', '').css('top', '');
    }
  };
	
	
	$('.fields-users-select').multiselect({
	  numberDisplayed: 0,
	  includeSelectAllOption: true,
	  includeSelectAllIfMoreThan: 3,
	  selectAllValue: "all",
	  nonSelectedText: "Users",
	  buttonClass: 'btn btn-link project-btn',
	  nSelectedText: '',
	  nSelectedText1: 'Users',
	  enableFiltering: true,
	  enableCaseInsensitiveFiltering: true,
	  enableHTML: true,
	  enableClickableOptGroups: true,
	  onInitialized: function(select, container) {
	    var selected_values;
	    selected_values = $(this.$select).data('selected-values');
	    if (selected_values && selected_values.length > 0) {
	      $(container).find('.multiselect-badge').text(selected_values.length);
	      $(container).find('.multiselect-selected-text').text("Users");
				$('.task-board-settings .multiselect-badge').text(selected_values.length);
	    }
	    $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
	  },
	  onDropdownShown: function() {
	    var select_el, selected_values, url, wrapper_el;
	    select_el = $(this.$select);
	    wrapper_el = $(this.$container);
	    if (select_el.find('option').length <= 0) {
	      selected_values = select_el.attr('data-selected-values');
	      wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
	      if (developement()) {
	        url = "https://app.breeze.test:3006/users/select_field?auth_token=" + Breeze.current_user.token;
	      } else {
	        url = "https://breeze-node-n3.herokuapp.com/users/select_field?auth_token=" + Breeze.current_user.token;
	      }
	      $.ajax({
	        type: "POST",
	        url: url,
	        data: {
	          selected_values: selected_values,
	          show_hide_selected: true
	        },
	        success: function(data) {
	          select_el.append($(data).find("select").html());
	          select_el.multiselect('rebuild');
	          wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
	          $('.fields-users-select').attr('data-selected', ($('.fields-users-select').val() || []).join(""));
	        }
	      });
	    } else {
	      $('.fields-users-select').attr('data-selected', ($('.fields-users-select').val() || []).join(""));
	    }
			
			return false
	  },
	  onDropdownHide: function() {
	    var selected;
	    selected = ($('.fields-users-select').val() || []).join("");
	    if (selected !== $('.fields-users-select').attr('data-selected')) {
				$('.task-board-settings .multiselect-badge').text($('.fields-users-select').val().length);
				
	      get_tasks_page();
	    }
	  }
	});
	
  $('.fields-projects-select').multiselect({
    numberDisplayed: 0,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Projects",
    buttonClass: 'btn btn-link project-btn',
    nSelectedText: '',
    nSelectedText1: 'Projects',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    enableHTML: true,
    enableClickableOptGroups: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function() {
      return move_dropdown();
    },
    onDropdownHide: function() {
      return reset_dropdown();
    }
  });
  $('.dashboard-tag-select').multiselect({
    numberDisplayed: 0,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Tags",
    nSelectedText1: 'Tags',
    buttonClass: 'btn btn-link tag-btn',
    nSelectedText: '',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function() {
      return move_dropdown();
    },
    onDropdownHide: function() {
      return reset_dropdown();
    }
  });
  $('.dashboard-stage-select').multiselect({
    numberDisplayed: 0,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Lists",
    nSelectedText1: 'Lists',
    buttonClass: 'btn btn-link stage-btn',
    nSelectedText: '',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function() {
      return move_dropdown();
    },
    onDropdownHide: function() {
      return reset_dropdown();
    }
  });
  return $('.dashboard-status-select').multiselect({
    numberDisplayed: 0,
    includeSelectAllOption: false,
    nonSelectedText: "Statuses",
    nSelectedText1: 'Statuses',
    buttonClass: 'btn btn-link project-btn',
    nSelectedText: '',
    allSelectedText: "Statuses",
    selectAllNumber: false,
    onDropdownShown: function() {
      return move_dropdown();
    },
    onDropdownHide: function() {
      return reset_dropdown();
    }
  });
};

Breeze.make_dashboard_sortable = function() {
  var sort_opts_card;
  sort_opts_card = {
    tolerance: 'pointer',
    scroll: true,
    items: '.card-outer:not(.stage-name)',
    delay: /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent) ? 600 : void 0,
    start: function(e, ui) {
      if (ui.item.next().next()[0] == null) {
        ui.placeholder.height(ui.item.height());
      } else {
        ui.placeholder.height(ui.item.height() + 1);
      }
      return Breeze.old_list = ui.item.parent();
    },
    stop: function(event, ui) {
      var item, item_id, ltoken, new_list, old_list, parent, prev, prev_id, ref, todos;
      item = ui.item.find('.card-dash');
      item_id = item.attr("data-card-id");
      parent = ui.item.closest(".stage-list");
      prev = ui.item.prevAll(".ui-sortable-handle:first");
      prev_id = ui.item.prev().find('.card').attr('data-card-id');
      ltoken = Breeze.project_token || ui.item.attr("data-project-token");
      old_list = $('.sortable_stage .stage_class[data-stage-id="' + Breeze.old_list.attr('data-stage-id') + '"]');
      new_list = $('.sortable_stage .stage_class[data-stage-id="' + parent.attr('data-stage-id') + '"]');
      Breeze.project_stages = (ref = _.where(Breeze.projects_stages, {
        name: parent.attr('data-project-id')
      })[0]) != null ? ref.stages : void 0;
      Breeze.project_users = Breeze.all_team_users;
      $(document).trigger("breeze:page", ["update_stage_card", item_id, parent.attr('data-stage-id'), Breeze.old_list.attr('data-stage-id'), item]);
      item.attr('data-stage-id', parent.attr("data-stage-id"));
      item.attr('data-stage-name', parent.find(".stage-name span").html());
      if (parseInt(parent.attr('data-project-id')) === parseInt(item.attr('data-project-id'))) {
        $.ajax({
          type: "POST",
          url: "/cards/move",
          queue: true,
          data: {
            card_id: item_id,
            prev_id: prev_id,
            parent_id: parent.attr("data-stage-id"),
            project_id: parent.attr('data-project-id'),
            dashboard: true,
            position: 0
          }
        });
      } else {
        $.ajax({
          type: "PUT",
          url: "/projects/" + item.attr('data-project-id') + "/cards/" + item_id,
          queue: true,
          data: {
            prev_id: prev_id,
            stage: parent.attr("data-stage-id"),
            project: parent.attr('data-project-id'),
            dashboard: true,
            position: 0
          }
        });
      }
      item.attr('data-project-id', parent.attr('data-project-id'));
      item.attr('data-project-token', item.closest(".card-block-elements").attr('data-project-token'));
      todos = ui.item.find('.todo [data-type="container"]');
      todos.attr('data-project-id', parent.attr('data-project-id'));
      return todos.attr('data-project-name', $('.card-block[data-project-id="' + parent.attr('data-project-id') + '"]').text());
    }
  };
  // $(".dashboard .right-menu.list-view:not(.duedate-sort) .card-block-elements").sortable(sort_opts_card).sortable({
  //   connectWith: ".card-block-elements"
  // });
  // $('.dashboard .right-menu.list-view.duedate-sort .card-block-elements .card-outer').draggable({
 //    revert: "invalid",
 //    scroll: true,
 //    delay: /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent) ? 600 : void 0,
 //    start: function(e, ui) {
 //      $(ui.helper).css('z-index', '999999');
 //      return $(this).data('preventBehaviour', true);
 //    }
 //  }, $('.dashboard .list-view.duedate-sort .card-outer .todo .todo-input-text').on('mousedown', function(e) {
 //    var mdown;
 //    mdown = document.createEvent('MouseEvents');
 //    mdown.initMouseEvent('mousedown', true, true, window, 0, e.screenX, e.screenY, e.clientX, e.clientY, true, false, false, true, 0, null);
 //    $(this).closest('.card-outer')[0].dispatchEvent(mdown);
 //  }).on('click', function(e) {
 //    var $draggable;
 //    $draggable = $(this).closest('.card-outer');
 //    if ($draggable.data('preventBehaviour')) {
 //      e.preventDefault();
 //      $draggable.data('preventBehaviour', false);
 //    }
 //  }));
  //  $('.dashboard .right-menu.list-view.duedate-sort .card-block-elements').droppable({
  //   activeClass: 'ui-sortable-placeholder',
  //   accept: '.card-outer',
  //   drop: function(e, ui) {
  //     var added, assigned, card, card_block, card_id, duedate, duedate_time, project, requestdate, startdate, startdate_time, today, todo, todo_id, todo_list;
  //     added = false;
  //     todo = false;
  //     if (ui.draggable.hasClass('todo-outer')) {
  //       todo = true;
  //     }
  //     if (todo) {
  //       $(this).find('.todo-outer').each(function() {
  //         if ($(this).find('[data-type="container"]').attr('data-id') === $(ui.draggable).find('[data-type="container"]').attr('data-id')) {
  //           $(ui.draggable).insertBefore($(this)).attr('style', '').css('position', 'relative');
  //           return added = true;
  //         }
  //       });
  //     } else {
  //       $(this).find('.card-outer').each(function() {
  //         if ($(this).find('.card').attr('data-card-id') === $(ui.draggable).find('.card').attr('data-card-id')) {
  //           $(ui.draggable).insertBefore($(this)).attr('style', '').css('position', 'relative');
  //           return added = true;
  //         }
  //       });
  //     }
  //     if (!added) {
  //       $(ui.draggable).attr('style', '').css('position', 'relative').prependTo($(this));
  //       card_block = parseInt($(this).attr('data-dueblock'));
  //       today = dateFormat(new Date(), "isoDate");
  //       if (card_block === 0) {
  //         duedate = dateFormat(Breeze.addDays(new Date(today), -1), "isoDate");
  //       } else if (card_block === 1) {
  //         duedate = today;
  //       } else if (card_block === 2) {
  //         duedate = dateFormat(Breeze.addDays(new Date(today), 1), "isoDate");
  //       } else if (card_block === 3) {
  //         duedate = dateFormat(Breeze.addDays(new Date(today), 8), "isoDate");
  //       } else if (card_block === 4) {
  //         duedate = null;
  //       }
  //       if (todo) {
  //         todo = ui.draggable.find('[data-type="container"]');
  //         project = todo.attr('data-project-id');
  //         todo_list = todo.attr('data-todo-list-id');
  //         todo_id = todo.attr('data-id');
  //         card_id = todo.attr('data-tcard-id');
  //         assigned = todo.attr('data-assigned');
  //         Breeze.update_todo_pill(todo_id, duedate, assigned);
  //         todo.attr("data-duedate", duedate);
  //         return $.ajax({
  //           type: "PUT",
  //           url: "/projects/" + project + "/cards/" + card_id + "/todo_lists/" + todo_list + "/todos/" + todo_id,
  //           queue: true,
  //           data: {
  //             todo: {
  //               duedate: duedate
  //             }
  //           }
  //         });
  //       } else {
  //         project = ui.draggable.find('.card').attr('data-project-id');
  //         card = ui.draggable.find('.card');
  //         card_id = card.attr('data-card-id');
  //         requestdate = $(this).attr("data-requestdate") || null;
  //         startdate = $(this).attr("data-startdate") || null;
  //         startdate_time = $(this).attr("data-startdate-time") === "true";
  //         duedate_time = false;
  //         Breeze.update_card_calendar(startdate, duedate, card_id, startdate_time, duedate_time, requestdate);
  //         card.attr("data-duedate", dateFormat(duedate, "isoDate"));
  //         return $.ajax({
  //           type: "PUT",
  //           url: "/projects/" + project + "/cards/" + card_id,
  //           queue: true,
  //           data: {
  //             card: {
  //               duedate: duedate
  //             }
  //           }
  //         });
  //       }
  //     }
  //   }
  // });
};

// ---
// generated by coffee-script 1.9.2