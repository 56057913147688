import PullToRefresh from 'mobile/utils/pullrefresh.js';

function init_pull_to_refresh() {
	if (window.navigator.standalone) {
		const ptr = PullToRefresh.init({
			distMax: 100,
			iconArrow: '<div class="m-loader"><svg viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none" stroke-width="3.6" stroke-miterlimit="10"></circle></svg></div>',
			iconRefreshing: '<div class="m-loader"><svg viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none" stroke-width="3.6" stroke-miterlimit="10"></circle></svg></div>',
			instructionsPullToRefresh: ' ',
			instructionsReleaseToRefresh: ' ',
			instructionsRefreshing: ' ',
		  onRefresh() {
		    window.location.reload();
		  }
		});
	}


}
export {init_pull_to_refresh};
