var init_stages_automation, update_stage_card,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page, stage_id) {
    if (page === "stages_automation_popovers") {
      return init_stages_automation(stage_id);
    }
  });
  return $(document).on("breeze:page", function(e, page, card_id, stage_id, old_stage_id, card) {
    if (page === "update_stage_card") {
      return update_stage_card(card_id, stage_id, old_stage_id, card);
    }
  });
});

init_stages_automation = function(stage_id) {
  return $('#stage-automation-menu').on("click", ".user-select", function(event) {
    var content, el, i, len, ref, ref1, selected, stage_obj, tag_el, tags_clear, update_stage_users, user;
    el = $('#stage-automation-menu');
    if ($('#stage-tags-menu').length > 0) {
      el.popover("hide");
      $('#stage-tags-menu').remove();
    } else {
      el.removeData("bs.popover");
      $('#stage-tags-menu').remove();
      stage_obj = _.find(Breeze.project_stages, function(s) {
        return s.id === parseInt(stage_id);
      });
      tag_el = '';
      ref = Breeze.project_users;
      for (i = 0, len = ref.length; i < len; i++) {
        user = ref[i];
        selected = '';
        if (stage_obj.actions.user.email && (ref1 = String(user.email), indexOf.call(stage_obj.actions.user.email, ref1) >= 0)) {
          selected = 'selected';
        }
        tag_el += '<option value="' + user.email + '" ' + selected + ' >' + user.email + '</option>';
      }
      tags_clear = "";
      if (stage_obj.actions.user.users_clear) {
        tags_clear = 'checked="checked"';
      }
      content = '<div> <div class="popover-header"><i class="fa fa-angle-left" aria-hidden="true"></i><span class="title">Users</span>' + Breeze.global_close + '</div> <div class="fields-tags-wrapper styled-multiselect"> <select class="fields-users-select badge-multiselect" multiple="multiple" name="tags[]"> <option value="all">Select all</option>' + tag_el + '</select> </div> <div class="clear-automatic"> <label class="m-label">Clear old users from task <input ' + tags_clear + ' name="tags_clear" type="checkbox" value="1"> <span class="m-checkmark"></span> </label> </div> </div>';
      el.popover({
        trigger: "manual",
        container: "element",
        placement: "topleft",
        template: '<div class="popover stage-automation-menu-sub" id="stage-tags-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
        html: true,
        content: content
      });
      $('#stage-automation-menu').hide();
      el.popover("show");
      $('#stage-tags-menu').on("click", ".fa", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $('#stage-tags-menu').remove();
        $('#stage-automation-menu').show();
        return false;
      });
      $('#stage-tags-menu').on("click", "svg", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $(".stage-settings.dropdown").removeClass("open");
        $('#stage-tags-menu').remove();
        $('#stage-automation-menu').remove();
        $('.popover').remove();
        return false;
      });
      $('#stage-tags-menu').on("click", ".clear-automatic input", function() {
        return update_stage_users();
      });
      $('#stage-tags-menu').on("click", function(e) {
        return e.stopPropagation();
      });
      $('#stage-tags-menu .fields-users-select').multiselect({
        numberDisplayed: 0,
        includeSelectAllOption: true,
        includeSelectAllIfMoreThan: 3,
        selectAllValue: "all",
        nonSelectedText: "Users",
        buttonClass: 'btn btn-link',
        nSelectedText: '',
        nSelectedText1: 'Users',
        enableFiltering: true,
        enableCaseInsensitiveFiltering: true,
        onInitialized: function(select, container) {
          return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
        },
        onSelectAll: function() {
          return update_stage_users();
        },
        onDeselectAll: function() {
          return update_stage_users();
        },
        onChange: function(option, checked, select) {
          return update_stage_users();
        }
      });
      update_stage_users = function() {
        var socket_params, users, users_clear;
        users = $('#stage-tags-menu .fields-users-select').val();
        users_clear = $('#stage-tags-menu .clear-automatic input').prop("checked");
        stage_obj.actions.user.email = users;
        stage_obj.actions.user.users_clear = users_clear;
        socket_params = {
          project: Breeze.project_token,
          method: "stage-update-js",
          message: Breeze.project_stages
        };
        if ((users != null ? users.length : void 0) > 0) {
          $('#stage-automation-menu .user-select .selected-count').text(users != null ? users.length : void 0).removeClass("clear");
        } else if (users_clear) {
          $('#stage-automation-menu .user-select .selected-count').html("clear").addClass("clear");
        } else {
          $('#stage-automation-menu .user-select .selected-count').text("").removeClass("clear");
        }
        return $.ajax({
          type: "PUT",
          url: '/stages/' + stage_id,
          queue: true,
          data: {
            project_id: $("#card_project_id").val(),
            users: users,
            users_clear: users_clear
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
      };
    }
    return false;
  });
};

update_stage_card = function(card_id, stage_id, old_stage_id, card) {
  var avatar, avatar_img, color, email, i, initials, len, name, old_stage_obj, ref, ref1, ref2, ref3, ref4, ref5, res, results, stage_obj, t;
  if (old_stage_id !== stage_id) {
    stage_obj = _.find(Breeze.project_stages, function(s) {
      return s.id === parseInt(stage_id);
    });
    old_stage_obj = _.find(Breeze.project_stages, function(s) {
      return s.id === parseInt(old_stage_id);
    });
    if ((stage_obj != null ? (ref = stage_obj.actions) != null ? (ref1 = ref.user) != null ? (ref2 = ref1.email) != null ? ref2.length : void 0 : void 0 : void 0 : void 0) > 0) {
      if (stage_obj.actions.user.users_clear) {
        $('.card[data-card-id="' + card_id + '"] .card_avatars  .member').remove();
      }
      ref3 = stage_obj.actions.user.email;
      results = [];
      for (i = 0, len = ref3.length; i < len; i++) {
        t = ref3[i];
        email = t;
        if ($('.card[data-card-id="' + card_id + '"] .card_avatars .member[data-card-user-email="' + t + '"]').length > 0) {
          continue;
        }
        res = $.grep(Breeze.project_users, function(e) {
          return e.email === email;
        });
        avatar = null;
        if (res.length > 0) {
          avatar = res[0].avatar;
        }
        avatar_img = $.gravatar(email, avatar, {
          size: 80,
          image: "blank",
          opt: "class=\"member_avatar\" title=\"" + name + "\""
        });
        color = res[0] ? res[0].color : "";
        initials = res[0] ? res[0].initials : "";
        name = res[0] ? res[0].name : "";
        $('.card[data-card-id="' + card_id + '"] .card_avatars').append('<div class="member" data-card-user-email="' + email + '" data-card-user-name="' + name + '" style="background-color:#' + color + ';">' + avatar_img + '<p class="member_initials">' + initials + '</p> </div>');
        if (Breeze.Card.id === card_id) {
          if ($(".card-content .assigned-people").length === 0) {
            $('<div class="row"><div class="span8 card-attributes assigned-people" data-readonly="false"><h5>People assigned</h5></div></div>').insertAfter(".card-header");
          }
          results.push($(".card-content .assigned-people").append('<span class="pill" data-user-email="' + email + '">' + name + '<span class="remove"> &times;</span></span>'));
        } else {
          results.push(void 0);
        }
      }
      return results;
    } else if (stage_obj != null ? (ref4 = stage_obj.actions) != null ? (ref5 = ref4.user) != null ? ref5.users_clear : void 0 : void 0 : void 0) {
      return $('.card[data-card-id="' + card_id + '"] .card_avatars .member').remove();
    }
  }
};

// ---
// generated by coffee-script 1.9.2