import {} from 'mobile/utils/gravatar.js';
import {developement,load_card_globals} from 'mobile/utils.js';

import moment from "mobile/moment.min.js";

var fetch_page, init_calendar_datepicker, init_calendar_modal_users, init_page, home_calendar_day_update;
var dateFormat = require('dateformat');

$(document).ready(function() {
  $(document).on("breeze:page:home", function() {
    init_page();
  });
  if ($('.workspace.home').length > 0) {
    init_page();
    if (window.openBreezeDashboardCard != null) {
      window.openBreezeDashboardCard();
    }
  }
  if ($('.workspace.home-empty').length > 0) {
    return fetch_page();
  }
});

fetch_page = function() {
  var has_card_modal, path, url;
  if ($('.workspace.home-empty').length > 0) {
    if (location.pathname === "/") {
      path = "/home";
    } else {
      path = location.pathname;
    }
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006";
    } else {
      url = "https://breeze-node-n3.herokuapp.com";
    }
    has_card_modal = $('#card_modal').length > 0;
    return $.ajax({
      type: 'GET',
      url: url + path,
      data: {
        auth_token: Breeze.current_user.token,
        partial: true,
				phone: true
      },
      beforeSend: function() {
        return $('.workspace').append(Breeze.global_loader);
      },
      success: function(data) {
        var new_classes;
        new_classes = $(data).attr("class");
        $(".workspace").removeClass('home-empty');
        $(".workspace").addClass(new_classes);
        $(".workspace").html($(data).html());
        $(document).trigger("breeze:page:home");
        $(document).trigger("breeze:page", "comments");
        $(document).trigger("breeze:page", "time_entries");
        $(document).trigger("breeze:page", "todo_lists");
        $(document).trigger("breeze:page", "todos");
        $(document).trigger("breeze:page", "card_move_short");
        if (!has_card_modal) {
          $(document).trigger("breeze:page", "card_page");
        }
        if (window.openBreezeDashboardCard != null) {
          window.openBreezeDashboardCard();
        }
        return $(document).trigger("breeze:page", "update-global-counters");
      }
    });
  }
};

init_page = function() {
  // Breeze.update_project_list_view_columns();
  // Breeze.update_dashboard_list_width();
  // $('.card').show_overdue_tasks();
  // Breeze.init_fileupload();
	// console.log("iii")
  $('.home').on("click", ".home-calendar-filters-btn li a", function(event) {
    var is_active, url;
    $(this).toggleClass("active");
    is_active = $(this).hasClass("active");
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006";
    } else {
      url = "https://breeze-node-n7.herokuapp.com";
    }
    $.ajax({
      type: 'POST',
      url: url + "/profile?auth_token=" + Breeze.current_user.token,
      data: {
        user: {
          home_show_project_event: $('.home-calendar-filters-projects a').hasClass("active"),
          home_show_task_event: $('.home-calendar-filters-tasks a').hasClass("active"),
          home_show_task_recurring_event: $('.home-calendar-filters-tasks-recurring a').hasClass("active"),
          home_show_todo_event: $('.home-calendar-filters-todos a').hasClass("active"),
          home_show_estimate_event: $('.home-calendar-filters-estimates a').hasClass("active"),
					phone: true
        }
      },
      success: function() {
        var date;
        Breeze.update_month_calendar();
        date = $("#home-calendar-list .home-calendar-day-header").attr("data-home-day-date");
        return $('.home .simple-calendar .home-calendar-day[data-date="' + date + '"]').trigger("click");
      }
    });
    return false;
  });
  $('.home').on("click", ".simple-calendar .day", function() {
    var el, open_card_id, start_date, url;
    $(".simple-calendar .day").removeClass("day-active");
    $(".simple-calendar .today").addClass("day-not-active");
    $(this).addClass("day-active");
    el = $(this);
    start_date = el.find(".home-calendar-day").attr("data-date");
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/home/day";
    } else {
      url = "https://breeze-node-n3.herokuapp.com/home/day";
    }
    $('#home-calendar-list > div').css("opacity", "0.6");
    open_card_id = $('#home-calendar').attr('data-home-open-card-id');
    return $.ajax({
      type: 'GET',
      url: url,
      queue: true,
      data: {
        auth_token: Breeze.current_user.token,
        start_date: start_date,
        card_id: open_card_id,
				phone: true
      },
      beforeSend: function() {
        window.clearTimeout(Breeze.calendar_loading);
        Breeze.calendar_loading = window.setTimeout((function() {
          $('#home-calendar-list > div').css("opacity", "0");
          $('#home-calendar-list').append(Breeze.global_loader);
        }), 400);
      },
      success: function(data) {
        $('#home-calendar').removeAttr('data-home-open-card-id');
        window.clearTimeout(Breeze.calendar_loading);
        $("#home-calendar-list").html(data);
        $('.home-calendar-day-body-entry[data-entry-type="card"][data-card-id="' + open_card_id + '"] .home-calendar-day-body-entry-name').trigger("click");
        return open_card_id = null;
      }
    });
  });
  $('.home .simple-calendar .today').trigger("click");
  $('.home').on("click", ".simple-calendar .calendar-heading a", function() {
    var el, url;
    el = $(this);
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006" + el.attr("href");
    } else {
      url = "https://breeze-node-n3.herokuapp.com" + el.attr("href");
    }
    $('.simple-calendar').addClass("loading-bg");
    $.ajax({
      type: 'GET',
      url: url,
      queue: true,
      data: {
        auth_token: Breeze.current_user.token,
				phone: true
      },
      success: function(data) {
        $('.simple-calendar').removeClass("loading-bg");
        $('.home .simple-calendar').html($(data).find(".simple-calendar").html());
      }
    });
    return false;
  });
  $('.home').on("click", ".home-calendar-day-body-entry-footer a", function(e) {
    var url;
    url = $(this).attr("href");
    // History.pushState({
    //   workspace: null,
    //   url: url,
    //   card_modal: true,
    //   todo_link: true
    // }, $("title").html(), url);
    // return false;
  });
  $('.home').on("click", ".home-calendar-day-body-entry-name, .home-calendar-day-body-entry-time", function() {
    var card_id, el, project_id, title, users;
    el = $(this).closest('.home-calendar-day-body-entry');
    title = el.find('.home-calendar-day-body-entry-name').text();
    $(".autosizejs").remove();
    $('#event-modal .event-links-box a').remove();
    $('#event-modal .event-users-box').show();
    Breeze.Card.id = card_id = parseInt(el.attr("data-card-id"));
    Breeze.Card.project = project_id = el.attr("data-project-id");
		
		Breeze.project_users = Breeze.all_team_users;
		
    if (el.attr("data-entry-type") === "card") {
      if ($('.project-cards .card[data-card-id="' + Breeze.Card.id + '"]').length <= 0) {
        $('.dummy-modal').modal({
          dynamic: true
        });
        $('.dummy-modal').modal('show');
        $.ajax({
          type: "GET",
          url: '/projects/' + project_id + '/cards/' + card_id,
          data: {
            calendar: true
          },
          success: function(data) {
            $('.dummy-modal').modal('hide');
            $('.project-cards').append(data);
            $('.project-cards .card[data-card-id="' + card_id + '"]').trigger("click");
          }
        });
      } else {
        $('.project-cards .card[data-card-id="' + card_id + '"]').trigger("click");
      }
    } else {
      $('#event-modal').modal({
        dynamic: true
      });
      $('#event-modal').modal('show');
      $("#event-modal .event-name").html(title).val(title).autosize().trigger('autosize.resize');
      Breeze.calendar_event = {};
      Breeze.calendar_event.project = project_id;
      Breeze.calendar_event.card_id = card_id;
      Breeze.calendar_event.end_real = el.attr("data-duedate");
      Breeze.calendar_event.start_real = el.attr("data-startdate");
      if (el.attr("data-entry-type") === "todo") {
        Breeze.calendar_event.todo = true;
        Breeze.calendar_event.id = el.attr("data-todo-id");
        $('#event-modal').attr("data-modal-type", "todo");
        $('#event-modal .event-actions-box').attr("data-modal-type", "todo");
        $("#event-modal .header .open").attr("href", "/projects/" + project_id + "/cards/" + card_id);
        $('<a href="/projects/' + project_id + '" class="board-link" data-partial="true" data-scroll="true">' + el.attr("data-project-name") + '</a>').insertAfter($("#event-modal .sub-head-project"));
        $('<a href="/projects/' + project_id + '/cards/' + card_id + '" class="board-link" data-partial="true" data-scroll="true">' + el.attr("data-card-name") + '</a>').insertAfter($("#event-modal .sub-head-card"));
        $('.event-dates-box .end').html("Due date");
        if (el.attr("data-todo-done") === "true") {
          $('#event-modal [data-behavior="toggle"]').attr('checked', 'checked');
        } else {
          $('#event-modal [data-behavior="toggle"]').removeAttr('checked');
        }
        $("#event-modal .event-estimate-input").val("");
        if (parseInt(el.attr("data-todo-estimate")) > 0) {
          $("#event-modal .event-estimate-input").val(Breeze.Time.formatPretty(el.attr("data-todo-estimate")));
        }
        users = el.data("todo-user-ids");
        Breeze.calendar_event.assigned_id = users;
        init_calendar_modal_users(users);
      } else if (el.attr("data-entry-type") === "estimate") {
        Breeze.calendar_event.estimate = true;
        Breeze.calendar_event.id = el.attr("data-estimate-id");
        $('#event-modal').attr("data-modal-type", "estimate");
        $('#event-modal .event-actions-box').attr("data-modal-type", "estimate");
        $("#event-modal .header .open").attr("href", "/projects/" + project_id + "/cards/" + card_id);
        $('<a href="/projects/' + project_id + '" class="board-link" data-partial="true" data-scroll="true">' + el.attr("data-project-name") + '</a>').insertAfter($("#event-modal .sub-head-project"));
        $('<a href="/projects/' + project_id + '/cards/' + card_id + '" class="board-link" data-partial="true" data-scroll="true">' + el.attr("data-card-name") + '</a>').insertAfter($("#event-modal .sub-head-card"));
        $('.event-dates-box .end').html("Date");
        $("#event-modal .event-estimate-input").val("");
        if (parseInt(el.attr("data-estimate-amount")) > 0) {
          $("#event-modal .event-estimate-input").val(Breeze.Time.formatPretty(el.attr("data-estimate-amount")));
        }
      } else if (el.attr("data-entry-type") === "project") {
        Breeze.calendar_event.id = project_id;
        $('#event-modal').attr("data-modal-type", "project");
        $('#event-modal .event-actions-box').attr("data-modal-type", "project");
        $("#event-modal .header .open").attr("href", "/projects/" + project_id);
        if (!Breeze.current_user.admin) {
          $('#event-modal .event-users-box').hide();
        }
        users = el.data("project-user-ids");
        init_calendar_modal_users(users);
      } else {
        $("#event-modal .header .open").attr("href", event.curl);
        $('#event-modal').attr("data-modal-type", "card");
        $('#event-modal .event-actions-box').attr("data-modal-type", "card");
        Breeze.init_calendar_modal_projects(event);
      }
      init_calendar_datepicker();
      Breeze.calendar_event.start_real = moment(Breeze.calendar_event.start_real);
      $('#event-modal .dateinput.start-date').datepicker('setDate', Breeze.calendar_event.start_real.toDate());
      $('#event-modal .dateinput-display.start-date-display').val(dateFormat(Breeze.calendar_event.start_real.toDate(), "mmmm d"));
      Breeze.calendar_event.end_real = moment(Breeze.calendar_event.end_real);
      $('#event-modal .dateinput.due-date').datepicker('setDate', Breeze.calendar_event.end_real.toDate());
      $('#event-modal .dateinput-display.due-date-display').val(dateFormat(Breeze.calendar_event.end_real.toDate(), "mmmm d"));
    }
    return false;
  });
};

init_calendar_datepicker = function() {
  $('#event-modal .dateinput').datepicker('destroy');
  $('#event-modal .dateinput').datepicker({
    showOtherMonths: true,
    selectOtherMonths: true,
    dateFormat: "yy-mm-dd",
    altFormat: "yy-mm-dd",
    constrainInput: true,
    prevText: "&larr;",
    nextText: '&rarr;',
    showAnim: "",
    firstDay: Breeze.first_day,
    onSelect: function(dateText, inst) {
      if ($(this).hasClass("start-date")) {
        Breeze.calendar_event.nostart = false;
        Breeze.calendar_event.start_real = dateText;
        $('#event-modal .dateinput-display.start-date-display').val(dateFormat(moment(dateText).toDate(), "mmmm d"));
      } else {
        Breeze.calendar_event.noend = false;
        Breeze.calendar_event.end_real = dateText;
        $('#event-modal .dateinput-display.due-date-display').val(dateFormat(moment(dateText).toDate(), "mmmm d"));
      }
    }
  });
};

init_calendar_modal_users = function(users) {
  var el, i, id, j, len, u;
  if (users != null) {
    for (i = j = 0, len = users.length; j < len; i = ++j) {
      id = users[i];
      u = _.find(Breeze.all_team_users, function(e) {
        return e.id === parseInt(id);
      });
      if (u) {
        el = $('<div class="member" data-user-id="' + u.email + '"  data-member-id="' + u.id + '" style="background-color:#' + u.color + '"> <img alt="" width="30" height="30" class="member_avatar" src="' + u.avatar_url + '" title="' + u.name + '"><p class="member_initials">' + u.initials + '</p> </div>');
        $("#event-modal .event-users-box .sub-head-users").append(el);
      }
    }
  }
  el = $('<div class="member member-add" style="background-color:#"> <p class="member_initials"><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class=""><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg></p> </div>');
   $("#event-modal .event-users-box .sub-head-users").append(el);
};

Breeze.home_calendar_day_update = function(card_id) {
  var card, card_footer, el, home_duedate, home_duedate_time, home_recurring_rule_changed, home_startdate, home_startdate_time, link_text, start_date, title, url;
  card = $('.card[data-card-id="' + card_id + '"]');
  el = $('.home-calendar-day-body-entry[data-entry-type="card"][data-card-id="' + card_id + '"]');
  title = $("#card_modal .card-name").text();
  el.find('.home-calendar-day-body-entry-name').text(title);
  card_footer = card.find('.card-footer');
  if (card.attr("data-swimlane-id") && parseInt(card.attr("data-swimlane-name")) !== 0) {
    link_text = card_footer.text() + " > " + card.attr("data-swimlane-name") + " > " + card.attr("data-stage-name");
  } else {
    link_text = card_footer.text() + " > " + card.attr("data-stage-name");
  }
  el.find('.home-calendar-day-body-entry-footer a').attr("href", card_footer.attr("href") + "/cards/" + card_id).text(link_text);
  home_startdate = card.attr("data-startdate") || null;
  home_duedate = card.attr("data-duedate") || null;
  home_startdate_time = card.attr("data-startdate-time") === "true";
  home_duedate_time = card.attr("data-duedate-time") === "true";
  home_recurring_rule_changed = card.attr("data-recurring-changed") === "true";
  if ((Breeze.Card.home_startdate !== home_startdate) || (Breeze.Card.home_duedate !== home_duedate) || (Breeze.Card.home_startdate_time !== home_startdate_time) || (Breeze.Card.home_duedate_time !== home_duedate_time) || home_recurring_rule_changed) {
    Breeze.update_month_calendar();
    card.removeAttr("data-recurring-changed");
    start_date = $("#home-calendar-list .home-calendar-day-header").attr("data-home-day-date");
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/home/day";
    } else {
      url = "https://breeze-node-n3.herokuapp.com/home/day";
    }
    return $.ajax({
      type: 'GET',
      url: url,
      queue: true,
      data: {
        auth_token: Breeze.current_user.token,
        start_date: start_date,
				phone: true
      },
      success: function(data) {
        return $("#home-calendar-list").html(data);
      }
    });
  }
};
