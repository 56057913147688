
(function($){
	$.extend({
		__stringPrototype: function(str){
			var splitCheck = ("a b".split(/\w/)[0] == " "); // test for crappy IE matching (feature sniffing instead of version sniffing)
			function makeRegExpGlobal(p) {
				if (!p.source) { return p; }
				var mods = "g"+((p.ignoreCase)?"i":"")+((p.multiline)?"m":"");
				return new RegExp(p.source, mods);
			}

			this.str = str;



			/**
						 * Returns a string with the first count occurances of pattern replaced by either a regular string
						 * or the returned value of a function.
						 * @param {Object} pattern RegEx pattern or string to replace
						 * @param {Object} replacement string or function to replace matched patterns
						 * @param {Integer} count number of (default = 1, -1 replaces all)
						 * @param {String} s string to be evaluated
						 * @return {Object} .string object (or string if internal)
						 */
						this.sub = function(pattern, replacement, count, s) {
							// console.log(this)
							s = this.s(s);
							count = (!count)?1:count;
							if (count < 0 || isNaN(count)) { return this; }
							// make RegExp global
							pattern = makeRegExpGlobal(pattern);
							var sarray = s.split(pattern), matches = s.match(pattern);
							if (splitCheck && typeof(pattern) == "object") {
								if (count == matches.length) ++count;
								if (s.indexOf(matches[0]) == 0) sarray.unshift("");
								if (s.lastIndexOf(matches[matches.length-1]) == s.length - matches[matches.length-1].length) sarray.push("");
							}
							s = sarray[0];
							for (var i=1; i<sarray.length; i++) {
								if (i <= count) {
									if ($.isFunction(replacement)) {
										s += replacement(matches[i-1] || matches) + sarray[i];
									} else { s += replacement + sarray[i]; }
								} else { s += (matches[i-1] || matches) + sarray[i]; }
							}
							this.str = s;
							return this;
						};
						
/**
			 * value -- convenience method to return .str
			 */
			this.value = function() {
				return this.str;
			};
			/**
			 * fetch str internally if no parameter is given
			 */
			this.s = function(s) {
				return (s)?s:this.str;
			};

		},
		string: function(str) {
			if (str === String.prototype) { $.extend(String.prototype, new $.__stringPrototype()); }
			else { return new $.__stringPrototype(str); }
		}
	});
	$.__stringPrototype.parseQuery = $.__stringPrototype.toQueryParams;
})(jQuery);



function toPaddedString(number,length, radix) {
  var string = number.toString(radix || 10);
  return times('0',length - string.length) + string;
}

function times(number,count) {
    return count < 1 ? '' : new Array(count + 1).join(number);
  }



// var Breeze = {};
// var Breeze = Breeze || {}

// Breeze.Card = Breeze.Card || {};


Breeze.Time = {
  VALID_TIME_STRING:  /^[0-9]{0,7}[:,.]?[0-9]{0,2}(M|m|min|mins|minute|minutes|H|h|hr|hrs|hour|hours|D|d|day|days)?$/,
  VALID_TIME_OF_DAY:  /^(0?[0-9]|1[0-9]|2[0-3])(:[0-9]{0,2})?(am|pm)?|noon|midnight$/,
  VALID_MONTH_NAMES: /^(jan(?:uary)?|feb(?:ruary)?|mar(?:ch)?|apr(?:il)?|may|jun(?:e)?|jul(:?y)?|aug(?:ust)?|sep(?:ember)?|oct(?:ober)?|nov(:ember)?|dec(?:ember)?)/,
  DAY_OF_WEEK: 'su mo tu we th fr sa su',
  MONTH_NAMES: 'jan feb mar apr may jun jul aug sep oct nov dec',
  DATE_PART: /^((jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec|mon|tue|wed|thu|fri|sat|sun|t|today|y|yd|yesterday)[a-z]*\s+\d{1,2}|(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec|mon|tue|wed|thu|fri|sat|sun|t|today|y|yd|yesterday)[a-z]*|\d{1,2}\/\d{1,2}|\d{1,2}\/\d{1,2}\/\d{2,4})[\s,]/,

  now: function(){
    return new Date;
  },

  valueWithoutDate: function(string){
    return $.string(string.toLowerCase()).sub(Breeze.Time.DATE_PART, '').replace(/^(\s|,)+/, '');
	// return string;
  },

  parseTimeWithDate: function(string, stepping){
    return({
      time: Breeze.Time.parse(Breeze.Time.valueWithoutDate(string), stepping),
      date: Breeze.Time.parseDate(string)
    });
  },


  parseDate: function(string){
    var parts = $.trim(string).replace(/\s\s/,' ').replace(/\s,\s/,',').replace(/\s,/,',').split(/,|\s/).reject(function(p){ return p === "" }),
         date = "", now = Breeze.Time.now(), year = now.getFullYear(), month, day, dayOfWeek;

    if(/^[0-9\.]+\s(H|h|hr|hrs|hours|D|d|day|days)$/.test(string)) return null;
    if(string==="") return null;
    if(parts.length==1) return null;

    if(parts.length<=2)
      date = parts[0];
    else if(parts.length>2)
      date = Breeze.Time.VALID_MONTH_NAMES.test($.trim(parts[0].toLowerCase())) ? parts[0]+' '+parts[1] : parts[0];
    else return null;
    date = $.trim(date.replace(/\s/,' ').toLowerCase());

    if(/^([1-9]|10|11|12)\/([1-9]|1[0-9]|2[0-9]|3[0-1])(\/(2[0-9]{3}|[0-9]{2}))?$/.test(date)){
      parts = date.split(/\//);
      if(parts.length == 3){
        year = parts[2].length==4 ? parts[2] : '20'+parts[2];
      }
      month = parts[0];
      day   = parts[1];
      if(parts.length < 3 && (new Date(year, month-1, day)) > now.addDays(30)) year--;
    }
    else if(/^([1-9]|2[0-9]|3[0-1])\.([1-9]|10|11|12)\.?$/.test(date)){
      parts = date.split(/\./);
      month = parts[1].replace(/[^0-9]/, ''); // filter trailing .
      day   = parts[0];
      if((new Date(year, month-1, day)) > now.addDays(30)) year--;
    }
    else if(Breeze.Time.VALID_MONTH_NAMES.test(date)){
      parts = date.split(/\s/);
      month = Breeze.Time.MONTH_NAMES.indexOf(parts[0].substr(0,3))+1;
      day   = parts[1];
      if((new Date(year, month-1, day)) > now.addDays(30)) year--;
    }
    else if(/^(today|t|yesterday|yd|y)$/.test(date)){
      date = (date=='today' || date=='t') ? now : now.addDays(-1);
      day = date.getDate();
      month = date.getMonth()+1;
      year = date.getFullYear();
    }
    else if(/^su|sun|sunday|mo|mon|monday|tu|tue|tuesday|we|wed|wednesday|th|thu|thursday|fr|fri|friday|sa|sat|saturday$/.test(date)){
      dayOfWeek = Breeze.Time.DAY_OF_WEEK.indexOf(date[0]+date[1]);
      date = now.addDays(-1);
      while(date.getDay() != dayOfWeek) date = date.addDays(-1);
      day = date.getDate();
      month = date.getMonth()+1;
      year = date.getFullYear();
    }
    else {
      return null;
    }

    if(typeof day === 'undefined') return null;
    if(typeof month === 'undefined') return null;

    return new Date(year, month-1, day);
  },

  timeToMinutesExtended: function(time){
    var shift = 0, hours = 0, minutes = 0, am = false, max12 = false;
    time = $.trim(time).toLowerCase().replace(/\s/,'');

    if(!Breeze.Time.VALID_TIME_OF_DAY.test(time)) return null;

    if(/pm$/.test(time)) { shift = 12; max12 = true; }
    if(/am$/.test(time)) { am = true; max12 = true; }
    time = time.replace(/am|pm/,'');

    if('noon' == time) return { max12: false, min: 12*60};
    if('midnight' == time) return { max12: false, min: 0};

    if(/:/.test(time)){
      hours   = parseInt(time.split(':')[0]);
      minutes = parseInt(time.split(':')[1]);
    } else {
      hours   = parseInt(time);
    }

    if(max12 && hours > 12) return null;
    hours += shift;
    if(hours == 24) { hours = 12; max12 = true; }
    if(hours == 12 && am) hours = 0;

    if(hours>23 || minutes>59) return null;

    return { max12: max12, min: hours*60 + minutes};
  },

  timeToMinutes: function(time) {
    time = Breeze.Time.timeToMinutesExtended(time)
    return time==null ? null : time.min
  },

  parseTimeSpan: function(string){
    var parts = string.split(/-|to/), now, span, from, to;

    if(parts.length!=2) return null;

    if(parts[1] == "") {
      now = Breeze.Time.now();
      parts[1] = now.getHours()+':'+now.getMinutes();
    }

    from = Breeze.Time.timeToMinutesExtended(parts[0]),
    to = Breeze.Time.timeToMinutesExtended(parts[1]);
    if(from==null || to == null) return null;
    f12 = from.max12, t12 = to.max12;
    from = from.min, to = to.min;
    if(from==to && f12==t12) return null;

    if(from>=to) {
      if(f12 && t12) {
        if(to==0) to = 24*60
        else if(from<=(12*60)) to += 12*60
        else to+= 24*60;
      } else if(!f12 && t12) {
        to += 12*60;
      } else if(f12 && !t12) {
        if(from<=(12*60)) to+= 12*60
        else to+= 24*60
      } else if(!f12 && !t12) {
        if(to==0) to = 24*60
        else if(from<=(12*60)) to += 12*60
        else to+= 24*60;
      }
    } else {
      if(f12 && !t12) {
        if(from<=12*60) to += 12*60
      } else if(!f12 && t12) {
        to += 12*60;
      }
    };

    span = Math.abs(to-from);

    if(span == 0) return null;
    return span;
  },

  applyStepping: function(minutes, stepping) {
    return Math.ceil((minutes / stepping)) * stepping;
  },

  formatByPrefs: function(minutes){
    var format = (prefs||{})['timeFormat'] || 'hours_minutes';
    if(format == 'hours_minutes'){
      return Breeze.Time.format(minutes);
    } else {
      return Breeze.Time.formatFraction(minutes);
    }
  },

  format: function(minutes) {
    return (!_.isNumber(minutes) || _.isNaN(minutes)) ? false :
      Math.floor(minutes / 60) + ':' + toPaddedString((minutes % 60),2);
  },

  formatPretty: function(minutes) {
	  var  m = Math.floor(minutes%60)
      var  h =  Math.floor(minutes / 60)
		var d = Math.floor(minutes / (60*24))
	  if(m == 0)
	  	return h.toString()+"h"
	  else if (h == 0)
	  	 return m.toString()+"m"
	  else
	  	return h.toString()+"h"+m.toString()+"m"
		 
	  //     if(minutes >= 60){
	  //       // var o = {
	  //       // var  m = ['','Â¼','Â½','Â¾',':'+(toPaddedString((minutes%60),2))][minutes % 15 == 0 ? (minutes/15)%4 : 4]
	  // var  m = Math.floor(minutes%60)
	  //       var  h =  Math.floor(minutes / 60)
	  //       // };
	  //       return h.toString()+"h"+m.toString()+"m"
	  //     } else if(minutes == 0) {
	  //       return "none"
	  //     } else {
	  //       return minutes + "m"
	  //     }
  },

  formatPrettyDay: function(minutes) {
	  var  m = Math.floor(minutes%60)
      var  h =  Math.floor(minutes/60%24)
		var d = Math.floor(minutes/24/60)
		
		
		if (d > 0) {
	  	if (h == 0 && m==0)
				return d.toString()+"d"
			else if(h > 0 && m==0)
				return d.toString()+"d"+h.toString()+"h"
			else if(h == 0 && m > 0)
					return d.toString()+"d"+m.toString()+"m"	
			else
				return d.toString()+"d"+h.toString()+"h"+m.toString()+"m"

		}
		else if(h > 0 && m==0)
			return h.toString()+"h"
		else if(h == 0 && m > 0)
				return m.toString()+"m"	
		else if(h > 0 && m > 0)
				return h.toString()+"h"+m.toString()+"m"		
		else
			return "0m"

  },
  parseMinutes: function(string, stepping){
    if(typeof stepping == 'undefined') stepping = Breeze.Time.defaultIncrement || 15;
    if(typeof string == 'undefined' || string === null) return null;

    if(_.isNumber(string))
      return Breeze.Time.applyStepping(string, stepping);


    string = $.trim(string).replace(/\s/,'');
    if(string.length == 0) return null;
		
    if(string.match(/(\d+)[hH](\d+)([mM]|$)/)) { // 1h15m
		string = $.string(string).sub('h', ':')
		string = string.sub('H', ':')
		string = string.sub('m', '')
		string = string.sub('M', '')
		string = string.str
		
		}	

      // string = $.string($.string($.string($.string(string).sub('h', ':')).sub('H', ':')).sub('m', '')).sub('M', '');
    if(!string.match(Breeze.Time.VALID_TIME_STRING)) return null;
    if(!/[0-9]/.test(string)) return null;
    var factor, minutes;

    if (string.match(/:/)) {
			
      minutes = Breeze.Time.applyStepping(
        parseInt(string.split(':')[0]||0,10)*60 +
        (parseInt(string.split(':')[1].replace(/^0/,''),10) || 0), // prevent octal conversion
        stepping);

      return minutes;
    }
    if (string.match(/(M|m|min|mins|minute|minutes)$/)) factor = 1;
    if (string.match(/(H|h|hr|hrs|hour|hours)$/)) factor = 60;
    if (string.match(/(D|d|day|days)$/)) factor = 60 * 8;


    minutes = parseInt(string.replace(/[,\.].*$/, '').replace(/[^0-9]/, ''),10) || 0;
    // console.log(factor)
    if (!factor && minutes < 10) factor = 60;
    !factor && minutes >= 10 && string.match(/[,\.]/) && (factor = 60);
    
    // console.log(factor)
    
    re = string.match(/[,\.]([0-9]|[0-9][0-9])(M|m|min|mins|minute|minutes|H|h|hr|hrs|hour|hours|D|d|day|days)?$/)
    if(re) {
      var f = parseInt(re[1],10);
      minutes = minutes * factor + (Math.round(factor * (re[1].length > 1 ? f/100 : f/10)));
      factor = 1;      
    }

    minutes *= (factor || 1);
    minutes = Breeze.Time.applyStepping(minutes, stepping);

    return minutes;
  },

  parse: function(string, stepping) {
    var span = Breeze.Time.parseTimeSpan(string),
      minutes = Breeze.Time.parseMinutes(span !== null ? span : string, stepping);
    return minutes === null ? null : Breeze.Time.format(minutes);
  },



};