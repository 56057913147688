$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "card_modal") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});
function init_page() {
  // $(document).on("click", '.card-content .tags[data-readonly="false"] .pill', function() {
  //   var card_popover, tag_color, tag_color_space, tag_name;
  //   if ($('#card-tag-menu').length <= 0 && ($(this).data('popover') != null)) {
  //     $(this).removeData('bs.popover');
  //   }
  //   if ($(this).data('popover') != null) {
  //     $(this).popover("hide");
  //     $(this).removeData('bs.popover');
  //   } else {
  //     $("#card-tag-menu").remove();
  //     $('.card-content .tags .pill .name').removeData('bs.popover');
  //     $('.card-content .tags .pill .remove').removeData('bs.popover');
  //     tag_name = $(this).attr('data-tag');
  //     tag_color_space = $(this).css('background-color');
  //     tag_color = tag_color_space.replace(/\s/g, '');
  // 			card_popover = `<div>
  // 			<form accept-charset="UTF-8" action="/tags" data-remote="true" id="global-card-tag" method="put">
  // 			  						<div class="deps-group">
  // 												<input type="hidden" name="tag" value="'+tag_name+'">
  //
  // 												<input type="hidden" name="color" value="'+tag_color+'">
  // 												<ul class="color-list-tag card-tag-color">
  // 													<li><span class="color" style="background:rgb(213,0,0);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,45,85);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,59,48);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,64,129);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,138,128);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(129,112,194);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(124,77,255);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(170,0,255);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(213,0,249);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(234,128,252);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(88,86,214);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(66,133,244);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(48,79,254);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(0,122,255);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(68,138,255);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(140,158,255);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(52,170,220);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(90,200,250);"><i class="check icon-checkmark-2"></i></span> </li>
  // 													<li><span class="color" style="background:rgb(76,175,80);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(102,187,106);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(76,217,100);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(0,230,118);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(118,255,3);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(174,234,0);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(247,233,142);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,223,0);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(238,238,0);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,204,0);"><i class="check icon-checkmark-2"></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,149,0);"><i class="check icon-checkmark-2"></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,112,67);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(255,109,0);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(142,142,147);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(196,178,143);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(188,170,164);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(199,199,204);"><i class="check icon-checkmark-2 "></i></span> </li>
  // 													<li><span class="color" style="background:rgb(102,102,102);"><i class="check icon-checkmark-2 "></i></span> </li>
  //
  // 												</ul>
  //
  // 			  						</div>
  //
  //
  // 			</form>
  //
  // 				</div>
  // 							`
  //
  //     $(this).popover({
  //       trigger: "manual",
  //       placement: "bottom",
  //       template: '<div class="popover tags-popover tags-popover-colors" id="card-tag-menu"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
  //       html: true,
  //       content: card_popover
  //     });
  //     $(this).popover("show");
  //     $('.tags-popover .color-list-tag .color[style="background:' + tag_color + ';"] .check').addClass('show');
  //     $('.tags-popover .color-list-tag .color[style="background: ' + tag_color_space + ';"] .check').addClass('show');
  //     $('.tags-popover').on("click", ".color", function() {
  //       var color, socket_params, tag_e, tag_el;
  //       $(this).find(".check").toggleClass("show");
  //       $(this).parent().siblings().find(".check").removeClass("show");
  //       tag_name = $('#global-card-tag input[name="tag"]').val();
  //       tag_e = $('.tags-popover .check.show').parent();
  //       color = tag_e.css('background-color') || "";
  //       if (color) {
  //         color = color.replace(/\s/g, '');
  //       }
  //       tag_el = _.find(Breeze.project_tags, function(tag) {
  //         return tag.name === tag_name;
  //       });
  //       if (tag_el) {
  //         tag_el.color = color;
  //       }
  //       $('.pill[data-tag="' + tag_name + '"]').attr('style', 'background:' + color + ';');
  //       socket_params = {
  //         project: Breeze.project_token,
  //         method: "card-tag-color",
  //         color: color,
  //         message: tag_name
  //       };
  //       $.ajax({
  //         type: "PUT",
  //         queue: true,
  //         url: '/tags',
  //         data: {
  //           tag: tag_name,
  //           old_tag: tag_name,
  //           color: color
  //         },
  //         success: function() {
  //           if (Breeze.is_socket_connected()) {
  //             return Breeze.socket.emit("message", socket_params);
  //           }
  //         }
  //       });
  //       $("#card-tag-menu").remove();
  //       $('.card-content .tags .pill .name').removeData('bs.popover');
  //       return $('.card-content .tags .pill .remove').removeData('bs.popover');
  //     });
  //   }
  //   return false;
  // });
  $(document).on("click", '.card-content .tags[data-readonly="false"] .pill .remove', function() {
    var card, project, socket_params, tag, that, token;
    tag = _.escape($(this).parent().attr("data-tag"))
    that = $(this).parent();
    card = Breeze.Card.id;
    project = Breeze.Card.project;
    token = Breeze.project_token;
    $("#card-tag-menu").remove();
    $('.card-content .tags .pill .name').removeData('bs.popover');
    $('.card-content .tags .pill .remove').removeData('bs.popover');
    $('.card[data-card-id="' + Breeze.Card.id + '"] .tags-bubble .pill[data-tag="' + tag + '"]').remove();
    if ($('.card[data-card-id="' + Breeze.Card.id + '"] .tags-bubble .pill ').length <= 0) {
      $('.card[data-card-id="' + Breeze.Card.id + '"] .tags-bubble').remove();
    }
    that.remove();
    if (!($('.card-content .tags .pill').length > 0)) {
      $('.card-content .tags').parent().remove();
    }
    socket_params = {
      project: token,
      method: "card-tag-remove",
      card: card,
      message: tag
    };
    $.ajax({
      type: "DELETE",
      queue: true,
      url: '/projects/' + project + '/cards/' + card + '/tags/1',
      data: {
        tag: tag
      },
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    return false;
  });
};
