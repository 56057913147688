function project_board_reset_fields() {
  $('.card_ref').css("min-width", "");
  $('.card_avatars').css("min-width", "");
  $('.text-overlay-container').css("width", "");
  $('.card-calendar-bubble-container').css("width", "");
  $('.card-recurring-bubble-container').css("width", "");
  $('.card-attach-bubble-container').css("width", "");
  $('.card-comment-bubble-container').css("width", "");
  $('.card-desc-bubble-container').css("width", "");
  $('.card-size-bubble-container').css("width", "");
  $('.card-reactions-bubble-container').css("width", "");
  $('.card-todos-bubble-container').css("width", "");
  $('.card-time-bubble-container').css("width", "");
  $('.card-custom-field-bubble-container').css("width", "");
  $('.card-tags-bubble-container').css("width", "");
  $('.card-calendar-close-bubble-container').css("width", "");
  $('.card-calendar-req-bubble-container').css("width", "");
  $('.card-subs-bubble-container').css("width", "");
  $('.card_name').css("flex", "");
  return $('.project-board .card').each(function() {
    $(this).find(".drop-shadow .badges").insertBefore($(this).find(".drop-shadow .card_avatars"));
    return $(this).find(".text-overlay-container").insertBefore($(this).find(".drop-shadow"));
  });
};

function init_board_list_view() {
  if ($('.project-board.project-list-view').length > 0) {
    $('.project-board.project-list-view .card').each(function() {
      $(this).find(".drop-shadow .card_avatars").insertBefore($(this).find(".drop-shadow .badges"));
      $(this).find(".text-overlay-container").appendTo($(this).find(".drop-shadow .badges"));
    });
		
    update_project_list_view_columns();
    update_project_layout();
  }
};

function update_project_list_view_columns(column) {
  var container, current_width;
  if (column == null) {
    column = "all";
  }
  if ($('.project-list-view').length > 0 || $('.right-menu.list-view').length > 0) {
    if ($('.right-menu.list-view').length > 0) {
      container = $('.dashboard');
    } else {
      container = $('.project-board');
    }
    if ($('.project-hide-images').length <= 0 && (column === "images" || column === "all")) {
      container.removeClass("project-empty-images");
      if ($('.card-cover-container img').length > 0) {
        container.removeClass("project-empty-images");
      } else {
        container.addClass("project-empty-images");
      }
    }
    if ($('.show-ref').length > 0 && (column === "id" || column === "all")) {
      current_width = 0;
      $('.card_ref').css("min-width", "");
      $('.card_ref').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      $('.card_ref').css("min-width", current_width + "px");
    }
    if (column === "avatars" || column === "all") {
      container.removeClass("project-empty-card-avatars");
      current_width = 0;
      $('.card_avatars').css("width", "");
      $('.card_avatars').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-card-avatars");
      } else {
        $('.card_avatars').css("min-width", current_width + "px");
        container.removeClass("project-empty-card-avatars");
      }
    }
    if ($('.project-hide-status').length <= 0 && (column === "status" || column === "all")) {
      container.removeClass("project-empty-status");
      current_width = 0;
      $('.text-overlay').each(function() {
        if ($(this).outerWidth() > current_width) {
          current_width = $(this).outerWidth();
        }
      });
      if (current_width <= 8) {
        container.addClass("project-empty-status");
      } else {
        $('.text-overlay-container').css("width", current_width + "px");
        container.removeClass("project-empty-status");
      }
    }
    if ($('.project-hide-date').length <= 0 && (column === "dates" || column === "all")) {
      container.removeClass("project-empty-dates");
      current_width = 0;
      $('.card-calendar-bubble-container').css("width", "");
      $('.card-calendar-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-dates");
      } else {
        $('.card-calendar-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-dates");
      }
      container.removeClass("project-empty-dates-req");
      current_width = 0;
      $('.card-calendar-req-bubble-container').css("width", "");
      $('.card-calendar-req-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-dates-req");
      } else {
        $('.card-calendar-req-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-dates-req");
      }
      container.removeClass("project-empty-dates-close");
      current_width = 0;
      $('.card-calendar-close-bubble-container').css("width", "");
      $('.card-calendar-close-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-dates-close");
      } else {
        $('.card-calendar-close-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-dates-close");
      }
    }
    if ($('.project-hide-tags').length <= 0 && (column === "tags" || column === "all")) {
      container.removeClass("project-empty-tags");
      current_width = 0;
      $('.card-tags-bubble-container').css("width", "");
      $('.card-tags-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-tags");
      } else {
        $('.card-tags-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-tags");
      }
    }
    if ($('.project-hide-customfields').length <= 0 && (column === "custom" || column === "all")) {
      container.removeClass("project-empty-custom-fields");
      current_width = 0;
      $('.card-custom-field-bubble-container').css("width", "");
      $('.card-custom-field-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-custom-fields");
      } else {
        $('.card-custom-field-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-custom-fields");
      }
    }
    if ($('.project-hide-time-tracking').length <= 0 && (column === "time" || column === "all")) {
      container.removeClass("project-empty-time-tracking");
      current_width = 0;
      $('.card-time-bubble-container').css("width", "");
      $('.card-time-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-time-tracking");
      } else {
        $('.card-time-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-time-tracking");
      }
    }
    if ($('.project-hide-todos').length <= 0 && (column === "todos" || column === "all")) {
      container.removeClass("project-empty-todos");
      current_width = 0;
      $('.card-todos-bubble-container').css("width", "");
      $('.card-todos-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-todos");
      } else {
        $('.card-todos-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-todos");
      }
    }
    if ($('.project-hide-counts').length <= 0 && (column === "reactions" || column === "counts" || column === "all")) {
      container.removeClass("project-empty-reactions");
      current_width = 0;
      $('.card-reactions-bubble-container').css("width", "");
      $('.card-reactions-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-reactions");
      } else {
        $('.card-reactions-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-reactions");
      }
    }
    if ($('.project-hide-counts').length <= 0 && (column === "size" || column === "counts" || column === "all")) {
      container.removeClass("project-empty-size");
      $('.card-size-bubble-container').css("width", "");
      current_width = 0;
      $('.card-size-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-size");
      } else {
        $('.card-size-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-size");
      }
    }
    if ($('.project-hide-counts').length <= 0 && (column === "desc" || column === "counts" || column === "all")) {
      container.removeClass("project-empty-desc");
      current_width = 0;
      $('.card-desc-bubble-container').css("width", "");
      $('.card-desc-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-desc");
      } else {
        $('.card-desc-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-desc");
      }
    }
    if ($('.project-hide-counts').length <= 0 && (column === "comment" || column === "counts" || column === "all")) {
      container.removeClass("project-empty-comment");
      current_width = 0;
      $('.card-comment-bubble-container').css("width", "");
      $('.card-comment-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-comment");
      } else {
        $('.card-comment-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-comment");
      }
    }
    if ($('.project-hide-counts').length <= 0 && (column === "attach" || column === "counts" || column === "all")) {
      container.removeClass("project-empty-attach");
      current_width = 0;
      $('.card-attach-bubble-container').css("width", "");
      $('.card-attach-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-attach");
      } else {
        $('.card-attach-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-attach");
      }
    }
    if ($('.project-hide-counts').length <= 0 && (column === "recurring" || column === "counts" || column === "all")) {
      container.removeClass("project-empty-recurring");
      current_width = 0;
      $('.card-recurring-bubble-container').css("width", "");
      $('.card-recurring-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-recurring");
      } else {
        $('.card-recurring-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-recurring");
      }
    }
    if ($('.project-hide-counts').length <= 0 && (column === "subs" || column === "counts" || column === "all")) {
      container.removeClass("project-empty-subs");
      current_width = 0;
      $('.card-subs-bubble-container').css("width", "");
      $('.card-subs-bubble-container').each(function() {
        if ($(this).width() > current_width) {
          return current_width = $(this).width();
        }
      });
      if (current_width <= 1) {
        container.addClass("project-empty-subs");
      } else {
        $('.card-subs-bubble-container').css("width", current_width + "px");
        container.removeClass("project-empty-subs");
      }
    }
    if ($('.badges').width() <= 50) {
      $('.card_name').css("flex", "0 0 40em");
      $('.list-view .item-footer').css("max-width", "40em");
      $('.list-view.duedate-sort .todo .todo-input-text').css("width", "36.34em");
      $('.list-view.project-sort .todo .todo-input-text').css("width", "36.34em");
			
    } 
    else if ($( window ).width() >= 1024) {
      $('.card_name').css("flex", "0 0 30em");
      $('.list-view .item-footer').css("max-width", "30em");
      $('.list-view.duedate-sort .todo .todo-input-text').css("width", "27em");
      $('.list-view.project-sort .todo .todo-input-text').css("width", "27em");
			
    } else if ($( window ).width() >= 768){
      $('.card_name').css("flex", "0 0 20em");
      $('.list-view .item-footer').css("max-width", "20em");
      $('.list-view.duedate-sort .todo .todo-input-text').css("width", "17.66em");
      $('.list-view.project-sort .todo .todo-input-text').css("width", "17.66m");
			
    } else {
      $('.card_name').css("flex", "0 0 15em");
      $('.list-view .item-footer').css("max-width", "15em");
      $('.list-view.duedate-sort .todo .todo-input-text').css("width", "13em");
      $('.list-view.project-sort .todo .todo-input-text').css("width", "13em");
			
    }
    update_dashboard_list_width();
    return update_project_list_width();
  }
};

function update_dashboard_list_width() {
  var x;
  if ($('.right-menu.list-view').length > 0) {
    if ($('.card-block-elements')[0] != null) {
      $('.card-outer').css("min-width", "");
      $('.stage-list').css("min-width", "");
      x = $('.card-block-elements:visible')[0].scrollWidth - 50;
      if ($('.card-block-elements').length > 0) {
        if ($('.list-view.duedate-sort').length > 0) {
          $('.card-outer').css("min-width", x + "px");
        }
        if ($('.list-view.project-sort').length > 0 || $('.list-view.status-sort').length > 0) {
          return $('.stage-list').css("min-width", x - 5 + "px");
        }
      }
    }
  }
};

function update_project_list_width() {
  var x;
  if ($('.project-board.project-list-view').length > 0) {
    $('.stage_class').css('min-width', '');
    $('.stage_class').css('width', '');
    if ($('.sortable_stage')[0] != null) {
      x = $('.sortable_stage')[0].scrollWidth - 20;
      if ($('.sortable_stage').length > 0) {
        return $('.stage_class').css("min-width", x + "px");
      }
    }
  }
};

function update_project_layout() {
  var svg;
  update_project_list_width();
  svg = $('.project-layout-btn li[data-project-layout="' + $(".project-layout-btn-text").text() + '"] svg').prop('outerHTML');
  return $('.project-layout-btn-drop svg').replaceWith(svg);
};


export {update_project_layout,update_project_list_width, update_dashboard_list_width, update_project_list_view_columns, init_board_list_view,project_board_reset_fields}