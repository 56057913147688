import {update_list_wip} from 'mobile/utils/wip.js'
import {stage_equal_heights,init_board_list_view} from 'mobile/utils.js'
import {hide_first_swimlane_init} from 'mobile/utils/swimlane.js'
import {hide_first_swimlane} from 'mobile/utils/swimlane.js'

import {make_sortable} from 'mobile/utils/sortable.js'

var init_project_stages,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$.fn.equalHeights = function(px) {
  var currentTallest, that;
  if ($(this).hasClass("sortable") && $('.project-board-fixed-height1').length > 0) {
    return false;
  }
  if ($(".project-list-view").length > 0 || $(".custom-background").length > 0 || $('.project-board-fixed-height1').length > 0) {
    return false;
  }
  currentTallest = 100;
  that = $(this);
  $(this).each(function() {
    if ($(this).hasClass('collapsed-stage') || $(this).parent().hasClass('collapsed-stage')) {
      return true;
    }
    if ($(this).height() > currentTallest) {
      return currentTallest = $(this).height();
    }
  });
  $(this).css({
    "min-height": currentTallest
  });
  return this;
};

$.fn.equalWidths = function(px) {
  var currentWidth;
  if ($(".sortable_stage").length === 0 || $(".project-list-view").length > 0) {
    return false;
  }
  currentWidth = 0;
  $(this).each(function() {
    currentWidth = parseInt(($(".card-sort").outerWidth()) / $(".sortable_stage:first >.stage_class:not(.collapsed-stage)").length);
    $(this).children().css({
      "width": currentWidth - 5
    });
    $(this).children().css({
      "max-width": "320px"
    });
    return $(this).children().css({
      "min-width": "250px"
    });
  });
  if (currentWidth < 250) {
    currentWidth = 250;
  }
  Breeze.scroller = 0;
  if ($(".card-sort")[0]) {
    Breeze.scroller_max = $(".card-sort")[0].scrollWidth - $(".card-sort")[0].clientWidth;
  }
  if ($(".card-sort").width() < (currentWidth * $(".sortable_stage:first >.stage_class").length)) {
    $(".carousel-control.next").show();
  } else {
    if (!($('.task-board').length > 0 && $(".carousel-control.next").attr('href').length > 0)) {
      $(".carousel-control.next").hide();
    }
  }
  return this;
};

Breeze.stage_equal_heights = function(swimlane) {
  if ($('.project-board-fixed-height1').length > 0) {
    return false;
  }
  swimlane.find('.stage_class').equalHeights();
  swimlane.css('min-height', $(this).find('.stage_class:visible').height() + 20);
  return swimlane.find('.stage_class .sortable').equalHeights();
};

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.task-board').length > 0) {
    setTimeout((function() {
      $('.sortable_stage').each(function() {
        $(this).find('.stage_class').equalHeights();
        return $(this).css('min-height', $(this).find('.stage_class:visible').height() + 20);
      });
      return hide_first_swimlane_init();
    }), 10);
    setTimeout((function() {
      return $('.sortable_stage').each(function() {
        return $(this).find('.stage_class .sortable').equalHeights();
      });
    }), 200);
    $('.sortable_stage').equalWidths();
  }
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      return init_project_stages();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    return init_project_stages();
  }
});

init_project_stages = function() {
  var tasks_copy_popover, tasks_move_popover;
  $('.project-board').on("click", '.card-sort .sortable_stage .stage_class .stage_text[data-readonly="false"],.project-board.project-list-view .stage_class .stage_text[data-readonly="false"]', function(e) {
  
	  var el, popover_content, project_id, stage_id, stage_name;
    el = $(this);
    stage_id = el.closest('.stage_class').attr('data-stage-id');
		stage_name = $(this).text()

    $(".dropdown").removeClass("open");
    if ($('.edit-stage-' + stage_id).length > 0) {
      el.popover("hide");
      el.removeData('bs.popover');
			// $('.popover').remove()
			
    } else {
      $('.popover').remove();
      $('.card-quick-menu').removeClass("show");
      el.removeData('bs.popover');
      project_id = el.closest('.project-board-loader').attr('data-project-id');
			
      
			popover_content = `<ul class="menu">
            
            <li class="stage-edit-name"><a href="#" class="mark " data-turbolinks="false"><i class="fa"></i>Edit name</a></li>

				            <li class="divider"><a href="#"></a></li>
				            <li class="delete-list"><a href="#" class="delete-list-action" data-turbolinks="false"><i class="fa fa-trash-o" aria-hidden="true"></i>Delete list </a></li>

              
        </ul>`
			el.popover({
	      container: '.card-sort div:first-of-type .stage_class[data-stage-id="'+stage_id+'"] .stage_name',
        trigger: "manual",
        placement: "bottom",
        template: '<div class="popover stage-actions stage-actions-menu  edit-popover edit-stage-' + stage_id + '" data-stage-id="'+stage_id+'"><div class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
        html: true,
				sanitize: false,
        content: popover_content,
				// animation: false
      });
      el.popover("show");
			
			
					  $('.stage-actions').on("click", ".stage-edit-name", function() {
					    var el, popover_content, stage_id;

					    stage_id = $(this).closest('.popover').attr('data-stage-id');
					    el = $('.card-sort div:first-of-type .stage_class[data-stage-id="'+stage_id+'"] .stage_name')
				      $('.popover').remove();
				      $('.card-quick-menu').removeClass("show");
				      el.removeData('bs.popover');
				      project_id = el.closest('.project-board-loader').attr('data-project-id');

				      popover_content = '<form id="edit-stage-form" data-turbolinks="false" data-remote="true"> <div class="border"><input class="stage_edit_active" type="text" name="stage" placeholder="List name" value="' + stage_name+ '"></div> <div class="actions"> <button class="c-button c-button--blue c-button-med" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Save <div class="progress-button"> <div class="indeterminate"></div> </div> </button> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> </div> </form>';


				el.popover({
					      container: '.card-sort div:first-of-type .stage_class[data-stage-id="'+stage_id+'"] .stage_name',
				        trigger: "manual",
				        placement: "bottom",
				        template: '<div class="popover stage-actions edit-popover edit-stage-' + stage_id + '" data-stage-id="'+stage_id+'"><div class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
				        html: true,
					sanitize: false,
				        content: popover_content
				      });
				      el.popover("show");


				      $('.edit-stage-' + stage_id).find('input[type="text"]').select();
				      $('.edit-popover').on("click", ".actions .cancel", function() {
				        el.popover("hide");
				        return el.removeData('popover');
				      });
				      $('#edit-stage-form').on("submit", function() {
				        var hide_list, hide_list_dash, hide_list_project, socket_params, stage, stage_name, status_color, status_id, status_name, wip;
				        stage_name = $(this).find("input").val();
				        if (stage_name.length <= 0) {
				          stage_name = "Untitled";
				          return false;
				        }
				        stage = $('.stage_class[data-stage-id="' + stage_id + '"]');
				        hide_list = stage.find('.hide-list .mark').hasClass("active");
				        hide_list_dash = stage.find('.hide-list-dashboard .mark').hasClass("active");
				        hide_list_project = stage.find('.hide-list-project .mark').hasClass("active");
				        status_id = stage.find('.status-select select').val();
				        status_name = stage.find('.status-select option:selected').text();
				        status_color = stage.find('.status-select option:selected').attr('data-status-color');
				        wip = stage.find('.wip-select select').val();
				        // Breeze.update_list_wip();
				        socket_params = {
				          project: Breeze.project_token,
				          method: "stage-update",
				          stage: stage_id,
				          status_id: status_id,
				          status_name: status_name,
				          status_color: status_color,
				          wip: wip,
				          hidden: hide_list,
				          hide_dashboard: hide_list_dash,
				          hide_project: hide_list_project,
				          message: stage_name
				        };
				        if (hide_list) {
				          $('.stage_class[data-stage-id="' + stage_id + '"] .card').attr('data-hidden', "true");
				        } else {
				          $('.stage_class[data-stage-id="' + stage_id + '"] .card').attr('data-hidden', "false");
				        }
				        $.ajax({
				          type: "PUT",
				          url: '/projects/' + project_id + '/stages/' + stage_id,
				          queue: true,
				          data: {
				            stage_name: stage_name
				          },
				          success: function() {
				            // if (Breeze.is_socket_connected()) {
				            //   return Breeze.socket.emit("message", socket_params);
				            // }
				          }
				        });
				        Breeze.project_stages = _.map(Breeze.project_stages, function(e, key) {
				          if (parseInt(e.id) === parseInt(stage_id)) {
				            e.name = stage_name;
				          }
				          return e;
				        });
				        //   Breeze.init_task_parser();
				        $('.stage_class[data-stage-id="' + stage_id + '"] .stage_text').text(stage_name);
				        el.popover("hide");
				        el.removeData('popover');
				      });

			})


					  $('.stage-actions').on("click", ".delete-list-action", function() {
					    var el, popover_content, stage_id;
					    stage_id = $(this).closest('.popover').attr('data-stage-id');
					    el = $('.card-sort div:first-of-type .stage_class[data-stage-id="'+stage_id+'"] .stage_name')

					    $('.popover').remove();
					    el.removeData("bs.popover");
					    popover_content = '<div class="confirmation-popover"> <h1>Delete task list?</h1> <div class="actions"> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> <button class="c-button c-button--blue c-button-med" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Delete <div class="progress-button"> <div class="indeterminate"></div> </div> </button> </div> </div>';
					    el.popover({
	      container: '.card-sort div:first-of-type .stage_class[data-stage-id="'+stage_id+'"] .stage_name',
					      trigger: "manual",
					      placement: "bottom",
					      template: '<div class="popover delete-stage-popover" id="delete-stage"><div class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
					      html: true,
					sanitize: false,
					      content: popover_content
					    });
					    el.popover("show");
					    $('.delete-stage-popover').on("click", ".actions .cancel", function(e) {
					      el.popover("hide");
					      el.removeData('popover');
					      return false;
					    });
					    $('.delete-stage-popover').on("click", '.actions button[type="submit"]', function(e) {
					      var socket_params, stage_class;
					      stage_class = $('.stage_class[data-stage-id="' + stage_id + '"]');
					      socket_params = {
					        project: Breeze.project_token,
					        method: "stage-trash",
					        message: stage_id
					      };
					      $.ajax({
					        type: "DELETE",
					        queue: true,
					        url: '/stages/' + stage_id,
					        data: {
					          project_id: Breeze.project
					        },
					        success: function() {
					          if (Breeze.is_socket_connected()) {
					            return Breeze.socket.emit("message", socket_params);
					          }
					        }
					      });
					      $('.stage_class[data-stage-id="' + stage_id + '"]').remove();
					      if (!($('.project-list-view').length > 0)) {
					        $('.sortable_stage').equalWidths();
					      }
					      Breeze.project_stages = _.reject(Breeze.project_stages, function(e) {
					        return e.id === stage_id;
					      });
					      // Breeze.init_task_parser();
			    $('.popover').remove();

					      el.removeData('bs.popover');
					    });
					    $('.delete-stage-popover').on("click", function(e) {
					      return e.stopPropagation();
					    });
					    return false;
					  });

  
    }
    return false;
  });
  $('.project-board').on("click", "#add_stage, .add_stage_list_action", function(e) {
    var i, len, new_stage, ref, s, shift_key, socket_params, statuses, temp_stage;
    temp_stage = "card_stage_" + Breeze.current_user.id + new Date().getTime();
    shift_key = e.shiftKey;
    statuses = "";
    ref = Breeze.project_statuses;
    for (i = 0, len = ref.length; i < len; i++) {
      s = ref[i];
      statuses += '<option value="' + s.id + '" data-status-color="' + s.color + '">' + s.name + '</option>';
    }
    new_stage = $('<div class="stage_class" id="' + temp_stage + '" data-stage-id="' + temp_stage + '" data-stage-tid="' + temp_stage + '" data-wip="" data-wip-total="0" data-wip-status="true"> <div class="stage_name" data-readonly="false"> <div class="stage_text" data-readonly="false">Untitled</div> <div class="dropdown dropdown-check  stage-settings"> <a href="#" data-toggle="dropdown" class="dropdown-toggle"><i class="fa fa-ellipsis-v fa-2" aria-hidden="true"></i> </a> <ul class="dropdown-menu"> <li class="hide-list-project"><a href="#" class="mark">Hide list from project board</a></li> <li class="hide-list"><a href="#" class="mark">Don\'t show this list to clients </a></li> <li class="hide-list-dashboard"><a href="#" class="mark">Don\'t show this list on tasks page </a></li> <li class="wip-select-menu"><a href="#">Work in progress limit </a></li> <li class="stage-color-menu"><a href="#" >List color</a></li><li class="subscribe-list"><a href="#" class="subscribe-list-action">Subscribe ... </a></li> <li class="divider"><a href="#"></a></li> <li class="automation-list"><a href="#" class="automation-list-action">Automation ... </a></li> <li class="divider"><a href="#"></a></li> <li class="copy-tasks"><a href="#" class="copy-tasks-action">Copy all tasks to ...</a></li> <li class="move-tasks"><a href="#" class="move-tasks-action">Move all tasks to ...</a></li> <li class="archive-list"><a href="#" class="archive-list-action">Archive all tasks</a></li> <li class="divider"><a href="#"></a></li> <li class="sort-list"><a href="#" class="sort-list-action">Sort by ...</a></li> <li class="divider"><a href="#"></a></li> <li class="delete-list"><a href="#" class="delete-list-action"><i class="fa fa-trash-o" aria-hidden="true"></i>Delete list </a></li> </ul> </div> <span class="wip"></span> </div> <div class="sortable connectedSortable unstyled"> <div class="card-placeholder"></div> <div class="task-new-stage"><div>Add a task</div></div> </div></div>');
    if (shift_key) {
      new_stage.prependTo(".sortable_stage");
      if (!($('.project-list-view').length > 0)) {
        $(".card-sort").stop(true, false).animate({
          scrollLeft: 0
        }, 'fast');
      }
    } else {
      new_stage.appendTo(".sortable_stage");
      if (!($('.project-list-view').length > 0)) {
        $(".card-sort").stop(true, false).animate({
          scrollLeft: Breeze.scroller_max + 320
        }, 'fast');
      }
    }
    if (!($('.project-list-view').length > 0)) {
      $('.sortable_stage').each(function() {
        return $(this).find('.stage_class').equalHeights();
      });
    }
    hide_first_swimlane_init();
    if (!($('.project-list-view').length > 0)) {
      $('.sortable_stage').each(function() {
        return $(this).find('.stage_class .sortable').equalHeights();
      });
      $('.sortable_stage').equalWidths();
    }
    Breeze.scroller_max = $(".card-sort")[0].scrollWidth - $(".card-sort")[0].clientWidth;
    if ((window.innerWidth - 85) < ($(".sortable_stage:first >li").width() * $(".sortable_stage:first >li").length)) {
      $(".carousel-control.prev").show();
    }
    $(".carousel-control.next").hide();
    Breeze.scroller = Breeze.scroller_max;
    socket_params = {
      project: Breeze.project_token,
      method: "stage-add"
    };
    make_sortable();
    $.ajax({
      type: "POST",
      url: "/stages.js",
      data: {
        project_id: Breeze.project,
        stage_temp: temp_stage,
        prepend: shift_key
      },
      success: function(data) {
        socket_params.create = data;
        socket_params.message = new_stage.prop('outerHTML');
        socket_params.prepend = shift_key;
        if (Breeze.is_socket_connected()) {
          Breeze.socket.emit("message", socket_params);
        }
        Breeze.project_stages.push({
          id: parseInt($('[data-stage-tid="' + temp_stage + '"]').attr('data-stage-id')),
          name: "Untitled",
          actions: {
            status: {
              name: null
            },
            color: {
              name: null
            },
            tag: {
              name: null
            },
            user: {
              email: null
            },
            archive: {
              name: null
            },
            duedate: {
              name: null
            }
          }
        });
         // Breeze.init_task_parser();
      }
    });
    return e.preventDefault();
  });
  $('.project-board').on("click", ".stage-settings .dropdown-menu select", function(event) {
    return false;
  });
  $('.project-board').on("shown.bs.dropdown", ".stage-settings", function(event) {
    $(this).closest('.stage_name').css('z-index', '999');
    $('.popover').remove();
    return $('.card-quick-menu').removeClass("show");
  });
  $('.project-board').on("shown.bs.dropdown", ".project_cog", function(event) {
    $('.popover').remove();
    return $('.card-quick-menu').removeClass("show");
  });
  $('.project-board').on("click", ".stage-settings .dropdown-menu .copy-tasks-action", function(event) {
    var el, popover_content, stage_id;
    el = $(this).closest('.stage_class').find('.dropdown-toggle');
    stage_id = $(this).closest('.stage_class').attr('data-stage-id');
    $('.popover').remove();
    el.removeData("bs.popover");
    if (Breeze.workspaces_projects == null) {
      popover_content = '<div class="empty-stage-popover">' + Breeze.global_loader + '</div>';
      $.ajax({
        type: "GET",
        url: '/projects/workspaces_projects.json',
        success: function(data) {
          if (data.workspaces_projects) {
            Breeze.workspaces_projects = data.workspaces_projects;
          }
          return $('.copy-stage-popover .popover-content').html(tasks_copy_popover());
        }
      });
    } else {
      popover_content = tasks_copy_popover();
    }
    el.popover({
      container: "element",
      trigger: "manual",
      placement: "bottomleft",
      template: '<div class="popover copy-stage-popover edit-popover" id="copy-stage"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
      html: true,
      content: popover_content
    });
    el.popover("show");
    $('.copy-stage-popover').on("change", '.copy-tasks-menu [name="list[project]"]', function() {
      var i, j, len, len1, list_opts, p, parent, project, project_e, ref, ref1, swimlane_opts;
      parent = $(this).closest('.copy-tasks-menu');
      project = $(this).val();
      list_opts = "";
      swimlane_opts = "";
      project_e = _.where(Breeze.projects_stages, {
        name: project
      })[0];
      if (project_e) {
        ref = project_e.stages;
        for (i = 0, len = ref.length; i < len; i++) {
          p = ref[i];
          list_opts += "<option value=\"" + p.name + "\">" + p.label + "</option>";
        }
        swimlane_opts += "<option value=\"0\">Select swimlane</option>";
        ref1 = project_e.swimlanes;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          p = ref1[j];
          swimlane_opts += "<option value=\"" + p.id + "\">" + p.name + "</option>";
        }
        parent.find('[name="list[swimlane]"]').html(swimlane_opts);
        parent.find('[name="list[stage]"]').html(list_opts);
        parent.find('[name="list[stage]"]').parent().removeClass("hidee");
        if (parent.find('[name="list[swimlane]"] option').length > 1) {
          return parent.find('[name="list[swimlane]"]').parent().removeClass("hidee");
        } else {
          return parent.find('[name="list[swimlane]"]').parent().addClass("hidee");
        }
      } else {
        parent.find('[name="list[swimlane]"]').parent().addClass("hidee");
        return parent.find('[name="list[stage]"]').parent().addClass("hidee");
      }
    });
    $('.copy-stage-popover').on("click", ".actions .cancel", function(e) {
      el.popover("hide");
      return el.removeData('popover');
    });
    $('.copy-stage-popover').on("click", '.actions button[type="submit"]', function(e) {
      var parent, project, res, same_project, socket_params, stage, swimlane, swimlane_src, that;
      parent = $(this).closest('.copy-tasks-menu');
      project = parent.find('[name="list[project]"] option:selected').val();
      if (project.length <= 0) {
        return false;
      }
      stage = parent.find('[name="list[stage]"] option:selected').val();
      swimlane = parent.find('[name="list[swimlane]"] option:selected').val();
      swimlane_src = parent.find('[name="list[swimlane_src]"] option:selected').val();
      same_project = parseInt(Breeze.project) === parseInt(project);
      that = $(this);
      res = $.grep(Breeze.projects_stages, function(e) {
        return parseInt(e.name) === parseInt(project);
      });
      socket_params = {
        project: (res != null) && (res[0] != null) ? res[0].token : "0101",
        method: "card-move-project",
        stage: stage,
        new_project: project,
        swimlane: swimlane
      };
      $.ajax({
        type: "POST",
        url: '/projects/' + Breeze.project + '/stages/' + stage_id + '/copy_all',
        queue: true,
        data: {
          project: project,
          stage: stage,
          swimlane: swimlane,
          swimlane_id: swimlane_src
        },
        beforeSend: function() {
          that.prop('disabled', true);
          return that.find('.progress-button').show();
        },
        success: function(data) {
          $(data).each(function() {
            var card;
            card = $(this);
            if (swimlane === "0") {
              $('.sortable_stage:first [data-stage-id="' + stage + '"] .sortable .task-new-stage').before(card);
            } else {
              $('.swimlane[data-swimlane-id="' + swimlane + '"] [data-stage-id="' + stage + '"] .sortable .task-new-stage').before(card);
            }
            Breeze.inc_wip_total(card);
            socket_params.card = card.prop('outerHTML');
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          });
          that.prop('disabled', false);
          that.find('.progress-button').hide();
          el.popover("hide");
          return el.removeData('popover');
        },
        error: function() {
          that.prop('disabled', false);
          return that.find('.progress-button').hide();
        }
      });
      return false;
    });
    $('.copy-stage-popover').on("click", function(e) {
      return e.stopPropagation();
    });
    return false;
  });
  tasks_copy_popover = function() {
    var i, j, k, l, len, len1, len2, len3, p, plist, popover_content, project_swimlanes, project_swimlanes_show, ref, ref1, ref2, ref3, w;
    plist = "";
    ref = Breeze.workspaces_projects.no_workspace;
    for (i = 0, len = ref.length; i < len; i++) {
      p = ref[i];
      plist += "<option value=\"" + p.id + "\">" + p.name + "</option>";
    }
    ref1 = Breeze.workspaces_projects.workspace;
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      w = ref1[j];
      plist += "<optgroup label=\"" + w.name + "\">";
      ref2 = w.projects;
      for (k = 0, len2 = ref2.length; k < len2; k++) {
        p = ref2[k];
        plist += "<option value=\"" + p.id + "\">" + p.name + "</option>";
      }
      plist += "</optgroup>";
    }
    project_swimlanes = "";
    project_swimlanes_show = "hidee";
    if (Breeze.project_swimlanes.length > 0) {
      project_swimlanes_show = "";
      ref3 = Breeze.project_swimlanes;
      for (l = 0, len3 = ref3.length; l < len3; l++) {
        p = ref3[l];
        project_swimlanes += "<option value=\"" + p.id + "\">" + p.name + "</option>";
      }
    }
    return popover_content = '<form class="copy-tasks-menu"> <div class="' + project_swimlanes_show + '"> From swimlane <div class="select-wrapper"> <select name="list[swimlane_src]"> <option value="0">all swimlanes</option> <option value="-1">without swimlane</option>' + project_swimlanes + '</select> </div> </div> <div class="' + project_swimlanes_show + '">To project</div> <div class="select-wrapper"> <select name="list[project]"> <option value="">select project</option>' + plist + '</select> </div> <div class="select-wrapper hidee"> <select  name="list[swimlane]"> <option value="">select swimlane</option> </select> </div> <div class="select-wrapper hidee"> <select name="list[stage]"> <option value="">select list</option> </select> </div> <div class="actions"> <button class="c-button c-button--blue c-button-med" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Copy tasks <div class="progress-button"> <div class="indeterminate"></div> </div> </button> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> </div> </form>';
  };
  $('.project-board').on("click", ".stage-settings .dropdown-menu .move-tasks-action", function(event) {
    var el, popover_content, stage_id;
    el = $(this).closest('.stage_class').find('.dropdown-toggle');
    stage_id = el.closest('.stage_class').attr('data-stage-id');
    $('.popover').remove();
    el.removeData("bs.popover");
    if (Breeze.workspaces_projects == null) {
      popover_content = '<div class="empty-stage-popover">' + Breeze.global_loader + '</div>';
      $.ajax({
        type: "GET",
        url: '/projects/workspaces_projects.json',
        success: function(data) {
          if (data.workspaces_projects) {
            Breeze.workspaces_projects = data.workspaces_projects;
          }
          return $('.move-stage-popover .popover-content').html(tasks_move_popover());
        }
      });
    } else {
      popover_content = tasks_move_popover();
    }
    el.popover({
      container: "element",
      trigger: "manual",
      placement: "bottomleft",
      template: '<div class="popover move-stage-popover edit-popover" id="copy-stage"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
      html: true,
      content: popover_content
    });
    el.popover("show");
    $('.move-stage-popover').on("change", '.move-tasks-menu [name="list[project]"]', function() {
      var i, j, len, len1, list_opts, p, parent, project, project_e, ref, ref1, swimlane_opts;
      project = $(this).val();
      parent = $(this).closest('.move-tasks-menu');
      list_opts = "";
      swimlane_opts = "";
      project_e = _.where(Breeze.projects_stages, {
        name: project
      })[0];
      if (project_e) {
        ref = project_e.stages;
        for (i = 0, len = ref.length; i < len; i++) {
          p = ref[i];
          list_opts += "<option value=\"" + p.name + "\">" + p.label + "</option>";
        }
        swimlane_opts += "<option value=\"0\">Select swimlane</option>";
        ref1 = project_e.swimlanes;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          p = ref1[j];
          swimlane_opts += "<option value=\"" + p.id + "\">" + p.name + "</option>";
        }
        parent.find('[name="list[swimlane]"]').html(swimlane_opts);
        parent.find('[name="list[stage]"]').html(list_opts);
        parent.find('[name="list[stage]"]').parent().removeClass("hidee");
        if (parent.find('[name="list[swimlane]"] option').length > 1) {
          return parent.find('[name="list[swimlane]"]').parent().removeClass("hidee");
        } else {
          return parent.find('[name="list[swimlane]"]').parent().addClass("hidee");
        }
      } else {
        parent.find('[name="list[swimlane]"]').parent().addClass("hidee");
        return parent.find('[name="list[stage]"]').parent().addClass("hidee");
      }
    });
    $('.move-stage-popover').on("click", '.actions button[type="submit"]', function() {
      var els, parent, project, res, same_project, socket_params, socket_params2, stage, swimlane, swimlane_src, that;
      parent = $(this).closest('.move-tasks-menu');
      project = parent.find('[name="list[project]"] option:selected').val();
      if (project.length <= 0) {
        return false;
      }
      stage = parent.find('[name="list[stage]"] option:selected').val();
      swimlane = parent.find('[name="list[swimlane]"] option:selected').val();
      swimlane_src = parent.find('[name="list[swimlane_src]"] option:selected').val();
      same_project = parseInt(Breeze.project) === parseInt(project);
      that = $(this);
      if (swimlane_src === "0") {
        els = $('.card[data-stage-id="' + stage_id + '"]');
      } else if (swimlane_src === "-1") {
        els = $('.sortable_stage:first .card[data-stage-id="' + stage_id + '"]');
      } else {
        els = $('.swimlane[data-swimlane-id="' + swimlane_src + '"] .card[data-stage-id="' + stage_id + '"]');
      }
      res = $.grep(Breeze.projects_stages, function(e) {
        return parseInt(e.name) === parseInt(project);
      });
      socket_params = {
        project: (res != null) && (res[0] != null) ? res[0].token : "0101",
        method: "card-move-project",
        stage: stage,
        new_project: project,
        swimlane: swimlane
      };
      socket_params2 = {
        project: Breeze.project_token,
        method: "card-delete"
      };
      els.each(function() {
        if (!same_project) {
          if ($(this).find('.timer.on').length > 0) {
            $(this).find('.timer.on').trigger('click');
          }
        }
        Breeze.dec_wip_total($(this));
        socket_params.card = $(this).prop('outerHTML');
        socket_params2.card = $(this).attr('data-card-id');
        if (Breeze.is_socket_connected()) {
          Breeze.socket.emit("message", socket_params);
        }
        if (Breeze.is_socket_connected() && !same_project) {
          Breeze.socket.emit("message", socket_params2);
        }
        return $(this).attr('data-project-id', project).attr('data-stage-id', stage);
      });
      $.ajax({
        type: "POST",
        url: '/projects/' + Breeze.project + '/stages/' + stage_id + '/move_all',
        queue: true,
        data: {
          project: project,
          stage: stage,
          swimlane: swimlane,
          swimlane_id: swimlane_src
        }
      });
      if (same_project) {
        els.each(function() {
          var card, card_id, new_list, old_list;
          card = $(this);
          card_id = card.attr('data-card-id');
          old_list = card.closest('.stage_class').attr('data-stage-id');
          new_list = $('.sortable_stage:first .stage_class[data-stage-id="' + stage + '"]');
          $(document).trigger("breeze:page", ["update_stage_card", card_id, stage, old_list, card]);
          Breeze.inc_wip_total(card);
          if (swimlane === "0") {
            $('.sortable_stage:first [data-stage-id="' + stage + '"] .sortable .task-new-stage').before(card);
          } else {
            $('.swimlane[data-swimlane-id="' + swimlane + '"] [data-stage-id="' + stage + '"] .sortable .task-new-stage').before(card);
          }
          return Breeze.update_task_stripe(card_id);
        });
      } else {
        els.each(function() {
          return $(this).remove();
        });
      }
      hide_first_swimlane();
      el.popover("hide");
      el.removeData('popover');
      return false;
    });
    $('.move-stage-popover').on("click", ".actions .cancel", function(e) {
      el.popover("hide");
      el.removeData('popover');
      return false;
    });
    $('.copy-stage-popover').on("click", function(e) {
      return e.stopPropagation();
    });
    return false;
  });
  tasks_move_popover = function() {
    var i, j, k, l, len, len1, len2, len3, p, plist, popover_content, project_swimlanes, project_swimlanes_show, ref, ref1, ref2, ref3, w;
    plist = "";
    ref = Breeze.workspaces_projects.no_workspace;
    for (i = 0, len = ref.length; i < len; i++) {
      p = ref[i];
      plist += "<option value=\"" + p.id + "\">" + p.name + "</option>";
    }
    ref1 = Breeze.workspaces_projects.workspace;
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      w = ref1[j];
      plist += "<optgroup label=\"" + w.name + "\">";
      ref2 = w.projects;
      for (k = 0, len2 = ref2.length; k < len2; k++) {
        p = ref2[k];
        plist += "<option value=\"" + p.id + "\">" + p.name + "</option>";
      }
      plist += "</optgroup>";
    }
    project_swimlanes = "";
    project_swimlanes_show = "hidee";
    if (Breeze.project_swimlanes.length > 0) {
      project_swimlanes_show = "";
      ref3 = Breeze.project_swimlanes;
      for (l = 0, len3 = ref3.length; l < len3; l++) {
        p = ref3[l];
        project_swimlanes += "<option value=\"" + p.id + "\">" + p.name + "</option>";
      }
    }
    return popover_content = '<form class="move-tasks-menu"> <div class="' + project_swimlanes_show + '"> From swimlane <div class="select-wrapper"> <select name="list[swimlane_src]"> <option value="0">all swimlanes</option> <option value="-1">without swimlane</option>' + project_swimlanes + '</select> </div> </div> <div class="' + project_swimlanes_show + '">To project</div> <div class="select-wrapper"> <select name="list[project]"> <option value="">select project</option>' + plist + '</select> </div> <div class="select-wrapper hidee"> <select  name="list[swimlane]"> <option value="">select swimlane</option> </select> </div> <div class="select-wrapper hidee"> <select  name="list[stage]"> <option value="">select list</option> </select> </div> <div class="actions"> <button class="c-button c-button--blue c-button-med" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Move tasks <div class="progress-button"> <div class="indeterminate"></div> </div> </button> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> </div> </form>';
  };
  $('.project-board').on("click", ".stage-settings .dropdown-menu .archive-list-action", function() {
    var el, popover_content, stage_id;
    el = $(this).closest('.stage_class').find('.dropdown-toggle');
    stage_id = el.closest('.stage_class').attr('data-stage-id');
    $('.popover').remove();
    el.removeData("bs.popover");
    popover_content = '<div class="confirmation-popover"> <h1>Archive all tasks in the list?</h1> <div class="actions"> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> <button class="c-button c-button--blue c-button-med" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Archive <div class="progress-button"> <div class="indeterminate"></div> </div> </button> </div> </div>';
    el.popover({
      container: "element",
      trigger: "manual",
      placement: "bottomleft",
      template: '<div class="popover archive-stage-popover" id="archive-stage"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
      html: true,
      content: popover_content
    });
    el.popover("show");
    $('.archive-stage-popover').on("click", ".actions .cancel", function(e) {
      el.popover("hide");
      return el.removeData('popover');
    });
    $('.archive-stage-popover').on("click", '.actions button[type="submit"]', function(e) {
      $.ajax({
        type: "PUT",
        queue: true,
        url: "/projects/" + Breeze.project + "/cards/-1/soft_destroy",
        data: {
          stage_id: stage_id
        }
      });
      $('.stage_class .card[data-stage-id="' + stage_id + '"]').each(function() {
        var socket_params;
        move_to_archive($(this).attr('data-card-id'), $(this).attr('data-project-id'));
        socket_params = {
          project: Breeze.project_token != null ? Breeze.project_token : $(this).attr('data-project-token'),
          method: "card-archive",
          card: Breeze.Card.id,
          project_id: Breeze.project,
          message: "delete"
        };
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      });
      Breeze.Card.id = null;
      Breeze.Card.project = null;
      el.popover("hide");
      return el.removeData('popover');
    });
    $('.archive-stage-popover').on("click", function(e) {
      return e.stopPropagation();
    });
    return false;
  });

  Breeze.scroller = 0;
  if ($(".sortable_stage")[0]) {
    Breeze.scroller_max = $(".card-sort")[0].scrollWidth - $(".card-sort")[0].clientWidth;
  }
  $('.project-board').on("click", ".carousel-control.next", function(e) {
    $(".carousel-control.prev").show();
    Breeze.scroller = $('.card-sort').scrollLeft() + 350;
    if (Breeze.scroller > Breeze.scroller_max) {
      Breeze.scroller = Breeze.scroller - (Breeze.scroller - Breeze.scroller_max);
    }
    $(".card-sort").stop(true, false).animate({
      scrollLeft: Breeze.scroller
    }, 200, "linear");
    if ($(window).scrollTop() > Breeze.stickyHeaderTop) {
      $(".stage_name").stop(true, false).animate({
        "margin-left": -Breeze.scroller
      }, 200, "linear");
    }
    if (Breeze.scroller >= Breeze.scroller_max) {
      $(this).hide();
      return false;
    }
    $(this).show();
    return e.preventDefault();
  });
  $('.project-board').on("click", ".carousel-control.prev", function(e) {
    $(".carousel-control.next").show();
    Breeze.scroller = $('.card-sort').scrollLeft() - 350;
    if (Breeze.scroller < 0) {
      Breeze.scroller = 0;
    }
    $(".card-sort").stop(true, false).animate({
      scrollLeft: Breeze.scroller
    }, 200, "linear");
    if ($(window).scrollTop() > Breeze.stickyHeaderTop) {
      $(".stage_name").stop(true, false).animate({
        "margin-left": -Breeze.scroller
      }, 200, "linear");
    }
    if (Breeze.scroller <= 0) {
      $(this).hide();
      return false;
    }
    $(this).show();
    return e.preventDefault();
  });
  return $('.project-board').on("click", ".stage-settings .subscribe-list-action", function(e) {
    var card_popover, el, email, selected, selected_users, stage_id, subs, u, users_options;
    el = $(this).closest('.stage_class').find('.dropdown-toggle');
    if ($('#stage-subscribe-menu').length > 0) {
      el.popover("hide");
    } else {
      el.removeData('popover');
      subs = $(this).closest('.stage_class').attr("data-subscribers") || [];
      selected_users = subs.length > 0 ? JSON.parse(subs) : [];
      stage_id = $(this).closest('.stage_class').attr('data-stage-id');
      users_options = [];
      users_options = (function() {
        var i, len, ref, ref1, results;
        ref = Breeze.project_users;
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
          u = ref[i];
          if (u.name === u.email) {
            email = "";
          } else {
            email = u.email;
          }
          selected = (ref1 = u.id, indexOf.call(selected_users, ref1) >= 0) ? 'selected="selected"' : ' ';
          results.push('<option value="' + u.email + '" ' + selected + '>&lt;b data-user-id="' + u.id + '"&gt;' + u.name + '&lt;/b&gt; ' + email + '</option>');
        }
        return results;
      })();
      card_popover = '<div class="fields-users-wrapper styled-multiselect"> <select class="user-item-select hide"  multiple="multiple" name="items[]">' + users_options.join('') + '</select> </div>';
      el.popover({
        container: "element",
        trigger: "manual",
        placement: "bottomleft",
        template: '<div class="popover element-popover card-actions" id="stage-subscribe-menu" data-stage-id="' + stage_id + '"><span class="popover-close">&times;</span><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
        html: true,
        content: card_popover
      });
      el.popover("show");
      Breeze.card_menu = true;
      $('.user-item-select').multiselect({
        numberDisplayed: 0,
        includeSelectAllOption: false,
        nonSelectedText: "Item",
        buttonClass: 'btn btn-link item-btn',
        nSelectedText: '',
        enableFiltering: true,
        enableCaseInsensitiveFiltering: true,
        filterPlaceholder: 'Search user',
        enableHTML: true,
        onInitialized: function(select, container) {
          return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
        },
        onDropdownHide: function() {
          return $(".popover").remove();
        },
        templates: {
          filter: '<li class="multiselect-item multiselect-filter"><div class="input-group"><span class="input-group-addons"><i class="icon-user"></i></span><input class="form-control multiselect-search" type="text" placeholder="Search user"><span class="input-group-btn"></span></div></li>'
        }
      });
      $('#stage-subscribe-menu .btn-group').addClass('open');
      $('#stage-subscribe-menu input[type="checkbox"]:checked').parent().addClass('checkbox-on');
      if (!$('body').hasClass("ie11")) {
        $('#stage-subscribe-menu input[type="text"]').focus();
      }
      $('#stage-subscribe-menu').on("change", ".multiselect-container .checkbox input", function() {
        var id, ref, socket_params, subscribers;
        $(this).parent().toggleClass("checkbox-on");
        stage_id = $(this).closest('.popover').attr('data-stage-id');
        subscribers = $('.stage_class[data-stage-id="' + stage_id + '"]').first().attr('data-subscribers') || "";
        if ($(this).parent().hasClass("checkbox-on")) {
          id = $(this).parent().find('b').attr('data-user-id');
          if (subscribers.length > 0) {
            subscribers = $.parseJSON(subscribers);
            subscribers.push(parseInt(id));
          } else {
            subscribers = [parseInt(id)];
          }
          $('.stage_class[data-stage-id="' + stage_id + '"]').first().attr('data-subscribers', JSON.stringify(subscribers));
          socket_params = {
            project: Breeze.project_token,
            method: "stage-subscribe",
            stage: stage_id,
            ids: subscribers
          };
          $.ajax({
            type: "POST",
            data: {
              ids: subscribers,
              multiple: true
            },
            queue: true,
            url: '/projects/' + Breeze.project + '/stages/' + stage_id + '/subscribe',
            success: function() {
              if (Breeze.is_socket_connected()) {
                return Breeze.socket.emit("message", socket_params);
              }
            }
          });
        } else {
          email = $(this).val();
          id = $(this).parent().find('b').attr('data-user-id');
          if (subscribers.length > 0) {
            subscribers = $.parseJSON(subscribers);
            subscribers = _.without(subscribers, parseInt(id));
          }
          $('.stage_class[data-stage-id="' + stage_id + '"]').first().attr('data-subscribers', JSON.stringify(subscribers));
          socket_params = {
            project: Breeze.project_token,
            method: "stage-subscribe",
            stage: stage_id,
            ids: subscribers
          };
          $.ajax({
            type: "POST",
            url: '/projects/' + Breeze.project + '/stages/' + stage_id + '/subscribe',
            queue: true,
            data: {
              ids: subscribers,
              multiple: true
            },
            success: function() {
              if (Breeze.is_socket_connected()) {
                return Breeze.socket.emit("message", socket_params);
              }
            }
          });
        }
        if (ref = Breeze.current_user.id, indexOf.call(subscribers, ref) >= 0) {
          if ($('.stage_class[data-stage-id="' + stage_id + '"]').first().find('.subs-bubble').length <= 0) {
            return $('<span class="subs-bubble"><i class="icon-eye" title="subscribed to email notifications"></i></span>').prependTo('.stage_class[data-stage-id="' + stage_id + '"]:first .stage_name');
          }
        } else {
          return $('.stage_class[data-stage-id="' + stage_id + '"]').first().find('.subs-bubble').remove();
        }
      });
      $('#stage-subscribe-menu').on("click", ".popover-close", function(e) {
        el.popover("hide");
        return el.removeData('popover');
      });
      $('#stage-subscribe-menu').on("click", function(e) {
        return e.stopPropagation();
      });
    }
    return false;
  });
};

Breeze.inc_wip_total = function(card) {
  var has_status, stage, v;
  if ($('.project-board').length > 0) {
    stage = $('.sortable_stage:first .stage_class[data-stage-id="' + card.attr('data-stage-id') + '"]');
    v = parseInt(stage.attr('data-wip-total'), 10);
    if (stage.attr('data-wip-status') === "true") {
      stage.attr('data-wip-total', stage.find('.card').length);
    } else {
      has_status = card.attr('data-status-id') && card.attr('data-status-id').length > 0;
      if (!has_status) {
        stage.attr('data-wip-total', (v < 0 ? 0 : v + 1));
      }
    }
    return Breeze.update_list_wip(stage);
  }
};

Breeze.dec_wip_total = function(card) {
  var has_status, stage, v;
  if ($('.project-board').length > 0) {
    stage = $('.sortable_stage:first .stage_class[data-stage-id="' + card.attr('data-stage-id') + '"]');
    v = parseInt(stage.attr('data-wip-total'), 10);
    if (stage.attr('data-wip-status') === "true") {
      stage.attr('data-wip-total', stage.find('.card').length);
    } else {
      has_status = card.attr('data-status-id') && card.attr('data-status-id').length > 0;
      if (!has_status) {
        stage.attr('data-wip-total', (v < 0 ? 0 : v - 1));
      }
    }
    return Breeze.update_list_wip(stage);
  }
};

Breeze.update_wip_total = function(card, has_old_status) {
  var has_status, stage, v;
  if (has_old_status == null) {
    has_old_status = false;
  }
  if ($('.project-board').length > 0) {
    stage = $('.sortable_stage:first .stage_class[data-stage-id="' + card.attr('data-stage-id') + '"]');
    v = parseInt(stage.attr('data-wip-total'), 10);
    if (stage.attr('data-wip-status') === "true") {
      stage.attr('data-wip-total', stage.find('.card').length);
    } else {
      has_status = card.attr('data-status-id') && card.attr('data-status-id').length > 0;
      if (has_status && !has_old_status) {
        stage.attr('data-wip-total', (v < 0 ? 0 : v - 1));
      } else if (!has_status) {
        stage.attr('data-wip-total', (v < 0 ? 0 : v + 1));
      }
    }
    return Breeze.update_list_wip(stage);
  }
};

Breeze.update_wip_total_all = function(stage_id) {
  var stage;
  if ($('.project-board').length > 0) {
    stage = $('.sortable_stage:first .stage_class[data-stage-id="' + stage_id + '"]');
    if (stage.attr('data-wip-status') === "true") {
      stage.attr('data-wip-total', stage.find('.card').length);
    } else {
      stage.attr('data-wip-total', stage.find('.card[data-status-id=""]').length);
    }
    return Breeze.update_list_wip(stage);
  }
};

Breeze.update_list_wip = function(el) {
  el = el || $('.sortable_stage:first .stage_class');
  return el.each(function() {
    var cards, stage, wel, wip;
    wip = parseInt($(this).attr('data-wip'));
    wel = $(this).find('.wip');
    if (wip > 0) {
      stage = $(this).attr('data-stage-id');
      cards = parseInt($(this).attr('data-wip-total'), 10);
      wel.html(cards + "/" + wip);
      if (cards > wip) {
        return wel.addClass("red");
      } else {
        return wel.removeClass("red");
      }
    } else {
      return wel.html("");
    }
  });
};

// ---
// generated by coffee-script 1.9.2