import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

var init_popover,
  
	indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
	var el;
$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});
function init_page() {
  // key("t", "board", function(event, handler) {
  //   var c, card, project, selected_items;
  //   c = $('.card:hover');
  //   if (c.length > 0 && !$('#card_modal').hasClass('show')) {
  //     $('.popover').remove();
  //     c.removeData("bs.popover");
  //     card = c.attr('data-card-id');
  //     project = c.attr('data-project-id');
  //     Breeze.Card.color = c.attr('data-card-colorbg');
  //     selected_items = [];
  //     $('.card:hover .tags-bubble .pill').each(function() {
  //       return selected_items.push($(this).attr('data-tag'));
  //     });
  //     init_popover(c, selected_items, card, project);
  //     return false;
  //   }
  // });
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-tag", function() {
    var selected_items;
		
		
		el = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
		
    $(".popover:not(#card-tag-menu)").remove();
    if ($('#card-tag-menu').length > 0) {
      el.popover("hide");
    } else {
       $('.element-menu').popover('dispose')
      selected_items = [];
			
      $('#card_modal .card-attributes.tags .pill').each(function() {
        selected_items.push($(this).attr('data-tag'));
      });
      init_popover(el, selected_items, Breeze.Card.id, Breeze.Card.project);
    }
    return false;
  });
	
};

init_popover = function(that, selected_items, card, project) {
  var card_popover, i, item_options, selected;
  item_options = [];
  item_options = (function() {
    var j, len, ref, ref1, results;
    ref = Breeze.project_tags;
    results = [];
    for (j = 0, len = ref.length; j < len; j++) {
      i = ref[j];
      if (Breeze.is_project_tag_hidden(Breeze.project_tags_hidden, i.name, Breeze.Card.project)) {
        continue;
      }
      selected = (ref1 = i.name, indexOf.call(selected_items, ref1) >= 0) ? 'selected="selected"' : ' ';
      results.push('<option value="' + i.name + '" ' + selected + ' data-color="' + i.color + '">' + i.name + '</option>');
    }
    return results;
  })();
  card_popover = '<div class="fields-users-wrapper styled-multiselect"> <select class="user-item-select hide"  multiple="multiple" name="items[]"> <option class="hidden-placeholder"></option>' + item_options.join('') + '</select> </div>';
  that.popover({
    trigger: "manual",
    placement: "bottom",
    template: '<div class="popover element-popover card-actions" ><div id="card-tag-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
    html: true,
		animation: false,
		container: el,
		sanitize: false,
    content: card_popover
  });
  that.popover("show");
  Breeze.card_menu = true;
  $('#card-tag-menu .user-item-select').multiselect({
    numberDisplayed: 0,
    includeSelectAllOption: false,
    nonSelectedText: "Item",
    buttonClass: 'btn btn-link item-btn',
    nSelectedText: '',
    filterPlaceholder: 'Create or search',
    enableHTML: true,
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    onInitialized: function(select, container) {
      $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownHide: function() {
      $(".popover").remove();
    },
    templates: {
      filter: '<li class="multiselect-item multiselect-filter"><div class="input-group"><span class="input-group-addons"><i class="icon-tag"></i></span><input class="form-control multiselect-search" type="text" placeholder="Search user"><span class="input-group-btn"></span></div><div class="create-tag-placeholder"></div></li>'
    }
  });
  $('#card-tag-menu .btn-group').addClass('show');
  $('#card-tag-menu .dropdown-menu').addClass('show');
	
  // $('#card-tag-menu .dropdown-toggle').trigger('click');
	
  $('#card-tag-menu input[type="checkbox"]:checked').parent().addClass('checkbox-on');
  $('#card-tag-menu .create-tag-placeholder').hide();
  if (!$('body').hasClass("ie11")) {
    $('#card-tag-menu input[type="text"]').focus();
  }
  if ($('#card-tag-menu .user-item-select option[value]').length <= 0) {
    $('#card-tag-menu .create-tag-placeholder').text('Type to add tags').show();
  }
  $('#card-tag-menu').on("input", ".multiselect-search", function(e) {
    var el, tag, target;
    el = $(this);
    target = $('#card-tag-menu .create-tag-placeholder');
    tag = el.val().trim();
    if (tag.length > 0 && $('#card-tag-menu .user-item-select option[value="' + tag + '"]').length <= 0) {
      return target.text("Press ENTER to add '" + tag + "'").show();
    } else {
      target.hide();
      if ($('#card-tag-menu .user-item-select option[value]').length <= 0) {
        return $('#card-tag-menu .create-tag-placeholder').text('Type to add tags').show();
      }
    }
  });
  $('#card-tag-menu').on("keydown", ".multiselect-search", function(e) {
    var tag_name;
    if (e.keyCode === 13) {
      tag_name = $('#card-tag-menu .multiselect-search').val().trim();
      if (tag_name.length <= 0) {
        return false;
      }
      if ($('#card-tag-menu .user-item-select option[value="' + tag_name + '"]').length > 0) {
        $('#card-tag-menu .multiselect-container li input[value="' + tag_name + '"]').trigger("click");
      } else {
        $('#card-tag-menu .user-item-select').prepend('<option value="' + tag_name + '">' + tag_name + '</option>');
        Breeze.project_tags.unshift({
          name: tag_name,
          label: tag_name,
          color: ""
        });
        $('#card-tag-menu .user-item-select').multiselect("destroy");
        $('#card-tag-menu .user-item-select').multiselect({
          numberDisplayed: 0,
          includeSelectAllOption: false,
          nonSelectedText: "Item",
          buttonClass: 'btn btn-link item-btn',
          nSelectedText: '',
          filterPlaceholder: 'Search or create',
          enableHTML: true,
          enableFiltering: true,
          enableCaseInsensitiveFiltering: true,
          onInitialized: function(select, container) {
            $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
            return $('#card-tag-menu .multiselect-container li input[value="' + tag_name + '"]').trigger("click");
          },
          onDropdownHide: function() {
            return $(".popover").remove();
          },
          templates: {
            filter: '<li class="multiselect-item multiselect-filter"><div class="input-group"><span class="input-group-addons"><i class="icon-user"></i></span><input class="form-control multiselect-search" type="text" placeholder="Search user"><span class="input-group-btn"></span></div><div class="create-tag-placeholder"></div></li>'
          }
        });
			  $('#card-tag-menu .btn-group').addClass('show');
			  $('#card-tag-menu .dropdown-menu').addClass('show');
				
        $('#card-tag-menu input[type="checkbox"]:checked').parent().addClass('checkbox-on');
        $('#card-tag-menu .create-tag-placeholder').hide();
        if (!$('body').hasClass("ie11")) {
          $('#card-tag-menu input[type="text"]').focus();
        }
      }
      $('#card-tag-menu .create-tag-placeholder').hide();
    }
  });
  $('#card-tag-menu').on("change", ".multiselect-container .checkbox input", function() {
    var el, name, socket_params, tag_color, tag_el, tag_name, tags_colors;
    $(this).parent().toggleClass("checkbox-on");
    that = $(this);
    name = $(this).val();
    if ($(this).parent().hasClass("checkbox-on")) {
      if ($('#card_modal').hasClass('show') && $(".card-content .tags").length === 0) {
        $('<div class="row"><div class="span8 card-attributes tags" data-readonly="false"><h5>Tags</h5></div></div>').insertAfter(".card-header-b");
      }
      if ($('.card[data-card-id="' + card + '"] .tags-bubble').length <= 0) {
        $('.card[data-card-id="' + card + '"] .badges .card-tags-bubble-container').append('<span class="tags-bubble"></span>');
      }
      tag_color = "";
      tag_name = _.escape(name.trim())
      tag_el = _.find(Breeze.project_tags, function(tag) {
        return tag.name.toLowerCase() === name.trim().toLowerCase();
      });
      if (tag_el) {
        tag_color = tag_el.color;
      }
      if (tag_el) {
        tag_name = tag_el.name;
      }
      el = $('<span class="pill" style="background:' + tag_color + ';" data-tag="' + tag_name + '">' + tag_name + '<span class="remove">&times;</span></span>');
      $('.card-content .tags, .card[data-card-id="' + card + '"] .tags-bubble').append(el).find(".pill:last");
      tags_colors = [
        {
          name: name,
          color: tag_color
        }
      ];
      socket_params = {
        project: Breeze.project_token,
        method: "card-tag",
        card: card,
        message: [name]
      };
      $.ajax({
        type: "POST",
        queue: true,
        data: {
          tags: name,
          tags_colors: tags_colors
        },
        url: '/projects/' + project + '/cards/' + card + '/tags',
        success: function() {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
    } else {
      $('.card-content .tags .pill[data-tag="' + name + '"]').remove();
      $('.card[data-card-id="' + card + '"] .tags-bubble .pill[data-tag="' + name + '"]').remove();
      if ($('.card[data-card-id="' + card + '"] .tags-bubble .pill ').length <= 0) {
        $('.card[data-card-id="' + card + '"] .tags-bubble').remove();
      }
      if ($('#card_modal').hasClass('show') && $('.card-content .tags .pill').length <= 0) {
        $('.card-content .tags').parent().remove();
      }
      socket_params = {
        project: Breeze.project_token,
        method: "card-tag-remove",
        card: card,
        message: name
      };
      $.ajax({
        type: "DELETE",
        queue: true,
        url: '/projects/' + project + '/cards/' + card + '/tags/1',
        data: {
          tag: name
        },
        success: function() {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
    }
		update_project_list_view_columns("tags")
    // return Breeze.init_task_parser();
  });
};

Breeze.is_project_tag_hidden = function(hidden_tags, tag_name, project_id) {
  var isFound;
  return isFound = hidden_tags.some(function(tag) {
    if (tag.name === tag_name && tag.project_id === parseInt(project_id)) {
      return true;
    }
    return false;
  });
};
