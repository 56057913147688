import {developement} from 'mobile/utils.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after,uniqueId,generate_s3_key} 
				from 'mobile/froala/setup.js';
import dateFormat from 'dateformat';
import {chooseDateFormat} from 'mobile/utils.js';
			
var init_note_editor, init_notes, init_page, save_note;


$(document).ready(function() {
  $(document).on('hide', '#project-notes-modal', function(e, d) {
    var count;
    count = $('#project-notes-modal .notes-note').length;
    if (count <= 0) {
      $('.project-actions [data-action="project-notes"] .number-circle').text("");
    } else {
      $('.project-actions [data-action="project-notes"] .number-circle').text(count);
    }
    // History.pushState({
    //   "static": true,
    //   notes_close: true,
    //   url: "/projects/" + Breeze.project
    // }, $("title").text(), "/projects/" + Breeze.project);

    $('#project-notes-modal .modal-body').html('');
    froala_setup.heightMin = 220;
  });
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    init_page();
  }
		
	$(window).resize(function() {
		
		if ($("#project-notes-modal").hasClass("show")) {
			$('.notes-note-content').attr("style","")
			$('.notes-side').attr("style","")
			$('.notes-note-back').attr("style","")
		}
		

		
	});
	
});

init_page = function() {
  var loc;
  loc = window.location.pathname.match(/notes/);
  if (loc && loc.length > 0) {
    setTimeout((function() {
      return $(".project-board-notes]").trigger("click");
    }), 150);
  }
	
  $('#project-notes-modal').on("click", ".notes-note-back", function(e) {
		$('.notes-note-content').hide()
		$('.notes-note-back').hide()
		
		$('.notes-side').show()
		
		return false;
		
	});
	
  $('.project-board').on("click", ".project-board-notes", function(e) {
    var url;
    if (developement()) {
      url = "https://app.breeze.test:3006/projects/" + Breeze.project + '/notes/all' + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n4.herokuapp.com/projects/" + Breeze.project + '/notes/all' + "?auth_token=" + Breeze.current_user.token;
    }
    $('#project-notes-modal').modal({
      dynamic: true
    });
    $('#project-notes-modal .modal-body').html('');
    $('#project-notes-modal .modal-body').append(Breeze.global_loader);
    $("#project-notes-modal").modal("show");
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        partial: true
      },
      success: function(data) {
        $('#project-notes-modal .m-loader').remove();
        $('#project-notes-modal .modal-body').html($(data).html());
        init_notes();
				
				if ($('.notes-note-content:visible').length > 0) {
				
	        loc = window.location.pathname.match(/\/notes\/(\d.*)/);
	        if (loc && loc.length > 0) {
	          return init_note_editor(loc[1]);
	        } else {
	          return init_note_editor();
	        }
				}
      }
    });
    return false;
  });
};

init_notes = function() {
	

	$('.notes-note-back').hide()
			
	
  $('.notes-side-list').on("click", ".notes-note-delete", function(e) {
    var note_id, prev_note_id, url;
    note_id = $(this).closest('.notes-note').attr('data-note-id');
    if (developement()) {
      url = "https://app.breeze.test:3006/projects/" + Breeze.project + "/notes/" + note_id + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n4.herokuapp.com/projects/" + Breeze.project + "/notes/" + note_id + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: 'DELETE',
      url: url,
      queue: true
    });
    $('.notes-note-content').removeAttr('data-note-last-id');
    prev_note_id = $(this).closest('.notes-note').prev().attr('data-note-id');
    init_note_editor(prev_note_id);
    $(this).closest('.notes-note').remove();
    return false;
  });
  $('.notes-side-list').on("click", ".notes-note", function(e) {
    return init_note_editor($(this).attr('data-note-id'));
  });
	

  $('.notes-body').on("click", ".notes-note-new", function(e) {
    var el, url;
    el = '<div class="notes-note"> <div class="dropdown notes-note-settings-dropdown"> <a href="#" data-toggle="dropdown" class="dropdown-toggle" aria-expanded="false"> <svg aria-hidden="true" class="more-icon" focusable="false" data-prefix="fas" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"></path></svg> </a> <ul class="dropdown-menu"> <li class="notes-note-delete"><a href="#"><i class="fa fa-trash-o" aria-hidden="true"></i> Delete </a></li> </ul> </div> <div class="notes-note-name">New note</div> <div class="notes-note-date"></div> <div class="notes-note-description"></div> </div>';
    $('.notes-side-list').prepend(el);
    $('.notes-note').removeClass("active");
    $(el).addClass("active");
    $('.notes-note-editor').removeAttr('data-note-id');
    $('.notes-note-empty').hide();
    $('.notes-note-editor').show();
    $('.notes-note-editor-name input').val("");
    $('.notes-note-editor-description textarea').val("");
    if ($('#project-notes-modal textarea').data('froala.editor') != null) {
      $('#project-notes-modal textarea').froalaEditor('destroy');
    }
    $('.notes-note-editor-description textarea').val("");
		
		if ($('.notes-note-content:visible').length <= 0) {
			$('.notes-note-content').show()
			$('.notes-note-back').show()
			
			$('.notes-side').hide()
			
		}
		
    $('#project-notes-modal textarea').on('froalaEditor.initialized', function(e, editor) {
      editor_setup(editor);
      find_editor_attachments();
      if ($('.upload-disabled').length > 0) {
        editor.events.on('drop', (function(dropE) {
          return false;
        }), true);
      }
    });
    froala_setup.heightMin = 350;
    $('#project-notes-modal textarea').froalaEditor(froala_setup);
    $('#project-notes-modal textarea').on('froalaEditor.blur', function(e, editor) {
      save_note();
    });
    $('.notes-note-editor-name input').focus();
    url = "/projects/" + Breeze.project + "/notes";
    // History.pushState({
    //   "static": true,
    //   notes_modal: true,
    //   url: url
    // }, $("title").html(), url);
    return false;
  });
  $('#project-notes-modal textarea').on('froalaEditor.blur', function(e, editor) {
    save_note();
  });
  $('.notes-note-editor').on("blur", "input", function() {
    save_note();
  });
};

init_note_editor = function(last_id) {
  var State, el, url;
  if (last_id == null) {
    last_id = $('.notes-note-content').attr('data-note-last-id');
  }
  el = $('.notes-side-list .notes-note[data-note-id="' + last_id + '"]');
  if (el.length > 0) {
    $('.notes-note-empty').hide();
    $('.notes-note-editor').show();
    $('.notes-note').removeClass("active");
    el.addClass('active');
		
		if ($('.notes-note-content:visible').length <= 0) {
			$('.notes-note-content').show()
			$('.notes-note-back').show()
			
			$('.notes-side').hide()
			
		}
			
			
    if ($('#project-notes-modal textarea').data('froala.editor') != null) {
      $('#project-notes-modal textarea').froalaEditor('destroy');
    }
    $('.notes-note-editor').attr('data-note-id', el.attr('data-note-id'));
    $('.notes-note-editor-name input').val(el.find('.notes-note-name').text());
    $('.notes-note-editor-description textarea').val(el.find('.notes-note-description').html());
    $('.notes-note-content').attr('data-note-last-id', last_id);
    Breeze.mention_users = $.map(Breeze.project_users, function(value, i) {
      return {
        id: value.id,
        name: $.user_name(value).replace(/\s+/g, ''),
        display: $.user_name_email(value),
        email: value.email,
        fname: value.firstname ? value.firstname : ""
      };
    });
		
		init_froala_setup()
		
    $('#project-notes-modal textarea').on('froalaEditor.initialized', function(e, editor) {
      editor_setup(editor);
      find_editor_attachments();
      if ($('.upload-disabled').length > 0) {
        return editor.events.on('drop', (function(dropE) {
          return false;
        }), true);
      }
    });
    froala_setup.heightMin = 350;
    $('#project-notes-modal textarea').froalaEditor(froala_setup);
    $('#project-notes-modal textarea').on('froalaEditor.blur', function(e, editor) {
      save_note();
    });
    $('#project-notes-modal textarea').froalaEditor('events.focus');
    url = "/projects/" + Breeze.project + "/notes/" + last_id;
    // State = History.getState();
    // if (State.data.url !== url) {
    //   return History.pushState({
    //     "static": true,
    //     notes_modal: true,
    //     url: url
    //   }, $("title").html(), url);
    // }
  } else {
    $('.notes-note-empty').show();
    $('.notes-note-editor').hide();
    url = "/projects/" + Breeze.project + "/notes";
    // return History.pushState({
    //   "static": true,
    //   notes_modal: true,
    //   url: url
    // }, $("title").html(), url);
  }
};

save_note = function() {
  var date, e, i, mentions, note_desc, note_id, note_name, ref, scanned_mentions, t, url;
  note_id = $('.notes-note-editor').attr('data-note-id');
  note_name = $('.notes-note-editor-name input').val();
  if (note_name.length <= 0) {
    note_name = "New note";
  }
  note_desc = $('#project-notes-modal textarea').froalaEditor('html.get');
  mentions = [];
  scanned_mentions = [];
  if (Breeze.clock24) {
    date = dateFormat(new Date(), "HH:MM");
  } else {
    date = dateFormat(new Date(), "HH:MMtt");
  }
  $('<div>' + note_desc + '</div>').find('.atwho-inserted').each(function() {
    return scanned_mentions.push($(this).text());
  });
  ref = Breeze.mentions;
  for (i in ref) {
    e = ref[i];
    if (_.contains(scanned_mentions, e.name)) {
      mentions.push(e.id);
    }
  }
  if (note_id != null) {
    if (developement()) {
      url = "https://app.breeze.test:3006/projects/" + Breeze.project + "/notes/" + note_id + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n4.herokuapp.com/projects/" + Breeze.project + "/notes/" + note_id + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: 'PUT',
      url: url,
      queue: true,
      data: {
        name: note_name,
        description: note_desc,
        mention_ids: mentions
      },
      success: function(data) {
        var t;
        if (data && data === "update") {
          t = $('.notes-side-list .notes-note[data-note-id="' + note_id + '"]');
          return t.find('.notes-note-date').html(date);
        }
      }
    });
    t = $('.notes-side-list .notes-note[data-note-id="' + note_id + '"]');
    if (t) {
      t.find('.notes-note-name').text(note_name);
      return t.find('.notes-note-description').html(note_desc);
    }
  } else {
    if (developement()) {
      url = "https://app.breeze.test:3006/projects/" + Breeze.project + '/notes' + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n4.herokuapp.com/projects/" + Breeze.project + '/notes' + "?auth_token=" + Breeze.current_user.token;
    }
    return $.ajax({
      type: 'POST',
      url: url,
      queue: true,
      data: {
        name: note_name,
        description: note_desc,
        mention_ids: mentions
      },
      success: function(data) {
        $('.notes-note-editor').attr('data-note-id', data);
        t = $('.notes-side-list .notes-note:first');
        if (t) {
          t.attr('data-note-id', data);
          t.find('.notes-note-name').text(note_name);
          t.find('.notes-note-description').html(note_desc);
          t.find('.notes-note-date').html(date);
          url = "/projects/" + Breeze.project + "/notes/" + data;
          // return History.pushState({
          //   "static": true,
          //   notes_modal: true,
          //   url: url
          // }, $("title").html(), url);
        }
      }
    });
  }
};

