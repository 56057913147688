/*!
 * froala_editor v2.8.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            return factory(jQuery);
        };
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function ($) {

  

  $.FE.PLUGINS.checklist = function (editor) {


    /**
     * Format blocks.
     */
    function _format(blocks, tag_name) {
      // _replace(blocks, tag_name);

      // Format those blocks that are not LI.
      var default_tag = editor.html.defaultTag();

      var start_margin = null;

      var prop;
      if (blocks.length) prop = (editor.opts.direction == 'rtl' || $(blocks[0]).css('direction') == 'rtl') ? 'margin-right' : 'margin-left';

      for (var i = 0; i < blocks.length; i++) {
        if (blocks[i].tagName != 'INPUT') {

          // Get margin left and unset it.
          var margin_left = editor.helpers.getPX($(blocks[i]).css(prop)) || 0;
          blocks[i].style.marginLeft = null;


          // Default tag.
          if (default_tag && blocks[i].tagName.toLowerCase() == default_tag) {
            $(blocks[i]).replaceWith( '<p><span class="breeze-checklist-wrapper fr-deletable" contenteditable="false"><input type="checkbox" class="breeze-checklist"' + editor.node.attributes(blocks[i]) + '></span><span>&nbsp;' + $(blocks[i]).html()+'</span></p>');
          }
          else {

            // $(blocks[i]).wrap(open_tag + '<li></li>' + end_tag);
            $(blocks[i]).replaceWith( '<p><span class="breeze-checklist-wrapper fr-deletable" contenteditable="false"><input type="checkbox" class="breeze-checklist"' + editor.node.attributes(blocks[i]) + '></span><span>&nbsp;' + $(blocks[i]).html()+'</span></p>');
						
      						// blocks[i].outerHTML =  '<p><span class="breeze-checklist-wrapper fr-deletable" contenteditable="false"><input type="checkbox" class="breeze-checklist"></span><span>&nbsp;' + blocks[i].outerHTML + '</span></p>';
          }
        }
      }

      // editor.clean.lists();
    }

    /**
     * Unformat.
     */
    function _unformat(blocks) {
      var i;
      var j;

      var lists = [];
      for (i = 0; i < blocks.length; i++) {
        var $li = $(blocks[i]);

				$li.find('.breeze-checklist-wrapper').remove()
				html = $li.html()
				html = html.replace(/&nbsp;/g, '')
				$li.html(html)
			}
      editor.html.wrap();
    }

    /**
     * Check if should unformat lists.
     */
    function _shouldUnformat(blocks, tag_name) {
      var do_unformat = true;
      for (var i = 0; i < blocks.length; i++) {
				// console.log(blocks[i].tagName )
				// blocks[i] && !blocks[i].firstElementChild.classList.contains("checklist")
        // Something else than LI is selected.
				// console.log(blocks[i])s

        // if (blocks[i] && !blocks[i].firstElementChild.classList.contains("breeze-checklist")) {
        //   return false;
        // }
        if (blocks[i] && !blocks[i].firstElementChild.classList.contains("breeze-checklist-wrapper")) {
          return false;
        }
        // There is a different kind of list selected. Replace is the appropiate action.
        if (blocks[i] && !blocks[i].firstElementChild.classList.contains("breeze-checklist-wrapper")) {
          do_unformat = false;
        }
      }
      return do_unformat;
    }

    /**
     * Call the list actions.
     */
    function format(tag_name) {
			
      // Wrap.
      editor.selection.save();
      editor.html.wrap(true, true, true, true);
      editor.selection.restore();

      var blocks = editor.selection.blocks();

      // Save selection so that we can play at wish.
      editor.selection.save();

      // Decide if to format or unformat list.
      if (_shouldUnformat(blocks, tag_name)) {
        _unformat(blocks);
      }
      else {
        _format(blocks, tag_name);
      }

      // Unwrap.
      editor.html.unwrap();

      // Restore the selection.
      editor.selection.restore();
    }

    /**
     * Refresh list buttons.
     */
    function refresh($btn, tag_name) {
      var $el = $(editor.selection.element());

      if ($el.get(0) != editor.el) {
        var li = $el.get(0);
				// console.log(li.previousSibling)
        if (li && ( li.classList.contains("breeze-checklist-wrapper") || ( li.previousSibling && li.previousSibling.classList && li.previousSibling.classList.contains("breeze-checklist-wrapper"))) && editor.el.contains(li)) {
          $btn.addClass('fr-active');
        }
      }
    }


    /**
     * Init.
     */
    function _init () {
      // editor.events.on('commands.after', _afterCommand);

			
      editor.events.on('click', function (e) {
				
				if (e && e.target && e.target && e.target.firstElementChild &&  e.target.firstElementChild.classList.contains("breeze-checklist")) {
					if (!e.target.firstElementChild.checked) {
						e.target.firstElementChild.setAttribute("checked",e.target.firstElementChild.checked);
					}
					else {
						
						e.target.firstElementChild.removeAttribute("checked");
					
					}
						$(e.target.firstElementChild).parent().toggleClass("breeze-checklist-checked")	
					
				}

				
				false
			})
			
      editor.events.on('keydown', function (e) {
        if (e.which == $.FE.KEYCODE.ENTER) {
          var blocks = editor.selection.blocks();
					if ( blocks.length == 1 ) {
						if (blocks[0]  && $(blocks[0]).find('.breeze-checklist').length > 0 ) {
							if ($(blocks[0]).text().trim().length > 0) {
								
								 editor.html.insert('<p><span class="breeze-checklist-wrapper fr-deletable" contenteditable="false"><input type="checkbox" class="breeze-checklist"></span><span>&nbsp;</span></p>', false);
									e.stopPropagation()
									e.preventDefault()
							 }
							 else {
								 
							 	$(blocks[0]).remove()
							 }

				             return false;
							 
						}
					}

					
					
				}

      }, true);
			
    }

    return {
      _init: _init,
      format: format,
      refresh: refresh
    }
  }


  // Register the font size command.
  $.FE.RegisterCommand('checklist', {
    title: 'Checklist',
    refresh: function ($btn) {
      this.checklist.refresh($btn, 'INPUT');
    },
    callback: function () {
      this.checklist.format('INPUT');
    },
    plugin: 'checklist'
  })


	$.FE.DefineIconTemplate('checklist_svg', '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tasks" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-tasks fa-w-16 fa-3x"><path fill="currentColor" d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" class=""></path></svg>');
	
  $.FE.DefineIcon('checklist', {
    NAME: 'checklist',
		template: 'checklist_svg'
		
  });

}));
