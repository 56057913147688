import {embed_images, unembed_images} from 'mobile/utils/embed.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after} 
				from 'mobile/froala/setup.js';

import {update_task_stripe} from 'mobile/utils/task_stripe.js';
import {dec_wip_total,inc_wip_total} from 'mobile/utils/wip.js'
import {hide_first_swimlane} from 'mobile/utils/swimlane.js'
import {update_calendar} from 'mobile/cards/card-menu/duedate.js'

import {} from 'mobile/utils/gravatar.js';
import {developement,load_card_globals} from 'mobile/utils.js';
import {update_project_list_view_columns, update_dashboard_list_width} from 'mobile/utils/project_board.js'


var init_page;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "dashboard") {
      return init_page();
    }
  });
  if ($('.workspace.dashboard').length > 0) {
    return init_page();
  }
});

init_page = function() {
  $('.dashboard').on("click", ".project-board-fields-btn", function(event) {
    $(this).find('li a').removeClass("active");
    if ($('.show-ref').length > 0) {
      $(this).find('.project-board-fields-task-id a').addClass("active");
    }
    if ($('.project-hide-status').length <= 0) {
      $(this).find('.project-board-fields-status a').addClass("active");
    }
    if ($('.project-hide-tags').length <= 0) {
      $(this).find('.project-board-fields-tags a').addClass("active");
    }
    if ($('.project-hide-color').length <= 0) {
      $(this).find('.project-board-fields-color a').addClass("active");
    }
    if ($('.project-hide-dates').length <= 0) {
      $(this).find('.project-board-fields-dates a').addClass("active");
    }
    if ($('.project-hide-time-tracking').length <= 0) {
      $(this).find('.project-board-fields-times a').addClass("active");
    }
    if ($('.project-hide-todos').length <= 0) {
      $(this).find('.project-board-fields-todos a').addClass("active");
    }
    if ($('.project-hide-counts').length <= 0) {
      $(this).find('.project-board-fields-counts a').addClass("active");
    }
    if ($('.project-hide-images').length <= 0) {
      $(this).find('.project-board-fields-images a').addClass("active");
    }
    if ($('.project-hide-custom-fields').length <= 0) {
      return $(this).find('.project-board-fields-custom a').addClass("active");
    }
  });
  return $('.dashboard').on("click", ".project-board-fields-btn li a", function(event) {
    var column, is_active, url;
    $(this).toggleClass("active");
    is_active = $(this).hasClass("active");
    column = $(this).attr("data-column");
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-task-id a').hasClass("active")) {
      $('.dashboard').addClass('show-ref');
    } else {
      $('.dashboard').removeClass('show-ref');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-status a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-status');
    } else {
      $('.dashboard').addClass('project-hide-status');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-tags a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-tags');
    } else {
      $('.dashboard').addClass('project-hide-tags');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-color a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-color');
    } else {
      $('.dashboard').addClass('project-hide-color');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-dates a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-dates');
    } else {
      $('.dashboard').addClass('project-hide-dates');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-times a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-time-tracking');
    } else {
      $('.dashboard').addClass('project-hide-time-tracking');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-todos a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-todos');
    } else {
      $('.dashboard').addClass('project-hide-todos');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-counts a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-counts');
    } else {
      $('.dashboard').addClass('project-hide-counts');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-images a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-images');
    } else {
      $('.dashboard').addClass('project-hide-images');
    }
    if ($(this).closest('.dropdown-menu').find('.project-board-fields-custom a').hasClass("active")) {
      $('.dashboard').removeClass('project-hide-custom-fields');
    } else {
      $('.dashboard').addClass('project-hide-custom-fields');
    }

    $.ajax({
      type: 'POST',
      url: "/profile?auth_token=" + Breeze.current_user.token,
      data: {
        user: {
          dashboard_show_ids: $('.project-board-fields-task-id a').hasClass("active"),
          dashboard_show_status: $('.project-board-fields-status a').hasClass("active"),
          dashboard_show_tags: $('.project-board-fields-tags a').hasClass("active"),
          dashboard_show_color: $('.project-board-fields-color a').hasClass("active"),
          dashboard_show_dates: $('.project-board-fields-dates a').hasClass("active"),
          dashboard_show_time_tracking: $('.project-board-fields-times a').hasClass("active"),
          dashboard_show_todos: $('.project-board-fields-todos a').hasClass("active"),
          dashboard_show_counts: $('.project-board-fields-counts a').hasClass("active"),
          dashboard_show_images: $('.project-board-fields-images a').hasClass("active"),
          dashboard_show_custom_fields: $('.project-board-fields-custom a').hasClass("active")
        }
      }
    });
    if (is_active) {
      update_project_list_view_columns(column);
    } else {
      update_dashboard_list_width();
    }
    return false;
  });
};

