require("mobile/utils/icons.js");
require("mobile/utils/timer.js");

import {developement} from 'mobile/utils.js';

import moment from "mobile/moment.min.js";
import dateFormat from 'dateformat';
import {update_project_layout,update_project_list_width, update_dashboard_list_width, update_project_list_view_columns, init_board_list_view,project_board_reset_fields} from 'mobile/utils/project_board.js'

var calc_time_from_tracked, calc_tracked_from_time, init_timeentries, init_timetracking, parse_hours_minutes, parse_times, set_timeentry_time;

init_timetracking = function() {
  if (Breeze.current_user.hide_timetracking) {
    $('#card_modal .time-tracker .tracker').addClass("hide");
    $('#card_modal .time-tracker .hide-timetracking').addClass("active");
  } else {
    $('#card_modal .time-tracker .tracker').removeClass("hide");
    $('#card_modal .time-tracker .hide-timetracking').removeClass("active");
  }
  if (Breeze.current_user.hide_estimates) {
    $('#card_modal .time-estimates .tracker').addClass("hide");
    return $('#card_modal .time-estimates .hide-estimates').addClass("active");
  } else {
    $('#card_modal .time-estimates .tracker').removeClass("hide");
    return $('#card_modal .time-estimates .hide-estimates').removeClass("active");
  }
};

init_timeentries = function() {
  var init_time_entry, update_billable_pill;
  $('#card_modal, .public-card').on("click", '.load-timeentries', function() {
    var ell, page, url;
    if (developement()) {
      url = "https://app.breeze.test:3006/cards/" + Breeze.Card.id + "/time_entries?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n4.herokuapp.com/cards/" + Breeze.Card.id + "/time_entries?auth_token=" + Breeze.current_user.token;
    }
    ell = $(this);
    page = parseInt(ell.attr('data-page'), 10) + 1;
    $('#card_modal .load-timeentries-box .m-loader').remove();
    $.ajax({
      type: "GET",
      url: url,
      data: {
        project_id: Breeze.Card.project,
        page: page,
        partial: true
      },
      beforeSend: function() {
        $('#card_modal  .load-timeentries-box .load-timeentries').hide();
        return $('#card_modal  .load-timeentries-box').append(Breeze.global_loader);
      },
      success: function(data) {
        $('#card_modal  .load-timeentries-box .m-loader').remove();
        $('#card_modal  .load-timeentries-box .load-timeentries').show();
        $("#card_modal .load-timeentries-box").remove();
        return $(data).find('.tracker').insertAfter($('#card_modal .tracker-hold .time-tracker h5'));
      }
    });
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.hide-timetracking', function(e) {
    var hidden;
    hidden = $(this).hasClass('active');
    if (hidden) {
      $('#card_modal .time-tracker .tracker').removeClass("hide");
      Breeze.current_user.hide_timetracking = false;
    } else {
      $('#card_modal .time-tracker .tracker').addClass("hide");
      Breeze.current_user.hide_timetracking = true;
    }
    $('#card_modal .time-tracker .hide-timetracking').toggleClass("active");
    return $.ajax({
      type: 'POST',
      url: '/profile',
      data: {
        user: {
          hide_timetracking: Breeze.current_user.hide_timetracking
        }
      }
    });
  });
  $('#card_modal .tracker-hold').on('click', '.time-tracker .tracker.creator .icon-remove', function() {
    var card, el, project, socket_params, tracked;
    el = $(this).closest('.tracker');
    card = el.attr('data-time-card-id');
    project = el.attr('data-project-id');
    tracked = -parseInt(el.attr('data-time-tracked'));
    if (el.attr('data-billable') !== "true") {
      Breeze.Card.tracked_time_notbillable = Breeze.Card.tracked_time_notbillable + tracked;
      $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-tracked-notbillable', Breeze.Card.tracked_time_notbillable);
    }
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-log-time-delete",
      message: "delete",
      entry: el.attr('data-id'),
      tracked: tracked,
      tracked_notbillable: Breeze.Card.tracked_time_notbillable
    };
    $.ajax({
      type: "DELETE",
      queue: true,
      url: '/projects/' + project + '/cards/' + card + '/time_entry/' + el.attr('data-id'),
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    el.fadeOut(function() {
      var sum;
      el.remove();
      sum = Breeze.sum_tracked_time(Breeze.Card.planned_time, tracked);
      return Breeze.update_card_time(sum);
    });
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.time-tracker .creator[data-readonly="false"] [data-type="item"]', function() {
    var box, el, icon, selected, selected_user, time_entry_user, u, users_options, users_options_select;
    Breeze.new_time_entry = false;
    $('#card_modal .time-tracker .actions .cancel').trigger("click");
    users_options_select = "";
    time_entry_user = "";
    if (Breeze.current_user.admin_owner && $('.card[data-card-id="' + Breeze.Card.id + '"]').data('project-owner-team')) {
      selected_user = $(this).parent().attr('data-user-email');
      users_options = (function() {
        var j, len, ref, results;
        ref = Breeze.project_users;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          u = ref[j];
          selected = u.email === selected_user ? 'selected="selected"' : ' ';
          results.push('<option value="' + u.id + '" data-email="' + u.email + '" ' + selected + '>' + u.name + '</option>');
        }
        return results;
      })();
      users_options_select = '<div class="single-select"><select name="timeentry_user" class="">' + users_options.join("") + '</select></div>';
      time_entry_user = "time-entry-user";
    }
    el = $('<span data-action="time-entry" class="' + time_entry_user + '"><span class="border"><input type="text" class="dateinput" spellcheck="false" placeholder="Today" data-type="date"><input type="text" class="dateinput-display" spellcheck="false" placeholder="Today"><input type="text" class="hide timeinput timeinput-start"><input type="text" class="hide timeinput timeinput-end"> </span><span class="border"><input type="text" name="workef" class="" spellcheck="false" placeholder="Worked" data-type="work" value="' + $(this).find('[data-type="time"]').html() + '"> </span>' + users_options_select + '<span class="border textarea-border"><textarea name="comment" cols="1" rows="1" placeholder="Comment or description" data-type="comment"></textarea></span><a href="#" class="pill timeentry-pill show" data-action="set-billable" data-readonly="false">Billable</a> <span class="actions"> <button class="c-button c-button--blue c-button-med c-button-save update" data-action="update" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Save <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Cancel </button> </span> </span>');
    $(this).hide();
    el.find('[name="timeentry_user"]').multiselect({
      numberDisplayed: 1,
      maxHeight: 300,
      nonSelectedText: "default",
      buttonClass: 'btn-material',
      nSelectedText: 'selected',
      multiple: false,
      onDropdownShown: function(e, i) {
        return $('.popover').remove();
      }
    });
    el.find('[data-type="work"]').on("blur", function() {
      return calc_time_from_tracked();
    });
    box = $(this).parent();
    icon = box.find("i");
    box.find('.timeentry-pill').removeClass('show').hide();
    if (box.attr('data-billable') === "true") {
      el.find('.timeentry-pill ').attr("title", "Billable").html(Breeze.icons.billable);
    } else {
      el.find('.timeentry-pill ').attr("title", "Not billable").html(Breeze.icons.not_billable);
    }
    icon.addClass("hide");
    el.find('[data-type="comment"]').val($(this).find('[data-type="desc"]').text());
    box.append(el);
    box.find('textarea').autosize().trigger('autosize.resize');
    $('#card_modal .tracker-hold .timeinput-start').val(box.attr('data-start'));
    $('#card_modal .tracker-hold .timeinput-end').val(box.attr('data-end'));
    init_time_entry(box.attr("data-date"));
    if ($('body').hasClass('ie')) {
      $('#card_modal .time-tracker input').placeholder();
    }
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.time-tracker .actions .cancel', function() {
    var box, container;
    container = $(this).closest('[data-type="container"]');
    if (container.data("action")) {
      container.remove();
      $('#card_modal .time-tracker [data-action="add-time-entry"]').show();
    } else {
      box = $(this).closest('[data-action="time-entry"]');
      if (container.attr('data-billable') === "true") {
        box.parent().find('.timeentry-pill').css('display', '').removeClass('show').addClass('hide');
      } else {
        box.parent().find('.timeentry-pill').addClass('show').css('display', '');
      }
      box.remove();
      container.find('[data-type="item"]').show();
      container.find("i").removeClass("hide show-item");
    }
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.time-tracker [data-action="add-time-entry"]', function() {
    var el, selected, time_entry_user, u, users_options, users_options_select;
    Breeze.new_time_entry = true;
    $('#card_modal .time-tracker .actions .cancel').trigger("click");
    users_options_select = "";
    if (Breeze.current_user.admin_owner && $('.card[data-card-id="' + Breeze.Card.id + '"]').data('project-owner-team')) {
      users_options = (function() {
        var j, len, ref, results;
        ref = Breeze.project_users;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          u = ref[j];
          selected = u.email === Breeze.current_user.email ? 'selected="selected"' : ' ';
          results.push('<option value="' + u.id + '" data-email="' + u.email + '" ' + selected + '>' + u.name + '</option>');
        }
        return results;
      })();
      users_options_select = '<div class="single-select"><select name="timeentry_user" class="">' + users_options.join("") + '</select></div>';
      time_entry_user = "time-entry-user";
    }
    if (Breeze.project_billable) {
      el = $('<div class="tracker row" data-type="container" data-action="new" data-billable="true"> <span data-action="time-entry" class="' + time_entry_user + '" ><span class="border"> <input type="text" class="dateinput" spellcheck="false" placeholder="Today" data-type="date"> <input type="text" class="dateinput-display" spellcheck="false" placeholder="Today"> <input type="text" class="hide timeinput timeinput-start"> <input type="text" class="hide timeinput timeinput-end"> </span><span class="border"> <input type="text" name="workef" class="" spellcheck="false" placeholder="Worked" data-type="work"> </span>' + users_options_select + '<span class="border textarea-border"> <textarea name="comment" cols="1" rows="1" placeholder="Comment or description" data-type="comment"></textarea> </span><a href="#" class="pill timeentry-pill show" data-action="set-billable" data-readonly="false" title="Billable">' + Breeze.icons.billable + '</a> <span class="actions"> <button class="c-button c-button--blue c-button-med c-button-save update" data-action="create" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Log it <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Cancel </button> </span> </span></div>');
    } else {
      el = $('<div class="tracker row" data-type="container" data-action="new" data-billable="false"> <span data-action="time-entry" class="' + time_entry_user + '"> <span class="border"> <input type="text" class="dateinput" spellcheck="false" placeholder="Today" data-type="date"> <input type="text" class="dateinput-display" spellcheck="false" placeholder="Today"> <input type="text" class="hide timeinput timeinput-start"> <input type="text" class="hide timeinput timeinput-end"> </span> <span class="border"> <input type="text" name="workef" class="" spellcheck="false" placeholder="Worked" data-type="work"> </span>' + users_options_select + '<span class="border textarea-border"> <textarea name="comment" cols="1" rows="1" placeholder="Comment or description" data-type="comment"></textarea> </span><a href="#" class="pill timeentry-pill show" data-action="set-billable" data-readonly="false" title="Not billable">' + Breeze.icons.not_billable + '</a> <span class="actions"> <button class="c-button c-button--blue c-button-med c-button-save update" data-action="create" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Log it <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Cancel </button> </span> </span></div>');
    }
    el.insertBefore($('#card_modal .time-tracker [data-action="start-time-entry"]').first());
    init_time_entry();
    el.find('textarea').autosize().trigger('autosize.resize');
    el.find('[name="timeentry_user"]').multiselect({
      numberDisplayed: 1,
      maxHeight: 300,
      nonSelectedText: "default",
      buttonClass: 'btn-material',
      nSelectedText: 'selected',
      multiple: false,
      onDropdownShown: function(e, i) {
        return $('.popover').remove();
      }
    });
    el.find('[data-type="work"]').on("blur", function() {
      return calc_time_from_tracked();
    });
    $(this).hide();
    if ($('body').hasClass('ie')) {
      $('#card_modal .time-tracker input').placeholder();
    }
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.time-tracker [data-action="update"]', function() {
    var box, card, container, desc, end_time, entry_user, entry_user_email, ldate, project, socket_params, start_time, sum, tracked, tracked_change, tracked_minutes, tracked_user_id;
    calc_time_from_tracked();
    container = $(this).closest('[data-type="container"]');
    box = $(this).closest('[data-action="time-entry"]');
    desc = box.find('[data-type="comment"]').val();
    tracked = box.find('[data-type="work"]').val();
    ldate = box.find('[data-type="date"]').val();
    start_time = box.find('.timeinput-start').val();
    end_time = box.find('.timeinput-end').val();
    card = container.attr('data-time-card-id');
    project = container.attr('data-project-id');
    tracked_minutes = Breeze.Time.parseMinutes(tracked, 1);
    tracked_change = tracked_minutes - parseInt(container.attr('data-time-tracked'));
    tracked_user_id = box.find('select[name="timeentry_user"]').val();
    if (Breeze.Time.parse(tracked) === null) {
      $(this).tooltip("show");
      $('#card_modal .time-tracker input[data-type="work"]').tooltip("show");
      return false;
    }
    container.find('[data-type="item"] [data-type="time"]').html(Breeze.Time.formatPretty(tracked_minutes));
    container.find('[data-type="item"] [data-type="date"]').html(dateFormat(ldate, "UTC:mmm d"));
    container.find('[data-type="item"] [data-type="desc"]').html(desc);
    if (Breeze.current_user.admin_owner) {
      entry_user = box.find('select[name="timeentry_user"] option:selected').text() || Breeze.current_user.name;
      entry_user_email = box.find('select[name="timeentry_user"] option:selected').attr('data-email') || Breeze.current_user.email;
      container.find('[data-type="item"] [data-type="user"]').html(entry_user);
      container.attr('data-user-email', entry_user_email);
    }
    container.attr('data-time-tracked', tracked_minutes);
    container.attr('data-date', dateFormat(ldate, "UTC:yyyy-mm-dd"));
    container.attr('data-start', start_time);
    container.attr('data-end', end_time);
    container.find('[data-type="times"]').html(parse_times(start_time, end_time));
    if (container.attr('data-billable') === "true") {
      container.find('.timeentry-pill ').attr("title", "Billable").html(Breeze.icons.billable).addClass("hide").css('display', '');
    } else {
      container.find('.timeentry-pill ').attr("title", "Not billable").html(Breeze.icons.not_billable).addClass("show").css('display', '');
    }
    box.remove();
    container.find('[data-type="item"]').show();
    container.find("i").removeClass("hide show-item");
    sum = Breeze.sum_tracked_time(Breeze.Card.planned_time, tracked_change);
    Breeze.update_card_time(sum, card);
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-log-time-update",
      message: container.prop('outerHTML'),
      entry: container.attr('data-id'),
      sum: sum,
      tracked: tracked_change
    };
    $.ajax({
      type: 'PUT',
      queue: true,
      url: '/projects/' + project + '/cards/' + card + '/time_entry/' + container.attr('data-id'),
      data: {
        time_entry: {
          desc: desc,
          logged_date: ldate,
          start: start_time,
          end: end_time,
          tracked: tracked_minutes,
          user_id: tracked_user_id
        }
      },
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.time-tracker .tracker[data-action="new"] [data-action="set-billable"]', function() {
    var container, notbillable;
    container = $(this).closest('.tracker');
    notbillable = Breeze.update_billable_pill(container);
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.time-tracker .creator[data-readonly="false"] [data-action="set-billable"]', function() {
    var card, container, notbillable, project, socket_params;
    container = $(this).closest('.tracker');
    card = container.attr('data-time-card-id');
    project = container.attr('data-project-id');
    notbillable = Breeze.update_billable_pill(container);
    if ($(this).parent().hasClass('tracker')) {
      if ($(this).parent().attr('data-billable') === "false") {
        $(this).addClass('show');
      } else {
        $(this).removeClass('show').addClass("hide");
      }
    }
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-log-time-update-billable",
      message: container.prop('outerHTML'),
      entry: container.attr('data-id'),
      notbillable: notbillable,
      tracked_notbillable: Breeze.Card.tracked_time_notbillable
    };
    $.ajax({
      type: 'PUT',
      queue: true,
      url: '/projects/' + project + '/cards/' + card + '/time_entry/' + container.attr('data-id'),
      data: {
        time_entry: {
          notbillable: notbillable
        }
      },
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.time-tracker [data-action="create"]', function() {
    var billable, box, desc, el, elt, end_time, entry_user, entry_user_email, ldate, socket_params, start_time, sum, tid, tracked, tracked_minutes, tracked_parsed, tracked_user_id;
    calc_time_from_tracked();
    box = $(this).closest('[data-action="time-entry"]');
    desc = box.find('[data-type="comment"]').val();
    ldate = box.find('[data-type="date"]').val();
    if ((ldate != null ? ldate.length : void 0) <= 0) {
      ldate = moment().format("YYYY-MM-DD");
    }
    start_time = box.find('.timeinput-start').val();
    end_time = box.find('.timeinput-end').val();
    billable = box.parent().attr('data-billable');
    tracked = box.find('[data-type="work"]').val();
    tracked_parsed = Breeze.Time.parse(tracked, 1);
    tracked_minutes = Breeze.Time.parseMinutes(tracked, 1);
    if (tracked_parsed === null) {
      $(this).tooltip("show");
      $('#card_modal .time-tracker input[data-type="work"]').tooltip("show");
      return false;
    }
    if (billable === "true") {
      elt = '<a href="#" class="pill timeentry-pill hide" data-action="set-billable" data-readonly="false" title="Billable">' + Breeze.icons.billable + '</a>';
    } else {
      elt = '<a href="#" class="pill timeentry-pill show" data-action="set-billable" data-readonly="false" title="Not billable">' + Breeze.icons.not_billable + '</a>';
    }
    tid = "time_temp_" + Breeze.current_user.id + new Date().getTime();
    if (Breeze.current_user.admin_owner) {
      entry_user = box.find('select[name="timeentry_user"] option:selected').text() || Breeze.current_user.name;
      entry_user_email = box.find('select[name="timeentry_user"] option:selected').attr('data-email') || Breeze.current_user.email;
    } else {
      entry_user = Breeze.current_user.name;
      entry_user_email = Breeze.current_user.email;
    }
    el = '<div class="row tracker creator" data-user-email="' + entry_user_email + '" data-time-tracked="' + tracked_minutes + '" data-type="container" data-readonly="false" data-id="' + tid + '" data-tid="' + tid + '" data-billable="' + billable + '" data-date="' + dateFormat(ldate, "UTC:yyyy-mm-dd") + '" data-start="' + start_time + '" data-end="' + end_time + '" data-tracking="false" data-paused="false" data-time-card-id="' + Breeze.Card.id + '" data-project-id="' + Breeze.Card.project + '"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg> <div data-type="item"><span data-type="date">' + dateFormat(ldate, "UTC:mmm d") + '</span><span data-type="time">' + Breeze.Time.formatPretty(tracked_minutes) + '</span><span data-type="user">' + entry_user + '</span><span data-type="desc">' + desc + '</span><span data-type="times">' + parse_times(start_time, end_time) + '</span> </div>' + elt + '</div>';
    $('#card_modal .time-tracker .tracker[data-action="new"]').remove();
    $(el).insertBefore('#card_modal .time-tracker [data-action="add-time-entry"]');
    $('#card_modal .time-tracker [data-action="add-time-entry"]').show();
    if (!(billable === "true")) {
      Breeze.Card.tracked_time_notbillable = Breeze.Card.tracked_time_notbillable + tracked_minutes;
      $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-tracked-notbillable', Breeze.Card.tracked_time_notbillable);
    }
    sum = Breeze.sum_tracked_time(Breeze.Card.planned_time, tracked_minutes);
    Breeze.update_card_time(sum);
    tracked_user_id = box.find('select[name="timeentry_user"]').val();
    socket_params = {
      card: Breeze.Card.id,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-project-token'),
      method: "card-log-time",
      sum: sum,
      tracked: tracked_minutes,
      tracked_notbillable: Breeze.Card.tracked_time_notbillable
    };
    $.ajax({
      type: 'POST',
      queue: true,
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/time_entry',
      data: {
        time_entry: {
          tid: tid,
          desc: desc,
          logged_date: ldate,
          start: start_time,
          end: end_time,
          tracked: tracked_minutes,
          notbillable: !(billable === "true"),
          user_id: tracked_user_id
        }
      },
      success: function(data) {
        if (data) {
					 $('#card_modal .tracker-hold [data-id="'+tid+'"]').attr("data-id",data);
					 $('.card[data-card-id="'+socket_params.card+'"] .people-tracked [data-id="'+tid+'"]').attr("data-id",data);
          if ($('#card_modal .tracker-hold [data-tid="' + tid + '"]').length > 0) {
            socket_params.message = $('#card_modal .tracker-hold [data-tid="' + tid + '"]').prop('outerHTML');
          } else if ($('.card[data-card-id="' + socket_params.card + '"] .people-tracked [data-tid="' + tid + '"]').length > 0) {
            socket_params.message = $('.card[data-card-id="' + socket_params.card + '"] .people-tracked [data-tid="' + tid + '"]').prop('outerHTML');
          }
          if (socket_params.message) {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        } else {
          return $.flashMessage("<span class='red-text'>We couldn't create the entry. Please refresh your browser window.</span>", true);
        }
      }
    });
    return false;
  });
  $('#card_modal .tracker-hold').on("mouseenter", '.time-tracker .creator[data-type="container"][data-readonly="false"]', function() {
    return $(this).find('.icon-remove').addClass("show-item");
  });
  $('#card_modal .tracker-hold').on("mouseleave", '.time-tracker .creator[data-type="container"][data-readonly="false"]', function() {
    return $(this).find('.icon-remove').removeClass("show-item");
  });
  $('#card_modal .tracker-hold').on("click", ".time-tracker .dateinput", function(e) {
    var card_popover, el_pop, logged_date, udate;
    el_pop = $(this).parent()
    if ($('#timeentry-date').length > 0) {
      $(this).popover("hide");
      $(this).removeData("bs.popover");
      $(".popover").remove();
    } else {
      $(this).removeData("bs.popover");
      $(".popover").remove();
      $('.time-entry-user .btn-group').removeClass("open");
      card_popover = $('<div> <div class="date-block"> <input type="text" class="time-input start-date-time" spellcheck="false" placeholder="Start"> <input type="text" class="time-input end-date-time" spellcheck="false" placeholder="End"> <div class="duedate"> <div class="dateinput-inline end-date"></div> </div> </div> </div>');
      el_pop.popover({
        container: el_pop,
        trigger: "manual",
        placement: "bottom",
        template: '<div class="popover timeentry-date" ><div id="timeentry-date" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
        // template: '<div class="popover reactions-menu" ><div id="reactions-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
				sanitize: false,
			  html: true,
        content: card_popover.html()
      });
      el_pop.popover("show");
      logged_date = el_pop.val();
      try {
        udate = $('#card_modal .tracker-hold .timeinput-start').val().match(/(\d+)/g);
        $('#timeentry-date .start-date-time').val(Breeze.CardTime.parse(new Date(udate[0], parseInt(udate[1], 10) - 1, udate[2], udate[3], udate[4])).toFriendlyString());
      } catch (_error) {
        $('#timeentry-date .start-date-time').val("");
      }
      try {
        udate = $('#card_modal .tracker-hold .timeinput-end').val().match(/(\d+)/g);
        $('#timeentry-date .end-date-time').val(Breeze.CardTime.parse(new Date(udate[0], parseInt(udate[1], 10) - 1, udate[2], udate[3], udate[4])).toFriendlyString());
      } catch (_error) {
        $('#timeentry-date .end-date-time').val("");
      }
      $('#timeentry-date .time-input').on("keydown", function(e) {
        if (e.keyCode === 13) {
          set_timeentry_time($('#card_modal .tracker-hold .dateinput').val());
          $(".popover").remove();
        }
      });
      $('#timeentry-date .time-input').on("blur", function() {
        set_timeentry_time($('#card_modal .tracker-hold .dateinput').val());
      });
      $('#timeentry-date .dateinput-inline').datepicker({
        showOtherMonths: true,
        selectOtherMonths: true,
        dateFormat: "yy-mm-dd",
        showAnim: "",
        constrainInput: true,
        prevText: "←",
        nextText: '→',
        firstDay: Breeze.first_day,
        onSelect: function(dateText, inst) {
          var day, month;
          month = inst.selectedMonth + 1 <= 9 ? "0" + (inst.selectedMonth + 1).toString() : (inst.selectedMonth + 1).toString();
          day = inst.selectedDay <= 9 ? "0" + inst.selectedDay.toString() : inst.selectedDay.toString();
          Breeze.Card.logged_date = inst.selectedYear.toString() + "-" + month + "-" + day + "T00:00:00+00:00";
          $('#card_modal .tracker-hold .dateinput-display').val(dateFormat(Breeze.Card.logged_date, "UTC:mmm d"));
          $('#card_modal .tracker-hold .dateinput').val(Breeze.Card.logged_date);
          set_timeentry_time(Breeze.Card.logged_date);
          el_pop.popover("hide");
          el_pop.removeData("bs.popover");
          return $(".popover").remove();
        }
      });
      if (logged_date) {
        udate = logged_date.match(/(\d+)/g);
        $('#timeentry-date .dateinput-inline').datepicker('setDate', new Date(udate[0], parseInt(udate[1], 10) - 1, udate[2]));
      } else {
        $('#timeentry-date .dateinput-inline').datepicker('setDate', null);
        $("#timeentry-date .dateinput-inline td").removeClass("ui-datepicker-current-day");
      }
    }
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '.tracking-bubble [data-action="stop"]', function() {
    var end_time, start_time, start_time_date, sum, timer_entry, ttime;
    timer_entry = $('#card_modal .tracker[data-user-email="' + Breeze.current_user.email + '"][data-tracking="true"]');
    if (window.TimerInterval && window.TimerInterval[Breeze.Card.id + "modal"]) {
      clearInterval(window.TimerInterval[Breeze.Card.id + "modal"]);
    }
    ttime = Math.round((parseInt($('#card_modal .time-tracker .tracking-bubble [data-timer="timer"]').attr('data-tracked')) || 0) / 60);
    timer_entry.attr('data-time-tracked', ttime);
    timer_entry.find('[data-type="time"]').html(Breeze.Time.formatPretty(parseInt(ttime)));
    timer_entry.attr('data-tracking', 'false');
    start_time_date = moment(parseInt(timer_entry.attr('data-tracking-start'), 10));
    start_time = start_time_date.format("YYYY-MM-DDTHH:mm:ss") + "Z";
    end_time = start_time_date.add(ttime, 'minutes').format("YYYY-MM-DDTHH:mm:ss") + "Z";
    timer_entry.attr('data-start', start_time);
    timer_entry.attr('data-end', end_time);
    timer_entry.find('[data-type="times"]').html(parse_times(start_time, end_time));
    Breeze.start_timer($('.card[data-card-id="' + Breeze.Card.id + '"] .timer'));
    sum = Breeze.sum_tracked_time(Breeze.Card.planned_time, 0);
    Breeze.update_card_time(sum);
    $(this).parent().addClass("hide");
    $('#card_modal .time-tracker [data-action="start-time-entry"]').show();
    $('#card_modal .time-tracker .tracking-bubble [data-timer="timer"]').html("0:00:0");
    $('#card_modal .time-tracker .tracking-bubble [data-timer="timer"]').attr('data-tracked', "0");
    return false;
  });
  $('#card_modal .tracker-hold').on("click", '[data-action="start-time-entry"]', function() {
    var el, elt, ldate, tid;
    if (!$(this).hasClass("toggl-button")) {
      Breeze.start_timer($('.card[data-card-id="' + Breeze.Card.id + '"] .timer'));
      tid = $('.card[data-card-id="' + Breeze.Card.id + '"] .people-tracked [data-user-email="' + Breeze.current_user.email + '"][data-tracking="true"]').attr('data-tid');
      if (Breeze.project_billable) {
        elt = '<a href="#" class="pill timeentry-pill hide" data-action="set-billable" data-readonly="false" title="Billable">' + Breeze.icons.billable + '</a>';
      } else {
        elt = '<a href="#" class="pill timeentry-pill show" data-action="set-billable" data-readonly="false" title="Not billable">' + Breeze.icons.not_billable + '</a>';
      }
      ldate = moment().format("YYYY-MM-DD");
      el = '<div class="row tracker creator" data-user-email="' + Breeze.current_user.email + '" data-time-tracked="0" data-type="container" data-readonly="false" data-id="' + tid + '" data-tid="' + tid + '" data-tracking-start="' + Date.now() + '" data-billable="true" data-date="' + dateFormat(ldate, "UTC:yyyy-mm-dd") + '" data-tracking="true" data-paused="false" data-time-card-id="' + Breeze.Card.id + '" data-project-id="' + Breeze.Card.project + '"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg> <div data-type="item"><span data-type="date">' + dateFormat(ldate, "UTC:mmm d") + '</span><span data-type="time">0</span><span data-type="user">' + Breeze.current_user.name + '</span><span data-type="desc"></span><span data-type="times"></span> </div>' + elt + '</div>';
      $(el).insertBefore('#card_modal .time-tracker [data-action="add-time-entry"]');
      el = $('.card[data-card-id="' + Breeze.Card.id + '"] .tracking-bubble [data-timer="timer"]');
      if (el.length > 0) {
        window.TimerInterval[Breeze.Card.id + "modal"] = setInterval(function() {
          $('#card_modal .time-tracker .tracking-bubble [data-timer="timer"]').attr('data-tracked', el.attr('data-tracked'));
          return $('#card_modal .time-tracker .tracking-bubble [data-timer="timer"]').html(el.html());
        }, 1000);
      }
      $(this).hide();
      $('#card_modal .time-tracker .tracking-bubble').removeClass("hide");
      return false;
    }
  });
  Breeze.update_billable_pill = function(container) {
    var notbillable, trackers;
    if (container.attr('data-billable') === "true") {
      if (container.attr("data-action") !== "new") {
        Breeze.Card.tracked_time_notbillable = Breeze.Card.tracked_time_notbillable + parseInt(container.attr('data-time-tracked'));
      }
      notbillable = true;
      container.attr('data-billable', 'false');
      container.find('.timeentry-pill').each(function() {
        return $(this).attr("title", "Not billable").html(Breeze.icons.not_billable);
      });
    } else {
      notbillable = false;
      container.attr('data-billable', 'true');
      if (container.attr("data-action") !== "new") {
        Breeze.Card.tracked_time_notbillable = Breeze.Card.tracked_time_notbillable - parseInt(container.attr('data-time-tracked'));
      }
      container.find('.timeentry-pill').each(function() {
        return $(this).attr("title", "Billable").html(Breeze.icons.billable);
      });
    }
    trackers = $(".card-content .time-tracker .tracker");
    if (container.attr("data-action") !== "new") {
      $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-tracked-notbillable', Breeze.Card.tracked_time_notbillable);
    }
    Breeze.tracked_time_tooltip();
    return notbillable;
  };
  return init_time_entry = function(logged_date) {
    var udate;
    if (logged_date) {
      udate = logged_date.match(/(\d+)/g);
      $('#card_modal .time-tracker .dateinput').val(logged_date);
      $('#card_modal .time-tracker .dateinput-display').val(dateFormat(new Date(udate[0], parseInt(udate[1], 10) - 1, udate[2]), "mmm d"));
    } else {
      $('#card_modal .time-tracker .dateinput').val("");
      $('#card_modal .time-tracker .dateinput-display').val("");
    }
    $('#card_modal .time-tracker input[data-type="work"]').tooltip({
      animation: false,
      placement: "bottomleft",
      trigger: "manual",
      title: "This isn't a correct format. Valid time formats are 45, 1:45, 1h, 1h45m, 1day"
    });
    return $('#card_modal .tracker-hold').on("keydown", '.time-tracker input[data-type="work"],.time-tracker textarea[name="comment"]', function(e, item) {
      $(this).tooltip("hide");
      if (e.keyCode === 13 && !e.shiftKey) {
        return $(this).closest('.tracker').find('[type="submit"]').trigger("click");
      }
    });
  };
};

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.public-card').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_timeentries();
  }
  $(document).on("breeze:page", function(e, page) {
    if (page === "card_modal") {
      return init_timetracking();
    }
  });
  $(document).on("breeze:page", function(e, page) {
    if (page === "time_entries") {
      return init_timeentries();
    }
  });
  $(document).on('mouseup', '#card_modal .toggl-button:not(".manual")', function() {
    if ($(this).hasClass("active")) {
      if (!$('#card_modal .tracking-bubble').hasClass('hide')) {
        return $('#card_modal .time-tracker [data-action="stop"]').trigger("click");
      }
    } else {
      return $('#card_modal a:not(".toggl-button"):not(".active")[data-action="start-time-entry"]').trigger("click");
    }
  });
  $(document).on("mouseup", ".card_box.toggl .toggl-button", function() {
    if ($(this).hasClass("active")) {
      return $(this).parent().find(".icon-stop").trigger("click");
    } else {
      return $(this).parent().find(".icon-play").trigger("click");
    }
  });
  Breeze.tracked_time_tooltip = function(sum, planned) {
    var planned_title, sum_billable_title, sum_not_billable, sum_not_billable_title, title, total_title;
    if (sum == null) {
      sum = Breeze.Card.tracked_time;
    }
    if (planned == null) {
      planned = Breeze.Card.planned_time;
    }
    sum_not_billable = Breeze.Card.tracked_time_notbillable;
    sum_not_billable_title = "";
    if (sum_not_billable > 0) {
      sum_not_billable_title = '<tr><td><span class="tool-time">' + Breeze.Time.formatPretty(sum_not_billable) + '</span></td><td class="tool-desc">not billable tracked</td> </tr>';
    }
    sum_billable_title = "";
    if ((sum_not_billable > 0) && (sum - sum_not_billable > 0)) {
      sum_billable_title = '<tr><td><span class="tool-time">' + Breeze.Time.formatPretty(sum - sum_not_billable) + '</span></td><td class="tool-desc">billable tracked</td> </tr>';
    }
    planned_title = "";
    if (planned > 0) {
      planned_title = '<tr><td><span class="tool-time tool-time-estimate">' + Breeze.Time.formatPretty(planned) + '</span></td><td class="tool-desc">estimated </td> </tr>';
    }
    total_title = "";
    if (sum > 0) {
      total_title = '<tr><td><span class="tool-time">' + Breeze.Time.formatPretty(sum) + '</span> </td><td class="tool-desc">total tracked </td> </tr>';
    }
    title = '<table>' + total_title + sum_not_billable_title + sum_billable_title + planned_title + '</table>';
    return $('.card-content .time-tracker span[data="tracked"],.card-content .time-tracker span[data="planned"]').attr('data-original-title', title);
  };
  Breeze.sum_tracked_time = function(planned, tracked) {
    var planned_pill, sum, tracked_pill, trackers;
    if (planned == null) {
      planned = Breeze.Card.planned_time;
    }
    tracked_pill = $('.card-content .time-tracker span[data="tracked"]');
    planned_pill = $('.card-content .time-tracker span[data="planned"]');
    trackers = $(".card-content .time-tracker .tracker");
    planned = parseInt(planned) || 0;
    sum = Breeze.Card.tracked_time + tracked;
    sum = Math.round(sum);
    tracked_pill.html(Breeze.Time.formatPretty(sum));
    planned_pill.html(Breeze.Time.formatPretty(planned));
    Breeze.tracked_time_tooltip(sum, planned);
    if (sum > 0 && planned <= 0) {
      tracked_pill.attr("class", "show pill");
      planned_pill.attr("class", "time-pill hide");
    } else if (sum <= 0 && planned > 0) {
      tracked_pill.attr("class", "hide pill");
      planned_pill.attr("class", "time-pill pill show");
    } else if (sum <= 0 && planned <= 0) {
      tracked_pill.attr("class", "pill hide");
      planned_pill.attr("class", "time-pill hide");
    } else {
      tracked_pill.attr("class", "pill clear-right show");
      planned_pill.attr("class", "time-pill show");
    }
    if (sum > 0 && (planned < sum)) {
      planned_pill.addClass("overdue red");
    } else {
      planned_pill.removeClass("overdue red");
    }
    Breeze.Card.tracked_time = sum;
    $('.card[data-card-id="' + Breeze.Card.id + '"] .time-bubble').attr("data-tracked", sum);
    return sum;
  };
  Breeze.update_card_time = function(sum, card, planned) {
    var planned_element, templ, time_element;
    if (card == null) {
      card = Breeze.Card.id;
    }
    if (planned == null) {
      planned = Breeze.Card.planned_time;
    }
    time_element = $('.card[data-card-id="' + card + '"]').find('.time-bubble span[data-timer="timer"]');
    planned_element = $('.card[data-card-id="' + card + '"]').find('.time-bubble span[data="planned"]');
    sum = parseInt(sum) || 0;
    planned = parseInt(planned) || 0;
    if (time_element.length === 0 || planned_element.length === 0) {
      templ = '<span class="time-bubble" data-tracked="0" data-planned="0"> <i class="icon-clock" title="work logged"></i> <span data-timer="timer" class="show"></span> <span class="separator show">/</span> <span data="planned" class="show"></span> </span>';
      $('.card[data-card-id="' + card + '"] .card-time-bubble-container').append(templ);
      time_element = $('.card[data-card-id="' + card + '"]').find('.time-bubble span[data-timer="timer"]');
      planned_element = $('.card[data-card-id="' + card + '"]').find('.time-bubble span[data="planned"]');
    }
    if (Breeze.Card.id === card) {
      Breeze.Card.tracked_time = sum;
    }
    $('.card[data-card-id="' + card + '"] .time-bubble').attr("data-tracked", sum);
    if (sum === 0 && !planned) {
      time_element.closest('.time-bubble').remove();
    } else {
      if (sum > 0) {
        time_element.html(Breeze.Time.formatPretty(sum)).attr("class", "show");
      } else {
        time_element.attr("class", "hide");
      }
      if (planned > 0) {
        planned_element.html(Breeze.Time.formatPretty(planned)).attr("class", "show");
      } else {
        planned_element.attr("class", "hide");
      }
      if (planned > 0) {
        planned_element.parent().find(".separator").attr("class", "separator show");
      } else {
        planned_element.parent().find(".separator").attr("class", "separator hide");
      }
      if (planned > 0 && (sum > planned)) {
        $('.card[data-card-id="' + card + '"]').find('.time-bubble').addClass("over");
      } else {
        $('.card[data-card-id="' + card + '"]').find('.time-bubble').removeClass("over");
      }
    }
    planned_element.parent().attr("data-planned", planned);
    time_element.parent().attr("data-tracked", sum);
    update_project_list_view_columns("time");
  };
  $.validate_time = function() {
    var time_valid;
    if (Breeze.Card.planned_time) {
      $('.time-track input[name="planned"]').val(Breeze.Time.formatPretty(Breeze.Card.planned_time));
    } else {
      $('.time-track input[name="planned"]').val("");
    }
    $("#card-track-time .person-icon i").attr("class", "icon-clock");
    $(".time-track input:text").tooltip({
      animation: false,
      placement: "bottomleft",
      trigger: "manual",
      title: "This isn't a correct format. Valid time formats are 45, 1:45, 1h, 1h45m, 1day"
    });
    return time_valid = true;
  };
  $(document).on("click", ".card-inner .timer, .drop-shadow .timer", function() {
    Breeze.start_timer($(this));
    return false;
  });
  Breeze.start_timer = function(ele) {
    var bubble, card, corr, el, elt, end_time, old_time, project, secs, socket_params, startTime, start_time, start_time_date, sum, tid, timer_entry, totals, tracking_tmpl, ttime;
    card = $(ele).closest(".card").attr("data-card-id");
    project = $(ele).closest(".card").attr("data-project-id");
    bubble = $(ele).parent().find('.card-tracking-bubble-container .tracking-bubble [data-timer="timer"]');
    if (bubble.length <= 0) {
      tracking_tmpl = '<span class="tracking-bubble" data-tracked="0" data-entry-id=""> <i class="icon-clock" title="tracking time"></i> <span data-timer="timer" class="show">0:00:00</span> </span>';
      bubble = $(ele).parent().find(".card-tracking-bubble-container").append(tracking_tmpl).find('.tracking-bubble [data-timer="timer"]');
    }
    timer_entry = $(ele).parent().find('.people-tracked .tracker[data-user-email="' + Breeze.current_user.email + '"][data-tracking="true"]');
    tid = "time_temp_" + Breeze.current_user.id + new Date().getTime();
    if (timer_entry.length === 0) {
      if ($('.task-board').length > 0 || $('.dashboard').length > 0) {
        Breeze.project_billable = $('.card[data-card-id="' + card + '"]').data('project-billable');
      } else if (Breeze.project_billable == null) {
        Breeze.project_billable = $('.card[data-card-id="' + card + '"]').data('project-billable');
      }
      if (Breeze.project_billable) {
        elt = '<a href="#" class="pill timeentry-pill hide" data-action="set-billable" data-readonly="false" title="Billable">' + Breeze.icons.billable + '</a>';
      } else {
        elt = '<a href="#" class="pill timeentry-pill show" data-action="set-billable" data-readonly="false" title="Not billable">' + Breeze.icons.not_billable + '</a>';
      }
      el = '<div class="row tracker creator" data-user-email="' + Breeze.current_user.email + '" data-time-tracked="0" data-type="container" data-readonly="false" data-id="' + tid + '" data-tid="' + tid + '" data-tracking-start="' + Date.now() + '" data-billable="true" data-date="' + dateFormat(Breeze.Card.logged_date, "UTC:yyyy-mm-dd") + '" data-tracking="true" data-paused="false" data-time-card-id="' + card + '" data-project-id="' + project + '"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg> <div data-type="item"><span data-type="date">' + dateFormat(new Date(), "UTC:mmm d") + '</span><span data-type="time">0</span><span data-type="user">' + Breeze.current_user.name + '</span><span data-type="desc"></span><span data-type="times"></span> </div>' + elt + '</div>';
      $(ele).parent().find('.people-tracked').append($(el));
      timer_entry = $(ele).parent().find('.people-tracked .tracker[data-user-email="' + Breeze.current_user.email + '"][data-tracking="true"]');
    }
    if ($(ele).hasClass("on")) {
      $(ele).removeClass("on");
      $(ele).find("i").attr("class", "icon-play hide").attr("title", "start timer");
      $(ele).closest(".drop-shadow").removeClass("timer-running");
      $(ele).closest(".card-inner").removeClass("timer-running");
    } else {
      $(ele).closest(".drop-shadow").addClass("timer-running");
      $(ele).closest(".card-inner").addClass("timer-running");
      $(ele).addClass("on");
      $(ele).find("i").attr("class", "icon-stop show").attr("title", "stop timer");
    }
    if (typeof window.TimerInterval === "undefined" || (!(window.TimerInterval instanceof Array))) {
      window.TimerInterval = [];
    }
    if ($(ele).hasClass("on")) {
      startTime = Date.now();
      old_time = Math.round(parseInt(bubble.parent().attr('data-tracked')) || 0);
      bubble.html(Breeze.hms(old_time));
      secs = old_time + 1;
      corr = 0;
      socket_params = {
        card: card,
        project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
        method: "card-log-timer-start",
        user: Breeze.current_user.id,
        old_time: old_time,
        project_id: project
      };
      if (old_time <= 0) {
        $.ajax({
          type: "POST",
          url: '/projects/' + project + '/cards/' + card + '/time_entry',
          queue: true,
          data: {
            time_entry: {
              tid: tid,
              started: startTime
            }
          },
          success: function() {
            var new_id;
            socket_params.message = $('[data-card-id="' + card + '"] .people-tracked [data-tid="' + tid + '"]').prop('outerHTML');
            if (Breeze.is_socket_connected()) {
              Breeze.socket.emit("message", socket_params);
            }
            new_id = $('.card[data-card-id="' + card + '"] .people-tracked [data-tid="' + tid + '"]').attr("data-id");
            return Breeze.add_menu_tracker(card, new_id, startTime, project);
          }
        });
      } else {
        startTime = timer_entry.attr('data-tracking-start');
        Breeze.add_menu_tracker(card, timer_entry.attr('data-id'), startTime, project);
      }
      window.TimerInterval[card] = setInterval(function() {
        if (corr >= 10) {
          secs = Math.round((Date.now() - startTime) / 1000);
          corr = 0;
        }
        bubble.html(Breeze.hms(secs));
        bubble.attr('data-tracked', secs);
        secs++;
        return corr++;
      }, 1000);
    } else {
      if (window.TimerInterval && window.TimerInterval[card]) {
        clearInterval(window.TimerInterval[card]);
      }
      if (window.TimerInterval && window.TimerInterval[card + "modal"]) {
        clearInterval(window.TimerInterval[card + "modal"]);
      }
      ttime = Math.round((parseInt(bubble.attr("data-tracked")) || 0) / 60);
      start_time_date = moment(parseInt(timer_entry.attr('data-tracking-start'), 10));
      start_time = start_time_date.format("YYYY-MM-DDTHH:mm:ss") + "Z";
      end_time = start_time_date.add(ttime, 'minutes').format("YYYY-MM-DDTHH:mm:ss") + "Z";
      timer_entry.attr('data-start', start_time);
      timer_entry.attr('data-end', end_time);
      timer_entry.find('[data-type="times"]').html(parse_times(start_time, end_time));
      timer_entry.attr('data-time-tracked', ttime);
      timer_entry.find('[data-type="time"]').html(Breeze.Time.formatPretty(ttime));
      timer_entry.attr('data-tracking', 'false');
      totals = $(ele).closest('.card').find('.time-bubble');
      if (totals.length <= 0) {
        sum = ttime;
      } else {
        sum = ttime + (parseInt(totals.attr("data-tracked")) || 0);
      }
      if (!Breeze.project_billable) {
        Breeze.Card.tracked_time_notbillable = Breeze.Card.tracked_time_notbillable + ttime;
        $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-tracked-notbillable', Breeze.Card.tracked_time_notbillable);
      }
      Breeze.Card.planned_time = parseInt($(ele).closest(".card").attr("data-time-planned")) || 0;
      Breeze.update_card_time(sum, card);
      socket_params = {
        card: card,
        project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
        method: "card-log-timer-stop",
        sum: sum,
        message: timer_entry.prop('outerHTML'),
        user: Breeze.current_user.id,
        entry: timer_entry.data('id'),
        tracked_notbillable: Breeze.Card.tracked_time_notbillable
      };
      $.ajax({
        type: "PUT",
        url: '/projects/' + project + '/cards/' + card + '/time_entry/' + timer_entry.attr('data-id'),
        queue: true,
        data: {
          time_entry: {
            started: null,
            tracked: ttime,
            start: start_time,
            end: end_time
          }
        },
        success: function() {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
      if (window.TimerInterval && window.TimerInterval[card + "menu"]) {
        clearInterval(window.TimerInterval[card + "menu"]);
      }
      // Breeze.remove_menu_tracker(timer_entry.attr('data-id'));
      bubble.parent().remove();
    }
    update_project_list_width();
    update_dashboard_list_width();
    $('#card-widget-menu').html("");
    return false;
  };
  Breeze.hms = function(secs) {
    var i, time;
    time = [0, 0, secs];
    i = void 0;
    i = 2;
    while (i > 0) {
      time[i - 1] = Math.floor(time[i] / 60);
      time[i] = time[i] % 60;
      if (time[i] < 10) {
        time[i] = "0" + time[i];
      }
      i--;
    }
    return time.join(":");
  };
  $(document).on("keydown", "#card-kb-estimate input", function(e) {
    var card, card_id, planned_time, planned_time_store, project, socket_params;
    if (e.keyCode === 13) {
      planned_time = Breeze.Time.parse($('#card-kb-estimate input').val(), 1);
      if (planned_time === null && $('#card-kb-estimate input').val() !== "") {
        $(this).closest('.row').find(".person-icon i").attr("class", "icon-minus");
        $('#card-kb-estimate input').tooltip("show");
        return false;
      }
      planned_time_store = Breeze.Time.parseMinutes(planned_time, 1);
      Breeze.Card.planned_time = planned_time_store;
      card_id = $('#card-kb-estimate').attr('data-card-id');
      project = $('#card-kb-estimate').attr('data-project-id');
      card = $('.card[data-card-id="' + card_id + '"]');
      Breeze.update_card_time(card.find(".time-bubble").attr("data-tracked"), card_id);
      card.attr("data-time-planned", Breeze.Card.planned_time);
      socket_params = {
        card: card_id,
        project: Breeze.project_token != null ? Breeze.project_token : card.attr('data-project-token'),
        method: "card-estimate",
        message: planned_time_store,
        sum: card.find(".time-bubble").attr("data-tracked")
      };
      $.ajax({
        type: "PUT",
        url: '/projects/' + project + '/cards/' + card_id,
        queue: true,
        data: {
          card: {
            planned_time: planned_time_store
          }
        },
        success: function() {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
      card.popover("hide");
      return false;
    }
  });
  $(document).on("keydown", "#card-kb-log input", function(e, item) {
    var box, card, card_id, desc, el, elt, ldate, project, socket_params, tid, total, tracked, tracked_minutes, tracked_parsed;
    if (e.keyCode === 13) {
      box = $(this).closest('[data-action="time-entry"]');
      desc = box.find('[data-type="comment"]').val();
      ldate = box.find('[data-type="date"]').val();
      if ((ldate != null ? ldate.length : void 0) <= 0) {
        ldate = moment().format("YYYY-MM-DD");
      }
      card_id = $('#card-kb-log').attr('data-card-id');
      project = $('#card-kb-log').attr('data-project-id');
      tracked = box.find('[data-type="work"]').val();
      tracked_parsed = Breeze.Time.parse(tracked, 1);
      tracked_minutes = Breeze.Time.parseMinutes(tracked, 1);
      if (tracked_parsed === null) {
        $(this).tooltip("show");
        $('#card-kb-log input[data-type="work"]').tooltip("show");
        return false;
      }
      tid = "time_temp_" + Breeze.current_user.id + new Date().getTime();
      if ($('.task-board').length > 0 || $('.dashboard').length > 0) {
        Breeze.project_billable = $('.card[data-card-id="' + card_id + '"]').data('project-billable');
      } else if (Breeze.project_billable == null) {
        Breeze.project_billable = $('.card[data-card-id="' + card_id + '"]').data('project-billable');
      }
      if (Breeze.project_billable) {
        elt = '<a href="#" class="pill timeentry-pill hide" data-action="set-billable" data-readonly="false" title="Billable">' + Breeze.icons.billable + '</a>';
      } else {
        elt = '<a href="#" class="pill timeentry-pill show" data-action="set-billable" data-readonly="false" title="Not billable">' + Breeze.icons.not_billable + '</a>';
      }
      el = '<div class="row tracker creator" data-user-email="' + Breeze.current_user.email + '" data-time-tracked="' + tracked_minutes + '" data-type="container"  data-billable="true" data-readonly="false" data-id="' + tid + '" data-tid="' + tid + '" data-date="' + dateFormat(ldate, "UTC:yyyy-mm-dd") + '" data-tracking="false" data-paused="false" data-time-card-id="' + card_id + '" data-project-id="' + project + '"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg> <div data-type="item"><span data-type="date">' + dateFormat(ldate, "UTC:mmm d") + '</span><span data-type="time">' + Breeze.Time.formatPretty(tracked_minutes) + '</span><span data-type="user">' + Breeze.current_user.name + '</span><span data-type="desc">' + desc + '</span><span data-type="times"></span> </div>' + elt + '</div>';
      card = $('.card[data-card-id="' + card_id + '"]');
      total = tracked_minutes + (parseInt(card.find(".time-bubble").attr("data-tracked")) || 0);
      socket_params = {
        card: card_id,
        project: Breeze.project_token != null ? Breeze.project_token : card.attr('data-project-token'),
        method: "card-log-time",
        sum: total
      };
      $.ajax({
        type: 'POST',
        queue: true,
        url: '/projects/' + project + '/cards/' + card_id + '/time_entry',
        data: {
          time_entry: {
            tid: tid,
            desc: desc,
            logged_date: ldate,
            tracked: tracked_minutes
          }
        },
        success: function() {
          socket_params.message = card.find('.people-tracked [data-tid="' + tid + '"]').prop('outerHTML');
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
      Breeze.Card.planned_time = card.attr("data-time-planned");
      card.find('.people-tracked').append($(el));
      Breeze.update_card_time(total, card_id);
      card.popover("hide");
      return false;
    }
  });
  Breeze.add_menu_tracker = function(card_id, entry_id, start, project_id) {
    return $('.main-timer-running').attr('data-show-menu', 'true');
  };
  Breeze.remove_menu_tracker = function(entry_id) {
    return $('.main-timer-running').attr('data-show-menu', 'false');
  };
  $.init_timer = function() {
    var el;
		
    el = $('.people-tracked .tracker[data-user-email="' + Breeze.current_user.email + '"][data-tracking="true"]');
    if ($(el).length) {
      $(el).each(function(i, e) {
        var bubble, now, old_time, planned, tracking_tmpl, ttime;
        if (!$(e).closest(".card").find(".timer").hasClass("on")) {
          now = Date.now();
          old_time = Math.round((($(e).attr("data-time-tracked")) * 60) || 0);
          ttime = Math.round((now - $(e).attr("data-tracking-start")) / 1000) + old_time;
          planned = $(e).closest(".card").attr("data-time-planned");
          bubble = $(e).closest(".card").find('.badges .tracking-bubble [data-timer="timer"]');
          tracking_tmpl = '<span class="tracking-bubble" data-tracked="' + ttime + '"> <i class="icon-clock" title="tracking time"></i> <span data-timer="timer" class="show">' + Breeze.hms(ttime) + '</span> </span>';
          $(e).closest(".card").find('.card-tracking-bubble-container').append(tracking_tmpl);
          $(e).closest(".card").find(".timer").trigger("click");
        }
      });
    }
  };
});

parse_times = function(start, end) {
  if (start.length > 0 && end.length > 0) {
    return parse_hours_minutes(start) + " - " + parse_hours_minutes(end);
  } else {
    return "";
  }
};

parse_hours_minutes = function(date) {
  var udate;
  udate = date.match(/(\d+)/g);
  Breeze.CardTime.parse(new Date(udate[0], parseInt(udate[1], 10) - 1, udate[2], udate[3], udate[4])).toFriendlyString();
};

set_timeentry_time = function(date) {
  var e, ldate, startdate, time;
  startdate = moment(date).utc();
  if (!startdate.isValid()) {
    startdate = moment();
    ldate = startdate.format("YYYY-MM-DD") + "T00:00:00Z";
    $('#timeentry-date .dateinput-inline').datepicker('setDate', new Date(ldate));
    $('#card_modal .tracker-hold .dateinput-display').val(dateFormat(ldate, "UTC:mmm d"));
    $('#card_modal .tracker-hold .dateinput').val(ldate);
  }
  try {
    time = Breeze.CardTime.parse($('#card_modal .tracker-hold .start-date-time').val());
    startdate.set({
      hour: time.hour,
      minute: time.minute,
      second: 0,
      millisecond: 0
    });
    $('#card_modal .tracker-hold .timeinput-start').val(startdate.format());
  } catch (_error) {
    e = _error;
  }
  try {
    time = Breeze.CardTime.parse($('#card_modal .tracker-hold .end-date-time').val());
    startdate.set({
      hour: time.hour,
      minute: time.minute,
      second: 0,
      millisecond: 0
    });
    $('#card_modal .tracker-hold .timeinput-end').val(startdate.format());
  } catch (_error) {
    e = _error;
  }
  return calc_tracked_from_time();
};

calc_tracked_from_time = function() {
  var end_time, start_time, tracked;
  start_time = $('#card_modal .tracker-hold .timeinput-start').val();
  end_time = $('#card_modal .tracker-hold .timeinput-end').val();
  new Date($('#card_modal .tracker-hold .timeinput-end').val()) - new Date($('#card_modal .tracker-hold .timeinput-start').val());
  if (start_time && end_time) {
    tracked = new Date(end_time) - new Date(start_time);
    if (tracked > 0) {
      return $('#card_modal .tracker-hold .time-entry-user [data-type="work"]').val(Breeze.Time.formatPretty((tracked / 1000) / 60));
    }
  }
};

calc_time_from_tracked = function() {
  var e, end_time, minutes, start_time, udate, udate_format;
  start_time = $('#card_modal .tracker-hold .timeinput-start').val();
  end_time = $('#card_modal .tracker-hold .timeinput-end').val();
  if (start_time) {
    minutes = Breeze.Time.parseMinutes($('#card_modal .tracker-hold .time-entry-user [data-type="work"]').val(), 1);
    if (minutes && minutes > 0) {
      try {
        udate = moment(start_time).utc();
        udate_format = udate.add(minutes, 'minutes').format("YYYY-MM-DDTHH:mm:ss") + "Z";
        return $('#card_modal .tracker-hold .timeinput-end').val(udate_format);
      } catch (_error) {
        e = _error;
      }
    }
  } else if (end_time) {
    minutes = Breeze.Time.parseMinutes($('#card_modal .tracker-hold .time-entry-user [data-type="work"]').val(), 1);
    if (minutes && minutes > 0) {
      try {
        udate = moment(end_time).utc();
        udate_format = udate.subtract(minutes, 'minutes').format("YYYY-MM-DDTHH:mm:ss") + "Z";
        return $('#card_modal .tracker-hold .timeinput-end').val(udate_format);
      } catch (_error) {
        e = _error;
      }
    }
  }
};

// ---
// generated by coffee-script 1.9.2