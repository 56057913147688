$(document).ready(function() {
	
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});
function init_page() {
  $("#card_modal").on("click", ".card-side .element-link", function() {
    var card_popover;
    $(".popover:not(#card-link-menu)").remove();
    card_popover = $('<div> <form accept-charset="UTF-8" action="/projects/" data-remote="true" id="card-link-deps" method="post"> <div class="deps-group"> <div class="dep-name"> <i class="fa fa-external-link" aria-hidden="true"></i> <input type="hidden" spellcheck="false" name="deps[]" value=""> <input type="text" class="" spellcheck="false" placeholder="Type task name or ID" autocomplete="off"> </div> </div> <div class="deps-results"></div> <div class="submit"> <input name="commit" type="submit" value="Link task" class="bs btn btn-new" data-original-title=""> <div class="loading-dots hide"></div> </div> </form> </div>');
    $(this).popover({
      trigger: "manual",
      placement: "bottom",
      template: '<div class="popover element-popover card-actions" id="card-link-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
      html: true,
      content: card_popover.html()
    });
    if ($('#card-link-menu').length > 0) {
      $(this).popover("hide");
    } else {
      $(this).popover("show");
      Breeze.card_menu = true;
      $('#card-link-deps input[type="text"]').typeahead({
        menu: '<ul class="inline-typeahead"></ul>',
        items: 25,
        appendTo: ".deps-results",
        source: function(query, process) {
          return $.get('/projects/cards/search.json', {
            query: query,
            card_deps: true
          }, function(data) {
            if (!data) {
              data = [];
            }
            return process(data);
          });
        }
      });
      $('#card-link-deps input[type="text"]').focus();
      $('#card-link-deps').on("selected", ".dep-name input", function(e, item) {
        $(e.target).siblings("input:hidden").val(item.id);
        $(e.target).val(item.label);
        return $(e.target).attr("value", item.label);
      });
      $('#card-link-deps').on("click", "input.btn", function(e) {
        var socket_params, that;
        that = $(this);
        socket_params = {
          project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-project-token'),
          method: "card-link",
          card: Breeze.Card.id,
          message: $("#card-assign-user .person-email").prop('outerHTML')
        };
        $.ajax({
          type: "POST",
          data: {
            deps: $('#card-link-deps .dep-name input[type="hidden"]').val()
          },
          queue: true,
          url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/card_links',
          beforeSend: function() {
            $('#card-link-deps .submit .btn').hide();
            return $('#card-link-deps .loading-dots').show();
          },
          error: function() {
            $('#card-link-deps .submit .btn').show();
            return $('#card-link-deps .loading-dots').hide();
          },
          success: function(data) {
            $('#card-link-deps .submit .btn').show();
            $('#card-link-deps .loading-dots').hide();
            if ($(data).hasClass("card-attributes")) {
              $('#card_modal .card-deps-parent').remove();
              $(data).insertAfter($("#card_modal .card-header")).show();
              $(data).find('.card-link-item').each(function() {
                return $('.card[data-card-id="' + $(this).attr('data-link-card-id') + '"]').data('extra-loaded', false);
              });
              socket_params.message = data;
              if (Breeze.is_socket_connected()) {
                Breeze.socket.emit("message", socket_params);
              }
              return $(".card-side .element-link").popover("hide");
            }
          }
        });
        return false;
      });
    }
    return false;
  });
  $("#card_modal").on("click", ".card-link-item .icon-remove", function() {

    $.ajax({
      type: "DELETE",
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/card_links/' + $(this).parent().attr('data-link-id'),
      queue: true
    });
    $(this).parent().fadeOut('fast', function() {
      if ($(this).parent().find('div').length > 1) {
        return $(this).remove();
      } else {
        return $(this).parent().remove();
      }
    });
    return false;
  });
	
	$('#card_modal').on("click", ".card-email-item .icon-remove", function() {
	  var url_host;
	  // if (Breeze.developement()) {
	  //   url_host = "https://app.breeze.test:3006";
	  // } else {
	  //   url_host = "https://breeze-node-n8.herokuapp.com";
	  // }
	  $.ajax({
	    type: "DELETE",
	    url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/email_unlink/' + $(this).parent().attr('data-email-id'),
	    queue: true,
	    data: {
	      auth_token: Breeze.current_user.token
	    }
	  });
	  $(this).parent().fadeOut('fast', function() {
	    if ($(this).parent().find('div').length > 1) {
	      return $(this).remove();
	    } else {
	      return $(this).parent().remove();
	    }
	  });
	  return false;
	});
	
};

