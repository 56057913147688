
import {update_list_wip} from 'mobile/utils/wip.js'
import {stage_equal_heights,init_board_list_view} from 'mobile/utils.js'
import {hide_first_swimlane_init} from 'mobile/utils/swimlane.js'
import {make_sortable} from 'mobile/utils/sortable.js'
import {update_project_list_view_columns} from 'mobile/utils/project_board.js'



var init_project_show;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_project_show();
			$('#card_modal').modal('hide')
		  if ($('.workspace.project-board').length > 0) {
		    if (Breeze.report_card  != null) {
		      openBreezeCard();
		    }
		  }
    }
    if (page === "project") {
      // update_mem_project_list();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    if (Breeze.report_card  != null) {
			
      openBreezeCard();
    }
    init_project_show();
		
  }
  // window.openBreezeCard = null;
  if (window.openBreezeProjectFiles != null) {
    window.openBreezeProjectFiles();
  }
  // sinit_project_show();
});


init_project_show = function() {
	
	
	Breeze.view_width = $('.card-sort').width()
	Breeze.view_height = $(window).height()
  Breeze.stickyHeaderTop = $('.card-sort').length > 0 ? $('.card-sort').offset().top - 30 : 100;
	Breeze.sticky = false
	
  $('.card').show_overdue_tasks();
  // $.init_timer();
		
	// console.log("0000")
	init_board_list_view()
	
	$('.project-board .card-sort').scroll(function() {
	  $('.swimlane-text').css("margin-left", $('.card-sort').scrollLeft());
	  if ($(window).scrollTop() > Breeze.stickyHeaderTop) {
	    return $(".stage_name").css("margin-left", -$('.card-sort').scrollLeft());
	  }
	});

	$(window).scroll(function() {

	
		if ($('.project-list-view').length <= 0 && (($('.project-board').length > 0 )|| Breeze.task_board)) {
		  if ($(window).scrollTop() > Breeze.stickyHeaderTop && !Breeze.sticky) {
		    $('.stage_name').addClass("sticky-head");
		    $('.stage_name:not(.collapsed-stage)').width($('.stage_class:not(.collapsed-stage)').width() - 22);
		    $(".stage_name").css("margin-left", -$('.card-sort').scrollLeft());
		    Breeze.sticky = true;
		  } else if ($(window).scrollTop() < Breeze.stickyHeaderTop && Breeze.sticky) {
		    $('.stage_name').removeClass("sticky-head");
		    $('.stage_name').width("");
		    $(".stage_name").css("margin-left", "");
		    Breeze.sticky = false;
		  }
		} else if ($('.calendar-fluid').length > 0) {
		  if ($(window).scrollTop() > Breeze.stickyCalendarTop) {
		    $('.calendar-fluid').addClass('calendar-scrolling');
		    $('#calendar .fc-header').css('width', $('#calendar').width() + 10 + 'px');
		  } else if ($(window).scrollTop() + 20 < Breeze.stickyCalendarTop) {
		    $('.calendar-fluid').removeClass('calendar-scrolling');
		    $('#calendar .fc-header').css('width', '100%');
		  }
		}
	
	});
	$.init_timer();
	if ($('.sortable_stage').length > 0) {
		make_sortable()
		
		$('html, body').scrollTop(0);

		setTimeout((function() {
		  $('.sortable_stage').each(function() {
		    $(this).find('.stage_class').equalHeights();
		    return $(this).css('min-height', $(this).find('.stage_class:visible').height() + 20);
		  });
		  hide_first_swimlane_init();
		}), 10);

		setTimeout((function() {
		  return $('.sortable_stage').each(function() {
		    return $(this).find('.stage_class .sortable').equalHeights();
		  });
		}), 200);

		$('.sortable_stage').equalWidths();

		$.stages_resize();

		if ($(".timer.on").length <= 0) {
		  $.init_timer();
		}

		$('.card_cover img').on("error", function() {
		  return $(this).parent().remove();
		});

	}
  $('.project-board').on("ajax:beforeSend", ".load-more-cards", function(evt, xhr, settings) {
    var offset;
    $(this).hide();
    $(this).parent().append(Breeze.global_loader);
    offset = $(this).closest('.stage_class').find('.card').length;
    settings.url += "&offset=" + offset;
  });
	
  $('.project-board ').on("ajax:complete", ".load-more-cards", function(evt, xhr, settings) {
    var el, stage, swimlane;
    stage = $(this).closest('.stage_class');
    el = $(xhr.responseText);
    swimlane = $(this).closest('.sortable_stage');
    if ($('.project-list-view').length > 0) {
      el.each(function() {
        $(this).find(".drop-shadow .card_avatars").insertBefore($(this).find(".drop-shadow .badges"));
        $(this).find(".text-overlay-container").appendTo($(this).find(".drop-shadow .badges"));
      });
    }
    if ($('.public-project').length > 0) {
      el.insertBefore(stage.find('.card-placeholder'));
    } else {
      el.insertBefore(stage.find('.task-new-stage'));
    }
    stage.find('.card').each(function() {
      return stage.find('.card[data-card-id="' + $(this).attr('data-card-id') + '"]').not(':last').remove();
    });
    $(this).parent().remove();
    stage.find('.stage-footer').insertAfter(stage.find('.task-new-stage'));
    if (!($('.public-project').length > 0)) {
      // Breeze.filter_cards();
    }
    update_list_wip();
		update_project_list_view_columns()
    $('.card').show_overdue_tasks();
    stage_equal_heights(swimlane);
    $.init_timer();
  });
	
	$('.project-board').on("click", '.project_cog', function() {
	  if (Breeze.current_user.observer) {
	    return false;
	  }
	});
	
	$('#project-delete-modal').on("click", ".actions .delete", function() {
	  if (Breeze.current_user.recent_projects != null) {
	    Breeze.current_user.recent_projects = _.without(Breeze.current_user.recent_projects, parseInt(Breeze.project));
	  }
	  $('.card .timer.on').trigger('click');
		
		Turbolinks.visit("/projects")
		
	});

	$('#project-delete-modal').on("click", ".actions .cancel", function() {
	  return $('#project-delete-modal').modal("hide");
	});

	$('.project-board').on("click", ".project_cog .dropdown-menu .project-delete", function(event) {
	  if ($('.project_cog').hasClass('open')) {
	    $('.project_cog .dropdown-toggle').dropdown("toggle");
	  }
	  $('#project-delete-modal').modal({
	    dynamic: true
	  });
	  return false;
	});

	
	$('.project-board').on("click", '.project_cog  [data-action="reactivate"]', function(e) {
	  $(this).parent().addClass("hide");
	  $(this).parent().next().removeClass("hide");
	  e.preventDefault();
	  $('.project-stripe-archive').remove();
	  $('.workspace.project-board').removeAttr("data-project-archived");
	});

	$('.project-board').on("click", ".project_cog .dropdown-menu .project-archive-action", function(e) {
	  var el, popover_content, project_id;
	  el = $(this).closest(".project_cog").find('.dropdown-toggle');
	  $('.popover').remove();
	  el.removeData("bs.popover");
	  project_id = $('.project-board-loader').attr('data-project-id');
	  popover_content = '<div class="confirmation-popover confirmation-popover-head comment-"> <h1>Archive this project?</h1> <div class="actions"> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button><a href="/projects/' + project_id + '/archive" class="c-button c-button--blue c-button-med archive" data-remote="true" data-redirect="/projects"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Archive <div class="progress-button"> <div class="indeterminate"></div> </div> </a> </div> </div>';
	  el.popover({
	    // container: "element",
	    trigger: "manual",
	    placement: "bottom",
	    template: '<div class="popover project-archive-popover"><div class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
	    html: true,
			sanitize: false,
	    content: popover_content
	  });
	  el.popover("show");
		$('.project_cog .dropdown-toggle').dropdown("toggle")
		
	  $('.project-archive-popover').on("click", '.cancel', function() {
	    el = $(this);
	    $('.popover').remove();
	    el.removeData("bs.popover");
	    return false;
	  });
		
	  $('.project-archive-popover').on("click", '.archive', function() {
				Turbolinks.visit("/projects")
	  });
		
	  $('.project-archive-popover').on("click", function(e) {
	    if (!$(e.target).hasClass("archive")) {
	      return e.stopPropagation();
	    }
	  });
		
	  return false
	});


	
};

function board_replace_open_card(id) {
  var el;
  el = $('.project-board-loader .card[data-card-id=' + id + ']');
  if (el.length > 0) {
    el.replaceWith($('.open-card .card'));
    return $('.open-card').remove();
  }
};

function board_toggle_hide() {
  if ($(".project-board > .m-loader").length > 0) {
    $(".project-board > .m-loader").remove();
  } else {
    $('.project-board').append(Breeze.global_loader);
  }
  $('.project-board .input-append').toggleClass("invisible");
  return $('.project-board .project-footer').toggleClass("invisible");
};

function openBreezeCard() {
	var card_id = Breeze.report_card
  $.ajax({
    method: "GET",
    url: "/projects/"+Breeze.project+"/board",
    success: function(msg) {
      board_toggle_hide()
      $('.project-board-loader[data-project-id="'+Breeze.project+'"]').html(msg)
      board_replace_open_card(card_id)

      // Breeze.init_board_stages()
      // Breeze.init_board_list_view()
			init_board_list_view()
	    $('.card').show_overdue_tasks();
			
      Breeze.card_open = false
  			$(document).trigger("breeze:page","load-swimlanes")
		}

    });
		
	if ($('#card_'+Breeze.report_card ).length > 0) {
		
		$('#card_'+Breeze.report_card).trigger("click") 
	Breeze.report_card = null
		
	}
		
	Breeze.report_card = null  
		
}




	