var $, next, push, queue, queues, remove, unshift;

$ = jQuery;

queues = {};

Breeze.ajax_running = false;

queue = function(name) {
  if (name === true) {
    name = 'default';
  }
  return queues[name] || (queues[name] = []);
};

next = function(name) {
  var deferred, list, options, ref;
  list = queue(name);
  if (!list.length) {
    Breeze.ajax_running = false;
    return;
  }
  ref = list.shift(), options = ref[0], deferred = ref[1];
  return $.ajax(options).always(function() {
    return next(name);
  }).done(function() {
    return deferred.resolve.apply(deferred, arguments);
  }).fail(function() {
    return deferred.reject.apply(deferred, arguments);
  });
};

push = function(name, options) {
  var deferred, list;
  list = queue(name);
  deferred = $.Deferred();
  list.push([options, deferred]);
  if (!Breeze.ajax_running) {
    next(name);
  }
  Breeze.ajax_running = true;
  return deferred.promise();
};

unshift = function(name, options) {
  var deferred, list;
  list = queue(name);
  deferred = $.Deferred();
  list.unshift([options, deferred]);
  if (!Breeze.ajax_running) {
    next(name);
  }
  Breeze.ajax_running = true;
  return deferred.promise();
};

remove = function(name, options) {
  var _, i, j, len, list, ref, results, value;
  list = queue(name);
  results = [];
  for (i = j = 0, len = list.length; j < len; i = ++j) {
    ref = list[i], value = ref[0], _ = ref[1];
    if (!(value === options)) {
      continue;
    }
    list.splice(i, 1);
    break;
  }
  return results;
};

$.ajaxTransport('+*', function(options) {
  var queuedOptions;
  if (options.queue) {
    queuedOptions = $.extend({}, options);
    queuedOptions.queue = false;
    queuedOptions.processData = false;
    return {
      send: function(headers, complete) {
        return push(options.queue, queuedOptions).fail(function(jqXHR, textStatus, errorThrown) {
          if (jqXHR.status === 0) {
            unshift(options.queue, queuedOptions).fail(function(jqXHR, textStatus, errorThrown) {
              if (jqXHR.status === 0) {
                return unshift(options.queue, queuedOptions).fail(function(jqXHR, textStatus, errorThrown) {
                  if (jqXHR.status === 0) {
                    return unshift(options.queue, queuedOptions).fail(function(jqXHR, textStatus, errorThrown) {
                      if (jqXHR.status === 0 || jqXHR.status === 401 || jqXHR.status === 504 || jqXHR.status === 503 || jqXHR.status === 522 || jqXHR.status === 408) {
                        return $.flashMessage("<span class='red-text'>Error connecting to the server. Changes were not saved.  Please refresh your browser window. " + jqXHR.status + "</span>", true);
                      }
                    });
                  }
                });
              }
            });
          }
          if (jqXHR.status === 401 || jqXHR.status === 504 || jqXHR.status === 503 || jqXHR.status === 522 || jqXHR.status === 408) {
            return $.flashMessage("<span class='red-text'>Error connecting to the server. Changes were not saved. Please refresh your browser window. " + jqXHR.status + "</span>", true);
          }
        });
      },
      abort: function() {
        return remove(options.queue, queuedOptions);
      }
    };
  }
});

$.ajaxPrefilter(function(options, originalOptions, xhr) {
  if (!options.crossDomain) {
    token = $('meta[name="csrf-token"]').attr('content');
    if (token) xhr.setRequestHeader('X-CSRF-Token', token);
  }
});
