import {update_project_list_view_columns} from 'mobile/utils/project_board.js'
import dateFormat from 'dateformat';

var elt;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});
function init_page() {
	
	// $(document).on("click", ".card-side .element-custom-fields, #card_modal .custom-field-value-display[data-readonly='false']", function() {
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-copy-link", function() {

	  var link = "https://app.breeze.pm/projects/" + Breeze.Card.project + "/cards/" + Breeze.Card.id;


	   // Copy the text inside the text field
	  navigator.clipboard.writeText(link);
		
		$('.dropdown-item-copy-link').popover('dispose')
    $('.dropdown-item-copy-link').removeData("bs.tooltip");
    $(".popover").remove();
		
		$('.dropdown-item-copy-link').tooltip({
        trigger: "manual",
        placement: "top",
				animation: true,
        // template: '<div class="popover" ><div class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
				title: "Copied link to clipboard"
			
		})
		$('.dropdown-item-copy-link').tooltip("show")
		
			    setTimeout(function() {
			$('.dropdown-item-copy-link').tooltip("hide")

			    }, 800);
		
		return false
		
		
	});
		
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-custom-fields", function() {
		
    var card_popover, el, force_height, new_pos, old_pos, parent_id, pos;
    $(".popover").remove();
		elt = $('.element-menu')

		$('.dropdown-menu').removeClass('show')
		
    if (typeof $(this).data('popover') !== 'undefined') {
      el.removeData("bs.popover");
      $(".popover").remove();
    } else {
       $('.element-menu').popover('dispose')
			
			
      $('#card_modal .custom-field-value-display').removeData('bs.popover');

      el = $('#card_modal .custom-fields')[0].outerHTML;
      card_popover = $('<div> <form accept-charset="UTF-8" action="/projects" data-remote="true" id="card-custom-fields" method="put">' + $(el).show()[0].outerHTML + '<div class="submit"> <input name="commit" type="submit" value="Save" class="bs btn btn-new"> </div> </form> </div>');
      card_popover.find('.custom-field').removeClass("hidden");
      if ($(this).hasClass("custom-field-value-display")) {
        parent_id = $(this).closest('.custom-field').attr('data-field-id');
        card_popover.find('.custom-field:not([data-field-id="' + parent_id + '"])').addClass("hidden");
      }
      elt.popover({
        trigger: "manual",
        placement: "bottom",
        template: '<div class="popover element-popover card-actions" ><div id="card-custom-fields-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
        html: true,
				animation: false,
				container: elt,
				sanitize: false,
        content: card_popover.html()
      });
      elt.popover("show");
      if ($('#card-custom-fields-menu').height() > 660) {
        force_height = true;
        $('#card-custom-fields-menu').addClass('force-height');
      }
      Breeze.card_menu = true;
      pos = $('#card-custom-fields-menu').offset().top + $('#card-custom-fields-menu').height() - $(window).scrollTop();
      if ($('html').height() < pos) {
        new_pos = $('html').height() - $('#card-custom-fields-menu').height() - 60 + $(window).scrollTop();
        $('#card-custom-fields-menu').css('top', new_pos + "px");
      }
      old_pos = $('#card-custom-fields-menu').css('top');
			
			$('#card-custom-fields-menu .custom-field[data-field-type="3"]').on("click", ".remove", function(e) {
			  $(this).parent().find(".date-picker").val("");
			  $(this).parent().find(".date-display").val("");
			  $('.custom-fields-dp').remove();
			  return $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').removeClass("hasDatepicker").removeAttr("id").removeData("datepicker");
			});
			
		  $('.custom-fields-dp').remove();
		  $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').removeClass("hasDatepicker").removeAttr("id").removeData("datepicker");
		  $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').on("click", function(e) {
		    if ($('.custom-fields-dp:visible').length <= 0) {
		      if ($('.date-picker.hasDatepicker').length <= 0) {
		        $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').datepicker({
		          showOtherMonths: true,
		          selectOtherMonths: true,
		          dateFormat: "yy-mm-dd",
		          altFormat: "M d",
		          showAnim: "",
		          constrainInput: true,
		          prevText: "←",
		          nextText: '→',
		          firstDay: Breeze.first_day,
		          showOn: null,
		          beforeShow: function(input, inst) {
		            return $(inst.dpDiv).addClass('custom-fields-dp');
		          },
		          onSelect: function(dateText, inst) {
		            var date_string, day, ddate, ddate_utc, month;
		            month = inst.selectedMonth + 1 <= 9 ? "0" + (inst.selectedMonth + 1).toString() : (inst.selectedMonth + 1).toString();
		            day = inst.selectedDay <= 9 ? "0" + inst.selectedDay.toString() : inst.selectedDay.toString();
		            date_string = inst.selectedYear.toString() + "-" + month + "-" + day;
		            ddate_utc = date_string + "T00:00:00.000Z";
		            ddate = inst.selectedYear.toString() + "-" + month + "-" + day + "T00:00:00" + Breeze.get_timezone_offset(date_string);
		            return $(this).prev().val(dateFormat(ddate_utc, "UTC:mmm d"));
		          }
		        });
		      }
		      $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').datepicker("show");
		    } else {
		      $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').datepicker("hide");
		    }
		    return false;
		  });
		  var startdate = $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').val();
		  if (startdate) {
		    $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').datepicker('setDate', dateFormat(startdate, "UTC:yyyy-mm-dd"));
		  } else {
		    $('#card-custom-fields-menu .custom-field[data-field-type="3"] .date-picker').datepicker('setDate', null);
		  }
			
      $('#card-custom-fields-menu .fields-type-select').multiselect({
        numberDisplayed: 1,
        maxHeight: 200,
        includeSelectAllOption: false,
        nonSelectedText: "",
        nSelectedText: '',
        buttonClass: 'float-label-select',
        enableFiltering: false,
        multiple: false,
        onInitialized: function(select, e) {
          if ($(select).val().length <= 0) {
            return $(e).closest('.custom-field-value').addClass("float-select-empty");
          }
        },
        onChange: function(option, checked, select) {
          el = $(option).closest('.custom-field-value');
          if ($(option).val().length > 0) {
            return $(option).closest('.custom-field-value').removeClass("float-select-empty");
          } else {
            return $(option).closest('.custom-field-value').addClass("float-select-empty");
          }
        },
        onDropdownShown1: function(e, i) {
          $(e.target).closest('.custom-field-value').addClass("float-select-open");
          if (!force_height) {
            pos = $(e.target).offset().top + 220 - $(window).scrollTop();
            old_pos = $('#card-custom-fields-menu').css('top');
            if ($('html').height() < pos) {
              new_pos = $('html').height() - 200 - $('#card-custom-fields-menu').height() + $(window).scrollTop();
              if ($('#card-custom-fields-menu').length > 0) {
                return $('#card-custom-fields-menu').css('top', new_pos + "px");
              }
            }
          }
        },
        onDropdownHidden1: function(e) {
          $(e.target).closest('.custom-field-value').removeClass("float-select-open");
          if (!force_height) {
            if ($('#card-custom-fields-menu').length > 0) {
              return $('#card-custom-fields-menu').css('top', old_pos);
            }
          }
        }
      });

      $('#card-custom-fields').on("click", ".dropdown-toggle", function(e) {
				$(this).parent().find('.multiselect-container.dropdown-menu').toggleClass("show")
				return false
			});
			
			
      $('#card-custom-fields').on("click", ".submit .btn", function(e) {
        var custom_fields, fields_cache, fields_cache_all, socket_params;
        el = $(this).closest('#card-custom-fields-menu');
        custom_fields = {};
        fields_cache = "";
        fields_cache_all = "";
		    el.find('.custom-field').each(function() {
		       var name, value, value_dp;
		       value = "";
		       name = $(this).find('.custom-field-name').text();
		       if ($(this).attr('data-field-type') === "0" || $(this).attr('data-field-type') === "2") {
		         el = $(this).find('.custom-field-value input');
		         value = el.val();
		         el.attr("value", value);
		       } else if ($(this).attr('data-field-type') === "1") {
		         el = $(this).find('.fields-type-select');
		         value = el.val();
		         el.find("option").removeAttr("selected");
		         el.find('option[value="' + value + '"]').attr("selected", "true");
		       } else if ($(this).attr('data-field-type') === "3") {
		         el = $(this).find('.custom-field-value .date-picker');
		         el.removeClass("hasDatepicker").removeAttr("id").removeData("datepicker");
		         value_dp = el.val();
		         value = el.prev().val();
		         el.attr("value", value_dp);
		         el.prev().attr("value", value);
		       }
		       $(this).find('.custom-field-value-display').html(value);
		       if (value.length > 0) {
		         if ($(this).attr('data-field-show-card') === "true") {
		           fields_cache += "<div class='custom-field'><span>" + name + ":</span> " + value + "</div>";
		         }
		         fields_cache_all += "<div class='custom-field'><span>" + name + ":</span> " + value + "</div>";
		         if (value_dp != null) {
		           value = value_dp;
		         }
		         custom_fields[$(this).attr('data-field-id')] = {
		           value: value,
		           name: name
		         };
		         return $(this).removeClass("hide");
		       } else {
		         return $(this).addClass("hide");
		       }
		     });
        $('#card-custom-fields-menu .fields-type-select').multiselect('destroy');
        socket_params = {
          project: Breeze.project_token,
          method: "card-custom-fields",
          card: Breeze.Card.id,
          message: $('#card-custom-fields-menu .custom-fields')[0].outerHTML,
          fields_cache: fields_cache
        };
        $.ajax({
          type: "PUT",
          queue: true,
          url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id,
          data: {
            custom_field: true,
            custom_fields: custom_fields,
            fields_cache: fields_cache,
            fields_cache_all: fields_cache_all
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        el = $($('#card-custom-fields-menu .custom-fields')[0].outerHTML);
        el.find('.custom-field').removeClass("hidden");
        $('#card_modal .custom-fields').html(el.html());
        $('.card[data-card-id="' + Breeze.Card.id + '"] .badges .custom-field').remove();
        $('.card[data-card-id="' + Breeze.Card.id + '"] .badges .card-custom-field-bubble-container').append(fields_cache);
				$('.card[data-card-id="' + Breeze.Card.id + '"] .badges .card-custom-field-bubble-container').append('<div class="custom-field-all hide">'+fields_cache_all+'</div>')
				
        $(".popover").remove();
        $('#card_modal .custom-field-value-display').removeData('bs.popover');
        $('#card_modal .card-side .element-custom-fields').removeData('bs.popover');
				update_project_list_view_columns("custom")
        if ($('#card_modal .custom-fields .custom-field:not(.hide)').length > 0) {
          $('#card_modal .custom-fields').show();
        } else {
          $('#card_modal .custom-fields').hide();
        }
        return false;
      });
    }
    return false;
  });
};

