var init_project_stages;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
       init_project_stages();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    init_project_stages();
  }
});

init_project_stages = function() {
  var load_stage_cards, update_stage, update_stage_cards;
  $('.project-board').on("click", ".stage-settings .automation-list-action", function(e) {
    var el, obj, ref, ref1, ref2, ref3, ref4, ref5, ref6, stage_id, stage_obj;
    el = $(this).closest('.stage_class').find('.dropdown-toggle');
    stage_id = $(this).closest('.stage_class').attr('data-stage-id');
    $(".popover:not(#stage-automation-menu)").remove();
    if ($('#stage-automation-menu').length > 0) {
      el.popover("hide");
    } else {
      el.removeData('bs.popover');
      el.popover({
        container: "element",
        trigger: "manual",
        placement: "bottomleft",
        template: '<div class="popover element-popover card-actions submenu-popover" id="stage-automation-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
        html: true,
        content: $('.stage-automation-popover').html()
      });
      el.popover("show");
      stage_obj = _.find(Breeze.project_stages, function(s) {
        return s.id === parseInt(stage_id);
      });
      if (stage_obj == null) {
        obj = {
          id: parseInt(stage_id),
          name: "Untitled"
        };
        Breeze.project_stages.push(obj);
      }
      if (stage_obj.actions == null) {
        stage_obj.actions = {};
        stage_obj.actions = {
          status: {
            name: null
          },
          color: {
            name: null
          },
          tag: {
            name: null
          },
          user: {
            email: null
          },
          archive: {
            name: null
          },
          duedate: {
            name: null
          }
        };
      }
      if (((ref = stage_obj.actions.status.name) != null ? ref.length : void 0) > 0) {
        $('#stage-automation-menu .status-select .selected-count').text(stage_obj.actions.status.human).removeClass("clear");
      } else if (stage_obj.actions.status.status_clear) {
        $('#stage-automation-menu .status-select .selected-count').text("clear").addClass("clear");
      }
      if (((ref1 = stage_obj.actions.color.name) != null ? ref1.length : void 0) > 0) {
        $('#stage-automation-menu .selected-color').css("background", stage_obj.actions.color.name);
        $('#stage-automation-menu .selected-color').removeClass("clear-color").text("");
      }
      if (stage_obj.actions.color.name && stage_obj.actions.color.name === "#1") {
        $('#stage-automation-menu .selected-color').addClass("clear-color").text("clear");
      }
      if (((ref2 = stage_obj.actions.tag.name) != null ? ref2.length : void 0) > 0) {
        $('#stage-automation-menu .tag-select .selected-count').text((ref3 = stage_obj.actions.tag.name) != null ? ref3.length : void 0).removeClass("clear");
      } else if (stage_obj.actions.tag.tags_clear) {
        $('#stage-automation-menu .tag-select .selected-count').text("clear").addClass("clear");
      }
      if (((ref4 = stage_obj.actions.user.email) != null ? ref4.length : void 0) > 0) {
        $('#stage-automation-menu .user-select .selected-count').text((ref5 = stage_obj.actions.user.email) != null ? ref5.length : void 0).removeClass("clear");
      } else if (stage_obj.actions.user.users_clear) {
        $('#stage-automation-menu .user-select .selected-count').text("clear").addClass("clear");
      }
      if (((ref6 = stage_obj.actions.duedate.name) != null ? ref6.length : void 0) > 0) {
        $('#stage-automation-menu .date-select .selected-count').text(stage_obj.actions.duedate.name).removeClass("clear");
      } else if (stage_obj.actions.duedate.duedate_clear) {
        $('#stage-automation-menu .date-select .selected-count').text("clear").addClass("clear");
      }
      if (stage_obj.actions.archive.name) {
        $('#stage-automation-menu .archive-select').addClass("active");
      } else {
        $('#stage-automation-menu .archive-select').removeClass("active");
      }
      $(document).trigger("breeze:page", ["stages_automation_popovers", stage_id]);
      $('#stage-automation-menu').on("click", ".fa", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        return false;
      });
      $('#stage-automation-menu').on("click", "svg", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $(".stage-settings.dropdown").removeClass("open");
        return false;
      });
      $('#stage-automation-menu').on("click", function(e) {
        return e.stopPropagation();
      });
    }
    return false;
  });
  $('.project-board').on("click", ".stage-settings .dropdown-menu .mark", function(event) {
    $(this).toggleClass("active");
    update_stage($(this));
    return false;
  });
  $('.project-board').on("change", ".stage-settings .dropdown-menu .wip-select select", function(event) {
    return update_stage($(this));
  });
  update_stage = function(that) {
    var hide_list, hide_list_dash, hide_list_project, sel, socket_params, stage, stage_el, stage_id, update_width, wip;
    stage = that.closest(".stage_name");
    stage_id = stage.closest('.stage_class').data("stage-id");
    hide_list = stage.find('.hide-list .mark').hasClass("active");
    hide_list_dash = stage.find('.hide-list-dashboard .mark').hasClass("active");
    hide_list_project = stage.find('.hide-list-project .mark').hasClass("active");
    wip = stage.find('.wip-select select').val();
    stage.find('.wip-selected').text(stage.find('.wip-select option:selected').text());
    stage_el = stage.closest('.stage_class').attr('data-stage-id');
    sel = $('.stage_class[data-stage-id="' + stage_el + '"]');
    sel.attr({
      'data-hidden': hide_list,
      'data-wip': wip
    });
    update_width = false;
    if ((hide_list_project && !sel.hasClass("collapsed-stage")) || (!hide_list_project && sel.hasClass("collapsed-stage"))) {
      update_width = true;
    }
    if (hide_list_project) {
      sel.addClass("collapsed-stage");
    } else {
      sel.removeClass("collapsed-stage");
      if (sel.attr('data-collapsed-loaded') === "false") {
        load_stage_cards(stage_id, $("#card_project_id").val());
      }
    }
    if (hide_list) {
      $('.stage_class[data-stage-id="' + stage_id + '"] .card').attr('data-hidden', "true");
    } else {
      $('.stage_class[data-stage-id="' + stage_id + '"] .card').attr('data-hidden', "false");
    }
    $('.sortable_stage').css('min-height', '');
    $('.stage_class').css('min-height', '');
    $('.stage_class .sortable').css('min-height', '');
    $('.sortable_stage').each(function() {
      $(this).find('.stage_class').equalHeights();
      $(this).css('min-height', $(this).find('.stage_class:visible').height() + 20);
      return $(this).find('.stage_class .sortable').equalHeights();
    });
    if (update_width) {
      $('.stage-settings.open .dropdown-toggle').dropdown('toggle');
      $('.sortable_stage').equalWidths();
    }
    Breeze.update_list_wip();
    socket_params = {
      project: Breeze.project_token,
      method: "stage-update",
      stage: stage_id,
      wip: wip,
      hidden: hide_list,
      hide_dashboard: hide_list_dash,
      hide_project: hide_list_project
    };
    return $.ajax({
      type: "PUT",
      url: '/stages/' + stage_id,
      queue: true,
      data: {
        project_id: $("#card_project_id").val(),
        hidden: hide_list,
        hide_dashboard: hide_list_dash,
        hide_project: hide_list_project,
        wip: wip
      },
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
  };
  update_stage_cards = function(data, stage_id, swimlane_id) {
    var el, stage;
    el = $(data);
    if (swimlane_id != null) {
      stage = $('.sortable_stage[data-swimlane-id="' + swimlane_id + '"] .stage_class[data-stage-id="' + stage_id + '"]');
    } else {
      stage = $('.sortable_stage:first .stage_class[data-stage-id="' + stage_id + '"]');
    }
    if ($('.project-list-view').length > 0) {
      el.each(function() {
        $(this).find(".drop-shadow .card_avatars").insertBefore($(this).find(".drop-shadow .badges"));
        return $(this).find(".text-overlay").appendTo($(this).find(".drop-shadow .badges"));
      });
    }
    stage.find('.sortable').html(el);
    if (!($('.public-project').length > 0)) {
      Breeze.filter_cards();
    }
    Breeze.update_list_wip();
    return $.init_timer();
  };
  return load_stage_cards = function(stage_id, project_id) {
    $('.stage_class[data-stage-id="' + stage_id + '"]').attr('data-collapsed-loaded', 'true');
    return $('.sortable_stage').each(function() {
      var stage, swimlane_id;
      swimlane_id = $(this).attr('data-swimlane-id');
      if (swimlane_id != null) {
        stage = $('.sortable_stage[data-swimlane-id="' + swimlane_id + '"] .stage_class[data-stage-id="' + stage_id + '"] .sortable');
      } else {
        stage = $('.sortable_stage:first .stage_class[data-stage-id="' + stage_id + '"] .sortable');
      }
      stage.html('<div class="card card-skeleton"></div>');
      return $.ajax({
        type: "GET",
        url: '/projects/' + project_id + '/stages/' + stage_id + '/cards',
        queue: true,
        data: {
          page: 1,
          swimlane: swimlane_id,
          collapsed_stage: true
        },
        success: function(data) {
          return update_stage_cards(data, stage_id, swimlane_id);
        }
      });
    });
  };
};
