import {dec_wip_total,inc_wip_total} from 'mobile/utils/wip.js'
import {hide_first_swimlane} from 'mobile/utils/swimlane.js'

function move_to_archive(card_id, project_id) {
  var card, el, pos;
  card = $('.card[data-card-id="' + card_id + '"]');
  if (card.find('.timer.on').length > 0) {
    card.find('.timer.on').trigger('click');
  }
  if (card.parent().hasClass('card-outer')) {
    return card.parent().toggleClass("hide");
  } else if ($('.task-board').length > 0) {
    return card.addClass("hide");
  } else if ($('.workspace').hasClass("calendar-fluid")) {
    return card.attr("data-archived", "true");
  } else {
    if ($("#task-archive-list.hide").length > 0) {
      $("#task-archive-list").append('<div class="cont"></div>');
    }
    $('.empty-archive').remove();
    card.data("stage-id", card.closest(".stage_class").data("stage-id"));
    card.data("stage-name", card.closest(".stage_class").find(".stage_text").html());
    pos = parseInt($('.sortable_stage .stage_class').index($('.stage_class[data-stage-id="' + card.attr("data-stage-id") + '"]')));
    card.data("stage-position", pos + 1);
    card.attr("data-archived", "true");
    card.data("archived", "true");
    el = $('<div class="archived-card" data-card-id="' + card_id + '"> <hr class="soften"> <a href="/projects/' + project_id + '/cards/' + card_id + '" data-method="delete" data-remote="true" class="card-delete empty" data-confirm="Do you want to permanently delete this card ? There is no undo for this action, the card will be removed from the board, calendar, reports and activity stream."><i class="icon-remove"></i></a> <a href="#" data-action="open">' + card.find('.card_name').html() + '</a> </div>');
    card.insertAfter(el.find('a[data-action="open"]'));
    $("#task-archive-list").prepend(el);
    dec_wip_total(card);
    hide_first_swimlane();
  }
};

export {move_to_archive};
