import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after,uniqueId,generate_s3_key} 
				from 'mobile/froala/setup.js';

var init_page;


$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();

    }
		

		
	})
  if ($('.workspace.project-board').length > 0) {

    init_page();
	
  }
});


init_page = function() {

$('.project-board').on("click", '.project_cog  a[data-target=project-description-modal], .project_name_group[data-readonly="false"] .project_name_card, .project_name_group[data-readonly="false"] .project_description_card', function(e) {
  if (getSelection().toString() !== "" && e.target.contains(getSelection().anchorNode)) {
    return false;
  }
  $('#project-description-modal').modal({
    dynamic: true
  });
  $("#project-description-modal").modal("show");
  if ($('.project_cog').hasClass('open')) {
    $('.project_cog .dropdown-toggle').dropdown("toggle");
  }
  $('#project-description-modal #project_description').attr('id', 'editor-element');
  Breeze.mention_users = null;
		init_froala_setup()
	
  $('#editor-element').on('froalaEditor.initialized', function(e, editor) {
    editor_setup(editor);
    find_editor_attachments();
    if ($('.upload-disabled').length > 0) {
      return editor.events.on('drop', (function(dropE) {
        return false;
      }), true);
    }
  });
  froala_setup.heightMin = 200;
  $('#project-description-modal #editor-element').froalaEditor(froala_setup);
  if ($('#project_name').attr("readonly")) {
    $("#project-description-modal #editor-element").froalaEditor("edit.off");
  }
  // $("html").css("overflow-y", "hidden");
  // $("html").css("overflow", "hidden");
  // $("html").css("-ms-overflow-y", "hidden");
  e.preventDefault();
});

$('.project-board').on('hide', '#project-description-modal', function(e) {
  // if (!Breeze.current_user.observer) {
  //   key.setScope("board");
  // }
  if (!Breeze.description_hide) {
    $('#project-description-modal .edit_project').submit();
  }
  Breeze.description_hide = false;
  $('#project-description-modal #editor-element').froalaEditor('destroy');
  $('#project-description-modal #editor-element').attr('id', 'project_description');
  $('#project-description-modal #project_description').off();
  froala_setup.heightMin = 220;
});




}

$(document).on("ajax:beforeSend", "#project-description-modal  .edit_project", function(evt, xhr, settings) {
	
  if ($('#project-description-modal #project_name').val().length <= 0) {
    return false;
  }
});

$(document).on("ajax:complete", "#project-description-modal .edit_project", function(evt, xhr, status) {
  var socket_params;
	
  $(".project_head").html(xhr.responseText);
  if ($('.project_description_card').text().length > 0) {
    $('.project-board').addClass('project-board-has-description');
  } else {
    $('.project-board').removeClass('project-board-has-description');
  }
  // socket_params = {
  //   card: Breeze.Card.id,
  //   project: Breeze.project_token,
  //   message: xhr.responseText,
  //   method: "edit-project",
  //   description: $('#project-description-modal #project_description').val()
  // };
  // if (Breeze.is_socket_connected()) {
  //   Breeze.socket.emit("message", socket_params);
  // }
});

$(document).on("click", '#project-description-modal input[type="submit"]', function(e) {
  if ($('#project-description-modal #project_name').val().length <= 0) {
    return false;
  }
  Breeze.description_hide = true;
  // $('#project-description-modal').modal('hide');
});

$(document).on('hide.bs.modal', '#project-description-modal', function(e) {
	$('#project-description-modal input[type="submit"]').trigger("click")
  // if (!Breeze.current_user.observer) {
  //   return key.setScope("board");
  // }
});
