$(document).ready(function() {
	
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
		
		
  }
	
});
function init_page() {
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-colors", function() {
    var card_popover, target;
		el = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
		
    $(".popover:not(#card-color-menu)").remove();

    if ($('#card-color-menu').length > 0) {
       el.popover("hide");
    } else {
			 $('.element-menu').popover('dispose')
			
		card_popover =` <ul class="color-list-card"> 
				<li><span class="color redbg"  data-color="redbg" title="dark red"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c13bg" data-color="c13bg" title="pink"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c6bg" data-color="c6bg" title="red"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c17bg" data-color="c17bg" title="deep pink"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c16bg" data-color="c16bg" title="salmon"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c2bg" data-color="c2bg" title="purple"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c21bg" data-color="c21bg" title="dark purple"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c18bg" data-color="c18bg" title="dark violet"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c19bg" data-color="c19bg" title="violet"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c20bg" data-color="c20bg" title="orchid"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c12bg" data-color="c12bg" title="dark magneta"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color bluebg" data-color="bluebg" title="dark blue"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c22bg" data-color="c22bg" title="royal blue"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c11bg" data-color="c11bg" title="blue"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c31bg" data-color="c31bg" title="dodger blue"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c23bg" data-color="c23bg" title="slate blue"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c10bg" data-color="c10bg" title="light blue"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c4bg" data-color="c4bg" title="turquoise"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color greenbg" data-color="greenbg" title="green"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c26bg" data-color="c26bg" title="sea green"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c9bg" data-color="c9bg" title="bright green"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c24bg" data-color="c24bg" title="spring green"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c25bg" data-color="c25bg" title="lime"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c28bg" data-color="c28bg" title="yellow green"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c33bg" data-color="c33bg" title="light yellow"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c29bg" data-color="c29bg" title="lemon"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color yellowbg" data-color="yellowbg" title="yellow"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c8bg" data-color="c8bg" title="gold"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c3bg" data-color="c3bg" title="orange"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c32bg" data-color="c32bg" title="dark orange"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c27bg" data-color="c27bg" title="orange red" ><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c14bg" data-color="c14bg" title="mid grey"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c5bg" data-color="c5bg" title="brown"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c30bg" data-color="c30bg" title="rosy brown"><i class="check icon-checkmark-2 "></i></span> </li>
				<li><span class="color c15bg" data-color="c15bg" title="light grey"><i class="check icon-checkmark-2 "></i></span> </li>								
				<li><span class="color c1bg" data-color="c1bg" title="dark grey"><i class="check icon-checkmark-2 "></i></span> </li>
		</ul>`
								
		  el.popover({
	      trigger: "manual",
	      placement: "bottom",
				animation: false,
				container: el,
				sanitize: false,
	      template: '<div class="popover element-popover card-actions" ><div id="card-color-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
	      html: true,
	      content: card_popover
	    });
			
			
       el.popover("show");
       // $('.element-menu').removeData('bs.popover');
       // $('.element-menu').popover('dispose')
      Breeze.card_menu = true;
      if (Breeze.Card.color === "") {
        $('.color-list-card .color .check').removeClass("show");
      } else {
        target = $('.color-list-card .color[data-color="' + Breeze.Card.color + '"]');
        target.parent().siblings().find(".check").removeClass("show");
        target.find(".check").addClass("show");
      }
      $('#card-color-menu').on("click", ".color", function() {
        var socket_params;
        $(this).find(".check").toggleClass("show");
        $(this).parent().siblings().find(".check").removeClass("show");
        Breeze.Card.color = $('#card-color-menu .check.show').parent().data('color') || "";
        socket_params = {
          card: Breeze.Card.id,
          project: Breeze.project_token,
          message: Breeze.Card.color,
          method: "card-color"
        };
        $.ajax({
          type: "PUT",
          url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id,
          queue: true,
          data: {
            card: {
              color: Breeze.Card.color
            }
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        $("#card_modal .color-label").attr("class", "color-stripe color-label " + Breeze.Card.color);
        if (Breeze.Card.color === "") {
          $('.card[data-card-id="' + Breeze.Card.id + '"] .color-stripe').attr("class", "color-stripe");
          $('.card[data-card-id="' + Breeze.Card.id + '"]').data("card-colorbg", "");
          $('.card[data-card-id="' + Breeze.Card.id + '"]').attr("data-card-colorbg", "");
        } else {
          $('.card[data-card-id="' + Breeze.Card.id + '"] .color-stripe').attr("class", "color-stripe color-label " + Breeze.Card.color);
          $('.card[data-card-id="' + Breeze.Card.id + '"]').data("card-colorbg", Breeze.Card.color);
          $('.card[data-card-id="' + Breeze.Card.id + '"]').attr("data-card-colorbg", Breeze.Card.color);
        }
			 $('.element-menu').popover('dispose')
        return false;
      });
    }
    return false;
  });
};
