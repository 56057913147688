import {developement} from 'mobile/utils.js';

var init_github_response, init_project_settings, init_settings_modal;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      return init_project_settings();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    return init_project_settings();
  }
});

init_settings_modal = function() {
  var duedate, startdate;
  $('#project-calendar-modal .board-bgcolor-select').multiselect({
    numberDisplayed: 1,
    nonSelectedText: "none",
    buttonClass: 'btn-material',
    nSelectedText: 'selected',
    multiple: true
  });
  $('#project-calendar-modal #project_workspace_id').multiselect({
    numberDisplayed: 1,
    maxHeight: 600,
    nonSelectedText: "default",
    buttonClass: 'btn-material',
    nSelectedText: 'selected',
    multiple: false
  });
  $('#project-calendar-modal .project-group-select').multiselect({
    numberDisplayed: 1,
    maxHeight: 600,
    nonSelectedText: "default",
    buttonClass: 'btn-material project-autoarchive-week',
    nSelectedText: 'selected',
    multiple: false
  });
  $('#project-calendar-modal .project-archive-select').multiselect({
    numberDisplayed: 1,
    maxHeight: 600,
    nonSelectedText: "default",
    buttonClass: 'btn-material project-autoarchive-status',
    nSelectedText: 'selected',
    multiple: false
  });
  $('#project-calendar-modal .project-archive-stage-select').multiselect({
    numberDisplayed: 1,
    maxHeight: 600,
    nonSelectedText: "default",
    buttonClass: 'btn-material project-autoarchive-status',
    nSelectedText: 'selected',
    multiple: false
  });
  $('#project-calendar-modal .project-autoduedate-status-id-select').multiselect({
    numberDisplayed: 1,
    maxHeight: 600,
    nonSelectedText: "default",
    buttonClass: 'btn-material project-autoarchive-status',
    nSelectedText: 'selected',
    multiple: false
  });
  $('#project-calendar-modal').on("ajax:beforeSend", ".edit_project", function(evt, xhr, settings) {
    $(this).find('.c-button').prop('disabled', true);
    return $(this).find('.c-button .progress-button').show();
  });
  $('#project-calendar-modal').on("ajax:complete", ".edit_project", function(evt, xhr, settings) {
    $(this).find('.c-button').prop('disabled', false);
    return $(this).find('.c-button .progress-button').hide();
  });
  $('#project-settings-features').on("ajax:complete", function(evt, xhr, status) {
    var el;
    el = 'https://public.breeze.pm/p/' + $('<div>' + xhr.responseText + '</div>').find('.project_name_group').attr('data-project-pslug');
    return $('.public-project-link a').attr('href', el).text(el);
  });
  $('#project-calendar-modal').on("change", '#project-settings-features input[type="radio"],#project-settings-features input[type="checkbox"]', function(e) {
    return $('#project-settings-features input[type="submit"]').trigger("click");
  });
  $('#project-calendar-modal').on("click", ".color-list .color", function() {
    $(this).find(".check").toggleClass("show");
    return $(this).parent().siblings().find(".check").removeClass("show");
  });
  $('#project-calendar-modal').on("change", '#project-settings-features input[type="radio"]', function(e) {
    return $('#public-project-settings').toggleClass('hide');
  });
  $('#project-calendar-modal').on("change", '.project-file-storage-section input[type="radio"]', function(e) {
    var url;
    $('#project-storage-google-settings').toggleClass('hide');
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/projects/" + Breeze.project + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n7.herokuapp.com/projects/" + Breeze.project + "?auth_token=" + Breeze.current_user.token;
    }
    Breeze.project_file_storage = $('input[name="project[file_storage]"]:checked').val();
    return $.ajax({
      type: 'PUT',
      url: url,
      data: {
        partial: true,
        project: {
          file_storage: $('input[name="project[file_storage]"]:checked').val()
        }
      }
    });
  });
  $('#project-calendar-modal').on("focus", '.budget input[name="project[budget_amount_display]"]', function(e) {
    var amount, rate, symbol, total;
    if ($('.budget input[name="project[budget_hours]"]').val() && $('.budget input[name="project[hourly_rate_display]"]').val()) {
      rate = $('.budget input[name="project[hourly_rate_display]"]').val();
      symbol = rate.match(/[^0-9-.,]/g);
      if (symbol != null) {
        symbol = symbol.join("");
      }
      amount = accounting.unformat(rate);
      total = amount * parseInt($('.budget input[name="project[budget_hours]"]').val());
      return $(this).val(accounting.formatMoney(total, symbol, 0));
    }
  });
  $('#project-calendar-modal').on("submit", ".edit_project, #project-settings-features", function(evt, xhr, settings) {
    var url;
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/projects/" + Breeze.project + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n7.herokuapp.com/projects/" + Breeze.project + "?auth_token=" + Breeze.current_user.token;
    }
    return $(this).attr('action', url);
  });
  $('#project-calendar-modal').on("click", ' #project-settings button[type="submit"], #project-settings-features input[type="submit"], #project-settings-automation button[type="submit"]', function(e) {
    var budget_amount, budget_amount_display, budget_hours, color, form, hourly_rate, hourly_rate_display, symbol;
    form = $(this).closest("form");
    if (form.is('#project-settings')) {
      budget_hours = form.find('input[name="project[budget_hours]"]');
      hourly_rate = form.find('input[name="project[hourly_rate]"]');
      hourly_rate_display = form.find('input[name="project[hourly_rate_display]"]');
      budget_amount = form.find('input[name="project[budget_amount]"]');
      budget_amount_display = form.find('input[name="project[budget_amount_display]"]');
      hourly_rate.val(accounting.unformat(hourly_rate_display.val()));
      budget_amount.val(accounting.unformat(budget_amount_display.val()));
      symbol = hourly_rate_display.val().match(/[^0-9-.,]/g);
      if (symbol == null) {
        symbol = budget_amount_display.val().match(/[^0-9-.,]/g);
      }
      if (symbol != null) {
        symbol = symbol.join("");
      }
      if (symbol != null) {
        form.find('input[name="project[currency_symbol]"]').val(symbol);
      }
      hourly_rate.attr("value", hourly_rate.val());
      hourly_rate_display.attr("value", hourly_rate_display.val());
      budget_amount.attr("value", budget_amount.val());
      budget_amount_display.attr("value", budget_amount_display.val());
      budget_hours.attr("value", budget_hours.val());
    }
    if ($('#project_show_gantt[type="checkbox"]').is(':checked')) {
      $('[data-action="project-gantt"]').removeClass('hide');
    } else {
      $('[data-action="project-gantt"]').addClass('hide');
    }
    if ($('#project_notes_show[type="checkbox"]').is(':checked')) {
      $('[data-action="project-notes"]').removeClass('hide');
    } else {
      $('[data-action="project-notes"]').addClass('hide');
    }
    if ($('#assign_tasks_self:checked').length > 0) {
      if (!Breeze.current_user.assign_tasks_self) {
        Breeze.current_user.assign_tasks_self = [];
      }
      Breeze.current_user.assign_tasks_self.push(parseInt(Breeze.project, 10));
    } else {
      if (Breeze.current_user.assign_tasks_self) {
        Breeze.current_user.assign_tasks_self = Breeze.current_user.assign_tasks_self.remove2(parseInt(Breeze.project, 10));
      }
    }
    if ($('#project_card_size[type="checkbox"]').is(':checked')) {
      Breeze.project_card_size = true;
    } else {
      Breeze.project_card_size = false;
    }
    if ($('#project_billable[type="checkbox"]').is(':checked')) {
      Breeze.project_billable = true;
    } else {
      Breeze.project_billable = false;
    }
    if ($('#project_task_deps[type="checkbox"]').is(':checked')) {
      Breeze.project_card_links = true;
    } else {
      Breeze.project_card_links = false;
    }
    if ($('#project_time_tracking[type="checkbox"]').is(':checked')) {
      Breeze.project_time_tracking = true;
      $('.workspace').removeClass('hide-timetracking');
      Breeze.update_project_list_view_columns();
      Breeze.update_project_layout();
    } else {
      Breeze.project_time_tracking = false;
      $('.workspace').addClass('hide-timetracking');
      Breeze.update_project_list_view_columns();
      Breeze.update_project_layout();
    }
    if ($('#project_estimates[type="checkbox"]').is(':checked')) {
      Breeze.project_estimates = true;
    } else {
      Breeze.project_estimates = false;
    }
    if ($('#project_user_estimates[type="checkbox"]').is(':checked')) {
      Breeze.project_user_estimates = true;
    } else {
      Breeze.project_user_estimates = false;
    }
    color = $('#project-settings-tab .board-bgcolor-select').val();
    if (color.length > 0) {
      $('body').css("background", color).addClass('custom-bg');
      $('.workspace').addClass('custom-background');
      $('#project_board_background_color').val(color);
      $('.stage_class, .sortable').css('min-height', 'auto');
      $('.custom-background').attr('data-bg-color', color);
    } else {
      $('body').css("background", "").removeClass('custom-bg');
      $('.workspace').removeClass('custom-background');
      $('#project_board_background_color').val("");
    }
    Breeze.autoduedate_status_id = parseInt($('.project-autoduedate-status-id-select').val()) || 0;
    return true;
  });
  $('#project-calendar-modal').on("change", '#slack-token input[type="checkbox"]', function(e) {
    return $(this).closest("form").submit();
  });
  $('#slack-token').on("click", "[data-action='slack-message-types']", function(e) {
    $('.slack-notification-panel').slideToggle();
    return false;
  });
  $('#project-integrations').on("change", "#project_github_prs", function() {
    var url;
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006";
    } else {
      url = "https://breeze-node-n7.herokuapp.com";
    }
    return $.ajax({
      type: 'PUT',
      url: url + "/projects/" + Breeze.project + "?auth_token=" + Breeze.current_user.token,
      data: {
        project: {
          github_prs: this.checked
        }
      }
    });
  });
  $('#project-calendar-modal .project-dates .date-picker').datepicker({
    showOtherMonths: true,
    selectOtherMonths: true,
    dateFormat: "yy-mm-dd",
    altFormat: "M d",
    showAnim: "",
    constrainInput: true,
    prevText: "←",
    nextText: '→',
    firstDay: Breeze.first_day,
    onSelect: function(dateText, inst) {
      var date_string, day, ddate, ddate_utc, month;
      month = inst.selectedMonth + 1 <= 9 ? "0" + (inst.selectedMonth + 1).toString() : (inst.selectedMonth + 1).toString();
      day = inst.selectedDay <= 9 ? "0" + inst.selectedDay.toString() : inst.selectedDay.toString();
      date_string = inst.selectedYear.toString() + "-" + month + "-" + day;
      ddate_utc = date_string + "T00:00:00.000Z";
      ddate = inst.selectedYear.toString() + "-" + month + "-" + day + "T00:00:00" + Breeze.get_timezone_offset(date_string);
      return $(this).prev().val(dateFormat(ddate_utc, "UTC:mmm d"));
    }
  });
  startdate = $('#project-calendar-modal .project-dates #project_startdate').val();
  if (startdate) {
    $('#project-calendar-modal .project-dates #project_startdate').datepicker('setDate', dateFormat(startdate, "UTC:yyyy-mm-dd"));
  } else {
    $('#project-calendar-modal .project-dates #project_startdate').datepicker('setDate', null);
  }
  duedate = $('#project-calendar-modal .project-dates #project_duedaredate').val();
  if (duedate) {
    $('#project-calendar-modal .project-dates #project_duedatedate').datepicker('setDate', dateFormat(duedate, "UTC:yyyy-mm-dd"));
  } else {
    $('#project-calendar-modal .project-dates #project_duedatedate').datepicker('setDate', null);
  }
  $('#project-calendar-modal').on("click", ".date-display", function(e) {
    $(this).next().datepicker("show");
    return $(this).focus();
  });
  $('#project-calendar-modal').on("keydown", ".date-display", function(e) {
    $(this).next().val("");
    $(this).next().datepicker("hide");
    return $(this).val("");
  });
  $('#project-calendar-modal').on("click", '[data-action="start-export"]', function(e) {
    var url;
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/backup/" + Breeze.project;
    } else {
      url = "https://breeze-node-n.herokuapp.com/backup/" + Breeze.project;
    }
    $('#project-calendar-modal .export-start').hide();
    $('#project-calendar-modal .export-url').hide();
    $('#project-calendar-modal .export-load').show();
    $.ajax({
      type: 'GET',
      headers: {
        Authorization: "" + Breeze.current_user.token
      },
      url: url
    });
    return false;
  });
  $('#project-calendar-modal').on("click", '.remove-slack', function(e) {
    return $.ajax({
      type: 'DELETE',
      url: "/slack-command/" + Breeze.project,
      data: {
        partial: true
      },
      success: function(data) {
        $('#project-calendar-modal .remove-slack').hide();
        return $('#project-calendar-modal .add-slack').show();
      }
    });
  });
  return $('#project-calendar-modal').on("click", '.add-slack', function(e) {
    var el, url, x, y;
    el = $(this);
    url = el.attr('data-url');
    y = window.top.outerHeight / 2 + window.top.screenY - (700 / 2);
    x = window.top.outerWidth / 2 + window.top.screenX - (700 / 2);
    Breeze.slack_auth_window = window.open(url, "Slack", 'toolbar=no, location=no, directories=no, status=no, menubar=no,copyhistory=no, width=700, height=700, top=' + y + ', left=' + x);
    return false;
  });
};

init_github_response = function() {
  var code, container;
  code = window.location.href.match(/\?code=(.*)/);
  if (code && code.length > 1) {
    $('.nav-tabs a[href="#project-integrations"]').tab('show');
    container = $('.modal-backdrop');
    container.scrollTop($('#project-calendar-modal .github-anchor').offset().top - container.offset().top + container.scrollTop() - 20);
    $('#project-calendar-modal .github-list').append('<div class="loading-dots"></div>');
    History.pushState({
      modal: "scroll",
      "static": true
    }, $("title").text(), "/projects/" + Breeze.project);
    return $.getJSON("https://breeze-gate.herokuapp.com/authenticate/" + code[1], function(data) {
      var github_repos, i, len, repo, repos;
      $('#project-calendar-modal .github-list .loading-dots').remove();
      if (data && data.token) {
        repos = Breeze.get_github_repos(data.token);
        github_repos = "";
        for (i = 0, len = repos.length; i < len; i++) {
          repo = repos[i];
          github_repos += '<div class="grepo"><label class="m-label"><a href="' + repo.url + '" target="_blank">' + repo.name + '</a><input type="checkbox" data-repo="' + repo.name + '" data-token="' + data.token + '"><span class="m-checkmark"></span></label></div>';
        }
        $('#project-calendar-modal .github-list').append(github_repos);
        container = $('.modal-backdrop');
        return container.scrollTop($('#project-calendar-modal .github-anchor').offset().top - container.offset().top + container.scrollTop() - 20);
      }
    });
  }
};

init_project_settings = function() {
  var code;
  if ($('.custom-background').length > 0) {
    $('body').css("background", $('.custom-background').attr('data-bg-color')).addClass('custom-bg');
  } else {
    $('body').css("background", "").removeClass('custom-bg');
  }
  $('.project_cog').on("click", "[data-target='calendar']", function(e) {
    var target, url;
    if (typeof gapi === "undefined") {
      $.getScript("https://apis.google.com/js/client.js");
    }
    if ($("#project-calendar-modal .nav-tabs").length <= 0) {
      target = $(this).attr('href');
      $('#project-calendar-modal').modal({
        dynamic: true
      });
      $('#project-calendar-modal').append(Breeze.global_loader);
      $("#project-calendar-modal").modal("show");
      if (Breeze.developement()) {
        url = "https://app.breeze.test:3006";
      } else {
        url = "https://breeze-node-n7.herokuapp.com";
      }
      $.ajax({
        type: 'GET',
        url: url + "/projects/" + Breeze.project + "/settings?auth_token=" + Breeze.current_user.token,
        data: {
          partial: true
        },
        beforeSend: function() {},
        success: function(data) {
          $('#project-calendar-modal .m-loader').remove();
          $('#project-calendar-modal .modal-body').html($(data).html());
          init_settings_modal();
          return init_github_response();
        }
      });
    } else {
      $("#project-calendar-modal").modal("show");
      if ($('#project-calendar-modal .export-load').is(":visible")) {
        if (Breeze.developement()) {
          url = "https://app.breeze.test:3006/backup_url/" + Breeze.project;
        } else {
          url = "https://breeze-node-n.herokuapp.com/backup_url/" + Breeze.project;
        }
        $.ajax({
          type: 'GET',
          headers: {
            Authorization: "" + Breeze.current_user.token
          },
          url: url,
          success: function(resp) {
            if (resp.length > 10) {
              $('#project-calendar-modal .export-url a').attr('href', resp);
              $('#project-calendar-modal .export-start').show();
              $('#project-calendar-modal .export-url').show();
              return $('#project-calendar-modal .export-load').hide();
            }
          }
        });
      }
    }
    return e.preventDefault();
  });
  code = window.location.href.match(/\?code=(.*)/);
  if (code && code.length > 1) {
    $('.project_cog [data-target="calendar"]').trigger('click');
    return $('.project_cog').removeClass("open");
  }
};

// ---
// generated by coffee-script 1.9.2