import {update_wip_total} from 'mobile/utils/wip.js'
import {update_task_stripe} from 'mobile/utils/task_stripe.js';
import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});
function init_page() {
	
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-mark", function() {

    var card_popover, i, len, ref, s, status, statuses, el;
		
		el = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
		
    $(".popover:not(#card-stage-menu)").remove();
    if ($('#card-stage-menu').length > 0) {
       el.popover("hide");
    } else {
      status = $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-status-id');
       $('.element-menu').popover('dispose')
			
      // $('.element-menu').removeData('bs.popover');
      statuses = "";
      ref = Breeze.project_statuses;
      for (i = 0, len = ref.length; i < len; i++) {
        s = ref[i];
				if (!s.project_only) {
	        statuses += '<li><a href="#" class="mark hide" data-status-color="' + s.color + '" data-status-id="' + s.id + '">' + s.name + '</a></li>';
				}
      }
      card_popover = $('<div> <ul class="menu status-check">' + statuses + '</ul> </div>');
      el.popover({
        trigger: "manual",
        placement: "bottom",
				animation: false,
				container: el,
        template: '<div class="popover element-popover card-actions dropdown-check" ><div class="popover-inner" id="card-stage-menu"><div class="popover-body"><div></div></div></div></div>',
        html: true,
				sanitize: false,
        content: card_popover.html()
      });
      el.popover("show");
      Breeze.card_menu = true;
      
			$('#card-stage-menu .mark[data-status-id="' + status + '"]').removeClass("hide");
			
      $('#card-stage-menu').on("click", ".mark", function(event) {
        var c, els, has_old_status, lcard, ltoken, status_color, status_id, status_name, that;
        that = $(this);
        if ($(this).hasClass("hide")) {
          $(this).closest('.menu').find('.mark').addClass("hide");
          $(this).toggleClass("hide");
        } else {
          $(this).closest('.menu').find('.mark').addClass("hide");
        }
        els = $('#card-stage-menu .menu .mark:not(.hide)');
        status_name = els.text() || "";
        status_color = els.attr('data-status-color') || "";
        status_id = els.attr('data-status-id') || "";
        c = $('.card[data-card-id="' + Breeze.Card.id + '"]');
        has_old_status = c.attr('data-status-id') && c.attr('data-status-id').length > 0;
        $('.card[data-card-id="' + Breeze.Card.id + '"]').attr("data-status-id", status_id);
        $('.card[data-card-id="' + Breeze.Card.id + '"] .text-overlay').text(status_name).css('background-color', status_color);
        $('[data-link-card-id=' + Breeze.Card.id + ']').each(function() {
          $(this).find('.card-link-status ').attr('data-status-id', status_id).css('background-color', status_color).text(status_name);
        });
        update_wip_total($('.card[data-card-id="' + Breeze.Card.id + '"]'), has_old_status);
        update_task_stripe(Breeze.Card.id);
        if (status_name === "Done" || status_name === "done") {
          $('.card[data-card-id="' + Breeze.Card.id + '"] .calendar-bubble.overdue-task').addClass("done");
        } else {
          $('.card[data-card-id="' + Breeze.Card.id + '"] .calendar-bubble.overdue-task').removeClass("done");
        }
        lcard = Breeze.Card.id;
        ltoken = Breeze.project_token;
        $.ajax({
          type: "PUT",
          queue: true,
          url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id,
          data: {
            card: {
              status_name: status_name,
              status_color: status_color,
              status_id: status_id
            }
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", {
                project: ltoken,
                method: "card-done",
                card: lcard,
                color: status_color,
                message: status_name,
                status_name: status_name,
                status_color: status_color,
                status_id: status_id
              });
            }
          }
        });
			update_project_list_view_columns("status")
			 $('.element-menu').popover('dispose')
        return false;
      });
    }
    return false;
  });
};
