import moment from "mobile/moment.min.js";

var init_page,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$(document).ready(function() {
  $(document).on("breeze:page:home", function() {
     init_page();
  });
  if ($('.workspace.home').length > 0) {
    init_page();
  }
  if ($('.workspace.home-empty').length > 0) {
     init_page();
  }
});

init_page = function() {
  $('#event-modal').on("click", function() {
    $('.tooltip').remove();
  });
   $(document).on('hide.bs.modal', '#event-modal', function(e) {
		
    var oel;
    $('#event-modal .member').remove();
    $('.popover').remove();
    $('.tooltip').remove();
    $('#event-modal input').val("");
    $('#event-modal .event-name').removeAttr("data-event-card-id");
    $("#event-modal .event-card-remove").addClass("hide");
    $('#event-modal .event-name').val("");
    $('#event-modal .event-name').attr("value", "");
    $('.typeahead').remove();
    oel = $('#event-modal .event-name').prop('outerHTML');
    $('#event-modal .event-name').remove();
    $(oel).insertAfter($('#event-modal .event-name-box .m-label'));
    $('#event-modal .sub-head-project .single-select').remove();
    $('#event-modal .js-ripple').removeClass('is-active');
    $("#event-modal .board-link").css('display', '');
    $("#event-modal event-modal-header .delete").css('display', '');
    $('.event-dates-box .end').html("End");
    Breeze.calendar_event = null;
  });
  $('#event-modal').on("keydown", "textarea", function(e) {
    if (e.keyCode === 13) {
      $('#event-modal .event-actions-box .c-button-save').trigger("click");
      return false;
    }
  });
  $('#event-modal').on("click", ".event-actions-box .cancel, .event-modal-header .closeb", function(e) {
    $('#event-modal').modal("hide");
    return e.preventDefault();
  });
  $('#event-modal').on("click", ".dateinput-display", function(e) {
    if ($(this).hasClass("datepicker-visible")) {
      $(this).parent().find(".dateinput").datepicker("hide");
      $(this).removeClass("datepicker-visible");
    } else {
      $(this).parent().find(".dateinput").datepicker("show");
      $(this).focus();
      $('#event-modal .dateinput-display').removeClass("datepicker-visible");
      $(this).addClass("datepicker-visible");
    }
  });
  $('#event-modal').on("keydown", ".dateinput-display", function(e) {
    $(this).removeClass("datepicker-visible");
    $(this).parent().find(".dateinput").datepicker("hide");
    $(this).parent().find(".dateinput").val("");
    $(this).val("");
  });
  $('#event-modal').on("click", '.header .delete-event', function() {
    var event, url;
    event = Breeze.calendar_event;
    if (Breeze.calendar_event.todo) {
      if (Breeze.developement()) {
        url = "https://app.breeze.test:3006/projects/" + event.project + "/cards/" + event.card_id + "/todo_lists/" + event.list_id + "/todos/" + event.id + "?auth_token=" + Breeze.current_user.token;
      } else {
        url = "https://breeze-node-n5.herokuapp.com/projects/" + event.project + "/cards/" + event.card_id + "/todo_lists/" + event.list_id + "/todos/" + event.id + "?auth_token=" + Breeze.current_user.token;
      }
      $.ajax({
        type: 'DELETE',
        queue: true,
        url: url,
        data: Breeze.socket ? {
          client_id: Breeze.socket.id
        } : void 0
      });
      $('.home-calendar-day-body-entry[data-todo-id="' + event.id + '"]').remove();
    } else if (Breeze.calendar_event.estimate) {
      if (Breeze.developement()) {
        url = "https://app.breeze.test:3006/projects/" + event.project + "/cards/" + event.card_id + "/estimates/" + event.id + "?auth_token=" + Breeze.current_user.token;
      } else {
        url = "https://breeze-node-n8.herokuapp.com/projects/" + event.project + "/cards/" + event.card_id + "/estimates/" + event.id + "?auth_token=" + Breeze.current_user.token;
      }
      $.ajax({
        type: 'DELETE',
        queue: true,
        url: url,
        data: Breeze.socket ? {
          client_id: Breeze.socket.id
        } : void 0
      });
      $('.home-calendar-day-body-entry[data-entry-type="estimate"][data-estimate-id="' + event.id + '"]').remove();
    } else {
      if (Breeze.developement()) {
        url = "https://app.breeze.test:3006/projects/" + Breeze.calendar_event.project + "/cards/" + Breeze.calendar_event.id + "/soft_destroy?auth_token=" + Breeze.current_user.token;
      } else {
        url = "https://breeze-node-n5.herokuapp.com/projects/" + Breeze.calendar_event.project + "/cards/" + Breeze.calendar_event.id + "/soft_destroy?auth_token=" + Breeze.current_user.token;
      }
      $.ajax({
        type: "PUT",
        queue: true,
        url: url,
        data: Breeze.socket ? {
          client_id: Breeze.socket.id
        } : void 0
      });
      $('.home-calendar-day-body-entry[data-entry-type="card"][data-card-id="' + event.id + '"]').remove();
    }
    $('#event-modal').modal("hide");
    Breeze.update_month_calendar();
    return false;
  });
  $('#event-modal').on("click", function() {
    $('.popover').remove();
  });
  $('#event-modal').on("click", ".member:not(.member-add)", function(e) {
    var content, el, user_email, user_id, user_name;
    el = $(this);
    user_id = $(this).attr('data-member-id');
    user_email = $(this).attr('data-user-id');
    user_name = $(this).find("img").attr("title");
    if ($('.edit-member-' + user_id).length > 0) {
      $('.popover').remove();
      el.removeData("popover");
    } else {
      $('.popover').remove();
      el.removeData("popover");
      content = '<div> <div class="name">' + user_name + '</div> <div class="email">' + user_email + '</div> <div class="action"><button class="c-button c-button--white c-button-small update" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Remove </button></div> </div>';
      $(this).popover({
        trigger: "manual",
        template: '<div class="popover edit-member-' + user_id + '" ><div class="popover-inner"><div class="popover-body" id="calendar-assign-calendar-user"><div></div></div></div></div>',
				sanitize: false,
				popperConfig: {
					positionFixed: true,
					placement: 'bottom'
				},
			  html: true,
        content: content
      });
      $(this).popover("show");
      $("#calendar-assign-calendar-user").on("click", "button", function() {
        $('#event-modal .member[data-user-id="' + user_email + '"]').remove();
        $('.popover').remove();
        el.removeData("popover");
      });
    }
    return false;
  });
  $('#event-modal').on("click", ".member-add", function(e) {
    var content, email, load_remote, member_id, selected, selected_user_ids, u, user_list, users_options,el;
    member_id = $(this).attr('data-member-id');
    load_remote = false;
    if ($('.edit-member-' + member_id).length > 0) {
      $('.bs.popover').remove();
      $(this).removeData("popover");
    } else {
      $('.bs.popover').remove();
      $(this).removeData("popover");
      user_list = [];
      if ($('#event-modal[data-modal-type="project"]').length > 0 || $('#event-modal[data-modal-type="card"]').length > 0) {
        user_list = Breeze.all_team_users;
        selected_user_ids = $('#event-modal .event-users-box .member').map(function() {
          return parseInt($(this).attr('data-member-id'));
        }).get();
      } else {
        load_remote = true;
        user_list = [];
      }
      users_options = [];
      if (user_list == null) {
        user_list = [];
      }
      users_options = (function() {
        var j, len, ref, results;
        results = [];
        for (j = 0, len = user_list.length; j < len; j++) {
          u = user_list[j];
          if (u.name === u.email) {
            email = "";
          } else {
            email = u.email;
          }
          selected = false;
          if (selected_user_ids) {
            selected = (ref = u.id, indexOf.call(selected_user_ids, ref) >= 0);
          }
          selected = selected ? 'selected="selected"' : ' ';
          if (u.name === u.email) {
            results.push('<option value="' + u.email + '" ' + selected + '>' + u.email + '</option>');
          } else {
            results.push('<option value="' + u.email + '" ' + selected + '>&lt;b&gt;' + u.name + '&lt;/b&gt; ' + email + '</option>');
          }
        }
        return results;
      })();
      content = '<div class="styled-multiselect"> <select class="user-item-select hide"  multiple="multiple" name="users[]">' + users_options.join('') + '</select> </div>';

			$(this).popover({
        trigger: "manual",
				container: $(this).parent(),
				template: '<div class="popover event-modal-popover"><div class="popover-inner calendar-element-popover card-actions edit-member-' + member_id + '"  id="card-assign-calendar-menu"><div class="popover-body"><div></div></div></div></div>',
				html: true,
				sanitize: false,
        content: content
      });
      $(this).popover("show");
			
      $('.user-item-select').multiselect({
        numberDisplayed: 0,
        maxHeight: 500,
        includeSelectAllOption: false,
        nonSelectedText: "Item",
        buttonClass: 'btn btn-link item-btn',
        nSelectedText: '',
        enableFiltering: true,
        enableCaseInsensitiveFiltering: true,
        filterPlaceholder: 'Search user',
        enableHTML: true,
        enableClickableOptGroups: true,
        onDropdownHide: function() {
           $(".popover").remove();
        },
        onInitialized: function(select, container) {
           $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
        // },
       //  onDropdownShown: function() {
          var project_id, select_el, selected_user, url, wrapper_el;
					
          if (load_remote) {
            select_el = $(this.$select);
            wrapper_el = $(this.$container);
            project_id = Breeze.calendar_event.project;
            selected_user = $('#event-modal .event-users-box .member').map(function() {
               $(this).attr('data-member-id');
            }).get();
            wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
            wrapper_el.find('.multiselect-filter').css('opacity', 0);
            wrapper_el.find('.options-wrapper').css('opacity', 0);
            if (Breeze.developement()) {
              url = "https://app.breeze.test:3006/users/select_field?auth_token=" + Breeze.current_user.token;
            } else {
              url = "https://breeze-node-n3.herokuapp.com/users/select_field?auth_token=" + Breeze.current_user.token;
            }
             $.ajax({
              type: "POST",
              url: url,
              data: {
                selected_users: selected_user,
                project_id: project_id
              },
              success: function(data) {
                select_el.append($(data).find("select").html());
                select_el.find('option[value="all"]').remove();
                wrapper_el.find('.multiselect-filter').css('opacity', 1);
                wrapper_el.find('.options-wrapper').css('opacity', 1);
                select_el.multiselect('rebuild');
                wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
                return select_el.parent().find("input[type='text'].multiselect-search").focus();
              }
            });
          }
        },
        templates: {
          filter: '<li class="multiselect-item multiselect-filter"><div class="input-group"><input class="form-control multiselect-search" type="text" placeholder="Search user"><span class="input-group-btn"></span></div></li>'
        }
      });
			
			// return false
      // $('#card-assign-calendar-menu .dropdown-toggle').trigger('click');
			
		  $('#card-assign-calendar-menu .btn-group').addClass('show');
		  $('#card-assign-calendar-menu .dropdown-menu').addClass('show');
			
      if (!$('body').hasClass("ie11")) {
        $('#card-assign-calendar-menu input[type="text"]').focus();
      }
      $('#card-assign-calendar-menu').on("change", ".multiselect-container .checkbox input", function() {
        var el, that, user, user_id;
        user_id = $(this).val();
        that = $(this);
        if ($(this).closest("li").hasClass("active")) {
          user = _.find(Breeze.all_team_users, function(e) {
            return (e.email === user_id) || (parseInt(e.id) === parseInt(user_id));
          });
          el = $('<div class="member" data-user-id="' + user.email + '" data-member-id="' + user.id + '" style="background-color:#' + user.color + '"> <img alt="" width="30" height="30" class="member_avatar" src="' + user.avatar_url + '" title="' + user.name + '"><p class="member_initials">' + user.initials + '</p> </div>');
          return el.insertBefore("#event-modal .event-users-box .member-add");
        } else {
          $('#event-modal .member[data-user-id="' + user_id + '"]').remove();
          return $('#event-modal .member[data-member-id="' + user_id + '"]').remove();
        }
      });
    }
    return false;
  });
  $('#event-modal').on("click", '.event-actions-box[data-modal-type="project"] button[type="submit"]', function(e) {
    var assigned, assigned_id, duedate, duedate_post, el, event, name, noend, nostart, startdate, startdate_post, url;
    el = $(this);
    event = Breeze.calendar_event;
    name = $('#event-modal textarea').val();
    assigned = [];
    assigned_id = [];
    $('#event-modal .member').each(function(i) {
      if ($(this).attr('data-user-id') != null) {
        assigned.push($(this).attr('data-user-id'));
      }
      if ($(this).attr('data-member-id') != null) {
        return assigned_id.push($(this).attr('data-member-id'));
      }
    });
    duedate = moment(Breeze.calendar_event.end_real);
    startdate = moment(Breeze.calendar_event.start_real);
    if ($('#event-modal .dateinput-display.start-date-display').val() === "") {
      nostart = true;
      startdate_post = "";
      startdate = moment(Breeze.calendar_event.end_real).toDate();
    } else {
      nostart = false;
      startdate_post = startdate.format("YYYY-MM-DD");
    }
    if ($('#event-modal .dateinput-display.due-date-display').val() === "") {
      noend = true;
      duedate_post = "";
      duedate = null;
    } else {
      noend = false;
      duedate_post = duedate.format("YYYY-MM-DD");
    }
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/projects/" + event.project + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/projects/" + event.project + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "PUT",
      url: url,
      queue: true,
      data: {
        project: {
          startdate: startdate_post,
          duedate: duedate_post,
          invitees: assigned,
          name: name
        },
        client_id: Breeze.socket ? Breeze.socket.id : void 0
      },
      beforeSend: function() {
        el.prop('disabled', true);
        return el.find('.progress-button').show();
      },
      success: function() {
        var current_date, target;
        el.prop('disabled', false);
        el.find('.progress-button').hide();
        el.find('.js-ripple').removeClass('is-active');
        target = $('.home-calendar-day-body-entry[data-entry-type="project"][data-project-id="' + event.id + '"]');
        target.attr('data-duedate', duedate_post);
        target.attr('data-startdate', startdate_post);
        target.attr('data-project-user-ids', JSON.stringify(assigned_id)).data("project-user-ids", assigned_id);
        target.find('.home-calendar-day-body-entry-name').text(name);
        Breeze.prevent_update_scroll = true;
        $('#event-modal').modal("hide");
        Breeze.prevent_update_scroll = false;
        current_date = moment($('.home-calendar-day-header').attr('data-home-day-date'));
        if (!(current_date.isBetween(startdate, duedate))) {
          target.remove();
          if ($('.home-calendar-day-body-entry').length <= 0) {
            $('.home-calendar-day-body-empty').show();
          }
        }
        return Breeze.update_month_calendar();
      }
    });
    return e.preventDefault();
  });
  $('#event-modal').on("keydown", '.event-estimate-input', function(e, item) {
    $(this).tooltip("hide");
    if (e.keyCode === 13 && !e.shiftKey) {
      return $(this).closest('.modal-body').find('.c-button-save').trigger("click");
    }
  });
  $('#event-modal').on("click", '.event-actions-box[data-modal-type="estimate"] button[type="submit"]', function(e) {
    var amount, assigned, current_date, duedate, duedate_post, el, event, name, noend, tracked, url, user_ids;
    el = $(this);
    event = Breeze.calendar_event;
    name = $('#event-modal textarea').val();
    duedate = moment(Breeze.calendar_event.end_real);
    tracked = $('#event-modal .event-estimate-input').val();
    current_date = moment($('.home-calendar-day-header').attr('data-home-day-date'));
    if (tracked.length > 0 && Breeze.Time.parse(tracked) === null) {
      $('#event-modal .event-estimate-input').tooltip({
        animation: false,
        placement: "bottomleft",
        trigger: "manual",
        title: "This isn't a correct format. Valid time formats are 45, 1:45, 1h, 1h45m, 1day"
      });
      $('#event-modal .event-estimate-input').tooltip("show");
      return false;
    }
    amount = Breeze.Time.parseMinutes(tracked, 1);
    assigned = [];
    user_ids = [];
    $('#event-modal .member').each(function(i) {
      if ($(this).attr('data-user-id') != null) {
        assigned.push($(this).attr('data-user-id'));
      }
      if ($(this).attr('data-member-id') != null) {
        return user_ids.push($(this).attr('data-member-id'));
      }
    });
    if ($('#event-modal .dateinput-display.due-date-display').val() === "") {
      noend = true;
      duedate = "";
      duedate_post = "";
    } else {
      noend = false;
      duedate_post = duedate.format("YYYY-MM-DD");
    }
    $('.project-cards .card[data-card-id="' + event.card_id + '"]').remove();
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006//projects/" + event.project + "/cards/" + event.card_id + "/estimates/" + event.id + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n8.herokuapp.com/projects/" + event.project + "/cards/" + event.card_id + "/estimates/" + event.id + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "PUT",
      url: url,
      queue: true,
      data: {
        estimate: {
          logged_date: duedate_post,
          user_id: user_ids[0],
          comment: name,
          amount: amount
        },
        client_id: Breeze.socket ? Breeze.socket.id : void 0
      },
      beforeSend: function() {
        el.prop('disabled', true);
        return el.find('.progress-button').show();
      },
      success: function() {
        var target;
        el.prop('disabled', false);
        el.find('.progress-button').hide();
        el.find('.js-ripple').removeClass('is-active');
        target = $('.home-calendar-day-body-entry[data-estimate-id="' + event.id + '"]');
        target.attr('data-estimate-amount', amount);
        target.attr('data-duedate', duedate_post);
        target.attr('data-estimate-user-ids', user_ids[0]).data("todo-user-ids", user_ids[0]);
        target.find('.home-calendar-day-body-entry-name').text(name);
        Breeze.prevent_update_scroll = true;
        $('#event-modal').modal("hide");
        Breeze.prevent_update_scroll = false;
        if (!(current_date.isSame(duedate))) {
          target.remove();
          if ($('.home-calendar-day-body-entry').length <= 0) {
            $('.home-calendar-day-body-empty').show();
          }
        }
        Breeze.prevent_update_scroll = true;
        return Breeze.update_month_calendar();
      }
    });
    return e.preventDefault();
  });
  $('#event-modal').on("click", '.event-actions-box[data-modal-type="todo"] button[type="submit"]', function(e) {
    var assigned, current_date, done, duedate, duedate_post, el, estimate, estimate_minutes, estimate_parsed, event, name, noend, url, user_ids;
    el = $(this);
    event = Breeze.calendar_event;
    done = $('#event-modal [data-behavior="toggle"]').is(':checked');
    name = $('#event-modal textarea').val();
    duedate = moment(Breeze.calendar_event.end_real);
    estimate = $('#event-modal .event-estimate-input').val();
    current_date = moment($('.home-calendar-day-header').attr('data-home-day-date'));
    if (estimate.length > 0 && Breeze.Time.parse(estimate) === null) {
      $('#event-modal .event-estimate-input').tooltip({
        animation: false,
        placement: "bottomleft",
        trigger: "manual",
        title: "This isn't a correct format. Valid time formats are 45, 1:45, 1h, 1h45m, 1day"
      });
      $('#event-modal .event-estimate-input').tooltip("show");
      return false;
    }
    estimate_minutes = Breeze.Time.parseMinutes(estimate, 1);
    estimate_parsed = Breeze.Time.formatPretty(estimate_minutes);
    assigned = [];
    user_ids = [""];
    $('#event-modal .member').each(function(i) {
      if ($(this).attr('data-user-id') != null) {
        assigned.push($(this).attr('data-user-id'));
      }
      if ($(this).attr('data-member-id') != null) {
        return user_ids.push($(this).attr('data-member-id'));
      }
    });
    if ($('#event-modal .dateinput-display.due-date-display').val() === "") {
      noend = true;
      duedate = "";
      duedate_post = "";
    } else {
      noend = false;
      duedate_post = duedate.format("YYYY-MM-DD");
    }
    $('.project-cards .card[data-card-id="' + event.card_id + '"]').remove();
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/projects/" + event.project + "/cards/" + event.card_id + "/todo_lists/" + event.list_id + "/todos/" + event.id + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/projects/" + event.project + "/cards/" + event.card_id + "/todo_lists/" + event.list_id + "/todos/" + event.id + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "PUT",
      url: url,
      queue: true,
      data: {
        todo: {
          duedate: duedate_post,
          user_id: user_ids,
          assigned: assigned,
          done: done,
          name: name,
          estimate: estimate_minutes
        },
        client_id: Breeze.socket ? Breeze.socket.id : void 0
      },
      beforeSend: function() {
        el.prop('disabled', true);
        return el.find('.progress-button').show();
      },
      success: function() {
        var target;
        el.prop('disabled', false);
        el.find('.progress-button').hide();
        el.find('.js-ripple').removeClass('is-active');
        target = $('.home-calendar-day-body-entry[data-todo-id="' + event.id + '"]');
        target.attr('data-todo-done', done);
        target.attr('data-todo-estimate', estimate_minutes);
        target.attr('data-duedate', duedate_post);
        target.attr('data-todo-user-ids', JSON.stringify(user_ids)).data("todo-user-ids", user_ids);
        target.find('.home-calendar-day-body-entry-name').text(name);
        Breeze.prevent_update_scroll = true;
        $('#event-modal').modal("hide");
        Breeze.prevent_update_scroll = false;
        if (!(current_date.isSame(duedate))) {
          target.remove();
          if ($('.home-calendar-day-body-entry').length <= 0) {
            $('.home-calendar-day-body-empty').show();
          }
        }
        return Breeze.update_month_calendar();
      }
    });
    return e.preventDefault();
  });
};

Breeze.update_month_calendar = function() {
  var active_day, current_date, url;
  current_date = $('.home-calendar-day-header').attr('data-home-day-date');
  active_day = $("#home-calendar-month .current-month.day-active .home-calendar-day").attr("data-date");
  if (Breeze.developement()) {
    url = "https://app.breeze.test:3006";
  } else {
    url = "https://breeze-node-n3.herokuapp.com";
  }
  $.ajax({
    type: 'GET',
    url: url + "/home",
    queue: true,
    data: {
      partial: true,
      auth_token: Breeze.current_user.token,
      start_date: current_date
    },
    success: function(data) {
      $('.home .simple-calendar').html($(data).find(".simple-calendar").html());
      if ($('#home-calendar-month .current-month .home-calendar-day[data-date="' + active_day + '"]').length > 0) {
        $(".simple-calendar .today").addClass("day-not-active");
        $('#home-calendar-month .current-month').removeClass("day-active");
        return $('#home-calendar-month .current-month .home-calendar-day[data-date="' + active_day + '"]').parent().addClass("day-active");
      }
    }
  });
  return false;
};

