function inc_wip_total(card) {
  var has_status, stage, v;
  if ($('.project-board').length > 0) {
    stage = $('.sortable_stage:first .stage_class[data-stage-id="' + card.attr('data-stage-id') + '"]');
    v = parseInt(stage.attr('data-wip-total'), 10);
    has_status = card.attr('data-status-id') && card.attr('data-status-id').length > 0;
    if (!has_status) {
      stage.attr('data-wip-total', (v < 0 ? 0 : v + 1));
    }
    return update_list_wip(stage);
  }
};

function dec_wip_total(card) {
  var has_status, stage, v;
  if ($('.project-board').length > 0) {
    stage = $('.sortable_stage:first .stage_class[data-stage-id="' + card.attr('data-stage-id') + '"]');
    v = parseInt(stage.attr('data-wip-total'), 10);
    has_status = card.attr('data-status-id') && card.attr('data-status-id').length > 0;
    if (!has_status) {
      stage.attr('data-wip-total', (v < 0 ? 0 : v - 1));
    }
    return update_list_wip(stage);
  }
};

function update_wip_total(card, has_old_status) {
  var has_status, stage, v;
  if (has_old_status == null) {
    has_old_status = false;
  }
  if ($('.project-board').length > 0) {
    stage = $('.sortable_stage:first .stage_class[data-stage-id="' + card.attr('data-stage-id') + '"]');
    v = parseInt(stage.attr('data-wip-total'), 10);
    has_status = card.attr('data-status-id') && card.attr('data-status-id').length > 0;
    if (has_status && !has_old_status) {
      stage.attr('data-wip-total', (v < 0 ? 0 : v - 1));
    } else if (!has_status) {
      stage.attr('data-wip-total', (v < 0 ? 0 : v + 1));
    }
    return update_list_wip(stage);
  }
};

function update_list_wip(el) {
  el = el || $('.sortable_stage:first .stage_class');
  return el.each(function() {
    var cards, stage, wel, wip;
    wip = parseInt($(this).attr('data-wip'));
    wel = $(this).find('.wip');
    if (wip > 0) {
      stage = $(this).attr('data-stage-id');
      cards = parseInt($(this).attr('data-wip-total'), 10);
      wel.html(cards + "/" + wip);
      if (cards > wip) {
        return wel.addClass("red");
      } else {
        return wel.removeClass("red");
      }
    } else {
      return wel.html("");
    }
  });
};

export {update_list_wip,update_wip_total,dec_wip_total,inc_wip_total};

