var init_stages_automation, update_swimlane_card;
import {update_task_stripe} from 'mobile/utils/task_stripe.js';
import {move_to_archive} from 'mobile/cards/utils/move_to_archive.js'

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page, stage_id) {
    if (page === "swimlanes_automation_popovers") {
      return init_stages_automation(stage_id);
    }
  });
  return $(document).on("breeze:page", function(e, page, card_id, stage_id, old_stage_id, card) {
    if (page === "update_swimlane_card") {
      return update_swimlane_card(card_id, stage_id, old_stage_id, card);
    }
  });
});

init_stages_automation = function(stage_id) {
  return $('#swimlane-automation-menu').on("click", ".archive-select", function(event) {
    var active, el, socket_params, stage_obj;
    el = $(this);
    active = $(this).hasClass("active");
    stage_obj = _.find(Breeze.project_swimlanes, function(s) {
      return s.id === parseInt(stage_id);
    });
    if (active) {
      $(this).removeClass("active");
    } else {
      $(this).addClass("active");
    }
    if (active) {
      stage_obj.actions.archive.name = false;
    } else {
      stage_obj.actions.archive.name = true;
    }
    socket_params = {
      project: Breeze.project_token,
      method: "swimlane-update-js",
      message: Breeze.project_swimlanes
    };
    $.ajax({
      type: "PUT",
      url: '/projects/' + Breeze.project + '/swimlanes/' + stage_id,
      queue: true,
      data: {
        swimlane: {
          archive: stage_obj.actions.archive.name
        }
      },
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    return false;
  });
};

update_swimlane_card = function(card_id, stage_id, old_stage_id, card) {
  var ref, ref1, stage_obj;
  if (old_stage_id !== stage_id) {
    stage_obj = _.find(Breeze.project_swimlanes, function(s) {
      return s.id === parseInt(stage_id);
    });
    if (stage_obj) {
      if ((ref = stage_obj.actions) != null ? (ref1 = ref.archive) != null ? ref1.name : void 0 : void 0) {
        move_to_archive(card_id, $('.card[data-card-id="' + card_id + '"]').attr('data-project-id'));
        $('.card[data-card-id="' + card_id + '"]').attr('data-archive', 'true');
        if (Breeze.Card.id === card_id) {
          update_task_stripe();
        }
      }
    }
  }
};

// ---
// generated by coffee-script 1.9.2