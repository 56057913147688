require("mobile/todos/todos.js") 
require("mobile/todos/todo-menu.js") 
require("mobile/todos/todo-list-menu.js") 
import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

var init_todo_lists;

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.public-card').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_todo_lists();
  }
  return $(document).on("breeze:page", function(e, page) {
    if (page === "todo_lists") {
      return init_todo_lists();
    }
  });
});

init_todo_lists = function() {
  var sort_opts_todo, sort_opts_todo_list;
  sort_opts_todo_list = {
    tolerance: 'pointer',
    items: '> [data-type="container-list"]:not([data-action="new"])',
    delay: /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent) ? 600 : 150,
    forcePlaceholderSize: true,
    start: function(event, ui) {
      if (!Breeze.todolist_sort) {
        // $(".modal .todos .list").sortable("refreshPositions");
        Breeze.todolist_sort = true;
      }
      if (ui.item.prev()[0] == null) {
        return $(".ui-sortable-placeholder").css({
          "width": ui.item.width(),
          "margin": "20px 0 0 -30px",
          "padding": "1px 0 1px 30px"
        });
      } else {
        return $(".ui-sortable-placeholder").css({
          "width": ui.item.width(),
          "margin": "20px 0 0 -30px",
          "padding": "1px 0 1px 30px"
        });
      }
    },
    stop: function(event, ui) {
      var list, socket_params;
      list = $(ui.item).attr('data-id');
      if (list == null) {
        list = $(ui.item).attr('data-tid');
      }
      if ((list == null) || list === "") {
        try {
          Bugsnag.notify('Todo list move failed', 'UI: ' + JSON.stringify(ui) + ' Item:' + ui.item.prop('outerHTML'), {}, 'error');
        } catch (_error) {
          true;
        }
      }
      socket_params = {
        card: Breeze.Card.id,
        project: Breeze.project_token,
        method: "card-todo-list-move",
        todo: list,
        prev: $(ui.item).prev().attr("data-id")
      };
      return $.ajax({
        type: 'PUT',
        url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists/' + list + '/move',
        queue: true,
        data: {
          prev_id: $(ui.item).prev().attr("data-id")
        },
        success: function(data) {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
    }
  };
  sort_opts_todo = {
    tolerance: 'pointer',
    scroll: true,
    items: '> [data-type="container"]:not([data-action="new"])',
    delay: /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent) ? 600 : 150,
    start: function(event, ui) {
      if (!Breeze.todo_sort) {
        // $(".modal .todos .todo-items").sortable("refreshPositions");
        Breeze.todo_sort = true;
      }
      return $(".ui-sortable-placeholder").css({
        "width": ui.item.width() + 8,
        "margin-left": "-31px",
        "padding": "2px 16px 1px 31px"
      });
    },
    stop: function(event, ui) {
      var card, list, project, socket_params, todo_id;
      ui.item.css({
        "padding-right": "0"
      });
      list = ui.item.closest('[data-type="container-list"]').attr('data-id');
      todo_id = $(ui.item).attr('data-id');
      if (todo_id == null) {
        todo_id = $(ui.item).attr('data-tid');
      }
      card = $(ui.item).attr('data-tcard-id');
      project = $(ui.item).attr('data-project-id');
      if ((card == null) || card === "" || (project == null) || project === "") {
        try {
          Bugsnag.notify('Todo move failed', 'UI: ' + JSON.stringify(ui) + ' Item:' + ui.item.prop('outerHTML'), {}, 'error');
        } catch (_error) {
          true;
        }
      }
      socket_params = {
        card: card,
        project: Breeze.project_token,
        method: "card-todo-move",
        todo: todo_id,
        prev: ui.item.prev().attr("data-id"),
        parent: ui.item.closest('[data-type="container-list"]').attr('data-id'),
        list: list
      };
      return $.ajax({
        queue: true,
        type: 'PUT',
        url: '/projects/' + project + '/cards/' + card + '/todo_lists/' + list + '/todos/' + todo_id + '/move',
        data: {
          prev_id: ui.item.prev().attr("data-id"),
          parent_id: ui.item.closest('[data-type="container-list"]').attr('data-id')
        },
        success: function(data) {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
    }
  };
  if (!Breeze.current_user.observer) {
    // document.addEventListener("mousedown", (function(event) {
    //   if ($(event.target).closest(".modal .todos .todo-items").length > 0) {
    //     // return $(".modal .todos .todo-items").sortable(sort_opts_todo).sortable({
    //     //   connectWith: ".modal .todos .todo-items"
    //     // });
    //   }
    // }), true);
    // document.addEventListener("mousedown", (function(event) {
    //   if ($(event.target).closest(".modal .todos .list").length) {
    //     // return $(".modal .todos .list").sortable(sort_opts_todo_list);
    //   }
    // }), true);
  }
  $('#card_modal .todo-lists').on("click", '[data-action="add"]', function() {
    var el;
    $('#card_modal .todos [data-action="todo-head"] .actions .cancel').trigger("click");
    el = $('<div data-type="container-list" class="row" data-id="" data-action="new" data-readonly="false"> <div class="head"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg> <span data-type="item-head" style="display: none;" data-readonly="false">Name your todo list</span> <span data-action="todo-head"> <span class="border"> <textarea name="todo" placeholder="To-do list name" rows="1" cols="40"></textarea></span> <span class="actions"> <button class="c-button c-button--blue c-button-med c-button-save" data-action="create" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Save and add to-dos <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Cancel </button> </span>				</span></div> <div class="todo-items"></div> <a href="#" data-action="add-item" style="display:none">Add a to-do</a> </div>');
    el.prependTo($('#card_modal .todos .list'));
    if ($("body").hasClass("ie")) {
      $('#card_modal .todos [data-action="new"] textarea').autosize().trigger('autosize.resize').placeholder();
    } else {
      $('#card_modal .todos [data-action="new"] textarea').focus().autosize().trigger('autosize.resize');
    }
    // $(".modal .todos .list").sortable({
    //   disabled: true
    // });
    return false;
  });
  $('#card_modal .todos').on("click", '[data-type="item-head"][data-readonly="false"]', function() {
    var box, el, icon;
    $('#card_modal .todos .actions .cancel').trigger("click");
    el = $('<span data-action="todo-head" > <span class="border"><textarea name="todo" placeholder="To-do list name" rows="1" cols="40"></textarea></span> <span class="actions"> <button class="c-button c-button--blue c-button-med c-button-save update" data-action="update" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Save <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Cancel </button> </span> </span>');
    $(this).hide();
    box = $(this).parent();
    icon = box.find("i");
    icon.addClass("hide");
    box.append(el);
    box.find('.border textarea').focus().val($(this).text()).autosize().trigger('autosize.resize');
    // return $(".modal .todos .list").sortable({
    //   disabled: true
    // });
  });
  $('#card_modal .todos').on("click", '[data-action="todo-head"] .actions .cancel', function() {
    var box, container;
    container = $(this).closest('[data-type="container-list"]');
    if (container.data("action")) {
      container.remove();
      $('.todos [data-action="add"]').show();
    } else {
      box = $(this).closest('[data-action="todo-head"]');
      container.find("i").removeClass("hide show-todo");
      container.find('input[type="checkbox"]').removeAttr("disabled");
      box.remove();
      container.find('[data-type="item-head"]').show();
    }
    // $(".modal .todos .list").sortable({
    //   disabled: false
    // });
    return false;
  });
  $('#card_modal .todos').on("click", '[data-action="todo-head"] [data-action="create"]', function() {
    var box, el, name, socket_params, tid, todo;
    todo = $(this).closest('[data-type="container-list"]');
    box = $(this).closest('[data-action="todo-head"]');
    name = todo.find("textarea").val();
    el = $(this);
    if (name === "") {
      return false;
    }
    tid = "todo_temp_" + new Date().getTime();
    todo.attr("data-id", tid);
    socket_params = {
      card: Breeze.Card.id,
      project: Breeze.project_token,
      method: "card-todo-list"
    };
    $.ajax({
      type: 'POST',
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists',
      queue: true,
      data: {
        tid: tid,
        todo_list: {
          name: name
        }
      },
      beforeSend: function(xhr) {
        el.prop('disabled', true);
        return el.find('.progress-button').show();
      },
      success: function(data) {
        el.prop('disabled', false);
        el.find('.progress-button').hide();
        box.remove();
        todo.attr("data-id", data);
        todo.attr("data-tid", tid);
        todo.attr("data-action", "");
        todo.find("i").attr("style", "");
        todo.find("i").removeClass("hide show-todo");
        todo.find('[data-type="item-head"]').html(_.escape(name)).show();
        socket_params.message = todo.prop('outerHTML');
        if (Breeze.is_socket_connected()) {
          Breeze.socket.emit("message", socket_params);
        }
        todo.find('[data-action="add-item"]').show();
        todo.find('input[type="checkbox"]').show();
        return todo.find('[data-action="add-item"]').trigger("click");
      }
    });
    // $(".modal .todos .list").sortable({
    //   disabled: false
    // });
    return false;
  });
  $('#card_modal .todos').on("click", '.head [data-action="update"]', function() {
    var name, socket_params, todo;
    todo = $(this).closest('.head');
    name = todo.find("textarea").val();
    if (name === "") {
      return false;
    }
    socket_params = {
      card: Breeze.Card.id,
      project: Breeze.project_token,
      method: "card-todo-list-update",
      todo: todo.parent().data("id"),
      message: name
    };
    $.ajax({
      type: 'PUT',
      queue: true,
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists/' + todo.parent().data("id"),
      data: {
        todo_list: {
          name: name
        }
      },
      success: function(data) {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    todo.find("i").removeClass("hide show-todo");
    todo.find('[data-type="item-head"]').html(_.escape(name)).show();
    $(this).closest('[data-action="todo-head"]').remove();
    // $(".modal .todos .list").sortable({
    //   disabled: false
    // });
    return false;
  });
  return $('#card_modal .todos').on("click", '.head .icon-remove', function() {
    var c, e, socket_params, t, todo, todo_bubble;
    todo = $(this).closest('[data-type="container-list"]');
    todo.fadeOut(function() {
      return $(this).remove();
    });
    socket_params = {
      card: Breeze.Card.id,
      project: Breeze.project_token,
      method: "card-todo-list-delete",
      todo: todo.data("id"),
      message: "delete"
    };
    $.ajax({
      type: 'DELETE',
      queue: true,
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists/' + todo.data("id"),
      success: function(data) {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    todo_bubble = $('.card[data-card-id="' + Breeze.Card.id + '"]').find('.todos-bubble');
    e = todo_bubble.find("span").first();
    c = parseInt(e.html());
    e.html(c - todo.find('[data-behavior="toggle"]:checkbox:checked').length);
    t = todo_bubble.find("span").last();
    c = parseInt(t.html());
    t.html(c - todo.find('[data-behavior="toggle"]:checkbox').length);
    $('.card[data-card-id="' + Breeze.Card.id + '"] .card-todos [data-type="container-list"][data-id="' + todo.data("id") + '"]').remove();
    return false;
  });
};

// ---
// generated by coffee-script 1.9.2