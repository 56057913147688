import {hex_md5} from "mobile/utils/md5.js"

$.gravatar = function(emailAddress, avatar, overrides)
{
    var options = $.extend({
        // Defaults are not hardcoded here in case gravatar changes them on their end.
        // integer size: between 1 and 512, default 80 (in pixels)
        size: '',
        // rating: g (default), pg, r, x
        rating: '',
        // url to define a default image (can also be one of: blank, monsterid, wavatar)
        image: '',
	opt: ''
    }, overrides);
		if(avatar) {
      return '<img src="'+avatar+'"' + options.opt+'/>';
		}
		else {
			 return '<img src="https://d1wdkw032nxkks.cloudfront.net/assets/avatar.png"' + options.opt+'/>';
		}		
};