import {update_project_list_view_columns} from 'mobile/utils/project_board.js'
import {update_task_stripe} from 'mobile/utils/task_stripe.js';
import {update_wip_total} from 'mobile/utils/wip.js'


var dateFormat = require('dateformat');
import moment from "mobile/moment.min.js";
import {addDays} from 'mobile/utils/adddays.js'

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});
function init_page() {
	
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-duedate", function() {
    var card_popover, closed_block, el_pop, new_pos, pos, request_block, udate;
    if ($("#card_modal").attr('data-readonly') === "true") {
      return false;
    }
		
		el_pop = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
		
    if (($(this).data('popover') == null) || ($("#card-duedate-menu").length <= 0 && typeof $(this).data('popover') !== 'undefined')) {
			$('.element-menu').popover('dispose')
			
      $(".popover").remove();
      $(".card-side .element-duedate, .calendar-box .pill , .calendar-box .time-pill").removeData("bs.popover");
      request_block = "";
      if (Breeze.current_user.request_date) {
        request_block = '<div class="date-block"> <div class="request"> <input type="text" class="dateinput-display request-date-display" spellcheck="false" placeholder="Request date"> <div class="dateinput-inline request-date hide"></div> </div> </div>';
      }
      closed_block = "";
      if (Breeze.current_user.closed_date) {
        closed_block = '<div class="date-block"> <div class="request"> <input type="text" class="dateinput-display closed-date-display" spellcheck="false" placeholder="Closed date"> <div class="dateinput-inline closed-date hide"></div> </div> </div>';
      }
      if (Breeze.current_user.request_date && (Breeze.current_user.team_id === 22983 || Breeze.current_user.team_id === 28180)) {
        request_block = '<div class="date-block"> <div class="request"> <input type="text" class="dateinput-display request-date-display" spellcheck="false" placeholder="Actual date"> <div class="dateinput-inline request-date hide"></div> </div> </div>';
      }
      card_popover = $('<div> <form accept-charset="UTF-8" action="/projects/53439/cards/940141" data-remote="true" id="card-duedate" method="post"><div style="margin:0;padding:0;display:inline"><input name="utf8" type="hidden" value="✓"><input name="_method" type="hidden" value="PUT"></div>' + request_block + '' + closed_block + '<div class="date-block"> <div class="startdate"> <input type="text" class="dateinput-display start-date-display" spellcheck="false" placeholder="Start date"> <input type="text" class="time-input start-date-time" spellcheck="false" placeholder="Any time"> <div class="dateinput-inline start-date hide"></div> </div> </div> <div class="date-block"> <div class="duedate"> <input type="text" class="dateinput-display end-date-display" spellcheck="false" placeholder="End date"> <input type="text" class="time-input end-date-time" spellcheck="false" placeholder="Any time"> <div class="dateinput-inline end-date"></div> </div> </div> <div class="submit"> <button class="c-button c-button--blue c-button-med c-button-save " data-action="update" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Save <div class="progress-button"> <div class="indeterminate"></div> </div> </button> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Remove </button> </div> </form> </div>');
      if (Breeze.current_user.request_date && (Breeze.current_user.team_id === 2335 || Breeze.current_user.team_id === 43606)) {
        card_popover.find('.duedate .end-date').addClass("hide");
        card_popover.find('.request .request-date').removeClass("hide");
      }
      if (el_pop.parent().hasClass("calendar-box")) {
        card_popover.find('.date-block').addClass("hide");
        card_popover.find('.dateinput-inline').addClass("hide");
        if ($(this).data('startdate') != null) {
          card_popover.find('.date-block .startdate').parent().removeClass("hide");
          card_popover.find('.date-block .startdate .dateinput-inline').removeClass("hide");
        } else if ($(this).data('duedate') != null) {
          card_popover.find('.date-block .duedate').parent().removeClass("hide");
          card_popover.find('.date-block .duedate .dateinput-inline').removeClass("hide");
        } else if ($(this).data('requestdate') != null) {
          card_popover.find('.date-block .request').parent().removeClass("hide");
          card_popover.find('.date-block .request .dateinput-inline').removeClass("hide");
        } else {
          card_popover.find('.date-block').removeClass("hide");
        }
      }
      el_pop.popover({
        trigger: "manual",
        placement: "bottom",
        template: '<div class="popover element-popover card-actions"><div id="card-duedate-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
        html: true,
				animation: false,
				container: el_pop,
				sanitize: false,
        content: card_popover.html()
      });
      el_pop.popover("show");
      Breeze.card_menu = true;
      // pos = $('#card-duedate-menu').offset().top + 390 - $(window).scrollTop();
      // if ($('html').height() < pos) {
      //   new_pos = $('html').height() - 390 + $(window).scrollTop();
      //   $('#card-duedate-menu').css('top', new_pos + "px");
      // }
      $('#card-duedate .dateinput-inline').datepicker({
        showOtherMonths: true,
        selectOtherMonths: true,
        dateFormat: "M d yy",
        altFormat: "M d",
        showAnim: "",
        constrainInput: true,
				// defaultDate: 0,
        prevText: "←",
        nextText: '→',
        firstDay: Breeze.first_day,
        onSelect: function(dateText, inst) {
          var date_string, day, month;
          month = inst.selectedMonth + 1 <= 9 ? "0" + (inst.selectedMonth + 1).toString() : (inst.selectedMonth + 1).toString();
          day = inst.selectedDay <= 9 ? "0" + inst.selectedDay.toString() : inst.selectedDay.toString();
          if ($(this).hasClass("start-date")) {
            date_string = inst.selectedYear.toString() + "-" + month + "-" + day;
            Breeze.Card.startdate = date_string + "T00:00:00" + Breeze.get_timezone_offset(date_string);
            Breeze.Card.startdate_utc = date_string + "T00:00:00.000Z";
            return $('#card-duedate .dateinput-display.start-date-display').val(dateFormat(Breeze.Card.startdate_utc, "UTC:mmm d"));
          } else if ($(this).hasClass("end-date")) {
            date_string = inst.selectedYear.toString() + "-" + month + "-" + day;
            Breeze.Card.duedate = date_string + "T00:00:00" + Breeze.get_timezone_offset(date_string);
            Breeze.Card.duedate_utc = date_string + "T00:00:00.000Z";
            return $('#card-duedate .dateinput-display.end-date-display').val(dateFormat(Breeze.Card.duedate_utc, "UTC:mmm d"));
          } else if ($(this).hasClass("request-date")) {
            date_string = inst.selectedYear.toString() + "-" + month + "-" + day;
            Breeze.Card.requestdate = date_string + "T00:00:00" + Breeze.get_timezone_offset(date_string);
            Breeze.Card.requestdate_utc = date_string + "T00:00:00.000Z";
            return $('#card-duedate .dateinput-display.request-date-display').val(dateFormat(Breeze.Card.requestdate_utc, "UTC:mmm d"));
          } else if ($(this).hasClass("closed-date")) {
            date_string = inst.selectedYear.toString() + "-" + month + "-" + day;
            Breeze.Card.closeddate = date_string + "T00:00:00" + Breeze.get_timezone_offset(date_string);
            Breeze.Card.closeddate_utc = date_string + "T00:00:00.000Z";
            return $('#card-duedate .dateinput-display.closed-date-display').val(dateFormat(Breeze.Card.closeddate_utc, "UTC:mmm d"));
          }
        }
      });
      if (Breeze.Card.startdate) {
        udate = Breeze.Card.startdate.match(/(\d+)/g);
        if (Breeze.Card.startdate_time) {
          $('#card-duedate .dateinput-inline.start-date').datepicker('setDate', new Date(Breeze.Card.startdate));
          $('#card-duedate .dateinput-display.start-date-display').val(dateFormat(Breeze.Card.startdate, "mmm d"));
        } else {
          $('#card-duedate .dateinput-inline.start-date').datepicker('setDate', dateFormat(Breeze.Card.startdate, "UTC:mmm d yyyy"));
          $('#card-duedate .dateinput-display.start-date-display').val(dateFormat(Breeze.Card.startdate, "UTC:mmm d"));
        }
        if (Breeze.Card.startdate_time) {
          try {
            $('#card-duedate .start-date-time').val(Breeze.CardTime.parse(Breeze.Card.startdate).toFriendlyString());
          } catch (_error) {
            $('#card-duedate .start-date-time').val("");
          }
        } else {
          $('#card-duedate .start-date-time').val("");
        }
      } else {
        $('#card-duedate .dateinput-inline.start-date').datepicker('setDate', null);
        $("#card-duedate .startdate .dateinput-inline td").removeClass("ui-datepicker-current-day");
        $('#card-duedate .dateinput-display.start-date-display').val("");
        $('#card-duedate .start-date-time').val("");
      }
      if (Breeze.Card.duedate) {
        udate = Breeze.Card.duedate.match(/(\d+)/g);
        if (Breeze.Card.duedate_time) {
          $('#card-duedate .dateinput-inline.end-date').datepicker('setDate', new Date(Breeze.Card.duedate));
          $('#card-duedate .dateinput-display.end-date-display').val(dateFormat(Breeze.Card.duedate, "mmm d"));
        } else {
          $('#card-duedate .dateinput-inline.end-date').datepicker('setDate', dateFormat(Breeze.Card.duedate, "UTC:mmm d yyyy"));
          $('#card-duedate .dateinput-display.end-date-display').val(dateFormat(Breeze.Card.duedate, "UTC:mmm d"));
        }
        if (Breeze.Card.duedate_time) {
          try {
            $('#card-duedate .end-date-time').val(Breeze.CardTime.parse(Breeze.Card.duedate).toFriendlyString());
          } catch (_error) {
            $('#card-duedate .end-date-time').val("");
          }
        } else {
          $('#card-duedate .end-date-time').val("");
        }
      } else {
        $('#card-duedate .dateinput-inline.end-date').datepicker('setDate', null);
        $("#card-duedate .duedate .dateinput-inline td").removeClass("ui-datepicker-current-day");
        $('#card-duedate .dateinput-display.end-date-display').val("");
        $('#card-duedate .end-date-time').val("");
      }
      if (Breeze.Card.requestdate) {
        udate = Breeze.Card.requestdate.match(/(\d+)/g);
        $('#card-duedate .dateinput-inline.request-date').datepicker('setDate', dateFormat(Breeze.Card.requestdate, "UTC:mmm d yyyy"));
        $('#card-duedate .dateinput-display.request-date-display').val(dateFormat(Breeze.Card.requestdate, "UTC:mmm d"));
      } else {
        $('#card-duedate .dateinput-inline.request-date').datepicker('setDate', null);
        $('#card-duedate .dateinput-display.request-date-display').val("");
        $('#card-duedate .request-date-time').val("");
        $("#card-duedate .request .dateinput-inline td").removeClass("ui-datepicker-current-day");
      }
      if (Breeze.Card.closeddate) {
        udate = Breeze.Card.closeddate.match(/(\d+)/g);
        $('#card-duedate .dateinput-inline.closed-date').datepicker('setDate', dateFormat(Breeze.Card.closeddate, "UTC:mmm d yyyy"));
        $('#card-duedate .dateinput-display.closed-date-display').val(dateFormat(Breeze.Card.closeddate, "UTC:mmm d"));
      } else {
        $('#card-duedate .dateinput-inline.closed-date').datepicker('setDate', null);
        $('#card-duedate .dateinput-display.closed-date-display').val("");
        $('#card-duedate .closed-date-time').val("");
        $("#card-duedate .request .dateinput-inline td").removeClass("ui-datepicker-current-day");
      }
      $('#card-duedate ').on("click", ".dateinput-display, .time-input", function(e) {
        var el;
        el = $(this).parent().find(".dateinput-inline");
        if (el.hasClass("hide")) {
          $("#card-duedate .dateinput-inline").addClass("hide");
          return el.removeClass("hide");
        }
      });
      $('#card-duedate').on("keydown", ".dateinput-display", function(e) {
        $(this).parent().find(".dateinput").val("");
        return $(this).val("");
      });
			
	    $("#card-duedate").click( function(event) {
	         event.stopPropagation();
	     });
			 
      $('#card-duedate').on("click", ".submit .cancel", function(e) {
        $('#card-duedate .dateinput-inline:not(.hide)').parent().find('.dateinput-display').val("");
        $('#card-duedate .c-button-save').trigger("click");
        return false;
      });
			
  
			
      $('#card-duedate').on("click", ".c-button-save", function(e) {
        var card, card_container, d_date, dueISO, duedate, edate, end_time, sdate, socket_params, startISO, start_time, startdate, today;
        if ($("#card-duedate .start-date-display").val() === "") {
          Breeze.Card.startdate = null;
        }
        if ($("#card-duedate .end-date-display").val() === "") {
          Breeze.Card.duedate = null;
        }
        if ($("#card-duedate .request-date-display").val() === "") {
          Breeze.Card.requestdate = Breeze.Card.requestdate_utc = null;
        }
        if ($("#card-duedate .closed-date-display").val() === "") {
          Breeze.Card.closeddate = Breeze.Card.closeddate_utc = null;
        }
        if (Breeze.Card.startdate != null) {
          if (Breeze.Card.startdate_time) {
            startdate = new Date(Breeze.Card.startdate);
          } else {
            sdate = Breeze.Card.startdate.match(/(\d+)/g);
            startdate = new Date(sdate[0], parseInt(sdate[1], 10) - 1, sdate[2]);
          }
        } else {
          startdate = null;
        }
        if (Breeze.Card.duedate != null) {
          if (Breeze.Card.duedate_time) {
            duedate = new Date(Breeze.Card.duedate);
          } else {
            edate = Breeze.Card.duedate.match(/(\d+)/g);
            duedate = new Date(edate[0], parseInt(edate[1], 10) - 1, edate[2]);
          }
        } else {
          duedate = null;
        }
        try {
          start_time = Breeze.CardTime.parse($('#card-duedate  .start-date-time').val());
          startdate.setHours(start_time.hour, start_time.minute, 0);
          Breeze.Card.startdate_time = true;
        } catch (_error) {
          e = _error;
          Breeze.Card.startdate_time = false;
          startdate = startdate != null ? new Date(Date.UTC(startdate.getFullYear(), startdate.getMonth(), startdate.getDate())) : null;
          if (startdate != null) {
            Breeze.Card.startdate_utc = startdate.toISOString();
          }
        }
        try {
          end_time = Breeze.CardTime.parse($('#card-duedate  .end-date-time').val());
          duedate.setHours(end_time.hour, end_time.minute, 0);
          Breeze.Card.duedate_time = true;
        } catch (_error) {
          e = _error;
          duedate = duedate != null ? new Date(Date.UTC(duedate.getFullYear(), duedate.getMonth(), duedate.getDate())) : null;
          if (duedate != null) {
            Breeze.Card.duedate_utc = duedate.toISOString();
          }
          Breeze.Card.duedate_time = false;
          if (start_time == null) {
            Breeze.Card.startdate_time = false;
          }
        }
        if (startdate != null) {
          if (start_time != null) {
            startISO = startdate.toISOString();
          } else {
            startISO = Breeze.Card.startdate_utc;
          }
        } else {
          startISO = "";
        }
        if (duedate != null) {
          if (end_time != null) {
            dueISO = duedate.toISOString();
          } else {
            dueISO = Breeze.Card.duedate_utc;
          }
        } else {
          dueISO = "";
        }
        socket_params = {
          project: Breeze.project_token,
          method: "card-date",
          card: Breeze.Card.id,
          startdate: startISO,
          duedate: dueISO,
          duedate_time: end_time != null,
          startdate_time: start_time != null,
          message: "date"
        };
				
        $.ajax({
          type: "PUT",
          queue: true,
          url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id,
          data: {
            card: {
              startdate: startISO,
              duedate: dueISO,
              startdate_time: start_time != null,
              duedate_time: end_time != null,
              request_date: Breeze.Card.requestdate,
              closed_date: Breeze.Card.closeddate
            },
            client_id: Breeze.socket ? Breeze.socket.id : void 0
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        update_calendar(startISO, dueISO, Breeze.Card.id, start_time != null, end_time != null, Breeze.Card.requestdate, Breeze.Card.closeddate);
        update_card_calendar(startISO, dueISO, Breeze.Card.id, start_time != null, end_time != null, Breeze.Card.requestdate, Breeze.Card.closeddate);
        
				
				card = $('.card[data-card-id="' + Breeze.Card.id + '"]');
        card.attr("data-startdate", startISO).attr("data-duedate", dueISO).attr("data-startdate-time", start_time != null).attr("data-duedate-time", end_time != null).attr("data-requestdate", Breeze.Card.requestdate_utc).attr("data-closeddate", Breeze.Card.closeddate_utc).show_overdue_tasks();
				Breeze.update_auto_status_duedate(Breeze.Card.project, Breeze.Card.id,duedate)
        
				Breeze.Card.startdate = card.attr("data-startdate") || null;
        Breeze.Card.duedate = card.attr("data-duedate") || null;
        Breeze.Card.requestdate = card.attr("data-requestdate") || null;
        Breeze.Card.closeddate = card.attr("data-closeddate") || null;
        if ($('.workspace').hasClass('dashboard')) {
          if (start_time != null) {
            (d_date = new Date(dueISO)).setHours(0, 0, 0, 0);
          } else {
            d_date = moment(dueISO.split("T")[0]).toDate();
          }
          (today = new Date()).setHours(0, 0, 0, 0);
          card_container = card.parent();

          if ($('.dashboard .list-view.duedate-sort').length > 0) {
            if (today.getTime() === d_date.getTime()) {
              $('[data-dueblock="1"]').removeClass("hide");
              card_container.prependTo($('.card-block-elements[data-dueblock="1"]'));
            } else if (d_date > today && d_date > addDays(today, 7)) {
              $('[data-dueblock="3"]').removeClass("hide");
              card_container.prependTo($('.card-block-elements[data-dueblock="3"]'));
            } else if (d_date > today && d_date <= addDays(today, 7)) {
              $('[data-dueblock="2"]').removeClass("hide");
              card_container.prependTo($('.card-block-elements[data-dueblock="2"]'));
            } else if (d_date < today) {
              $('[data-dueblock="0"]').removeClass("hide");
              card_container.prependTo($('.card-block-elements[data-dueblock="0"]'));
            } else {
              $('[data-dueblock="4"]').removeClass("hide");
              card_container.prependTo($('.card-block-elements[data-dueblock="4"]'));
            }
          } else {
            if (today.getTime() === d_date.getTime()) {
              $('[data-dueblock="1"]').removeClass("hide");
              card_container.insertAfter($('[data-dueblock="1"]'));
            } else if (d_date > today && d_date > addDays(today, 7)) {
              $('[data-dueblock="3"]').removeClass("hide");
              card_container.insertAfter($('[data-dueblock="3"]'));
            } else if (d_date > today && d_date <= addDays(today, 7)) {
              $('[data-dueblock="2"]').removeClass("hide");
              card_container.insertAfter($('[data-dueblock="2"]'));
            } else if (d_date < today) {
              $('[data-dueblock="0"]').removeClass("hide");
              card_container.insertAfter($('[data-dueblock="0"]'));
            } else {
              $('[data-dueblock="4"]').removeClass("hide");
              card_container.insertAfter($('[data-dueblock="4"]'));
            }
          }
        }
				$('.element-menu').popover('dispose')
				
        // $('#card-duedate-menu').remove();
        // el_pop.removeData("bs.popover");
        return false;
      });
    } else if (typeof $(this).data('popover') !== 'undefined') {
			$('.element-menu').popover('dispose')
      // el_pop.popover("hide");
      // $(this).removeData("bs.popover");
      $(".popover").remove();
    }
    return false;
  });
  return Breeze.Card = Breeze.Card || {};
};

function update_calendar(startdate, duedate, card, start_time, end_time, requestdate, closeddate) {
  var el, end_format, s, start_format;
  if (startdate == null) {
    startdate = Breeze.Card.startdate;
  }
  if (duedate == null) {
    duedate = Breeze.Card.duedate;
  }
  if (card == null) {
    card = Breeze.Card.id;
  }
  if (requestdate == null) {
    requestdate = Breeze.Card.requestdate;
  }
  if (closeddate == null) {
    closeddate = Breeze.Card.closeddate;
  }
  start_format = choose_date_format(startdate, start_time);
  end_format = choose_date_format(duedate, end_time);
  el = '';
  if (!startdate && duedate) {
    el = '<span class="calendar-box"> <span class="time-pill pill" data-duedate="" title="due date">' + dateFormat(duedate, end_format) + '</span> </span>';
  } else if (!duedate && startdate) {
    el = '<span class="calendar-box"> <span class="pill" data-startdate="" title="start date">' + dateFormat(startdate, start_format) + '</span> </span>';
  } else if (duedate && startdate) {
    el = '<span class="calendar-box"> <span class="pill clear-right" data-startdate="" title="start date">' + dateFormat(startdate, start_format) + '</span> <span class="time-pill" data-duedate="" title="due date">' + dateFormat(duedate, end_format) + '</span> </span>';
  }
  $("#card_modal").find(".calendar-box").remove();
  if (requestdate) {
    s = "Req: ";
    if (Breeze.current_user.team_id === 22983) {
      s = "";
    }
    $("#card_modal .card-owner").append('<span class="calendar-box" title="request date"> <span class="pill" title="request date" data-requestdate>' + s + '' + dateFormat(requestdate, "mmm d") + '</span> </span>');
  }
  $("#card_modal .card-owner").append(el);
  if (closeddate) {
    s = "Closed: ";
    $("#card_modal .card-owner").append('<span class="calendar-box" title="closed date"> <span class="pill" title="closed date" data-closeddate>' + s + '' + dateFormat(closeddate, "mmm d") + '</span> </span>');
  }
  Breeze.overdue_task_color(duedate, end_time);
};

function update_card_calendar(startdate, duedate, card, start_time, end_time, requestdate, closeddate) {
	var el, elc, elr, end_format, s, start_format;

	start_format = choose_date_format(startdate, start_time);

	end_format = choose_date_format(duedate, end_time);

	el = '';

	if (!startdate && duedate) {
	  el = '<span class="calendar-bubble"> <i class="icon-calendar" title="due dates"></i> <span data="duedate" class="show">' + dateFormat(duedate, end_format) + '</span> </span>';
	} else if (!duedate && startdate) {
	  el = '<span class="calendar-bubble"> <i class="icon-calendar" title="due dates"></i> <span data="startdate" class="show">' + dateFormat(startdate, start_format) + '</span> </span>';
	} else if (duedate && startdate) {
	  el = '<span class="calendar-bubble"> <i class="icon-calendar" title="due dates"></i> <span data="startdate" class="show">' + dateFormat(startdate, start_format) + '</span> <span class="separator show">-</span> <span data="duedate" class="show">' + dateFormat(duedate, end_format) + '</span> </span>';
	}

	if (requestdate) {
	  s = "Req: ";
	  if (Breeze.current_user.team_id === 22983) {
	    s = "";
	  }
	  elr = '<span class="calendar-bubble" title="request date"> <i class="icon-calendar" title="request date"></i> <span class="show">' + s + '' + dateFormat(requestdate, "mmm d") + '</span> </span>';
	}

	if (closeddate) {
	  s = "Closed: ";
	  elc = '<span class="calendar-bubble" title="closed date"> <i class="icon-calendar" title="closed date"></i> <span class="show">' + s + '' + dateFormat(closeddate, "mmm d") + '</span> </span>';
	}

	$('.card[data-card-id="' + card + '"]').find(".calendar-bubble").remove();

	if (requestdate) {
	  $('.card[data-card-id="' + card + '"] .badges .card-calendar-req-bubble-container').append(elr);
	}

	$('.card[data-card-id="' + card + '"] .badges .card-calendar-bubble-container').append(el);

	if (closeddate) {
	  $('.card[data-card-id="' + card + '"] .badges .card-calendar-close-bubble-container').append(elc);
	}

	
	update_project_list_view_columns("dates")
};

Breeze.get_timezone_offset = function(date) {
  var offset;
  offset = parseInt(new Date(date).getTimezoneOffset() / 60);
  if (offset < 10 && offset > -1) {
    offset = "-0" + offset;
  } else if (offset >= 0) {
    offset = "-" + offset;
  } else if (offset > -10 && offset < 0) {
    offset = "+0" + (-offset);
  } else if (offset <= -10) {
    offset = "+" + (-offset);
  }
  return offset + ":00";
};


Breeze.update_auto_status_duedate = function(project, card, duedate) {
  var c, has_old_status, lcard, ltoken, status, status_color, status_id, status_name;
  if (card == null) {
    card = Breeze.Card.id;
  }
  if (duedate && (Breeze.autoduedate_status_id != null) && Breeze.autoduedate_status_id !== 0) {
    if (new Date(duedate) < new Date()) {
      status = _.where(Breeze.project_statuses, {
        id: parseInt(Breeze.autoduedate_status_id)
      })[0];
      if (status != null) {
        status_name = status.name;
        status_color = status.color;
        status_id = Breeze.autoduedate_status_id;
        c = $('.card[data-card-id="' + card + '"]');
        has_old_status = c.attr('data-status-id') && c.attr('data-status-id').length > 0;
        $('.card[data-card-id="' + card + '"]').attr("data-status-id", status_id);
        $('.card[data-card-id="' + card + '"] .text-overlay').text(status_name).css('background-color', status_color);
        $('[data-link-card-id=' + card + ']').each(function() {
          return $(this).find('.card-link-status ').attr('data-status-id', status_id).css('background-color', status_color).text(status_name);
        });
        update_wip_total($('.card[data-card-id="' + card + '"]'), has_old_status);
        update_task_stripe(card);
        update_project_list_view_columns("status");
        if (status_name === "Done" || status_name === "done") {
          $('.card[data-card-id="' + card + '"] .calendar-bubble.overdue-task').addClass("done");
        } else {
          $('.card[data-card-id="' + card + '"] .calendar-bubble.overdue-task').removeClass("done");
        }
        lcard = card;
        ltoken = Breeze.project_token;
        // return $.ajax({
        //   type: "PUT",
        //   queue: true,
        //   url: '/projects/' + project + '/cards/' + card,
        //   data: {
        //     card: {
        //       status_name: status_name,
        //       status_color: status_color,
        //       status_id: status_id
        //     }
        //   },
        //   success: function() {
        //     if (Breeze.is_socket_connected()) {
        //       return Breeze.socket.emit("message", {
        //         project: ltoken,
        //         method: "card-done",
        //         card: lcard,
        //         color: status_color,
        //         message: status_name,
        //         status_name: status_name,
        //         status_color: status_color,
        //         status_id: status_id
        //       });
        //     }
        //   }
        // });
      }
    }
  }
};



function choose_date_format(date, time) {
  var format;
  if (time) {
    if (Breeze.clock24) {
      return format = "mmm d, HH:MM";
    } else {
      if (new Date(date).getMinutes() === 0) {
        return format = "mmm d, htt";
      } else {
        return format = "mmm d, h:MMtt";
      }
    }
  } else {
    return format = "UTC:mmm d";
  }
};

export {update_calendar,update_card_calendar}
