import {make_sortable} from 'mobile/utils/sortable.js'

var load_swimlanes;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "load-swimlanes") {
      load_swimlanes();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    load_swimlanes();
  }
});

load_swimlanes = function() {
  var el, stages_count;
  stages_count = $('.sortable_stage:first .stage_class').length;
  el = '<div class="stage-skeleton"><div class="card card-skeleton"></div></div>';
  $('.card-sort .swimlane:not(.collapsed-swimlane)[data-swimlane-loaded="false"] .sortable_stage').each(function() {
    $(this).html(Array(stages_count + 1).join(el));
  });
  $('.sortable_stage').equalWidths();
  $('.card-sort .swimlane:not(.collapsed-swimlane)[data-swimlane-loaded="false"]').each(function() {
    var elt, swimlane_id;
    swimlane_id = $(this).attr('data-swimlane-id');
    elt = $(this);
    return $.ajax({
      type: 'GET',
      url: '/projects/' + Breeze.project + '/swimlanes/' + $(this).attr('data-swimlane-id') + '/cards',
      queue: true,
      data: {
        partial: true
      },
      success: function(data) {
        elt.find('.sortable_stage').html(data);
        elt.removeAttr("data-swimlane-loaded");
        // Breeze.filter_cards();
        Breeze.stickyHeaderTop = $('.card-sort').length > 0 ? $('.card-sort').offset().top - 30 : 100;
        Breeze.view_width = $('.card-sort').length > 0 ? $('.card-sort').width() : $(window).width();
        Breeze.view_height = $(window).height();
        if ($('.project-board.project-list-view').length > 0) {
          elt.find('.card').each(function() {
            $(this).find(".drop-shadow .card_avatars").insertBefore($(this).find(".drop-shadow .badges"));
            $(this).find(".text-overlay").appendTo($(this).find(".drop-shadow .badges"));
          });
        }
        make_sortable();
        elt.find('.stage_class').equalHeights();
        elt.find('.sortable_stage').css('min-height', elt.find('.stage_class:visible').height() + 20);
        elt.find('.stage_class .sortable').equalHeights();
        // elt.find('.sortable_stage').equalWidths();
        $.stages_resize();
        $.init_timer();
        elt.find('.card').show_overdue_tasks();
			  $('.sortable_stage').equalWidths();
				
        $('.card_cover img').on("error", function() {
          $(this).parent().remove();
        });
      }
    });
  });
};

