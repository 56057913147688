
import {update_task_stripe} from 'mobile/utils/task_stripe.js';
import {dec_wip_total,inc_wip_total,update_list_wip} from 'mobile/utils/wip.js'
import {hide_first_swimlane} from 'mobile/utils/swimlane.js'
import {update_calendar} from 'mobile/cards/card-menu/duedate.js'

var init_page;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "card_move_short") {
      return init_page();
    }
  });
  if ($('#card_modal').length > 0) {
    return init_page();
  }
});

init_page = function() {
  $('#card_modal[data-readonly="false"]').on("click", '.card-swimlane-list a', function() {
		
    $(".popover .popover-inner:not(#card-move-short)").parent().remove();

    if ($('#card-move-short').length > 0) {
      $("#card_modal .card-swimlane-list a").popover("hide");
			$(this).removeData("bs.popover")
			
      $('#card-move-short').parent().remove();
    } else {
			$(this).removeData("bs.popover")
		  $(this).popover({
	      trigger: "manual",
	      placement: "bottom",
	      template: '<div class="popover"><div id="card-move-short" class="popover-inner" data-card-id="' + Breeze.Card.id + '" data-project-id="' + Breeze.Card.project + '"><div class="popover-body"><p></p></div></div></div>',     	
			  html: true,
				sanitize: false,
	      content: $('.move-task-pop').html()
	    });
			
      $(this).popover("show");
      $('#card-move-short').on("click", '.create', function() {
        var btn_el, card, card_el, link_text, new_list, new_project, old_list, old_swimlane_id, position, project, project_name, project_name_label, ref, ref1, stage, stage_name, swimlane, swimlane_name, target;
        btn_el = $(this);
        if ($('#card-move-short #card_project_id').val() === "" || $('#card-move-short #card_stage_id').val() === "") {
          $('#card-move-short #card_stage_id').effect("shake", {
            distance: 10
          });
          return false;
        }
        project = $('#card-move-short').attr('data-project-id');
        card = $('#card-move-short').attr('data-card-id');
        stage = $('#card-move-short #card_stage_id').val();
        swimlane = $('#card-move-short #card_swimlane_id').val();
        position = $('#card-move-short #card_position_id').val();
        new_project = $('#card-move-short #card_project_id').val();
        stage_name = $('#card-move-short #card_stage_id option:selected').text();
        swimlane_name = $("#card-move-short #card_swimlane_id option:selected").text();
        project_name = $("#card-move-short #card_project_id option:selected").text();
        card_el = $('.card[data-card-id="' + card + '"]');
        
				dec_wip_total(card_el);
        
				card_el.attr('data-stage-id', stage).attr('data-swimlane-id', swimlane).attr('data-stage-name', stage_name);
        old_list = $('.card[data-card-id="' + card + '"]').closest('.stage_class').attr('data-stage-id');
        new_list = $('.sortable_stage:first .stage_class[data-stage-id="' + stage + '"]');
        old_swimlane_id = $('.card[data-card-id="' + card + '"]').closest('.swimlane').attr('data-swimlane-id');
        
				update_task_stripe();
        
				$.ajax({
          type: 'PUT',
          queue: true,
          url: '/projects/' + project + '/cards/' + card,
          beforeSend: function() {
            return btn_el.find('.progress-button').show();
          },
          success: function(data) {
            return btn_el.find('.progress-button').hide();
          },
          data: {
            project: $('#card-move-short #card_project_id').val(),
            stage: stage,
            swimlane: swimlane,
            position: position,
            same_project: parseInt(project) === parseInt(new_project),
            card: {
              stage_id: stage,
              swimlane_id: swimlane
            },
            client_id: Breeze.socket ? Breeze.socket.id : void 0
          }
        });
        $("#card_modal .card-swimlane-list a").popover("hide");
        if ($('.workspace').hasClass('dashboard') || $('.workspace').hasClass('task-board')) {
          Breeze.Card.project = new_project;
          project_name = _.find(Breeze.projects_stages, function(num) {
            return num.name === Breeze.Card.project;
          });
          project_name_label = null;
          if (project_name != null) {
            project_name_label = project_name.label;
          }
          if (swimlane_name === "select swimlane") {
            swimlane_name = null;
          }
          link_text = "<a href='#'>" + _.compact([project_name_label, swimlane_name, stage_name]).join(' > ') + "</a>";
        } else if ($('.workspace').hasClass('calendar-fluid')) {
          Breeze.Card.project = new_project;
          project_name = _.find(Breeze.projects_stages, function(num) {
            return num.name === Breeze.Card.project;
          });
          project_name_label = null;
          if (project_name != null) {
            project_name_label = project_name.label;
          }
          if (swimlane_name === "select swimlane") {
            swimlane_name = null;
          }
          link_text = "<a href='#'>" + _.compact([project_name_label, swimlane_name, stage_name]).join(' > ') + "</a>";
          Breeze.calendar_event.project = String(project);
          Breeze.calendar_event.project_name = String(project_name);
        } else {
          if (swimlane) {
            link_text = "In swimlane <a href='#'>" + swimlane_name + "</a>, in list <a href='#'>" + stage_name + "</a>.";
          } else if (stage) {
            link_text = "In list <a href='#'>" + stage_name + "</a>.";
          }
        }
        $('#card_modal .card-swimlane-list').html(link_text);
        if ($('.workspace').hasClass('dashboard') || $('.workspace').hasClass('task-board') || $('.workspace').hasClass('calendar-fluid')) {
          if (swimlane) {
            $('.card[data-card-id="' + card + '"]').attr('data-stage-id', stage).attr('data-stage-name', stage_name).attr('data-swimlane-id', swimlane).attr('data-swimlane-name', swimlane_name).attr('data-project-id', new_project).attr('data-project', project_name_label);
          } else if (stage) {
            $('.card[data-card-id="' + card + '"]').attr('data-stage-id', stage).attr('data-stage-name', stage_name).attr('data-project-id', new_project).attr('data-project', project_name_label);
          }
          if ($('.workspace').hasClass('dashboard')) {
            if ($('.dashboard .list-view:not(.duedate-sort)').length > 0) {
              target = $('.dashboard .list-view .stage-list[data-stage-id="' + stage + '"][data-project-id="' + new_project + '"]');
              if (target.length > 0) {
                card_el.parent().appendTo(target);
              } else {
                card_el.parent().remove();
              }
            } else {
              card_el.parent().find('.card-footer').html(stage_name + " - " + project_name_label).attr('href', '/projects/' + new_project);
            }
          } else if ($('.workspace').hasClass('task-board')) {
            if (position === "-1") {
              $('.sortable_stage:first .stage_class[data-stage-id="' + stage_name + '"] .sortable').append(card_el);
            } else {
              $('.sortable_stage:first .stage_class[data-stage-id="' + stage_name + '"] .sortable').prepend(card_el);
            }
          }
          Breeze.project_stages = (ref = _.where(Breeze.projects_stages, {
            name: String(new_project)
          })[0]) != null ? ref.stages : void 0;
          Breeze.project_users = Breeze.all_team_users;
          Breeze.project_swimlanes = (ref1 = _.where(Breeze.projects_stages, {
            name: String(new_project)
          })[0]) != null ? ref1.swimlanes : void 0;
          $(document).trigger("breeze:page", ["update_stage_card", card, stage, old_list, card_el]);
          $(document).trigger("breeze:page", ["update_swimlane_card", card, swimlane, old_swimlane_id, card_el]);
        } else {
          if (parseInt(project) !== parseInt(new_project)) {
            $('.card[data-card-id="' + card + '"]').remove();
            $('#card_modal').modal('hide');
          } else {
            $(document).trigger("breeze:page", ["update_stage_card", card, stage, old_list, card_el]);
            $(document).trigger("breeze:page", ["update_swimlane_card", card, swimlane, old_swimlane_id, card_el]);
            if (swimlane) {
              if (position === "-1") {
                $('.sortable_stage[data-swimlane-id="' + swimlane + '"] .stage_class[data-stage-id="' + stage + '"] .sortable .task-new-stage').before($('.card[data-card-id="' + card + '"]'));
              } else {
                $('.sortable_stage[data-swimlane-id="' + swimlane + '"] .stage_class[data-stage-id="' + stage + '"] .sortable').prepend($('.card[data-card-id="' + card + '"]'));
              }
            } else if (stage) {
              if (position === "-1") {
                $('.sortable_stage:first .stage_class[data-stage-id="' + stage + '"] .sortable .task-new-stage').before($('.card[data-card-id="' + card + '"]'));
              } else {
                $('.sortable_stage:first .stage_class[data-stage-id="' + stage + '"] .sortable').prepend($('.card[data-card-id="' + card + '"]'));
              }
            }
          }
          update_list_wip();
          hide_first_swimlane();
        }
        return false;
      });
      $('#card-move-short').on("change", '#card_project_id', function() {
        var project;
        project = $('#card-move-short #card_project_id option:selected').val();
        if (project > 0) {
          return $.ajax({
            type: 'GET',
            queue: true,
            url: '/projects/' + project + '/stagelane',
            beforeSend: function() {
              $('#card-move-short .m-loader').remove();
              $('#card-move-short .actions').append(Breeze.global_loader);
              $('#card-move-short .create').attr('disabled', 'disabled');
            },
            success: function(data) {
              $('#card-move-short .create').removeAttr('disabled');
              $('#card-move-short .m-loader').remove();
              $('#card-move-short #card_swimlane_id').replaceWith($(data).find('#card_swimlane_id'));
              $('#card-move-short #card_stage_id').replaceWith($(data).find('#card_stage_id'));
              $('#card-move-short #card_stage_id').val($('#card-move-short #card_stage_id option:nth-child(2)').val());
              if ($('#card-move-short #card_swimlane_id option').length > 1) {
                 $('#card-move-short #card_swimlane_id').parent().show();
              } else {
                 $('#card-move-short #card_swimlane_id').parent().hide();
              }
            }
          });
        }
      });
      $('#card-move-short').on("click", '.cancel,svg[data-icon="times"]', function() {
        $("#card_modal .card-swimlane-list a").popover("hide");
        return false;
      });
			
      $.ajax({
        type: 'GET',
        queue: true,
        url: '/projects/' + Breeze.Card.project + '/stagelane',
        beforeSend: function() {
          $('#card-move-short .m-loader').remove();
          $('#card-move-short .actions').append(Breeze.global_loader);
        },
        success: function(data) {
          $('#card-move-short .m-loader').remove();
          $('#card-move-short #card_project_id').replaceWith($(data).find('#card_project_id'));
          $('#card-move-short #card_swimlane_id').replaceWith($(data).find('#card_swimlane_id'));
          $('#card-move-short #card_stage_id').replaceWith($(data).find('#card_stage_id'));
          if ($('#card-move-short #card_swimlane_id option').length > 1) {
            $('#card-move-short #card_swimlane_id').parent().show();
          } else {
            $('#card-move-short #card_swimlane_id').parent().hide();
          }
          $('#card-move-short #card_stage_id').val($('.card[data-card-id="' + Breeze.Card.id + '"]').attr("data-stage-id"));
          if ($('.project-board').length > 0) {
            if ($('.card[data-card-id="' + Breeze.Card.id + '"]').closest('.swimlane').attr("data-swimlane-id")) {
              $('#card-move-short #card_swimlane_id').val($('.card[data-card-id="' + Breeze.Card.id + '"]').closest('.swimlane').attr("data-swimlane-id"));
            }
          } else {
            if ($('.card[data-card-id="' + Breeze.Card.id + '"]').attr("data-swimlane-id") && $('.card[data-card-id="' + Breeze.Card.id + '"]').attr("data-swimlane-id") !== "0") {
              $('#card-move-short #card_swimlane_id').val($('.card[data-card-id="' + Breeze.Card.id + '"]').attr("data-swimlane-id"));
            }
          }
          $('#card-move-short #card_project_id').val(Breeze.Card.project);
        }
      });
      if ($('#card-move-short #card_swimlane_id option').length > 1) {
        $('#card-move-short #card_swimlane_id').parent().show();
      } else {
        $('#card-move-short #card_swimlane_id').parent().hide();
      }
    }
    return false;
  });
};
