$(document).ready(function() {
	
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});

function init_page() {
	Breeze.init_fileupload()
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-files", function() {
    var card_popover;
		
		el = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
    $(".popover:not(#card-file-menu)").remove();
		
    if ($('#card-file-menu').length > 0) {
      el.popover("hide");
    } else {
      $('.element-menu').popover('dispose')
			
		card_popover = `<div>
			<ul class="menu">
	      <li><a href="#" class="mark temp_attach"><span class="checkmark hide"></span>Upload</a></li>

				
	    </ul>
			
			</div>	
						`
			
			el.popover({
	      trigger: "manual",
	      placement: "bottom",
				animation: false,
				container: el,
	      template: '<div class="popover element-popover card-actions"><div id="card-file-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
	      html: true,
				sanitize: false,
	      content: card_popover
	    });
			
      el.popover("show");
      Breeze.card_menu = true;
      // $(document).trigger("breeze:page", "evernote");
      $('#card-file-menu').on("click", ".mark", function(event) {
	      // $('.element-menu').popover('dispose')
      });
    }
    return false;
  });
};
