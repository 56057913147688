import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

var init_popover,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

var el;

$(document).ready(function() {
	
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
	
});
	
function init_page() {
	$('.element-menu').on('show.bs.dropdown', function () {
		$('.popover').remove()
	});
	$('.element-menu').on('hide.bs.dropdown', function () {
		$('.popover').remove()
	});
	
  // key("a", "board", function(event, handler) {
  //   var c, card, project, selected_users;
  //   c = $('.card:hover');
  //   if (c.length > 0 && !$('#card_modal').hasClass('show')) {
  //     $('.popover').remove();
  //     c.removeData("bs.popover");
  //     card = c.attr('data-card-id');
  //     project = c.attr('data-project-id');
  //     selected_users = [];
  //     $('.card:hover .card_avatars .member').each(function() {
  //       return selected_users.push($(this).attr('data-card-user-email'));
  //     });
  //     if ($('.workspace').hasClass('dashboard') || $('.workspace').hasClass('task-board')) {
  //       c.popover({
  //         trigger: "manual",
  //         placement: "bottom",
  //         template: '<div class="popover element-popover card-actions" id="card-assign-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
  //         html: true,
  //         content: '<div class="popover-loading">' + Breeze.global_loader + '</div>'
  //       });
  //       c.popover("show");
  //       Breeze.load_card_globals(card, project, function() {
  //         $('.popover').remove();
  //         c.removeData("bs.popover");
  //         return init_popover(c, selected_users, card, project);
  //       });
  //     } else {
  //       init_popover(c, selected_users, card, project);
  //     }
  //     return false;
  //   }
  // });
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-assign", function() {
    var selected_users;
		
		el = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
		
    $(".popover:not(#card-assign-menu)").remove();
    if ($('#card-assign-menu').length > 0) {
      el.popover("hide");
    } else {
       $('.element-menu').popover('dispose')
			
      selected_users = [];
      $('#card_modal .assigned-people .pill').each(function() {
        return selected_users.push($(this).attr('data-user-email'));
      });
      init_popover(el, selected_users, Breeze.Card.id, Breeze.Card.project);
    }
    return false;
  });
};

init_popover = function(that, selected_users, card, project) {
  var card_popover, email, selected, u, users_options;
  users_options = [];
  users_options = (function() {
    var i, len, ref, ref1, results;
    ref = Breeze.project_users;
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      u = ref[i];
      if (u.name === u.email) {
        email = "";
      } else {
        email = u.email;
      }
      selected = (ref1 = u.email, indexOf.call(selected_users, ref1) >= 0) ? 'selected="selected"' : ' ';
      results.push('<option value="' + u.email + '" ' + selected + '>&lt;b&gt;' + u.name + '&lt;/b&gt; ' + email + '</option>');
    }
    return results;
  })();
  card_popover = '<div class="fields-users-wrapper styled-multiselect"> <select class="user-item-select hide"  multiple="multiple" name="items[]">' + users_options.join('') + '</select> </div>';
  that.popover({
    trigger: "manual",
    placement: "bottom",
    template: '<div class="popover element-popover card-actions"><div id="card-assign-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
    html: true,
		animation: false,
		container: el,
		sanitize: false,
    content: card_popover
  });
  that.popover("show");
  Breeze.card_menu = true;
  $('.user-item-select').multiselect({
    numberDisplayed: 0,
    includeSelectAllOption: true,
    nonSelectedText: "Item",
    buttonClass: 'btn btn-link item-btn',
    nSelectedText: '',
    filterPlaceholder: 'Search user',
    enableHTML: true,
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownHide: function() {
      return $(".popover").remove();
    },
		
    templates: {
      filter: '<li class="multiselect-item multiselect-filter"><div class="input-group"><span class="input-group-addons"><i class="icon-user"></i></span><input class="form-control multiselect-search" type="text" placeholder="Search user"><span class="input-group-btn"></span></div></li>'
    }
  });
  $('#card-assign-menu .btn-group').addClass('show');
  $('#card-assign-menu .dropdown-menu').addClass('show');
	
  $('#card-assign-menu input[type="checkbox"]:checked').parent().addClass('checkbox-on');
  if (!$('body').hasClass("ie11")) {
    $('#card-assign-menu input[type="text"]').focus();
  }
  $('#card-assign-menu').on("change", ".multiselect-container .checkbox input", function() {
    var avatar, avatar_img, color, emails, i, initials, lcard, len, ltoken, name, res, results, socket_params;
    if ($(this).val() === "multiselect-all") {
      if ($(this).closest("li").hasClass("active")) {
        $('#card-assign-menu label').addClass("checkbox-on");
      } else {
        $('#card-assign-menu label').removeClass("checkbox-on");
      }
      emails = $('#card-assign-menu select').val();
      $("#card_modal .assigned-people").remove();
      $('.card[data-card-id="' + card + '"] .card_avatars .member').remove();
      if (emails && emails.length > 0) {
        socket_params = {
          project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
          method: "card-assigned-people",
          card: card,
          emails: emails
        };
        $.ajax({
          type: "POST",
          data: {
            project_id: project,
            invitees: emails,
            loc: "card side assign",
            client_id: Breeze.socket ? Breeze.socket.id : void 0
          },
          queue: true,
          url: '/cards/' + card + '/members',
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        if ($('#card_modal').hasClass('show') && $(".card-content .assigned-people").length <= 0) {
          $('<div class="row"><div class="span8 card-attributes assigned-people" data-readonly="false"><h5>People assigned</h5></div></div>').insertAfter(".card-header-b");
        }
        results = [];
        for (i = 0, len = emails.length; i < len; i++) {
          email = emails[i];
          res = $.grep(Breeze.project_users, function(e) {
            return e.email === email;
          });
          avatar = null;
          if (res.length > 0) {
            avatar = res[0].avatar;
          }
          avatar_img = $.gravatar(email, avatar, {
            size: 80,
            image: "blank",
            opt: "class=\"member_avatar\" title=\"" + name + "\""
          });
          color = res[0] ? res[0].color : "";
          initials = res[0] ? res[0].initials : "";
          name = email;
          if (res[0]) {
            if (res[0].firstname && res[0].firstname.length > 0) {
              name = res[0].firstname;
            }
          }
          $(".card-content .assigned-people").append('<span class="pill" data-user-email="' + email + '">' + name + '<span class="remove"> &times;</span></span>');
          results.push($('.card[data-card-id="' + card + '"] .card_avatars').append('<div class="member" data-card-user-email="' + email + '" data-card-user-name="' + name + '" style="background-color:#' + color + ';">' + avatar_img + '<p class="member_initials">' + initials + '</p> </div>'));
        }
        return results;
      } else {
        emails = $('#card-assign-menu select option').map(function() {
          return $(this).val();
        }).get();
        socket_params = {
          project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
          method: "card-assigned-people",
          card: card,
          emails: []
        };
        $.ajax({
          type: "DELETE",
          url: '/cards/' + card + '/member',
          queue: true,
          data: {
            project_id: project,
            email: emails
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
      }
    } else {
      $(this).parent().toggleClass("checkbox-on");
      if ($(this).parent().hasClass("checkbox-on")) {
        if ($('#card_modal').hasClass('show') && $(".card-content .assigned-people").length === 0) {
          $('<div class="row"><div class="span8 card-attributes assigned-people" data-readonly="false"><h5>People assigned</h5></div></div>').insertAfter(".card-header-b");
        }
        that = $(this);
        email = $(this).val();
        name = $(this).parent().find('b').text();
        if ($('.card-content .assigned-people .pill[data-user-email="' + email + '"]').length > 0) {
          return true;
        }
        $(".card-content .assigned-people").append('<span class="pill" data-user-email="' + email + '">' + name + '<span class="remove"> &times;</span></span>');
        res = $.grep(Breeze.project_users, function(e) {
          return e.email === email;
        });
        avatar = null;
        if (res.length > 0) {
          avatar = res[0].avatar;
        }
        avatar_img = $.gravatar(email, avatar, {
          size: 80,
          image: "blank",
          opt: "class=\"member_avatar\" title=\"" + name + "\""
        });
        color = res[0] ? res[0].color : "";
        initials = res[0] ? res[0].initials : "";
        $('.card[data-card-id="' + card + '"] .card_avatars').append('<div class="member" data-card-user-email="' + email + '" data-card-user-name="' + name + '" style="background-color:#' + color + ';">' + avatar_img + '<p class="member_initials">' + initials + '</p> </div>');
        socket_params = {
          project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
          method: "card-assign-new",
          card: card,
          email: email,
          name: name
        };
        $.ajax({
          type: "POST",
          data: {
            project_id: project,
            invitees: [email],
            loc: "card side assign",
            client_id: Breeze.socket ? Breeze.socket.id : void 0
          },
          queue: true,
          url: '/cards/' + card + '/members',
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
      } else {
        email = $(this).val();
        $('.card-content .assigned-people [data-user-email="' + email + '"]').remove();
        if ($('#card_modal').hasClass('show') && $('#card_modal .assigned-people .pill').length === 0) {
          $("#card_modal .assigned-people").remove();
        }
        $('.card[data-card-id="' + card + '"] .card_avatars .member[data-card-user-email="' + email + '"]').remove();
        lcard = card;
        ltoken = Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token');
        $.ajax({
          type: "DELETE",
          url: '/cards/' + card + '/member',
          queue: true,
          data: {
            project_id: project,
            email: email
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", {
                project: ltoken,
                method: "person-delete",
                card: lcard,
                message: email
              });
            }
          }
        });
      }
			update_project_list_view_columns("avatars")
    }
			update_project_list_view_columns("avatars")
		
  });
};
