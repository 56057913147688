var init_stages_automation, update_swimlane_card;
import moment from "mobile/moment.min.js";
import {update_card_calendar} from 'mobile/cards/card-menu/duedate.js'

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page, stage_id) {
    if (page === "swimlanes_automation_popovers") {
      return init_stages_automation(stage_id);
    }
  });
  return $(document).on("breeze:page", function(e, page, card_id, stage_id, old_stage_id, card) {
    if (page === "update_swimlane_card") {
      return update_swimlane_card(card_id, stage_id, old_stage_id, card);
    }
  });
});

init_stages_automation = function(stage_id) {
  return $('#swimlane-automation-menu').on("click", ".date-select", function(event) {
    var card_popover, duedates, e, el, ele, h, i, len, new_pos, pos, selected, stage_obj, swimlane;
    el = $('#swimlane-automation-menu');
    if ($('#stage-sublist-menu').length > 0) {
      el.popover("hide");
      $('#stage-sublist-menu').remove();
    } else {
      el.removeData("bs.popover");
      $('#stage-sublist-menu').remove();
      stage_obj = _.find(Breeze.project_swimlanes, function(s) {
        return s.id === parseInt(stage_id);
      });
      ele = '';
      duedates = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "End of month"];
      for (i = 0, len = duedates.length; i < len; i++) {
        e = duedates[i];
        selected = '';
        if (stage_obj.actions.duedate.name && (String(e) === String(stage_obj.actions.duedate.name))) {
          selected = 'active';
        }
        ele += '<li><a href="#" class="mark ' + selected + '">' + e + '</a></li>';
      }
      selected = "";
      if (stage_obj.actions.duedate.duedate_clear) {
        selected = 'active';
      }
      card_popover = $('<div> <div class="popover-header"><i class="fa fa-angle-left" aria-hidden="true"></i><span class="title">Due date</span>' + Breeze.global_close + '</div> <ul class="stage-actions-sublist dropdown-menu">' + ele + '</ul> <ul class="dropdown-menu dropdown-menu-footer"> <li class="clear-element"><a href="#" data-status-id="0" class="mark ' + selected + '">Clear due date from task</a></li> </ul> </div>');
      el.popover({
        trigger: "manual",
        container: "element",
        placement: "bottomleft",
        template: '<div class="popover swimlane-automation-menu-sub" id="stage-sublist-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
        html: true,
        content: card_popover.html()
      });
      $('#swimlane-automation-menu').hide();
      el.popover("show");
      swimlane = $(this).closest('.swimlane');
      pos = swimlane.position().top + $('.swimlane-automation-menu-sub').height() - 10;
      h = $('.card-sort').height();
      new_pos = -(pos - h) + 'px !important';
      if (pos > h) {
        $('.swimlane-automation-menu-sub').attr("style", "left: 0px; display: block; top:" + new_pos);
      }
      $('#stage-sublist-menu').on("click", ".mark", function() {
        var active, socket_params;
        el = $(this);
        active = $(this).hasClass("active");
        $('#stage-sublist-menu .mark').removeClass("active");
        if (active) {
          $(this).removeClass("active");
        } else {
          $(this).addClass("active");
        }
        if ($('#stage-sublist-menu .mark.active').length <= 0) {
          stage_obj.actions.duedate.name = "";
          stage_obj.actions.duedate.duedate_clear = false;
          $('#swimlane-automation-menu .date-select .selected-count').text("");
        } else if (el.attr("data-status-id") === "0") {
          stage_obj.actions.duedate.name = "";
          stage_obj.actions.duedate.duedate_clear = true;
          $('#swimlane-automation-menu .date-select .selected-count').text("clear");
        } else {
          stage_obj.actions.duedate.name = el.text();
          stage_obj.actions.duedate.duedate_clear = false;
          $('#swimlane-automation-menu .date-select .selected-count').text(stage_obj.actions.duedate.name);
        }
        socket_params = {
          project: Breeze.project_token,
          method: "swimlane-update-js",
          message: Breeze.project_swimlanes
        };
        $.ajax({
          type: "PUT",
          url: '/projects/' + Breeze.project + '/swimlanes/' + stage_id,
          queue: true,
          data: {
            swimlane: {
              duedate: stage_obj.actions.duedate.name,
              duedate_clear: stage_obj.actions.duedate.duedate_clear
            }
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        return false;
      });
      $('#stage-sublist-menu').on("click", ".fa", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $('#stage-sublist-menu').remove();
        $('#swimlane-automation-menu').show();
        return false;
      });
      $('#stage-sublist-menu').on("click", "svg", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $(".stage-settings.dropdown").removeClass("open");
        $('#stage-sublist-menu').remove();
        $('#swimlane-automation-menu').remove();
        $('.popover').remove();
        return false;
      });
      $('#stage-sublist-menu').on("click", function(e) {
        return e.stopPropagation();
      });
    }
    return false;
  });
};

update_swimlane_card = function(card_id, stage_id, old_stage_id, card) {
  var day_need, new_date, new_day, ref, ref1, ref2, stage_obj, start_date, start_time, today;
  if (old_stage_id !== stage_id) {
    stage_obj = _.find(Breeze.project_swimlanes, function(s) {
      return s.id === parseInt(stage_id);
    });
    if (stage_obj && (stage_obj.actions != null)) {
      if ((ref = stage_obj.actions) != null ? (ref1 = ref.duedate) != null ? ref1.duedate_clear : void 0 : void 0) {
        $('.card[data-card-id="' + card_id + '"]').attr('data-duedate', '');
        start_time = $('.card[data-card-id="' + card_id + '"]').attr('data-startdate-time') === "true";
        start_date = $('.card[data-card-id="' + card_id + '"]').attr('data-startdate');
        return update_card_calendar(start_date, null, card_id, start_time, false);
      } else if (((ref2 = stage_obj.actions.duedate.name) != null ? ref2.length : void 0) > 0) {
        start_time = $('.card[data-card-id="' + card_id + '"]').attr('data-startdate-time') === "true";
        start_date = $('.card[data-card-id="' + card_id + '"]').attr('data-startdate');
        if (stage_obj.actions.duedate.name === "End of month") {
          new_date = moment().endOf('month');
        } 
				else if (stage_obj.actions.duedate.name.indexOf("In") !== -1) {
				          new_date = moment().add(stage_obj.actions.duedate.duedate_xdays, 'days');
				        }
				else {
          day_need = moment().isoWeekday(stage_obj.actions.duedate.name).day();
          today = moment().isoWeekday();
          if (today <= day_need) {
            new_date = moment().isoWeekday(day_need);
          } else {
            new_date = moment().add(1, 'weeks').isoWeekday(day_need);
          }
        }
        new_day = new_date.format("Y-MM-DD");
        update_card_calendar(start_date, new_day, card_id, start_time, false);
        $('.card[data-card-id="' + card_id + '"]').attr('data-duedate', new_day);
        if (Breeze.Card.id === card_id) {
          return update_calendar(start_date, new_day, Breeze.Card.id, start_time, false, Breeze.Card.requestdate, Breeze.Card.closeddate);
        }
      }
    }
  }
};

// ---
// generated by coffee-script 1.9.2