function update_task_stripe(id) {
  var marked, bgcolor;
  if (id == null) {
    id = Breeze.Card.id;
  }
	$('.done-card-stripe').remove();
	
  marked = $('.card[data-card-id="' + id + '"] .text-overlay').first().text();
	bgcolor = $('.card[data-card-id="'+id+'"] .text-overlay').attr("style")
	
	$('.done-card-stripe').remove()
	
	
  if (marked.length > 0) {
    if (Breeze.current_user.observer) {
      $('<div class="done-card-stripe"  style="'+bgcolor+'"><b>' + marked + '</b></div>').insertBefore('#card_modal .card-header-b');
    } else {
      $('<div class="done-card-stripe"  style="'+bgcolor+'"><b>' + marked + '</b></div>').insertBefore('#card_modal .card-header-b');
    }
  }
  $('.archived-card-stripe').remove();
	
  if (Breeze.Card.archived) {
		
    if ($('#card_modal .element-menu .dropdown-item-archive').length > 0) {
      $('#card_modal .element-menu .dropdown-item-archive').attr("data-action", "unarchive").contents().last()[0].textContent = 'Unarchive';
    }
    if (Breeze.current_user.observer) {
      $('<div class="archived-card-stripe"><b>Archived</b></div>').insertBefore('#card_modal .card-header-b');
    } else {
      $('<div class="archived-card-stripe"><b>Archived</b></div>').insertBefore('#card_modal .card-header-b');
    }
  } else {
    if ($('#card_modal .element-menu .dropdown-item-archive').length > 0) {
      $('#card_modal .element-menu .dropdown-item-archive').attr("data-action", "archive").contents().last()[0].textContent = 'Archive';
    }
  }
};

export {update_task_stripe};
