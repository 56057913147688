import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after,uniqueId,generate_s3_key} 
				from 'mobile/froala/setup.js';
				
								
import {ResumableUploadToGoogleDrive} 
				from 'mobile/resumableupload_js.js';
								
var attach_google_upload, checkAuth, checkExistingAuth, createPicker, create_attach, folder_not_found_popover, 
				handleAuthResult, handleClientLoad, init_page, pickerCallback, remove_upload_overlay, resumableUpload, 
				set_upload_progress, set_upload_progress_froala, upload_files_google, create_attach_template_remote;

Breeze.google = {}
Breeze.google.developerKey = "AIzaSyC2pMMIRX8MCsUs84dBtrp4OSK70voomOY"
Breeze.google.clientId = "406151822530"
Breeze.google.appId = "406151822530"

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      return init_page();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    return init_page();
  }
});

init_page = function() {
  return $('#project-calendar-modal').on("click", '#project-storage-google-settings a', function(e) {
    Breeze.file_storage_picker = true;
    attach_google_upload();
    return false;
  });
};

Breeze.upload_gdrive = function(e, data) {
  Breeze.google_uploaded_files = null;
  Breeze.google_uploaded_files = data.files[0];
  Breeze.file_storage_picker = false;
  return attach_google_upload();
};

Breeze.upload_gdrive_froala = function(e, data) {
  Breeze.google_uploaded_files = null;
  Breeze.google_uploaded_files = data[0];
  Breeze.file_storage_picker = false;
  return attach_google_upload(true);
};

attach_google_upload = function(data) {
  var err;
	
  if (typeof gapi === "undefined") {
    $.getScript("https://apis.google.com/js/client.js");
  }
	
  if (data) {
    Breeze.froala_upload = true;
  } else {
    Breeze.froala_upload = false;
  }
  Breeze.temp_new_comment = $(".comment-editor").data("new-comment");
  if (!Breeze.temp_new_comment) {
    Breeze.temp_comment = $("#editor-element").closest('.comment-box').attr('data-comment-id');
  }
  $(".picker").remove();

  if (Breeze.google.oauthToken == null) {
    try {
      Breeze.google.oauthToken = JSON.parse(localStorage.getItem("bgoauthtoken"));
    } catch (_error) {
      err = _error;
    }
  }
  if (Breeze.google.oauthToken == null) {
    return gapi.load("auth", {
      callback: handleClientLoad
    });
  } else {
    return gapi.load("auth", {
      callback: checkExistingAuth
    });
  }
};

checkExistingAuth = function() {
  gapi.auth.setToken(Breeze.google.oauthToken);
  if (Breeze.file_storage_picker) {
    return gapi.client.load("oauth2", "v2", function() {
      return gapi.client.oauth2.userinfo.get().execute(function(t) {
        var err;
        if (t.email != null) {
          if (Breeze.file_storage_picker) {
            return gapi.load("picker", {
              callback: createPicker
            });
          } else {
            return upload_files_google();
          }
        } else {
          gapi.auth.setToken(null);
          Breeze.google.oauthToken = null;
          try {
            localStorage.removeItem("bgoauthtoken");
          } catch (_error) {
            err = _error;
          }
          return attach_google_upload(true);
        }
      });
    });
  } else {
    return upload_files_google();
  }
};

checkAuth = function() {
  gapi.auth.authorize({
    client_id: Breeze.google.clientId,
    scope: ["https://www.googleapis.com/auth/drive.file", "https://www.googleapis.com/auth/userinfo.email"],
    immediate: true,
    approval_prompt: "force",
    offline: true,
    access_type: "offline",
    authuser: !Breeze.google.oauthToken ? -1 : void 0,
    prompt: !Breeze.google.oauthToken ? 'select_account' : void 0
  }, handleAuthResult);
};

handleClientLoad = function() {
  return window.setTimeout(checkAuth(), 1);
};

handleAuthResult = function(authResult) {
  var card_popover, el, err, popover_center, token;
  if (authResult && !authResult.error) {
    Breeze.google.oauthToken = gapi.auth.getToken();
    try {
      token = gapi.auth.getToken();
      token["g-oauth-window"] = null;
      localStorage.setItem("bgoauthtoken", JSON.stringify(gapi.auth.getToken()));
    } catch (_error) {
      err = _error;
    }
    if (Breeze.file_storage_picker) {
      if (typeof google === "undefined" || google === null) {
        gapi.load("picker", {
          callback: createPicker
        });
      } else {
        createPicker();
      }
    } else {
      upload_files_google();
    }
  } else {
    if (!(authResult && authResult.error && authResult.error === "popup_closed_by_user")) {
      $('#google-connect').remove();
      popover_center = '';
      card_popover = $('<div> <div> <p> <b>Connect your Google account</b> Before you can upload to Google Drive you need to connect your account. </p> <p class="submit"><button data-action="create" class="c-button c-button--blue c-button-med c-button-save ">Connect Google account</button>  <button data-action="cancel" class="c-button c-button--white c-button-med cancel">Cancel</button></p> </div> </div>');
      el = $("html");
      popover_center = "popover-center-modal";
      $(".popover").remove();
      $('.card-side .element-files').removeData('bs.popover');
      $("html").removeData('bs.popover');
      el.popover({
        trigger: "manual",
        template: '<div class="popover ' + popover_center + '" id="google-connect"><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
        html: true,
        content: card_popover.html()
      });
      el.popover("show");
      $('#google-connect').on("click", '[data-action="cancel"]', function() {
        $('#google-connect').remove();
        return false;
      });
      $('#google-connect').on("click", '[data-action="create"]', function() {
        $('#google-connect').remove();
        gapi.auth.authorize({
          client_id: Breeze.google.clientId,
          scope: ["https://www.googleapis.com/auth/drive.file", "https://www.googleapis.com/auth/userinfo.email"],
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
          immediate: false,
          authuser: !Breeze.google.oauthToken ? -1 : void 0,
          prompt: !Breeze.google.oauthToken ? 'select_account' : void 0
        }, handleAuthResult);
        return false;
      });
    }
  }
};

createPicker = function() {
  var el, picker;
  if (Breeze.google.oauthToken != null) {
    picker = new google.picker.PickerBuilder().setAppId(Breeze.google.appId).addView(new google.picker.DocsView().setIncludeFolders(true).setEnableTeamDrives(true).setMimeTypes('application/vnd.google-apps.folder').setSelectFolderEnabled(true)).addView(new google.picker.DocsView().setIncludeFolders(true).setMimeTypes('application/vnd.google-apps.folder').setSelectFolderEnabled(true)).addView(new google.picker.DocsUploadView()).setOAuthToken(Breeze.google.oauthToken.access_token).setDeveloperKey(Breeze.google.developerKey).enableFeature(google.picker.Feature.MULTISELECT_ENABLED).enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES).setCallback(pickerCallback).build();
    picker.setVisible(true);
  }
  el = '<div class="picker_account_switcher"> <a data-behavior="google_account_switcher" href="#">Sign out and use a different Google account</a> </div>';
  $('.picker.picker-dialog').append($(el));
  return $('.picker_account_switcher').on("click", '[data-behavior~=google_account_switcher]', function() {
    var err;
    gapi.auth.setToken(null);
    Breeze.google.oauthToken = null;
    $(".picker").remove();
    try {
      localStorage.removeItem("bgoauthtoken");
    } catch (_error) {
      err = _error;
    }
    return false;
  });
};

pickerCallback = function(data) {
  var doc, url;
  if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
    doc = data["docs"][0];
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006/projects/" + Breeze.project + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n7.herokuapp.com/projects/" + Breeze.project + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: 'PUT',
      url: url,
      data: {
        partial: true,
        project: {
          file_storage_google_path: doc[google.picker.Document.NAME],
          file_storage_google_path_id: doc[google.picker.Document.ID]
        }
      }
    });
    Breeze.project_file_storage_google_path_id = doc[google.picker.Document.ID];
    Breeze.project_file_storage_google_path = doc[google.picker.Document.NAME];
    return $('#project-storage-google-settings a').text(doc[google.picker.Document.NAME]);
  }
};

resumableUpload = function(e) {
  var accessToken, data, f, project_templ, resource, ru, upload_el;
  accessToken = Breeze.google.oauthToken.access_token;
  data = {};
  f = e.target;
  resource = {
    fileName: f.fileName,
    fileSize: f.fileSize,
    fileType: f.fileType,
    fileBuffer: f.result,
    accessToken: accessToken,
    folderId: Breeze.project_file_storage_google_path_id
  };
  if (!Breeze.froala_upload) {
    upload_el = $('<div class="upload-progress-box"> <div class="upload-file"><span class="upload-name">File name</span><span class="upload-cancel">&times;</span></div> <div class="upload-progres-container"><div class="upload-progres"></div></div> </div>');
    data.upload_pid = "u" + uniqueId();
    upload_el.attr('data-pid', data.upload_pid);
    upload_el.find('.upload-name').html(f.fileName);
    if (Breeze.foundDropzone != null) {
      if (Breeze.foundDropzone.hasClass("sortable_stage")) {
        return false;
      }
      if (Breeze.foundDropzone.hasClass("card")) {
        data.project = Breeze.foundDropzone.attr("data-project-id");
        data.card = Breeze.foundDropzone.attr("data-card-id");
      } else {
        data.project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
        data.card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
      }
      data.comment = Breeze.foundDropzone.attr("data-comment-id");
      data.modal_task = Breeze.foundDropzone.hasClass("modal-body");
      data.new_comment = Breeze.foundDropzone.hasClass("new-comment-box") || Breeze.foundDropzone.hasClass("comment-editor");
      data.project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in') || $('.roadmap-project-modal').hasClass('in') || $('#project-notes-modal').hasClass('show');
    } else {
      data.manual_upload = true;
      data.modal_task = true;
      data.project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
      data.card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
      data.comment = Breeze.comment_box_trigger;
      data.new_comment = $(".comment-editor").data("new-comment");
      data.project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in') || $('.roadmap-project-modal').hasClass('in') || $('#project-notes-modal').hasClass('show');
    }
    if (Breeze.foundDropzone != null) {
      Breeze.foundDropzone.find('.upload-overlay-placeholder').hide();
      if (data.modal_task || data.project_files) {
        Breeze.foundDropzone.find('.upload-overlay-container').append(upload_el);
        Breeze.foundDropzone.addClass('in loading-progres');
      } else {
        Breeze.foundDropzone.find('.upload-overlay').append(upload_el);
        if (Breeze.foundDropzone.find('.upload-overlay').css('min-height') === "0px") {
          Breeze.foundDropzone.find('.upload-overlay').css('min-height', Breeze.foundDropzone.height() - 2);
          Breeze.foundDropzone.find('.drop-shadow').css('min-height', Breeze.foundDropzone.find('.drop-shadow').height());
        }
        Breeze.foundDropzone.addClass('in');
        if ((Breeze.foundDropzone.find('.upload-overlay').height()) > Breeze.foundDropzone.height()) {
          Breeze.foundDropzone.find('.drop-shadow').height(Breeze.foundDropzone.find('.upload-overlay').height() - 5);
        }
      }
      data.context = Breeze.foundDropzone;
    } else {
      if (data.project_files) {
        project_templ = $('.cell.upload-progress-box:not(.used)').clone().removeClass('hide').addClass("used");
        project_templ.attr('data-pid', data.upload_pid);
        project_templ.find('.upload-name').html(f.fileName);
        $('.files-body').prepend(project_templ);
        data.context = $('.files-body').find('.cell.upload-progress-box[data-pid="' + data.upload_pid + '"]');
      } else {
        $(".upload-progress").append(upload_el);
        data.context = $('.upload-progress');
      }
    }
  }
  ru = new ResumableUploadToGoogleDrive;
	

	
  ru.Do(resource, function(res, err) {
    var msg, progress;
    if (err) {
			
      gapi.auth.setToken(null);
      Breeze.google.oauthToken = null;
      try {
        localStorage.removeItem("bgoauthtoken");
      } catch (_error) {
        err = _error;
      }
			
      if (err.error.code === 404) {
        folder_not_found_popover();
        if (Breeze.froala_upload) {
          $('.fr-popup.fr-active').remove();
        } else {
          if (data.manual_upload || data.context.find('.wysi-placeholder').length > 0) {
            $('.upload-progress-box[data-pid="' + data.upload_pid + '"]').remove();
          } else {
            remove_upload_overlay(data.context, data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"]'));
          }
        }
      } else {

        if (Breeze.froala_upload) {
          attach_google_upload(true);
        } else {
          if (data.manual_upload || data.context.find('.wysi-placeholder').length > 0) {
            $('.upload-progress-box[data-pid="' + data.upload_pid + '"]').remove();
          } else {
            remove_upload_overlay(data.context, data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"]'));
          }
          attach_google_upload(false);
        }
      }
      return;
    }
    progress = 0;
    if (res.status === 'Uploading') {
      msg = Math.round(res.progressNumber.current / res.progressNumber.end * 100);
      progress = msg;
    } else {
      msg = res.status;
    }
    if (Breeze.froala_upload) {
      set_upload_progress_froala("Uploading", progress);
    } else {
      set_upload_progress("Uploading", progress, data);
    }
    if (res.status === 'Done') {
      if (Breeze.froala_upload) {
        set_upload_progress_froala("Uploading", 100);
        $("#editor-element").froalaEditor('popups.show', 'file.insert', null, null);
      } else {
        set_upload_progress("Uploading", 100, data);
        if (data.manual_upload || data.context.find('.wysi-placeholder').length > 0) {
          $('.upload-progress-box[data-pid="' + data.upload_pid + '"]').remove();
        } else {
          remove_upload_overlay(data.context, data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"]'));
        }
      }
      create_attach(data, res);
    }
  });
};

upload_files_google = function(data) {
  var file, fr;
  file = Breeze.google_uploaded_files;
  if (file && file.name !== '') {
    fr = new FileReader;
    fr.fileName = file.name;
    fr.fileSize = file.size;
    fr.fileType = file.type;
    fr.readAsArrayBuffer(file);
    return fr.onload = resumableUpload;
  }
};

remove_upload_overlay = function(element, upload_box) {
  return upload_box.fadeOut(function() {
    element.find('.drop-shadow').height(element.find('.drop-shadow').height() - $(this).height());
    $(this).remove();
    if (element.find('.upload-progress-box').length <= 0 || (element.hasClass('modal-body') && element.find('.modal-task .upload-progress-box').length <= 0)) {
      element.find('.card_box').css('min-height', '');
      element.find('.upload-overlay').css('min-height', '');
      element.find('.drop-shadow').css('min-height', '').css('height', '');
      element.find('.upload-overlay-placeholder').css('display', '');
      return element.removeClass('hover in loading-progres');
    }
  });
};

create_attach = function(data, resp) {
  var card, comment, file_name, file_url, message_id, new_comment, project, project_files, room_id, thumb, thumb_url, url;
  project = data.project || $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
  card = data.card || $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
  comment = Breeze.temp_comment || data.comment;
  new_comment = Breeze.temp_new_comment;
  project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in') || $('.roadmap-project-modal').hasClass('in') || $('#project-notes-modal').hasClass('show');
  room_id = $('.chat-right-content-room').attr('data-room-id');
  message_id = $('#room-message-edit').attr('data-message-id') || 0;
  thumb_url = "";
  thumb = false;
  url = "";
  file_name = resp.result.name;
  file_url = "https://drive.google.com/file/d/" + resp.result.id;
  resp.url = file_url;
  resp.name = file_name;
  resp.project = project;
  resp.card = card;
  if (comment) {
    url = '/projects/' + project + '/cards/' + card + '/comments/' + comment + '/attachments';
  } else if (new_comment) {
    url = '/projects/' + project + '/cards/' + card + '/comments/0/attachments';
  } else if (project_files) {
    url = '/projects/' + project + '/attachments';
  } else if (room_id) {
    if (Breeze.developement()) {
      url = ("https://app.breeze.test:3006/rooms/" + room_id + "/room_messages/" + message_id + "/attachments?auth_token=") + Breeze.current_user.token;
    } else {
      url = ("https://breeze-node-n3.herokuapp.com/rooms/" + room_id + "/room_messages/" + message_id + "/attachments?auth_token=") + Breeze.current_user.token;
    }
  } else {
    url = '/projects/' + project + '/cards/' + card + '/attachments';
  }
  $.ajax({
    type: 'POST',
    url: url,
    queue: true,
    data: {
      attachment: {
        name: file_name,
        url: file_url,
        thumb_url: thumb_url,
        thumb: thumb,
        embeded: Breeze.froala_upload
      }
    },
    success: function(ret) {
      if (Breeze.froala_upload) {
        if (project_files) {
          ret = $(ret).attr('data-attach-id');
        }
        return $("#editor-element").froalaEditor('file.insert', file_url, file_name, {
          'attach-id': ret,
          'project-id': project,
          'comment-id': comment,
          'attach-card-id': card
        });
      } else if (project_files) {
        $('.project-files-empty').remove();
        return $('.files-body').prepend(ret);
      } else {
        url = url + "/" + ret;
        if (new_comment) {
          if ($(".comment-editor").length > 0) {
            $(".comment-editor").data("attach").push(ret);
          }
        }
        create_attach_template_remote(resp, ret, url, comment, new_comment, thumb, thumb_url);
      }
    }
  });
};

set_upload_progress_froala = function(message, progress) {
  var $layer, $popup;
  $popup = $('.fr-popup.fr-active');
  if ($popup) {
    $layer = $popup.find('.fr-file-progress-bar-layer');
    if ($layer.length <= 0) {
      $layer = $popup.find('.fr-image-progress-bar-layer');
    }
    $layer.find('h3').text(message + (progress ? ' ' + progress + '%' : ''));
    $layer.removeClass('fr-error');
    if (progress) {
      $layer.find('div').removeClass('fr-indeterminate');
      return $layer.find('div > span').css('width', progress + '%');
    } else {
      return $layer.find('div').addClass('fr-indeterminate');
    }
  }
};

set_upload_progress = function(message, progress, data) {
  if (data.project_files && data.manual_upload) {
    return data.context.filter('.upload-progress-box[data-pid="' + data.upload_pid + '"]').find('.upload-progres').css('width', progress + "%");
  } else {
    return data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"] .upload-progres').css('width', progress + "%");
  }
};

folder_not_found_popover = function() {
  var card_popover, el, popover_center;
  $('.google-connect').remove();
  card_popover = $('<div> <div> <p> <b>Folder not found!</b> Please check that the Google Drive folder exists and you have access to it. </p> <p class="submit"><button data-action="cancel" class="c-button c-button--blue c-button-med cancel">Close</button></p> </div> </div>');
  popover_center = "";
  el = $("html");
  popover_center = "popover-center-modal";
  // $(".popover").remove();
  // $('.card-side .element-files').removeData('bs.popover');
  // $("html").removeData('bs.popover');
	
	el = $('.element-menu')

  el.popover({
    trigger: "manual",
		placement: "bottom",
    template: '<div class="popover google-connect ' + popover_center + '" id="google-connect"><div class="popover-inner"><div class="popover-body"><p></p></div></div></div>',
    html: true,
		sanitize: false,
		animation: false,
    content: card_popover.html()
  });
  el.popover("show");
	
	
  $('.google-connect').on("click", '[data-action="cancel"]', function() {
    $('.google-connect').remove();
    return false;
  });
	
	return false
};


create_attach_template_remote = function(data, id, url, comment, new_comment, thumb, thumb_url) {
  var attach_count, attach_element, box, card, hide_pin, kala, list, project;
  if (thumb == null) {
    thumb = false;
  }
  project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id');
  card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
  if (comment || new_comment) {
    hide_pin = "hide";
  }
  if (thumb) {
    box = $('<span class="attach-box" data-attach-id="' + id + '"> <a href="' + url + '"  rel="nofollow" class="attach-delete action" data-owner="true">Delete</a> <a href="' + url + '/pinit?cover=true" data-remote="true" rel="nofollow" class="attach-pin action ' + hide_pin + '" data-cover="false">Pin it</a> <a href="' + data.url + '" target="_blank" class="attach-image"> <img src="' + thumb_url + '" class="s3_img"> </a> <a href="' + data.url + '" target="_blank" class="name">' + data.name + '</a> </span>');
    if (comment) {
      $('[data-comment-id="' + comment + '"]').find(".boxes").append(box);
    } else if (new_comment) {
      $(".new_attachments .boxes").append(box);
    } else {
      kala = $(".cards .boxes").first().append(box);
      box.find("[data-cover]").trigger("click");
    }
  } else {
    list = $('<div class="attach-list" data-attach-id="' + id + '"> <a href="' + url + '" rel="nofollow" class="attach-delete action" data-owner="true"><i class="icon-remove pull-left" title="delete attachment" style=""></i></a> <i class="iconf-file-o" title="' + data.name + '"></i> <a href="' + data.url + '" target="_blank" class="name">' + data.name + '</a> </div>');
    if (comment) {
      $('[data-comment-id="' + comment + '"]').find(".attachments").append(list);
    } else if (new_comment) {
      $(".new_attachments").append(list);
    } else {
      $(".cards.attachments").first().append(list);
    }
  }
  if (!(comment || new_comment)) {
    attach_element = $('.card[data-card-id="' + card + '"]').find('.attach-bubble span');
    if (attach_element.length) {
      attach_count = parseInt(attach_element.html());
      return attach_element.html(attach_count + 1);
    } else {
      return $('.card[data-card-id="' + card + '"]').find('.badges').append('<span class="attach-bubble"> <i class="icon-attachment" title="attachments"></i> <span>1</span> </span>');
    }
  }
};
// ---
// generated by coffee-script 1.9.2