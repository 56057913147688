import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

var init_todo_menu, todo_remove;

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.public-card').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_todo_menu();
  }
  return $(document).on("breeze:page", function(e, page) {
    if (page === "todos" || page === "todos-menu") {
      return init_todo_menu();
    }
  });
});

init_todo_menu = function() {
  var init_dropdown;
  $('#card_modal .todos').on("click", '.todo-items .more-icon', function() {
    return init_dropdown($(this));
  });
  $('.dashboard .right-menu').on("click", '.more-icon', function() {
    return init_dropdown($(this));
  });
  init_dropdown = function(that) {
    var el, popover_html, thide, todo_id;
    if ($('.workspace.dashboard').length > 0) {
      el = that.parent();
      todo_id = that.parent().attr('data-id');
      thide = "hide";
    } else {
      thide = "";
      el = that;
      todo_id = that.parent().attr('data-id');
    }
    thide = "hide";
		
		$('#card_modal .todo-items .fa-ellipsis-v').css('visibility','')

    $('#todo-list-menu').remove();
    if ($('#todo-menu[data-todo-id="' + todo_id + '"]').length > 0) {
      el.popover("hide");
      el.removeData("bs.popover");
      $('#todo-menu').parent().remove();
    } else {
      el.removeData("bs.popover");
			el.css('visibility','visible')
			
      $('#todo-menu').parent().remove();
      popover_html = $('<div> <ul class="dropdown-menu"> <li class="' + thide + '"><a href="#" data-action="convert">Convert to task</a></li> <li><a href="#" data-action="delete">Delete</a></li> </ul> </div>');
      el.popover({
        trigger: "manual",
        container: el.parent(),
        placement: "right",
        template: '<div class="popover todo-menu"><div id="todo-menu" data-todo-id="' + todo_id + '" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
        html: true,
				sanitize:false,
        content: popover_html.html()
      });

      el.popover("show");
			
			var pop = $('.todo-menu [data-todo-id="'+todo_id+'"]').parent()
			pop.prependTo(pop.parent())
			
      $('#todo-menu').on("click", '[data-action="convert"]', function() {
        var card, duedate, i, invitees, j, len, name, stage_id, swimlane, todo, u, users;
        todo = $(this).closest('.popover').prev();
        card = $('.card[data-card-id="' + todo.attr("data-tcard-id") + '"]');
        swimlane = card.closest('.sortable_stage');
        stage_id = card.attr('data-stage-id');
        name = todo.find('[data-type="item"]').html();
        duedate = todo.attr('data-duedate');
        invitees = [];
        try {
          users = JSON.parse(todo.attr('data-user-id'));
        } catch (_error) {
          users = [];
        }
        if (users != null) {
          for (j = 0, len = users.length; j < len; j++) {
            u = users[j];
            i = _.find(Breeze.all_team_users, function(e) {
              return e.id === parseInt(u);
            });
            if (i != null) {
              invitees.push(i.email);
            }
          }
        }
        Breeze.create_new_task(stage_id, swimlane, false, name, duedate, invitees, true);
        todo_remove($(this));
        return false;
      });
      $('#todo-menu').on("click", '[data-action="delete"]', function() {
        return todo_remove($(this));
      });
    }
    return false;
  };
};

todo_remove = function(el) {
  var c, e, list, socket_params, t, todo, todo_bubble;
  list = el.closest('[data-type="container-list"]').attr('data-id');
  todo = el.closest('[data-type="container"]')
	
  $(".popover").remove();
  if ($('.workspace').hasClass("dashboard") && !$('#card_modal').hasClass('show')) {
    Breeze.Card.project = todo.attr('data-project-id');
    Breeze.Card.id = todo.attr('data-tcard-id');
    list = todo.attr('data-todo-list-id');
  }
  socket_params = {
    card: Breeze.Card.id,
    project: Breeze.project_token,
    method: "card-todo-delete",
    list: list,
    todo: todo.attr("data-id")
  };
  $.ajax({
    type: 'DELETE',
    queue: true,
    url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists/' + list + '/todos/' + todo.attr("data-id"),
    success: function(data) {
      if (Breeze.is_socket_connected()) {
        return Breeze.socket.emit("message", socket_params);
      }
    }
  });
  if ($('.dashboard .list-view.duedate-sort').length > 0) {
    $('.dashboard .todo-items .todo [data-type="container"][data-id=' + todo.attr("data-id") + ']').parent().parent().remove();
  } else if ($('.workspace').hasClass("dashboard")) {
    $('.dashboard .todo-items .todo [data-type="container"][data-id=' + todo.attr("data-id") + ']').parent().remove();
    $('.dashboard .card-outer .todo [data-type="container"][data-id=' + todo.attr("data-id") + ']').parent().remove();
  }
  $('.todo-items [data-id="' + todo.attr("data-id") + '"]').remove();
  todo_bubble = $('.card[data-card-id="' + Breeze.Card.id + '"]').find('.todos-bubble');
  if (todo.find('[data-behavior="toggle"]').attr("checked")) {
    e = todo_bubble.find("span").first();
    c = parseInt(e.html());
    e.html(c - 1);
  }
  t = todo_bubble.find("span").last();
  c = parseInt(t.html());
  t.html(c - 1);
  if ((c - 1) <= 0) {
    todo_bubble.remove();
  }
	update_project_list_view_columns("todos")
  return false;
};

// ---
// generated by coffee-script 1.9.2