var init_stages_automation, update_stage_card,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page, stage_id) {
    if (page === "stages_automation_popovers") {
      return init_stages_automation(stage_id);
    }
  });
  return $(document).on("breeze:page", function(e, page, card_id, stage_id, old_stage_id, card) {
    if (page === "update_stage_card") {
      return update_stage_card(card_id, stage_id, old_stage_id, card);
    }
  });
});

init_stages_automation = function(stage_id) {
  return $('#stage-automation-menu').on("click", ".tag-select", function(event) {
    var content, el, i, len, ref, ref1, selected, stage_obj, tag, tag_el, tags_clear, update_stage_tags;
    el = $('#stage-automation-menu');
    if ($('#stage-tags-menu').length > 0) {
      el.popover("hide");
      $('#stage-tags-menu').remove();
    } else {
      el.removeData("bs.popover");
      $('#stage-tags-menu').remove();
      stage_obj = _.find(Breeze.project_stages, function(s) {
        return s.id === parseInt(stage_id);
      });
      tag_el = '';
      ref = Breeze.project_tags;
      for (i = 0, len = ref.length; i < len; i++) {
        tag = ref[i];
        selected = '';
        if (stage_obj.actions.tag.name && (ref1 = String(tag.name), indexOf.call(stage_obj.actions.tag.name, ref1) >= 0)) {
          selected = 'selected';
        }
        tag_el += '<option value="' + tag.name + '" ' + selected + ' >' + tag.name + '</option>';
      }
      tags_clear = "";
      if (stage_obj.actions.tag.tags_clear) {
        tags_clear = 'checked="checked"';
      }
      content = '<div> <div class="popover-header"><i class="fa fa-angle-left" aria-hidden="true"></i><span class="title">Tags</span>' + Breeze.global_close + '</div> <div class="fields-tags-wrapper styled-multiselect"> <select class="fields-tags-select badge-multiselect" multiple="multiple" name="tags[]"> <option value="all">Select all</option>' + tag_el + '</select> </div> <div class="clear-automatic"> <label class="m-label">Clear old tags from task <input ' + tags_clear + ' name="tags_clear" type="checkbox" value="1"> <span class="m-checkmark"></span> </label> </div> </div>';
      el.popover({
        trigger: "manual",
        container: "element",
        placement: "topleft",
        template: '<div class="popover stage-automation-menu-sub" id="stage-tags-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
        html: true,
        content: content
      });
      $('#stage-automation-menu').hide();
      el.popover("show");
      $('#stage-tags-menu').on("click", ".fa", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $('#stage-tags-menu').remove();
        $('#stage-automation-menu').show();
        return false;
      });
      $('#stage-tags-menu').on("click", "svg", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $(".stage-settings.dropdown").removeClass("open");
        $('#stage-tags-menu').remove();
        $('#stage-automation-menu').remove();
        $('.popover').remove();
        return false;
      });
      $('#stage-tags-menu').on("click", ".clear-automatic input", function() {
        return update_stage_tags();
      });
      $('#stage-tags-menu').on("click", function(e) {
        return e.stopPropagation();
      });
      $('#stage-tags-menu .fields-tags-select').multiselect({
        numberDisplayed: 0,
        includeSelectAllOption: true,
        includeSelectAllIfMoreThan: 3,
        selectAllValue: "all",
        nonSelectedText: "Tags",
        buttonClass: 'btn btn-link',
        nSelectedText: '',
        nSelectedText1: 'Tags',
        enableFiltering: true,
        enableCaseInsensitiveFiltering: true,
        onInitialized: function(select, container) {
          return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
        },
        onSelectAll: function() {
          return update_stage_tags();
        },
        onDeselectAll: function() {
          return update_stage_tags();
        },
        onChange: function(option, checked, select) {
          return update_stage_tags();
        }
      });
      update_stage_tags = function() {
        var socket_params, tags;
        tags = $('#stage-tags-menu .fields-tags-select').val();
        tags_clear = $('#stage-tags-menu .clear-automatic input').prop("checked");
        stage_obj.actions.tag.name = tags;
        stage_obj.actions.tag.tags_clear = tags_clear;
        socket_params = {
          project: Breeze.project_token,
          method: "stage-update-js",
          message: Breeze.project_stages
        };
        if ((tags != null ? tags.length : void 0) > 0) {
          $('#stage-automation-menu .tag-select .selected-count').text(tags != null ? tags.length : void 0).removeClass("clear");
        } else if (tags_clear) {
          $('#stage-automation-menu .tag-select .selected-count').html("clear").addClass("clear");
        } else {
          $('#stage-automation-menu .tag-select .selected-count').text("").removeClass("clear");
        }
        return $.ajax({
          type: "PUT",
          url: '/stages/' + stage_id,
          queue: true,
          data: {
            project_id: $("#card_project_id").val(),
            tags: tags,
            tags_clear: tags_clear
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
      };
    }
    return false;
  });
};

update_stage_card = function(card_id, stage_id, old_stage_id, card) {
  var el, elt, i, len, old_stage_obj, r, ref, ref1, ref2, ref3, ref4, ref5, results, stage_obj, t, tag_color, tag_el, tag_name;
  if (old_stage_id !== stage_id) {
    stage_obj = _.find(Breeze.project_stages, function(s) {
      return s.id === parseInt(stage_id);
    });
    old_stage_obj = _.find(Breeze.project_stages, function(s) {
      return s.id === parseInt(old_stage_id);
    });
    if ((stage_obj != null ? (ref = stage_obj.actions) != null ? (ref1 = ref.tag) != null ? (ref2 = ref1.name) != null ? ref2.length : void 0 : void 0 : void 0 : void 0) > 0) {
      if (stage_obj.actions.tag.tags_clear) {
        $('.card[data-card-id="' + card_id + '"] .tags-bubble .pill').remove();
      }
      ref3 = stage_obj.actions.tag.name;
      results = [];
      for (i = 0, len = ref3.length; i < len; i++) {
        t = ref3[i];
        elt = _.escape(t.trim()).toLowerCase();
        r = $('.card[data-card-id="' + card_id + '"] .tags-bubble .pill').filter(function() {
          $(this).attr('data-tag').toLowerCase() === elt;
        });
        if (r.length > 0) {
          continue;
        }
        tag_color = "";
        tag_name = _.escape(t.trim());
        tag_el = _.find(Breeze.project_tags, function(tag) {
          return tag.name.toLowerCase() === t.trim().toLowerCase();
        });
        if (tag_el) {
          tag_color = tag_el.color;
        }
        if (tag_el) {
          tag_name = tag_el.name;
        }
        if ($('.card[data-card-id="' + card_id + '"] .tags-bubble').length <= 0) {
          $('.card[data-card-id="' + card_id + '"] .badges').append('<span class="tags-bubble"></span>');
        }
        el = '<span class="pill" style="background:' + tag_color + ';" data-tag="' + tag_name + '">' + tag_name + '<span class="remove">&times;</span></span>';
        $('.card[data-card-id="' + card_id + '"] .tags-bubble').append(el).find(".pill:last");
        if (Breeze.Card.id === card_id) {
          if ($(".card-content .tags").length === 0) {
            $('<div class="row"><div class="span7 card-attributes tags" data-readonly="false"><h5>Tags</h5></div></div>').insertAfter(".card-header");
          }
          results.push($('.card-content .tags').append(el).find(".pill:last"));
        } else {
          results.push(void 0);
        }
      }
      return results;
    } else if (stage_obj != null ? (ref4 = stage_obj.actions) != null ? (ref5 = ref4.tag) != null ? ref5.tags_clear : void 0 : void 0 : void 0) {
      return $('.card[data-card-id="' + card_id + '"] .tags-bubble .pill').remove();
    }
  }
};

// ---
// generated by coffee-script 1.9.2