
$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      init_page();
    }
  });
	
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_page();
  }
	
});
function init_page() {
	
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-estimate", function() {
    var card_popover;
		el = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
		
    $(".popover:not(#card-estimate-menu)").remove();

    if ($('#card-estimate-menu').length > 0) {
      el.popover("hide");
    } else {
			
       $('.element-menu').popover('dispose')
			
	    card_popover = $('<div> <div id="card-track-time"> <div class="row"> <div class="planned"> <i class="icon-clock"></i> <input type="text" name="planned" class="" spellcheck="false" placeholder="Planned work" data-original-title=""> </div> </div> <div class="submit"> <input name="commit" type="submit" value="Save" class="bs btn btn-new" data-original-title=""> </div> </div> </div>');
	    el.popover({
	      trigger: "manual",
	      placement: "bottom",
	      template: '<div class="popover element-popover card-actions" ><div id="card-estimate-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
	      html: true,
				animation: false,
				container: el,
				sanitize: false,
	      content: card_popover.html()
	    });
			
      el.popover("show");
      Breeze.card_menu = true;
      if (Breeze.Card.planned_time && Breeze.Card.planned_time > 0) {
        $('#card-track-time .planned input:text').val(Breeze.Time.formatPretty(Breeze.Card.planned_time));
      }

      $('#card-track-time .planned input:text').select();
			
      $('#card-track-time').on("click", "input.btn", function(e) {
        var card, planned_time, planned_time_store, socket_params, sum, that;
        that = $(this);
        planned_time = Breeze.Time.parse($('#card-track-time input[name="planned"]:text').val(), 1);
        if (planned_time === null && $('#card-track-time input[name="planned"]:text').val() !== "") {
          $('#card-track-time .planned input[type="text"]').tooltip({
            animation: false,
            trigger: "manual",
            title: "This isn't a correct format. Valid time formats are 45, 1:45, 1h, 1h45m, 1day"
          });
          $('#card-track-time .planned input[type="text"]').tooltip("show");
          return false;
        }
        planned_time_store = Breeze.Time.parseMinutes(planned_time, 1);
        Breeze.Card.planned_time = planned_time_store;
				sum = Breeze.sum_tracked_time(Breeze.Card.planned_time,0)
        Breeze.update_card_time(sum);
				
				
        card = $('.card[data-card-id="' + Breeze.Card.id + '"]');
        card.attr("data-time-planned", Breeze.Card.planned_time);
        socket_params = {
          card: Breeze.Card.id,
          project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-project-token'),
          method: "card-estimate",
          message: planned_time_store,
          sum: sum,
					tracked: 0
        };
        $.ajax({
          type: "PUT",
          url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id,
          queue: true,
          data: {
            card: {
              planned_time: planned_time_store
            }
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        },        $('.element-menu').popover('dispose'));
        return false;
      });
      $('#card-track-time').on("keydown", '.planned input[type="text"]', function(e, item) {
        if ($(this).data('tooltip')) {
          $(this).tooltip("hide");
        }
        if (e.keyCode === 13) {
          $("#card-track-time input.btn").trigger("click");
          return false;
        }
      });
    }
    return false;
  });
};
