var load_workspaces, init_page;


$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "load-workspaces") {
      load_workspaces();
    }
    if (page === "projects-list") {
      init_page();
			load_workspaces()
    }
  });

	
  if ($('.workspace.projects-list').length > 0) {
		init_page();
    load_workspaces();
  }

	
});
init_page = function() {
	
	$('.projects-list').on("ajax:complete", ".hide-workspace", function(evt, xhr, settings) {
	  $(this).addClass('hidden-workspace').removeClass("hide-workspace");
	  $(this).attr('href', $(this).attr('href') + '&hidden=false');
	});

	$('.projects-list').on("ajax:complete", ".hidden-workspace", function(evt, xhr, settings) {
	  var workspace;
	  workspace = $(this).closest('.project-workspace');
	  $(this).removeClass('hidden-workspace').addClass("hide-workspace");
	  $(this).attr('href', $(this).attr('href') + '&hidden=true');
	  if (workspace.attr('data-workspace-loaded') === "false") {
	    $(document).trigger("breeze:page", "load-workspaces");
	  } else {
	    workspace.attr('data-hidden', 'false');
	  }
	});
	
	$('.projects-list').on("click", ".hidden-workspace", function(e) {
	  var workspace;
	  workspace = $(this).closest('.project-workspace');
	  workspace.attr('data-hidden', 'false');
	  workspace.find('.workspace-sortable').show();
	});

	$('.projects-list').on("click", ".hide-workspace", function(e) {
	  var workspace;
	  workspace = $(this).closest('.project-workspace');
	  workspace.attr('data-hidden', 'true');
	  workspace.find('.workspace-sortable').hide();
	  workspace.find('.workspace-skeleton').remove();
	});
	
	
}

load_workspaces = function() {
  var el;
  el = '<div class="workspace-skeleton"><div></div></div>';
  $('.project-workspace[data-hidden="false"][data-workspace-loaded="false"]').each(function() {
    if (!($(this).find('.workspace-skeleton').length > 0)) {
      $(this).append(el);
    }
  });
  $('.project-workspace[data-hidden="false"][data-workspace-loaded="false"]').each(function() {
    var elt, workspace_id;
    workspace_id = $(this).attr('data-workspace-id');
    elt = $(this);
    $.ajax({
      type: 'GET',
      url: '/workspaces/' + workspace_id,
      queue: true,
      data: {
        partial: true
      },
      success: function(data) {
        var wrk;
        wrk = $(data).find('.workspace-sortable');
        elt.find('.workspace-skeleton').remove();
        elt.attr('data-workspace-loaded', "true");
        elt.append(wrk);
        // $('.projects-list[data-sorting="true"] .project_cards').sortable(Breeze.sort_opts_projects).sortable({
        //   connectWith: ".project_cards"
        // });
        // $('.projects-list[data-sorting="true"] .project_list').sortable(Breeze.sort_opts_projects).sortable({
        //   connectWith: ".project_list"
        // });
        resize_workspaces();
      }
    });
  });
};



resize_workspaces = function() {
  $(".project_cards").each(function(e) {
    if ($(this).find('.drop-shadow').length <= 0) {
      return $(this).addClass('hidden-zone-minimal');
    } else {
      return $(this).removeClass('hidden-zone-minimal');
    }
  });
  $(".project_list").each(function(e) {
    if ($(this).find('.project_name').length <= 0) {
      return $(this).addClass('hidden-zone-minimal');
    } else {
      return $(this).removeClass('hidden-zone-minimal');
    }
  });
  $(".project-workspace").each(function(e) {
    if ($(this).find('.project_list').hasClass('hidden-zone-minimal') && $(this).find('.project_cards').hasClass('hidden-zone-minimal')) {
      return $(this).addClass('workspace-minimal');
    } else {
      return $(this).removeClass('workspace-minimal');
    }
  });
  if ($('.project-workspace-main .project_cards .drop-shadow').length <= 0 && $('.project-workspace-main .project_list .project_name').length <= 0) {
    return $('.project-workspace-main').addClass('hidden-zone-main');
  } else {
    return $('.project-workspace-main').removeClass('hidden-zone-main');
  }
};

