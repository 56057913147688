import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after,uniqueId,generate_s3_key} 
				from 'mobile/froala/setup.js';

import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

			
var create_attach_template, remove_upload_overlay, add_upload_files;

$(document).ready(function() {
  var boxSelect, checkAuth, checkExistingAuth, createPicker, create_attach_template_remote, dragTimer, get_content_type, handleAuthResult, handleClientLoad, onAuthApiLoad, open_dropbox, options, pickerCallback;
  // if (typeof Dropbox === "undefined") {
  //   $.getScript("https://www.dropbox.com/static/api/2/dropins.js", function(data, textStatus, jqxhr) {
  //     return Dropbox.appKey = "e5wfq699ciy8mj3";
  //   });
  // }
  // if (typeof gapi === "undefined") {
  //   $.getScript("https://apis.google.com/js/client.js");
  // }
	
	// if (typeof gapi === "undefined") {
	//   $.getScript("https://apis.google.com/js/client.js");
	// }
	
	Breeze.init_fileupload()
	
  $(document).on("click", ".attach-delete", function() {
    var attach, attach_count, attach_element, card, card_element, socket_params;
    card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
    attach = $(this).parent().data("attach-id");
    socket_params = {
      card: Breeze.Card.id,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-project-token'),
      method: "attach-delete",
      attach: attach,
      cbox: $(this).closest(".comment-box").length
    };
    if (Breeze.is_socket_connected()) {
      Breeze.socket.emit("message", socket_params);
    }
    $('[data-attach-id="' + attach + '"]').fadeOut(function() {
      return $(this).remove();
    });
    if ($(this).closest(".comment-box").length === 0) {
      attach_element = $('.card[data-card-id="' + card + '"]').find('.attach-bubble span');
      if (attach_element.length) {
        attach_count = parseInt(attach_element.html()) - 1;
        if (attach_count > 0) {
          attach_element.html(attach_count);
        } else {
          attach_element.closest('.attach-bubble').remove();
					update_project_list_view_columns("attach")
        }
      }
      card_element = $('.card[data-card-id="' + card + '"] .card_cover');
      if (card_element.data("cover-id") === attach) {
        card_element.remove();
				update_project_list_view_columns("images")
      }
    }
    return $(".comment-editor").data("attach", _.without($(".comment-editor").data("attach"), attach.toString()));
  });
  // $(document).on('drop dragover', 'html', function(e) {
  //   if (!($(e.target).closest("#editor-element").length > 0)) {
  //     return e.preventDefault();
  //   }
  // });
  // $(document).bind("dragover1", function(e) {
  //   var dropZone, found, node, timeout;
  //   dropZone = $(".dropzone");
  //   Breeze.foundDropzone = void 0;
  //   timeout = window.dropZoneTimeout;
  //   if (!timeout) {
  //     if ($('#card_modal').hasClass('show')) {
  //       $('#card_modal .comment-box').css('position', 'relative');
  //     }
  //     if ($('.public-card').length > 0) {
  //       $('.public-card .comment-box').css('position', 'relative');
  //     }
  //   }
  //   if (timeout) {
  //     clearTimeout(timeout);
  //   }
  //   found = false;
  //   node = e.target;
  //   while (true) {
  //     if ($(node).hasClass("dropzone")) {
  //       found = true;
  //       Breeze.foundDropzone = $(node);
  //       break;
  //     }
  //     node = node.parentNode;
  //     if (node == null) {
  //       break;
  //     }
  //   }
  //   dropZone.removeClass("hover");
  //   if (found) {
  //     Breeze.foundDropzone.addClass("hover");
  //   }
  //   window.dropZoneTimeout = setTimeout(function() {
  //     window.dropZoneTimeout = null;
  //     dropZone.removeClass("hover");
  //     $('#card_modal .comment-box').css('position', '');
  //     $('.public-card .comment-box').css('position', '');
  //   }, 150);
  // });
  $(document).on("click", ".upload-cancel", function(e) {
    if ($(this).closest('.upload-progress-box').data("jqXHR")) {
      $(this).closest('.upload-progress-box').data("jqXHR").abort();
    }
    remove_upload_overlay($(this).closest('.dropzone'), $(this).closest('.upload-progress-box'));
    return false;
  });
  $(document).on("click", ".attach-pin", function(e) {
    var card, socket_params, that;
    that = $(this);
    if ($('#card_modal').hasClass('show')) {
      card = Breeze.Card.id;
    } else {
      card = $(this).closest('.card').attr('data-card-id');
    }
    $(this).parent().siblings().find(".attach-pin").each(function() {
      return $(this).text("Pin it").attr("data-cover", false).attr("href", $(this).attr("href").split("?")[0] + "?cover=true");
    });
    if (that.attr("data-cover") === "true") {
      that.text("Pin it");
      that.attr("data-cover", false);
      that.attr("href", $(this).attr("href").split("?")[0] + "?cover=true");
      $('.card[data-card-id="' + card + '"] .card_cover').remove();
    } else {
      that.text("Unpin it");
      that.attr("data-cover", true);
      that.attr("href", $(this).attr("href").split("?")[0] + "?cover=false");
      $('.card[data-card-id="' + card + '"] .card_cover').remove();
      $('<div class="card_cover" data-cover-id="' + that.parent().data("attach-id") + '"> <img src="' + that.parent().find(".attach-image img").attr("src") + '" class="s3_img"> </div>').insertBefore('.card[data-card-id="' + card + '"] .card_name');
    }
		update_project_list_view_columns("images")
    if (e.originalEvent) {
      socket_params = {
        card: Breeze.Card.id,
        project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-project-token'),
        method: "card-attach-pin",
        user: Breeze.current_user.email,
        attach: that.parent().data("attach-id")
      };
      if (Breeze.is_socket_connected()) {
        return Breeze.socket.emit("message", socket_params);
      }
    }
  });
  create_attach_template_remote = function(data, id, url, comment, new_comment, thumb, thumb_url) {
    var attach_count, attach_element, box, card, hide_pin, kala, list, project;
    if (thumb == null) {
      thumb = false;
    }
    project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id');
    card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
    if (comment || new_comment) {
      hide_pin = "hide";
    }
    if (thumb) {
      box = $('<span class="attach-box" data-attach-id="' + id + '"> <a href="' + url + '" data-method="delete" data-remote="true" rel="nofollow" class="attach-delete action" data-owner="true">Delete</a> <a href="' + url + '/pinit?cover=true" data-remote="true" rel="nofollow" class="attach-pin action ' + hide_pin + '" data-cover="false">Pin it</a> <a href="' + data.url + '" target="_blank" class="attach-image"> <img src="' + thumb_url + '" class="s3_img"> </a> <a href="' + data.url + '" target="_blank" class="name">' + data.name + '</a> </span>');
      if (comment) {
        $('[data-comment-id="' + comment + '"]').find(".boxes").append(box);
      } else if (new_comment) {
        $(".new_attachments .boxes").append(box);
      } else {
        kala = $(".cards .boxes").first().append(box);
        box.find("[data-cover]").trigger("click");
      }
    } else {
      list = $('<div class="attach-list" data-attach-id="' + id + '"> <a href="' + url + '" data-method="delete" data-remote="true" rel="nofollow" class="attach-delete action" data-owner="true"><i class="icon-remove pull-left" title="delete attachment" style=""></i></a> <i class="iconf-file-o" title="' + data.name + '"></i> <a href="' + data.url + '" target="_blank" class="name">' + data.name + '</a> </div>');
      if (comment) {
        $('[data-comment-id="' + comment + '"]').find(".attachments").append(list);
      } else if (new_comment) {
        $(".new_attachments").append(list);
      } else {
        $(".cards.attachments").first().append(list);
      }
    }
    if (!(comment || new_comment)) {
      attach_element = $('.card[data-card-id="' + card + '"]').find('.attach-bubble span');
      if (attach_element.length) {
        attach_count = parseInt(attach_element.html());
        return attach_element.html(attach_count + 1);
      } else {
        return $('.card[data-card-id="' + card + '"]').find('.badges').append('<span class="attach-bubble"> <i class="icon-attachment" title="attachments"></i> <span>1</span> </span>');
      }
    }
		update_project_list_view_columns("attach")
  };
  $(document).on("click", ".temp_attach", function() {
    if (!$(".comment-editor").data("new-comment")) {
      Breeze.comment_box_trigger = $("#editor-element").closest('.comment-box').attr('data-comment-id');
    }
    Breeze.foundDropzone = void 0;
    $('#file_attach').trigger('click');
    return false;
  });
  $(document).on("click", ".attach-slide", function() {
    var card, project, that;
    project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id');
    card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
    $(this).hide();
    that = $(this).parent();
    that.find(".attach-select").show("slide", {
      direction: "left"
    }, 300);
    if (typeof Dropbox === "undefined") {
      $.getScript("https://www.dropbox.com/static/api/2/dropins.js", function(data, textStatus, jqxhr) {
        return Dropbox.appKey = "e5wfq699ciy8mj3";
      });
    }
    return false;
  });
  Breeze.attach_google = function(data) {
    var err;
    if (data) {
      Breeze.froala_upload = true;
    } else {
      Breeze.froala_upload = false;
    }
    Breeze.temp_new_comment = $(".comment-editor").data("new-comment");
    if (!Breeze.temp_new_comment) {
      Breeze.temp_comment = $("#editor-element").closest('.comment-box').attr('data-comment-id');
    }
    $(".picker").remove();
    if (Breeze.google.oauthToken == null) {
      try {
        Breeze.google.oauthToken = JSON.parse(localStorage.getItem("bgoauthtoken"));
      } catch (_error) {
        err = _error;
      }
    }
    if (Breeze.google.oauthToken == null) {
      gapi.load("auth", {
        callback: handleClientLoad
      });
      return gapi.load("picker");
    } else {
      return gapi.load("auth", {
        callback: checkExistingAuth
      });
    }
  };
  $(document).on("click", ".attach-google", function(e, data) {
    Breeze.attach_google(data);
    return false;
  });
  checkExistingAuth = function() {
    gapi.auth.setToken(Breeze.google.oauthToken);
    return gapi.client.load("oauth2", "v2", function() {
      return gapi.client.oauth2.userinfo.get().execute(function(t) {
        var err;
        if (t.email != null) {
          return gapi.load("picker", {
            callback: createPicker
          });
        } else {
          gapi.auth.setToken(null);
          Breeze.google.oauthToken = null;
          try {
            localStorage.removeItem("bgoauthtoken");
          } catch (_error) {
            err = _error;
          }
          if (Breeze.froala_upload) {
            return Breeze.attach_google(true);
          } else {
            return Breeze.attach_google(false);
          }
        }
      });
    });
  };
  checkAuth = function() {
    gapi.auth.authorize({
      client_id: Breeze.google.clientId,
      scope: ["https://www.googleapis.com/auth/drive.file", "https://www.googleapis.com/auth/userinfo.email"],
      immediate: true,
      authuser: !Breeze.google.oauthToken ? -1 : void 0,
      prompt: !Breeze.google.oauthToken ? 'select_account' : void 0
    }, handleAuthResult);
  };
  handleClientLoad = function() {
    return window.setTimeout(checkAuth, 1);
  };
  Breeze.attach_dropbox = function(data, that) {
    if (data) {
      Breeze.froala_upload = true;
    } else {
      Breeze.froala_upload = false;
    }
    Breeze.temp_new_comment = $(".comment-editor").data("new-comment");
    if (!Breeze.temp_new_comment) {
      Breeze.temp_comment = $("#editor-element").closest('.comment-box').attr('data-comment-id');
    }
    if (typeof Dropbox === "undefined") {
      return $.getScript("https://www.dropbox.com/static/api/2/dropins.js", function(data, textStatus, jqxhr) {
        Dropbox.appKey = "e5wfq699ciy8mj3";
        return open_dropbox();
      });
    } else {
      return open_dropbox();
    }
  };
  $(document).on("click", ".attach-dropbox", function(e, data) {
    Breeze.attach_dropbox(data, $(this));
    return false;
  });
  open_dropbox = function() {
    Dropbox.choose({
      success: function(files) {
        var card, comment, new_comment, project, project_files, thumb, thumb_url, types, url;
        project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
        card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
        comment = Breeze.temp_comment;
        new_comment = Breeze.temp_new_comment;
        project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in');
        if (files[0]) {
          types = /^(gif|jpeg|jpg|png|apng|svg)$/;
          if (types.test(files[0].name.split(".").pop())) {
            thumb_url = files[0].link.replace("www.dropbox.com", "dl.dropboxusercontent.com");
            thumb = true;
          } else {
            thumb_url = "";
            thumb = false;
          }
          if (comment) {
            url = '/projects/' + project + '/cards/' + card + '/comments/' + comment + '/attachments';
          } else if (new_comment) {
            url = '/projects/' + project + '/cards/' + card + '/comments/0/attachments';
          } else if (project_files) {
            url = '/projects/' + project + '/attachments';
          } else {
            url = '/projects/' + project + '/cards/' + card + '/attachments';
          }
          return $.ajax({
            type: 'POST',
            url: url,
            queue: true,
            data: {
              attachment: {
                name: files[0].name,
                url: files[0].link,
                thumb: thumb,
                thumb_url: thumb_url,
                embeded: Breeze.froala_upload
              }
            },
            success: function(ret) {
              if (Breeze.froala_upload) {
                if (project_files) {
                  ret = $(ret).attr('data-attach-id');
                }
                if (thumb) {
                  return $("#editor-element").froalaEditor('image.insert', thumb_url, true, false, false, {
                    'attach-id': ret,
                    'project-id': project,
                    'comment-id': comment,
                    'attach-card-id': card
                  });
                } else {
                  return $("#editor-element").froalaEditor('file.insert', files[0].link, files[0].name, {
                    'attach-id': ret,
                    'project-id': project,
                    'comment-id': comment,
                    'attach-card-id': card
                  });
                }
              } else if (project_files) {
                $('.project-files-empty').remove();
                return $('.files-body').prepend(ret);
              } else {
                url = url + "/" + ret;
                if (new_comment) {
                  if ($(".comment-editor").length > 0) {
                    $(".comment-editor").data("attach").push(ret);
                  }
                }
                files[0].url = files[0].link;
                return create_attach_template_remote(files[0], ret, url, comment, new_comment, thumb, thumb_url);
              }
            }
          });
        }
      }
    });
    return false;
  };
  // options = {
 //    clientId: "eopk6vacqr03mkohqp49t2z63zj5sl0f",
 //    linkType: "shared",
 //    multiselect: true
 //  };
 //  boxSelect = new BoxSelect(options);
 //  boxSelect.success(function(response) {
 //    var card, comment, file, i, len, new_comment, project, project_files, results, thumb, thumb_url, types, url;
 //    project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
 //    card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
 //    comment = Breeze.temp_comment;
 //    new_comment = Breeze.temp_new_comment;
 //    project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in');
 //    if (response.length > 0) {
 //      results = [];
 //      for (i = 0, len = response.length; i < len; i++) {
 //        file = response[i];
 //        types = /^(gif|jpeg|jpg|png|apng|svg)$/;
 //        thumb_url = "";
 //        thumb = false;
 //        if (comment) {
 //          url = '/projects/' + project + '/cards/' + card + '/comments/' + comment + '/attachments';
 //        } else if (new_comment) {
 //          url = '/projects/' + project + '/cards/' + card + '/comments/0/attachments';
 //        } else if (project_files) {
 //          url = '/projects/' + project + '/attachments';
 //        } else {
 //          url = '/projects/' + project + '/cards/' + card + '/attachments';
 //        }
 //        results.push($.ajax({
 //          type: 'POST',
 //          url: url,
 //          queue: true,
 //          local_file: file,
 //          lthumb: thumb,
 //          lthumb_url: thumb_url,
 //          lurl: url,
 //          data: {
 //            attachment: {
 //              name: file.name,
 //              url: file.url,
 //              thumb: thumb,
 //              thumb_url: thumb_url,
 //              embeded: Breeze.froala_upload
 //            }
 //          },
 //          success: function(ret) {
 //            var murl;
 //            if (Breeze.froala_upload) {
 //              if (project_files) {
 //                ret = $(ret).attr('data-attach-id');
 //              }
 //              return $("#editor-element").froalaEditor('file.insert', this.local_file.url, this.local_file.name, {
 //                'attach-id': ret,
 //                'project-id': project,
 //                'comment-id': comment,
 //                'attach-card-id': card
 //              });
 //            } else if (project_files) {
 //              $('.project-files-empty').remove();
 //              return $('.files-body').prepend(ret);
 //            } else {
 //              murl = this.lurl + "/" + ret;
 //              if (new_comment) {
 //                if ($(".comment-editor").length > 0) {
 //                  $(".comment-editor").data("attach").push(ret);
 //                }
 //              }
 //              return create_attach_template_remote(this.local_file, ret, murl, comment, new_comment, this.lthumb, this.lthumb_url);
 //            }
 //          }
 //        }));
 //      }
 //      return results;
 //    }
 //  });
  Breeze.attach_onedrive = function(data) {
    var odOptions;
    odOptions = {
      clientId: '9cbdd389-e953-49a2-850d-381a9b9f19dc',
      action: 'share',
      multiSelect: true,
      openInNewWindow: true,
      advanced: {
        redirectUri: Breeze.developement() ? "http://localhost:3000/profile" : "https://app.breeze.pm/profile"
      },
      success: function(files) {
        var card, comment, new_comment, project, project_files;
        project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
        card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
        comment = Breeze.temp_comment;
        new_comment = Breeze.temp_new_comment;
        project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in');
        $.each(files.value, function(index, file) {
          var thumb, thumb_url, url;
          thumb_url = "";
          thumb = false;
          url = "";
          if (comment) {
            url = '/projects/' + project + '/cards/' + card + '/comments/' + comment + '/attachments';
          } else if (new_comment) {
            url = '/projects/' + project + '/cards/' + card + '/comments/0/attachments';
          } else if (project_files) {
            url = '/projects/' + project + '/attachments';
          } else {
            url = '/projects/' + project + '/cards/' + card + '/attachments';
          }
          return $.ajax({
            type: 'POST',
            url: url,
            queue: true,
            data: {
              attachment: {
                name: file.name,
                url: file.webUrl,
                thumb_url: thumb_url,
                thumb: thumb,
                embeded: Breeze.froala_upload
              }
            },
            success: function(ret) {
              if (Breeze.froala_upload) {
                if (project_files) {
                  ret = $(ret).attr('data-attach-id');
                }
                return $("#editor-element").froalaEditor('file.insert', file.webUrl, file.name, {
                  'attach-id': ret,
                  'project-id': project,
                  'comment-id': comment,
                  'attach-card-id': card
                });
              } else if (project_files) {
                $('.project-files-empty').remove();
                return $('.files-body').prepend(ret);
              } else {
                url = url + "/" + ret;
                if (new_comment) {
                  if ($(".comment-editor").length > 0) {
                    $(".comment-editor").data("attach").push(ret);
                  }
                }
                file.url = file.webUrl;
                return create_attach_template_remote(file, ret, url, comment, new_comment, thumb, thumb_url);
              }
            }
          });
        });
      },
      cancel: function() {},
      error: function(e) {}
    };
    Breeze.temp_new_comment = $(".comment-editor").data("new-comment");
    if (!Breeze.temp_new_comment) {
      Breeze.temp_comment = $("#editor-element").closest('.comment-box').attr('data-comment-id');
    }
    if (typeof OneDrive === "undefined") {
      return $.getScript("https://js.live.net/v7.2/OneDrive.js", function(data, textStatus, jqxhr) {
        return OneDrive.open(odOptions);
      });
    } else {
      return OneDrive.open(odOptions);
    }
  };
  $(document).on("click", ".attach-onedrive", function(e, data) {
    Breeze.attach_onedrive(data);
    return false;
  });
  Breeze.attach_boxnet = function(data) {
    if (data) {
      Breeze.froala_upload = true;
    } else {
      Breeze.froala_upload = false;
    }
    Breeze.temp_new_comment = $(".comment-editor").data("new-comment");
    if (!Breeze.temp_new_comment) {
      Breeze.temp_comment = $("#editor-element").closest('.comment-box').attr('data-comment-id');
    }
    if (typeof BoxSelect === "undefined") {
      $.getScript("https://app.box.com/js/static/select.js", function(data, textStatus, jqxhr) {
        // console.log("ddad");
      });
    }
    return boxSelect.launchPopup();
  };
  $(document).on("click", ".attach-boxnet", function(e, data) {
    Breeze.attach_boxnet(data);
    return false;
  });
  onAuthApiLoad = function() {
    gapi.auth.authorize({
      client_id: Breeze.google.clientId,
      scope: ["https://www.googleapis.com/auth/drive.file", "https://www.googleapis.com/auth/userinfo.email"],
      immediate: true,
      authuser: !Breeze.google.oauthToken ? -1 : void 0,
      prompt: !Breeze.google.oauthToken ? 'select_account' : void 0
    }, handleAuthResult);
  };
  handleAuthResult = function(authResult) {
    var card_popover, el, err, placement;
    if (authResult && !authResult.error) {
      Breeze.google.oauthToken = gapi.auth.getToken();
      try {
        localStorage.setItem("bgoauthtoken", JSON.stringify(gapi.auth.getToken()));
      } catch (_error) {
        err = _error;
      }
      createPicker();
    } else {
      if (!(authResult && authResult.error && authResult.error === "popup_closed_by_user")) {
        $('#google-connect').remove();
        card_popover = $('<div> <div> <p> <b>Connect your Google account</b> Before you can attach Google Docs you need to connect your account. </p> <p class="submit"><button data-action="create" class="btn">Yes, connect my Google account</button> or <a data-action="cancel" href="#">Cancel</a></p> </div> </div>');
        if (Breeze.froala_upload) {
          el = $('.fr-toolbar');
          placement = "top";
        } else if ($('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in')) {
          el = $('.project-files-add-menu');
          placement = "bottom";
        } else if ($('.public-project').length > 0) {
          el = $(".fr-toolbar");
          placement = "bottom";
        } else {
          el = $(".element-files");
          placement = "topover";
          $(".popover").remove();
          $('.card-side .element-files').removeData('bs.popover');
        }
        el.popover({
          trigger: "manual",
          placement: placement,
          template: '<div class="popover" id="google-connect"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
          html: true,
          content: card_popover.html()
        });
        el.popover("show");
        $('#google-connect').on("click", '[data-action="cancel"]', function() {
          $('#google-connect').remove();
          return false;
        });
        $('#google-connect').on("click", '[data-action="create"]', function() {
          $('#google-connect').remove();
          gapi.auth.authorize({
            client_id: Breeze.google.clientId,
            scope: ["https://www.googleapis.com/auth/drive.file", "https://www.googleapis.com/auth/userinfo.email"],
            immediate: false,
            authuser: !Breeze.google.oauthToken ? -1 : void 0,
            prompt: !Breeze.google.oauthToken ? 'select_account' : void 0
          }, handleAuthResult);
          return false;
        });
      }
    }
  };
  createPicker = function() {
    var el, picker;
    if (Breeze.google.oauthToken != null) {
      picker = new google.picker.PickerBuilder().setAppId(Breeze.google.appId).addView(new google.picker.DocsView().setIncludeFolders(true)).addView(new google.picker.DocsView().setIncludeFolders(true).setEnableTeamDrives(true)).setOAuthToken(gapi.auth.getToken().access_token).setDeveloperKey(Breeze.google.developerKey).addView(new google.picker.DocsUploadView()).enableFeature(google.picker.Feature.MULTISELECT_ENABLED).enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES).setCallback(pickerCallback).build();
      picker.setVisible(true);
    }
    el = '<div class="picker_account_switcher"> <a data-behavior="google_account_switcher" href="#">Sign out and use a different Google account</a> </div>';
    $('.picker.picker-dialog').append($(el));
    return $('.picker_account_switcher').on("click", '[data-behavior~=google_account_switcher]', function() {
      var err;
      gapi.auth.setToken(null);
      Breeze.google.oauthToken = null;
      $(".picker").remove();
      try {
        localStorage.removeItem("bgoauthtoken");
      } catch (_error) {
        err = _error;
      }
      return false;
    });
  };
  pickerCallback = function(data) {
    var card, comment, new_comment, project, project_files;
    if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
      project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
      card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
      comment = Breeze.temp_comment;
      new_comment = Breeze.temp_new_comment;
      project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in');
      return $.each(data[google.picker.Response.DOCUMENTS], function(index, doc) {
        var thumb, thumb_url, url;
        thumb_url = "";
        thumb = false;
        url = "";
        if (comment) {
          url = '/projects/' + project + '/cards/' + card + '/comments/' + comment + '/attachments';
        } else if (new_comment) {
          url = '/projects/' + project + '/cards/' + card + '/comments/0/attachments';
        } else if (project_files) {
          url = '/projects/' + project + '/attachments';
        } else {
          url = '/projects/' + project + '/cards/' + card + '/attachments';
        }
        return $.ajax({
          type: 'POST',
          url: url,
          queue: true,
          data: {
            attachment: {
              name: doc[google.picker.Document.NAME],
              url: doc[google.picker.Document.URL],
              thumb_url: thumb_url,
              thumb: thumb,
              embeded: Breeze.froala_upload
            }
          },
          success: function(ret) {
            if (Breeze.froala_upload) {
              if (project_files) {
                ret = $(ret).attr('data-attach-id');
              }
              return $("#editor-element").froalaEditor('file.insert', doc[google.picker.Document.URL], doc[google.picker.Document.NAME], {
                'attach-id': ret,
                'project-id': project,
                'comment-id': comment,
                'attach-card-id': card
              });
            } else if (project_files) {
              $('.project-files-empty').remove();
              return $('.files-body').prepend(ret);
            } else {
              url = url + "/" + ret;
              if (new_comment) {
                if ($(".comment-editor").length > 0) {
                  $(".comment-editor").data("attach").push(ret);
                }
              }
              return create_attach_template_remote(doc, ret, url, comment, new_comment, thumb, thumb_url);
            }
          }
        });
      });
    }
  };
  $(document).on("change", "#froala-upload-file", function(e, f) {
    var file, i, len, ref, toolbar_manual, types;
    types = /^image\/(gif|jpeg|jpg|png|apng|svg)$/;
    Breeze.froala_upload_files = e.target.files.length;
    ref = e.target.files;
    for (i = 0, len = ref.length; i < len; i++) {
      file = ref[i];
      if (types.test(file.type.toLowerCase())) {
        $("#editor-element").froalaEditor('image.upload', [file]);
      } else {
        $("#editor-element").froalaEditor('file.upload', [file], toolbar_manual = true);
      }
    }
    $('#froala-upload-form')[0].reset();
    return false;
  });
  dragTimer = void 0;
  return get_content_type = function(name) {
    var exts;
    exts = {
      evy: "application/envoy",
      fif: "application/fractals",
      spl: "application/futuresplash",
      hta: "application/hta",
      acx: "application/internet-property-stream",
      hqx: "application/mac-binhex40",
      doc: "application/msword",
      dot: "application/msword",
      bin: "application/octet-stream",
      "class": "application/octet-stream",
      dms: "application/octet-stream",
      exe: "application/octet-stream",
      lha: "application/octet-stream",
      lzh: "application/octet-stream",
      oda: "application/oda",
      axs: "application/olescript",
      pdf: "application/pdf",
      prf: "application/pics-rules",
      p10: "application/pkcs10",
      crl: "application/pkix-crl",
      ai: "application/postscript",
      eps: "application/postscript",
      ps: "application/postscript",
      rtf: "application/rtf",
      xla: "application/vnd.ms-excel",
      xlc: "application/vnd.ms-excel",
      xlm: "application/vnd.ms-excel",
      xls: "application/vnd.ms-excel",
      xlt: "application/vnd.ms-excel",
      xlw: "application/vnd.ms-excel",
      msg: "application/vnd.ms-outlook",
      sst: "application/vnd.ms-pkicertstore",
      cat: "application/vnd.ms-pkiseccat",
      stl: "application/vnd.ms-pkistl",
      pot: "application/vnd.ms-powerpoint",
      pps: "application/vnd.ms-powerpoint",
      ppt: "application/vnd.ms-powerpoint",
      mpp: "application/vnd.ms-project",
      wcm: "application/vnd.ms-works",
      wdb: "application/vnd.ms-works",
      wks: "application/vnd.ms-works",
      wps: "application/vnd.ms-works",
      hlp: "application/winhlp",
      bcpio: "application/x-bcpio",
      cdf: "application/x-cdf",
      z: "application/x-compress",
      tgz: "application/x-compressed",
      cpio: "application/x-cpio",
      csh: "application/x-csh",
      dcr: "application/x-director",
      dir: "application/x-director",
      dxr: "application/x-director",
      dvi: "application/x-dvi",
      gtar: "application/x-gtar",
      gz: "application/x-gzip",
      hdf: "application/x-hdf",
      iii: "application/x-iphone",
      js: "application/x-javascript",
      latex: "application/x-latex",
      mdb: "application/x-msaccess",
      crd: "application/x-mscardfile",
      clp: "application/x-msclip",
      dll: "application/x-msdownload",
      m13: "application/x-msmediaview",
      m14: "application/x-msmediaview",
      mvb: "application/x-msmediaview",
      wmf: "application/x-msmetafile",
      mny: "application/x-msmoney",
      pub: "application/x-mspublisher",
      scd: "application/x-msschedule",
      trm: "application/x-msterminal",
      wri: "application/x-mswrite",
      nc: "application/x-netcdf",
      pma: "application/x-perfmon",
      pmc: "application/x-perfmon",
      pml: "application/x-perfmon",
      pmr: "application/x-perfmon",
      pmw: "application/x-perfmon",
      p12: "application/x-pkcs12",
      pfx: "application/x-pkcs12",
      p7b: "application/x-pkcs7-certificates",
      spc: "application/x-pkcs7-certificates",
      p7r: "application/x-pkcs7-certreqresp",
      p7c: "application/x-pkcs7-mime",
      p7m: "application/x-pkcs7-mime",
      p7s: "application/x-pkcs7-signature",
      sh: "application/x-sh",
      shar: "application/x-shar",
      swf: "application/x-shockwave-flash",
      sit: "application/x-stuffit",
      sv4cpio: "application/x-sv4cpio",
      sv4crc: "application/x-sv4crc",
      tar: "application/x-tar",
      tcl: "application/x-tcl",
      tex: "application/x-tex",
      texi: "application/x-texinfo",
      texinfo: "application/x-texinfo",
      roff: "application/x-troff",
      t: "application/x-troff",
      tr: "application/x-troff",
      man: "application/x-troff-man",
      me: "application/x-troff-me",
      ms: "application/x-troff-ms",
      ustar: "application/x-ustar",
      src: "application/x-wais-source",
      cer: "application/x-x509-ca-cert",
      crt: "application/x-x509-ca-cert",
      der: "application/x-x509-ca-cert",
      pko: "application/ynd.ms-pkipko",
      zip: "application/zip",
      au: "audio/basic",
      snd: "audio/basic",
      mid: "audio/mid",
      rmi: "audio/mid",
      mp3: "audio/mpeg",
      aif: "audio/x-aiff",
      aifc: "audio/x-aiff",
      aiff: "audio/x-aiff",
      m3u: "audio/x-mpegurl",
      ra: "audio/x-pn-realaudio",
      ram: "audio/x-pn-realaudio",
      wav: "audio/x-wav",
      bmp: "image/bmp",
      cod: "image/cis-cod",
      gif: "image/gif",
      ief: "image/ief",
      jpe: "image/jpeg",
      jpeg: "image/jpeg",
      jpg: "image/jpeg",
      png: "image/png",
      jfif: "image/pipeg",
      svg: "image/svg+xml",
      tif: "image/tiff",
      tiff: "image/tiff",
      ras: "image/x-cmu-raster",
      cmx: "image/x-cmx",
      ico: "image/x-icon",
      pnm: "image/x-portable-anymap",
      pbm: "image/x-portable-bitmap",
      pgm: "image/x-portable-graymap",
      ppm: "image/x-portable-pixmap",
      rgb: "image/x-rgb",
      xbm: "image/x-xbitmap",
      xpm: "image/x-xpixmap",
      xwd: "image/x-xwindowdump",
      mht: "message/rfc822",
      mhtml: "message/rfc822",
      nws: "message/rfc822",
      css: "text/css",
      htm: "text/plain",
      html: "text/plain",
      stm: "text/plain",
      uls: "text/iuls",
      bas: "text/plain",
      c: "text/plain",
      h: "text/plain",
      txt: "text/plain",
      rtx: "text/richtext",
      sct: "text/scriptlet",
      tsv: "text/tab-separated-values",
      htt: "text/webviewhtml",
      htc: "text/x-component",
      etx: "text/x-setext",
      vcf: "text/x-vcard",
      mp2: "video/mpeg",
      mpa: "video/mpeg",
      mpe: "video/mpeg",
      mpeg: "video/mpeg",
      mpg: "video/mpeg",
      mpv2: "video/mpeg",
      mov: "video/quicktime",
      qt: "video/quicktime",
      lsf: "video/x-la-asf",
      lsx: "video/x-la-asf",
      asf: "video/x-ms-asf",
      asr: "video/x-ms-asf",
      asx: "video/x-ms-asf",
      avi: "video/x-msvideo",
      movie: "video/x-sgi-movie",
      flr: "x-world/x-vrml",
      vrml: "x-world/x-vrml",
      wrl: "x-world/x-vrml",
      wrz: "x-world/x-vrml",
      xaf: "x-world/x-vrml",
      xof: "x-world/x-vrml"
    };
    return exts[name] != null ? exts[name] : exts[name] = "application/octet-stream";
  };
});

window.Breeze = Breeze || {};

Breeze.google = {}
Breeze.google.developerKey = "AIzaSyC2pMMIRX8MCsUs84dBtrp4OSK70voomOY"
Breeze.google.clientId = "406151822530"
Breeze.google.appId = "406151822530"

Breeze.init_fileupload = function(el) {
	
  if (el == null) {
    el = $("#fileupload");
  }
  try {
    $('#fileupload').fileupload('destroy');
  } catch (_error) {}
  return el.fileupload({
    autoUpload: true,
    pasteZone: void 0,
    dropZone: $('.dropzone'),
    add: function(e, data) {
      var base, jqXHR, project_templ, upload_el;
			if ($('.public-project').length > 0) {
			  return false;
			}

			if ($('.upload-disabled').length > 0) {
			  return false;
			}

			if (e.delegatedEvent && e.delegatedEvent.delegateTarget && $(e.delegatedEvent.delegateTarget).hasClass('card') && ((Breeze.foundDropzone != null) && Breeze.foundDropzone.hasClass('card'))) {
			  return false;
			}

			if (e.delegatedEvent && e.delegatedEvent.delegateTarget && $(e.delegatedEvent.delegateTarget).hasClass('comment-box') && ((Breeze.foundDropzone != null) && Breeze.foundDropzone.hasClass('comment-box'))) {
			  return false;
			}

			if ((Breeze.foundDropzone != null) && Breeze.foundDropzone.hasClass("card") && ($('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0)) {
			  if ((Breeze.projects == null) || (Breeze.projects[Breeze.foundDropzone.attr("data-project-id")] == null)) {
			    Breeze.load_card_globals(Breeze.foundDropzone.attr("data-card-id"), Breeze.foundDropzone.attr("data-project-id"), function() {
			      if (Breeze.project_file_storage === "google") {
			        Breeze.upload_gdrive(e, data);
			        return false;
			      }
			      add_upload_files(e, data);
			    });
			  }
			} else {
			  if (Breeze.project_file_storage === "google") {
			    Breeze.upload_gdrive(e, data);
			    return false;
			  }
			  add_upload_files(e, data);
			}

    },
    progress: function(e, data) {
      var progress;
      progress = parseInt(data.loaded / data.total * 100, 10);
      if (data.project_files && data.manual_upload) {
        return data.context.filter('.upload-progress-box[data-pid="' + data.upload_pid + '"]').find('.upload-progres').css('width', progress + "%");
      } else {
        return data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"] .upload-progres').css('width', progress + "%");
      }
    },
    fail: function(e, data) {
      var jqXHR;
      if (data.attempt < 2 && data.jqXHR.statusText !== "abort") {
        data.attempt++;
        return jqXHR = data.submit();
      } else {
        remove_upload_overlay(data.context, data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"]'));
        if (data.manual_upload) {
          data.context.remove();
        }
        if (data.jqXHR.statusText !== "abort") {
          $.flashMessage("<span class='red-text'>Failed to upload file. Please refresh your browser window. </span> <span>" + data.textStatus + "</span> <span>" + data.errorThrown + "</span>", true);
          Bugsnag.notify('Attach upload failed', 'Response: ' + data.jqXHR.responseText + ' Code:' + data.jqXHR.status + ' Status:' + data.jqXHR.statusText + ' KEY: ' + data.key + ' BUCKET:' + data.bucket + ' FILES:' + JSON.stringify(data.files) + ' Error:' + data.errorThrown, {}, 'error');
          Breeze.get_s3form();
        }
        return $('.comment-editor input[type="submit"], .card-editor input[type="submit"]').removeAttr('disabled');
      }
    },
    done: function(event, data) {
      var s3name, url;
      s3name = $(data.result).find("Key").text();
			

      if (s3name != null) {
        s3name = encodeURIComponent(s3name.substring(data.key.length));
      }
      url = "";
      if (data.comment) {
        url = '/projects/' + data.project + '/cards/' + data.card + '/comments/' + data.comment + '/attachments';
      } else if (data.new_comment) {
        url = '/projects/' + data.project + '/cards/' + data.card + '/comments/0/attachments';
      } else if (data.project_files) {
        url = '/projects/' + data.project + '/attachments';
      } else {
        url = '/projects/' + data.project + '/cards/' + data.card + '/attachments';
      }
      return $.ajax({
        type: 'POST',
        url: url,
        queue: true,
        data: {
          attachment: {
            name: data.files[0].name,
            s3name: s3name,
            key: data.key,
            bucket: data.bucket
          }
        },
        success: function(ret) {
          var efname;
          url = url + "/" + ret;
          efname = s3name != null ? s3name.replace(/\+/g, '%2B') : encodeURIComponent(data.files[0].name);
          if (data.manual_upload || data.context.find('.wysi-placeholder').length > 0) {
            $('.upload-progress-box[data-pid="' + data.upload_pid + '"]').remove();
          } else {
            remove_upload_overlay(data.context, data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"]'));
          }
          if (data.project_files) {
            $('.project-files-empty').remove();
            return $('.files-body').prepend(ret);
          } else {
            if (data.context.find('.wysi-placeholder').length > 0) {
              data.context.find('.upload-progress').append(data.context.find('.upload-progress-box[data-pid="' + data.upload_pid + '"]'));
              if (data.context.find('.upload-overlay .upload-progress-box').length <= 0) {
                data.context.find('.upload-overlay-placeholder').css('display', '');
                data.context.removeClass('hover in');
              }
            }
            if (data.new_comment) {
              if ($(".comment-editor").length > 0) {
                $(".comment-editor").data("attach").push(ret);
              }
            }
            return create_attach_template(data.project, data.card, data, ret, url, data.comment, data.new_comment, efname, data.modal_task);
          }
        },
        complete: function() {
          return $('.comment-editor input[type="submit"], .card-editor input[type="submit"]').removeAttr('disabled');
        }
      });
    }
  });
};



add_upload_files = function(e, data) {
  var base, jqXHR, project_templ, s3form_date, time_now, upload_el;
  time_now = Math.round((Date.now()) / 1000);
  s3form_date = parseInt($('#fileupload').attr('data-created-at'), 10);
  if ((time_now - s3form_date) >= 3600) {
    Breeze.get_s3form(false);
  }
  upload_el = $('<div class="upload-progress-box"> <div class="upload-file"><span class="upload-name">Filename here </span><span class="upload-cancel">&times;</span></div> <div class="upload-progres-container"><div class="upload-progres"></div></div> </div>');
  if (Breeze.foundDropzone != null) {
    if (Breeze.foundDropzone.hasClass("sortable_stage")) {
      return false;
    }
    if (Breeze.foundDropzone.hasClass("card")) {
      data.project = Breeze.foundDropzone.attr("data-project-id");
      data.card = Breeze.foundDropzone.attr("data-card-id");
    } else {
      data.project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
      data.card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
    }
    data.comment = Breeze.foundDropzone.attr("data-comment-id");
    data.modal_task = Breeze.foundDropzone.hasClass("modal-body");
    data.new_comment = Breeze.foundDropzone.hasClass("new-comment-box") || Breeze.foundDropzone.hasClass("comment-editor");
    data.project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in') || $('.roadmap-project-modal').hasClass('in') || $('#project-notes-modal').hasClass('show');
  } else {
    data.manual_upload = true;
    data.modal_task = true;
    data.project = $('#card_modal').data('current_project') || $('.public-card .card-content').attr('data-project-id') || Breeze.project;
    data.card = $('#card_modal').data('current_card') || $('.public-card .card-content').attr('data-card-id');
    data.comment = Breeze.comment_box_trigger;
    data.new_comment = $(".comment-editor").data("new-comment");
    data.project_files = $('#project-attachments-modal').hasClass('in') || $('#project-description-modal').hasClass('in') || $('.roadmap-project-modal').hasClass('in') || $('#project-notes-modal').hasClass('show');
  }
  data.upload_pid = "u" + uniqueId();
  upload_el.attr('data-pid', data.upload_pid);
  upload_el.find('.upload-name').html(data.files[0].name);
	
  if (Breeze.foundDropzone != null) {
    Breeze.foundDropzone.find('.upload-overlay-placeholder').hide();
    if (data.modal_task || data.project_files) {
      Breeze.foundDropzone.find('.upload-overlay-container').append(upload_el);
      Breeze.foundDropzone.addClass('in loading-progres');
    } else {
      Breeze.foundDropzone.find('.upload-overlay').append(upload_el);
      if (Breeze.foundDropzone.find('.upload-overlay').css('min-height') === "0px") {
        Breeze.foundDropzone.find('.upload-overlay').css('min-height', Breeze.foundDropzone.height() - 2);
        Breeze.foundDropzone.find('.drop-shadow').css('min-height', Breeze.foundDropzone.find('.drop-shadow').height());
      }
      Breeze.foundDropzone.addClass('in');
      if ((Breeze.foundDropzone.find('.upload-overlay').height()) > Breeze.foundDropzone.height()) {
        Breeze.foundDropzone.find('.drop-shadow').height(Breeze.foundDropzone.find('.upload-overlay').height() - 5);
      }
    }
    data.context = Breeze.foundDropzone;
  } else {
    if (data.project_files) {
      project_templ = $('.cell.upload-progress-box:not(.used)').clone().removeClass('hide').addClass("used");
      project_templ.attr('data-pid', data.upload_pid);
      project_templ.find('.upload-name').html(data.files[0].name);
      $('.files-body').prepend(project_templ);
      data.context = $('.files-body').find('.cell.upload-progress-box[data-pid="' + data.upload_pid + '"]');
    } else {
      $(".upload-progress").append(upload_el);
      data.context = $('.upload-progress');
    }
  }
  $('.comment-editor input[type="submit"], .card-editor input[type="submit"]').attr('disabled', 'disabled');
	
	var file_type;

	file_type = data.files[0].type;

	if (typeof file_type !== 'undefined' && file_type === 'text/html') {
	  file_type = 'text/plain';
	}

	$('#fileupload input[name="Content-Type"]').val(file_type != null ? file_type : file_type = get_content_type(data.files[0].name.split(".").pop()));

	
	
  // $("#Content-Type").val((base = data.files[0]).type != null ? base.type : base.type = get_content_type(data.files[0].name.split(".").pop()));
  data.key = generate_s3_key();
  $('#fileupload input[name="key"]').remove();
  $('#fileupload').append('<input type="hidden" name="key" value="' + data.key + '${filename}"/>');
  data.bucket = $("#fileupload").attr("data-bucket");
  data.attempt = 0;
  jqXHR = data.submit();
  upload_el.data("jqXHR", jqXHR);
	
};





create_attach_template = function(project, card, data, id, url, comment, new_comment, ename, modal_task) {
	var attach_count, attach_element, box, hide_pin, kala, list, types;

	types = /^image\/(gif|jpeg|jpg|png)$/;

	if (comment || new_comment) {
	  hide_pin = "hide";
	}

	if (types.test(data.files[0].type)) {
	  box = $('<span class="attach-box" data-attach-id="' + id + '"> <a href="' + url + '" data-method="delete" data-remote="true" rel="nofollow" class="attach-delete action" data-owner="true">Delete</a> <a href="' + url + '/pinit?cover=true" data-remote="true" rel="nofollow" class="attach-pin action ' + hide_pin + '" data-cover="false">Pin it</a> <a href="https://' + data.bucket + '.s3.amazonaws.com/' + data.key + ename + '" target="_blank" class="attach-image"> <img src="https://' + data.bucket + '.s3.amazonaws.com/' + data.key + ename + '" class="s3_img"> </a> <a href="https://' + data.bucket + '.s3.amazonaws.com/' + data.key + ename + '" target="_blank" class="name">' + data.files[0].name + '</a> </span>');
	  if (comment) {
	    $('[data-comment-id="' + comment + '"]').find(".boxes").append(box);
	  } else if (new_comment) {
	    $(".new_attachments .boxes").append(box);
	  } else if (modal_task) {
	    kala = $(".cards .boxes").first().append(box);
	    box.find("[data-cover]").trigger("click");
	  } else {
	    $('.card[data-card-id="' + data.card + '"] .card_attachments .boxes').first().append(box);
	    box.find("[data-cover]").trigger("click");
	  }
	} else {
	  list = $('<div class="attach-list" data-attach-id="' + id + '"> <a href="' + url + '" data-method="delete" data-remote="true" rel="nofollow" class="attach-delete action" data-owner="true"><i class="icon-remove pull-left" title="delete attachment" style=""></i></a> <i class="iconf-file-o" title="' + data.files[0].name + '"></i> <a href="https://' + data.bucket + '.s3.amazonaws.com/' + data.key + ename + '" target="_blank" class="name">' + data.files[0].name + '</a> </div>');
	  if (comment) {
	    $('[data-comment-id="' + comment + '"]').find(".attachments").append(list);
	  } else if (new_comment) {
	    $(".new_attachments").append(list);
	  } else if (modal_task) {
	    $(".cards.attachments").first().append(list);
	  } else {
	    $('.card[data-card-id="' + data.card + '"] .card_attachments').append(list);
	  }
	}

	if (!(comment || new_comment)) {
	  attach_element = $('.card[data-card-id="' + card + '"]').find('.attach-bubble span');
	  if (attach_element.length) {
	    attach_count = parseInt(attach_element.html());
	    attach_element.html(attach_count + 1);
	  } else {
	    $('.card[data-card-id="' + card + '"]').find('.badges .card-attach-bubble-container').append('<span class="attach-bubble"> <i class="icon-attachment" title="attachments"></i> <span>1</span> </span>');
	  }
	}


	update_project_list_view_columns("attach")
};

remove_upload_overlay = function(element, upload_box) {
  return upload_box.fadeOut(function() {
    element.find('.drop-shadow').height(element.find('.drop-shadow').height() - $(this).height());
    $(this).remove();
    if (element.find('.upload-progress-box').length <= 0 || (element.hasClass('modal-body') && element.find('.modal-task .upload-progress-box').length <= 0)) {
      element.find('.card_box').css('min-height', '');
      element.find('.upload-overlay').css('min-height', '');
      element.find('.drop-shadow').css('min-height', '').css('height', '');
      element.find('.upload-overlay-placeholder').css('display', '');
      return element.removeClass('hover in loading-progres');
    }
  });
};

Breeze.get_s3form = function() {
  $.ajax({
    type: 'GET',
    url: '/s3form',
    success: function(ret) {
      var el;
      el = $('<div>' + ret + '</div>').find("#fileupload");
      if (el.length > 0) {
        $('#fileupload').replaceWith(el);
        return Breeze.init_fileupload();
      }
    }
  });
  return true;
};

