import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

var init_todo_list_menu, todo_list_remove;

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.public-card').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_todo_list_menu();
  }
  return $(document).on("breeze:page", function(e, page) {
    if (page === "todo_lists") {
      return init_todo_list_menu();
    }
  });
});

init_todo_list_menu = function() {
  $('#card_modal').on("click", '.todo-lists .more-icon', function() {
    var el, popover_html;
    el = $(this).parent();
    $('#todo-menu').parent().remove();
    $('#todo-list-menu').parent().remove();
    if ($('#todo-template-menu').length > 0) {
      el.popover("hide");
      el.removeData("bs.popover");
      $('#todo-template-menu').remove();
    } else {
      el.removeData("bs.popover");
      $('#todo-template-menu').remove();
      popover_html = $('<div> <ul class="dropdown-menu"> <li><a href="#" data-action="template">Copy to-do list from ...</a></li> </ul> </div>');
      el.popover({
        trigger: "manual",
        container: "element",
        placement: "bottomleft",
        template: '<div class="popover todo-menu" id="todo-template-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
        html: true,
        content: popover_html.html()
      });
      el.popover("show");
      $('#todo-template-menu').on("click", '[data-action="template"]', function() {
        var els, popover_content, url;
        $('.card-attributes-todos').popover("hide");
        $('.card-attributes-todos').removeData("bs.popover");
        els = $('.card-attributes-todos');
        popover_content = '<div class="empty-popover">' + Breeze.global_loader + '</div>';
        if (Breeze.developement()) {
          url = ("https://app.breeze.test:3006/projects/" + Breeze.Card.project + "/todos?auth_token=") + Breeze.current_user.token;
        } else {
          url = ("https://breeze-node-n2.herokuapp.com/projects/" + Breeze.Card.project + "/todos?auth_token=") + Breeze.current_user.token;
        }
        $.ajax({
          type: 'GET',
          queue: true,
          url: url,
          success: function(data) {
            $('.copy-todos-popover .popover-content').html(data);
            return $('.copy-todos-popover .popover-content select').multiselect({
              numberDisplayed: 1,
              nonSelectedText: "select to-do list",
              buttonClass: 'btn btn-white',
              nSelectedText: '',
              enableFiltering: true,
              enableCaseInsensitiveFiltering: true,
              multiple: false,
              includeSelectAllOption: false,
              unselect: false,
              onInitialized: function(select, container) {
                return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
              }
            });
          }
        });
        els.popover({
          container: "element",
          trigger: "manual",
          placement: "bottomleft",
          template: '<div class="popover copy-todos-popover edit-popover" id="copy-stage"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
          html: true,
          content: popover_content
        });
        els.popover("show");
        $('.copy-todos-popover').on("click", '.cancel', function() {
          $('.card-attributes-todos').popover("hide");
          $('.card-attributes-todos').removeData("bs.popover");
          return false;
        });
        $('.copy-todos-popover').on("click", 'button[type="submit"]', function() {
          var list, socket_params;
          list = $('.copy-todos-popover select').find(":selected");
          el = $(this);
          if (list.val() === "") {
            return false;
          }
          socket_params = {
            card: Breeze.Card.id,
            project: Breeze.project_token,
            method: "card-todo-list-copy",
            list: list.val()
          };
          $.ajax({
            type: 'PUT',
            queue: true,
            url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists/' + list.val() + '/copy',
            data: {
              old_card: list.attr('data-card')
            },
            beforeSend: function() {
              el.prop('disabled', true);
              return el.find('.progress-button').show();
            },
            success: function() {
              $('#card_modal .copy-todo').hide();
              $('#card_modal .todo-lists [data-action="copy"]').removeClass('open');
              socket_params.message = $('#card_modal .todos [data-type="container-list"][data-copy-id="' + list.val() + '"]').prop('outerHTML');
              if (Breeze.is_socket_connected()) {
                return Breeze.socket.emit("message", socket_params);
              }
            },
            complete: function() {
              el.prop('disabled', false);
              el.find('.progress-button').hide();
              $('.card-attributes-todos').popover("hide");
              return $('.card-attributes-todos').removeData("bs.popover");
            }
          });
          return false;
        });
        return false;
      });
    }
    return false;
  });
  $('#card_modal').on("click", '.todos .head .more-icon', function() {
    var el, marked, popover_html, todo_list, todo_list_id;
    el = $(this)
    todo_list = $(this).closest('[data-type="container-list"]');
    todo_list_id = todo_list.attr('data-id');
    if (todo_list.attr('data-template') === "true") {
      marked = "active";
    } else {
      marked = "";
    }
		$('#card_modal .todo-items .fa-ellipsis-v').css('visibility','')
    $('#todo-menu').parent().remove();
    if ($('#todo-list-menu[data-todo-id="' + todo_list_id + '"]').length > 0) {
      el.popover("hide");
      el.removeData("bs.popover");
      $('#todo-list-menu').parent().remove();
    } else {
      el.removeData("bs.popover");
			el.css('visibility','visible')
      $('#todo-list-menu').parent().remove();
      popover_html = $('<div> <ul class="dropdown-menu"><li><a href="#" data-action="template" class="' + marked + '">Mark as template</a></li> <li class="divider"><a href="#"></a></li> <li><a href="#" data-action="delete"><span class="fa fa-trash-o" aria-hidden="true"></span>Delete</a></li> </ul> </div>');
      el.popover({
        trigger: "manual",
        container: el.parent(),
        placement: "bottom",
        template: '<div class="popover todo-menu" ><div id="todo-list-menu" data-todo-id="' + todo_list_id + '" class="popover-inner"><div class="popover-body dropdown-check"><div></div></div></div></div>',
        html: true,
				sanitize: false,
        content: popover_html.html()
      });
      el.popover("show");
			
			var pop = $('.todo-menu [data-todo-id="'+todo_list_id+'"]').parent()
			pop.prependTo(pop.parent())
			
      $('#todo-list-menu').on("click", '[data-action="convert"]', function() {
        var card, convert, name, stage_id, swimlane, todo;
        todo = $(this).closest('[data-type="container-list"]');
        card = $('.card[data-card-id="' + Breeze.Card.id + '"]');
        swimlane = card.closest('.sortable_stage');
        stage_id = card.attr('data-stage-id');
        todo_list_id = todo.attr('data-id');
        name = todo.find('[data-type="item-head"]').html();
        Breeze.create_new_task(stage_id, swimlane, false, name, null, null, true, todo_list_id);
        todo_list_remove($(this), convert = true);
        return false;
      });
      $('#todo-list-menu').on("click", '[data-action="template"]', function() {
        var card, template, todo;
        todo = $(this).closest('[data-type="container-list"]');
        card = $('.card[data-card-id="' + Breeze.Card.id + '"]');
        todo_list_id = todo.attr('data-id');
        template = todo.attr('data-template');
        if (template === "true") {
          template = false;
        } else {
          template = true;
        }
        todo.attr('data-template', template);
        $(this).toggleClass("active");
        $.ajax({
          type: 'PUT',
          queue: true,
          url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists/' + todo_list_id,
          data: {
            todo_list: {
              template: template
            }
          }
        });
        return false;
      });
      $('#todo-list-menu').on("click", '[data-action="delete"]', function() {
        todo_list_remove($(this));
        return false;
      });
    }
    return false;
  });
};

todo_list_remove = function(el, convert) {
  var c, e, socket_params, t, todo, todo_bubble;
  if (convert == null) {
    convert = false;
  }
  todo = el.closest('[data-type="container-list"]');
  todo.fadeOut(function() {
    return $(this).remove();
  });
  socket_params = {
    card: Breeze.Card.id,
    project: Breeze.project_token,
    method: "card-todo-list-delete",
    todo: todo.data("id"),
    message: "delete"
  };
  if (!convert) {
    $.ajax({
      type: 'DELETE',
      queue: true,
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/todo_lists/' + todo.data("id"),
      success: function(data) {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
  }
  todo_bubble = $('.card[data-card-id="' + Breeze.Card.id + '"]').find('.todos-bubble');
  e = todo_bubble.find("span").first();
  c = parseInt(e.html());
  e.html(c - todo.find('[data-behavior="toggle"]:checkbox:checked').length);
  t = todo_bubble.find("span").last();
  c = parseInt(t.html());
  t.html(c - todo.find('[data-behavior="toggle"]:checkbox').length);
  $('.card[data-card-id="' + Breeze.Card.id + '"] .card-todos [data-type="container-list"][data-id="' + todo.data("id") + '"]').remove();
	update_project_list_view_columns("todos")
	
};

// ---
// generated by coffee-script 1.9.2