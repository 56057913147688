var init_stages_automation, update_stage_card;
import {update_task_stripe} from 'mobile/utils/task_stripe.js';

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page, stage_id) {
    if (page === "stages_automation_popovers") {
      return init_stages_automation(stage_id);
    }
  });
  return $(document).on("breeze:page", function(e, page, card_id, stage_id, old_stage_id, card) {
    if (page === "update_stage_card") {
      return update_stage_card(card_id, stage_id, old_stage_id, card);
    }
  });
});

init_stages_automation = function(stage_id) {
  return $('#stage-automation-menu').on("click", ".status-select", function(event) {
    var card_popover, e, el, ele, i, len, ref, selected, stage_obj;
    el = $('#stage-automation-menu');
    if ($('#stage-sublist-menu').length > 0) {
      el.popover("hide");
      $('#stage-sublist-menu').remove();
    } else {
      el.removeData("bs.popover");
      $('#stage-sublist-menu').remove();
      stage_obj = _.find(Breeze.project_stages, function(s) {
        return s.id === parseInt(stage_id);
      });
      ele = '';
      ref = Breeze.project_statuses;
      for (i = 0, len = ref.length; i < len; i++) {
        e = ref[i];
        selected = '';
        if (stage_obj.actions.status.id && (String(e.id) === String(stage_obj.actions.status.id))) {
          selected = 'active';
        }
        ele += '<li><a href="#" data-status-id="' + e.id + '" data-status-color="' + e.color + '" class="mark ' + selected + '">' + e.name + '</a></li>';
      }
      selected = "";
      if (stage_obj.actions.status.status_clear) {
        selected = 'active';
      }
      card_popover = $('<div> <div class="popover-header"><i class="fa fa-angle-left" aria-hidden="true"></i><span class="title">Status</span>' + Breeze.global_close + '</div> <ul class="stage-actions-sublist dropdown-menu">' + ele + '</ul> <ul class="dropdown-menu dropdown-menu-footer"> <li class="clear-element"><a href="#" data-status-id="0" class="mark ' + selected + '">Clear status from task</a></li> </ul> </div>');
      el.popover({
        trigger: "manual",
        container: "element",
        placement: "bottomleft",
        template: '<div class="popover stage-automation-menu-sub" id="stage-sublist-menu"><div class="popover-inner"><div class="popover-content"><div></div></div></div></div>',
        html: true,
        content: card_popover.html()
      });
      $('#stage-automation-menu').hide();
      el.popover("show");
      $('#stage-sublist-menu').on("click", ".mark", function() {
        var active, socket_params;
        el = $(this);
        active = $(this).hasClass("active");
        $('#stage-sublist-menu .mark').removeClass("active");
        if (active) {
          $(this).removeClass("active");
        } else {
          $(this).addClass("active");
        }
        if ($('#stage-sublist-menu .mark.active').length <= 0) {
          stage_obj.actions.status.id = "";
          stage_obj.actions.status.name = "";
          stage_obj.actions.status.human = "";
          stage_obj.actions.status.color = "";
          stage_obj.actions.status.status_clear = false;
          $('#stage-automation-menu .status-select .selected-count').text("").removeClass("clear");
        } else if (el.attr("data-status-id") === "0") {
          stage_obj.actions.status.id = "";
          stage_obj.actions.status.name = "";
          stage_obj.actions.status.human = "";
          stage_obj.actions.status.color = "";
          stage_obj.actions.status.status_clear = true;
          $('#stage-automation-menu .status-select .selected-count').text("clear").addClass("clear");
        } else {
          stage_obj.actions.status.id = el.attr('data-status-id');
          stage_obj.actions.status.name = el.text();
          stage_obj.actions.status.human = el.text();
          stage_obj.actions.status.color = el.attr('data-status-color');
          stage_obj.actions.status.status_clear = false;
          $('#stage-automation-menu .status-select .selected-count').text(stage_obj.actions.status.human).removeClass("clear");
        }
        socket_params = {
          project: Breeze.project_token,
          method: "stage-update-js",
          message: Breeze.project_stages
        };
        $.ajax({
          type: "PUT",
          url: '/stages/' + stage_id,
          queue: true,
          data: {
            project_id: $("#card_project_id").val(),
            status_id: stage_obj.actions.status.id,
            status_name: stage_obj.actions.status.name,
            status_color: stage_obj.actions.status.color,
            status_clear: stage_obj.actions.status.status_clear
          },
          success: function() {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        return false;
      });
      $('#stage-sublist-menu').on("click", ".fa", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $('#stage-sublist-menu').remove();
        $('#stage-automation-menu').show();
        return false;
      });
      $('#stage-sublist-menu').on("click", "svg", function() {
        el.popover("hide");
        el.removeData('bs.popover');
        $(".stage-settings.dropdown").removeClass("open");
        $('#stage-sublist-menu').remove();
        $('#stage-automation-menu').remove();
        $('.popover').remove();
        return false;
      });
      $('#stage-sublist-menu').on("click", function(e) {
        return e.stopPropagation();
      });
    }
    return false;
  });
};

update_stage_card = function(card_id, stage_id, old_stage_id, card) {
  var old_stage_obj, ref, ref1, ref2, ref3, ref4, stage_obj;
  if (old_stage_id !== stage_id) {
    stage_obj = _.find(Breeze.project_stages, function(s) {
      return s.id === parseInt(stage_id);
    });
    old_stage_obj = _.find(Breeze.project_stages, function(s) {
      return s.id === parseInt(old_stage_id);
    });
    if (stage_obj) {
      if ((ref = stage_obj.actions) != null ? (ref1 = ref.status) != null ? ref1.status_clear : void 0 : void 0) {
        $('.card[data-card-id="' + card_id + '"]').attr('data-status-id', '');
        return $('.card[data-card-id="' + card_id + '"] .text-overlay').css('background-color', '').text('');
      } else if (((ref2 = stage_obj.actions) != null ? (ref3 = ref2.status) != null ? (ref4 = ref3.name) != null ? ref4.length : void 0 : void 0 : void 0) > 0) {
        $('.card[data-card-id="' + card_id + '"]').attr('data-status-id', stage_obj.actions.status.id);
        $('.card[data-card-id="' + card_id + '"] .text-overlay').css('background-color', stage_obj.actions.status.color).text(stage_obj.actions.status.name);
        if (Breeze.Card.id === card_id) {
           update_task_stripe();
        }
      }
    }
  }
};

// ---
// generated by coffee-script 1.9.2