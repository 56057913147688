import {developement} from 'mobile/utils.js';
import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

var init_subscribers, open_popover, update_card,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

var el;
$(document).ready(function() {

  $(document).on("breeze:page", function(e, page) {
    if (page === "card_page") {
      return init_subscribers();
    }
  });
  if ($('#card_modal').length > 0) {
    init_subscribers();
  }
});

function card_subscribers_add(subscribers, users) {
  var avatar_img, j, len, ref, u, user;
  if (subscribers.length > 0) {
    ref = _.uniq(subscribers);
    for (j = 0, len = ref.length; j < len; j++) {
      u = ref[j];
      user = _.where(users, {
        id: parseInt(u)
      })[0];
      if (user) {
        if ($('.card-owner-container .subsribed-user[data-user-id="' + user.id + '"]').length > 0) {
          return true;
        }
        avatar_img = $.gravatar(user.email, user.avatar, {
          size: 80,
          image: "blank"
        });
        if ($('.card-owner-container .subscribed-title').length <= 0) {
          $('.card-owner-container').append('<div class="subscribed-title"> Subscribed</div>');
        }
        $('.card-owner-container').append('<div class="subsribed-user" title="' + user.name + '" data-user-id="' + user.id + '" style="background-color:#' + user.color + ';">' + avatar_img + '<p class="member_initials">' + user.initials + '</p> </div>');
      }
    }
  }
};

Breeze.card_subscribers_remove = function(user) {
  $('.card-owner-container .subsribed-user[data-user-id="' + user + '"]').remove();
  if ($('.card-owner-container .subsribed-user').length <= 0) {
    return $('.card-owner-container .subscribed-title').remove();
  }
};

init_subscribers = function() {
  // if (!Breeze.current_user.observer) {
  //   $('.dashboard, .task-board, .project-board').on("click", '.card-subs-bubble-container', function(e) {
  //     var container, popover_container, subs, that;
  //     $('.card-quick-menu').removeClass("show");
  //     subs = $(this).closest('.card').attr('data-subscribed');
  //     Breeze.Card.subscribed = subs && subs.length > 0 && (JSON.parse(subs));
  //     Breeze.Card.id = $(this).closest('.card').attr('data-card-id');
  //     Breeze.Card.project = $(this).closest('.card').attr('data-project-id');
  //     that = $(this);
  //     container = $(this);
  //     popover_container = null;
  //     if ($('.project-list-view').length > 0) {
  //       popover_container = "stage";
  //     }
  //     if ((container.data("popover") != null) && $('#card-subscribe-menu').length > 0) {
  //       $('.popover').remove();
  //       container.removeData("bs.popover");
  //     } else {
  //       $('.popover').remove();
  //       $('.card-side .element-subscribe').removeData('bs.popover');
  //       $('.card-owner-container .subsribed-user').removeData('bs.popover');
  //       $('.card-subs-bubble-container').removeData('bs.popover');
  //       open_popover(that, Breeze.Card.subscribed);
  //     }
  //     e.stopPropagation();
  //     return false;
  //   });
  // }
  $('.element-menu .dropdown-menu').on("click", ".dropdown-item-subscribe", function() {
		// 	  $('#card_modal[data-readonly="false"]').on("click", ".element-menu .dropdown-menu .dropdown-item-subscribe ", function(e) {
		// 	console.log("0000000")
		// })
  // $('#card_modal[data-readonly="false"]').on("click", ".card-owner-container .subsribed-user, .element-menu .dropdown-menu .dropdown-item-subscribe ", function(e) {

		el = $('.element-menu')
		
		$('.dropdown-menu').removeClass('show')
		
    $(".popover:not(#card-subscribe-menu)").remove();
    if ($('#card-subscribe-menu').length > 0) {
      // $(this).popover("hide");
      el.popover("hide");
			
    } else {
       $('.element-menu').popover('dispose')
			
      $('.card-side .element-subscribe').removeData('bs.popover');
      $('.card-owner-container .subsribed-user').removeData('bs.popover');
      open_popover(el, Breeze.Card.subscribed);
    }
    return false;
  });
};

open_popover = function(that, selected_users) {
  var card_popover, placement, users_options;
  users_options = "";
  if (Breeze.project_users_groups_select != null) {
    users_options = $(Breeze.project_users_groups_select).html();
  }
  card_popover = $('<div><div class="fields-users-wrapper styled-multiselect"><select class="user-item-select fields-users-select"  multiple="multiple" name="items[]">' + users_options + '</select> </div> </div>	');
  if (Breeze.project_users_groups_select) {
    card_popover.find('option').each(function(i, e) {
      var ref;
      if (ref = parseInt($(e).attr("value")), indexOf.call(selected_users, ref) >= 0) {
        return $(e).attr("selected", true);
      } else {
        return $(e).removeAttr("selected");
      }
    });
  }
  if (that.hasClass('element-subscribe')) {
    placement = "bottomleft";
  } else {
    placement = "bottom";
  }
	

	
  that.popover({
    trigger: "manual",
    placement: placement,
    template: '<div class="popover element-popover card-actions"><div id="card-subscribe-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
    // template: '<div class="popover element-popover card-actions"><div id="card-assign-menu" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
    
		html: true,
		animation: false,
		container: el,
		sanitize: false,
    content: card_popover.html()
  });
  that.popover("show");
  Breeze.card_menu = true;
	
	$("#card-subscribe-menu").on("click", function(e) {
	  return false;
	});
	
  $('.user-item-select').multiselect({
    numberDisplayed: 0,
    includeSelectAllOption: true,
    nonSelectedText: "Item",
    buttonClass: 'btn btn-link item-btn',
    nSelectedText: '',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    filterPlaceholder: 'Search user',
    enableHTML: true,
    enableClickableOptGroups: true,
    selectAllValue: "all",
    onInitialized: function(select, container) {
      $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
			// $('#card-subscribe-menu .dropdown-toggle').trigger('click');
			
      var select_el, selected_values, url, wrapper_el;
      if (Breeze.project_users_groups_select == null) {
        select_el = $(this.$select);
        wrapper_el = $(this.$container);
        if ((select_el.find('option') != null) && select_el.find('option').length <= 0) {
          selected_values = selected_users;
          if ((selected_users == null) || selected_users.length <= 0) {
            selected_users = [""];
          }
          wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
          wrapper_el.find('.multiselect-filter').css('opacity', 0);
          wrapper_el.find('.options-wrapper').css('opacity', 0);
          if (developement()) {
            url = "https://app.breeze.test:3006/users/select_field?auth_token=" + Breeze.current_user.token;
          } else {
            url = "https://breeze-node-n3.herokuapp.com/users/select_field?auth_token=" + Breeze.current_user.token;
          }
          $.ajax({
            type: "POST",
            url: url,
            data: {
              selected_users: selected_users || [""],
              project_id: Breeze.Card.project
            },
            success: function(data) {
              Breeze.project_users_groups_select = $(data).html();
              if ((Breeze.projects != null) && (Breeze.projects[Breeze.Card.project] != null)) {
                Breeze.projects[Breeze.Card.project].project_users_groups_select = Breeze.project_users_groups_select;
              }
              select_el.append($(data).find("select").html());
              wrapper_el.find('.multiselect-filter').css('opacity', 1);
              wrapper_el.find('.options-wrapper').css('opacity', 1);
              select_el.multiselect('rebuild');
              wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
              select_el.parent().find("input[type='text'].multiselect-search").focus();
            }
          });
        }
      }
			
			
    },
    onChange: function() {
      update_card();
    },
    onDeselectAll: function() {
      update_card();
    },
    onSelectAll: function() {
      update_card();
    },
    onDropdownShown: function() {

    },
    onDropdownHide: function() {
      $(".popover").remove();
    },
    templates: {
      filter: '<li class="multiselect-item multiselect-filter"><div class="input-group"><span class="input-group-addons"><i class="icon-user"></i></span><input class="form-control multiselect-search" type="text" placeholder="Search user"><span class="input-group-btn"></span></div></li>'
    	
		}
  });
	

	

	
  $('#card-subscribe-menu .btn-group').addClass('show');
  $('#card-subscribe-menu .dropdown-menu').addClass('show');
	
  // $('#card-subscribe-menu input[type="checkbox"]:checked').parent().addClass('checkbox-on');
  if (!$('body').hasClass("ie11")) {
    $('#card-subscribe-menu input[type="text"]').focus();
  }
	

  return false;
};

update_card = function() {
  var avatar_img, j, lcard, len, ltoken, ref, ref1, u, url_host, user, user_ids;
  user_ids = _.uniq($('#card-subscribe-menu select').val()).map(function(e) {
    return parseInt(e) || [];
  });
  $('.card-owner-container .subsribed-user').remove();
  $('.card-owner-container .subscribed-title').remove();
  if (user_ids.length > 0) {
    ref = _.uniq(user_ids);
    for (j = 0, len = ref.length; j < len; j++) {
      u = ref[j];
      user = _.where(Breeze.all_team_users, {
        id: parseInt(u)
      })[0];
      if (user) {
        if ($('.card-owner-container .subsribed-user[data-user-id="' + user.id + '"]').length > 0) {
          return true;
        }
        avatar_img = $.gravatar(user.email, user.avatar_url, {
          size: 80,
          image: "blank"
        });
        if ($('.card-owner-container .subscribed-title').length <= 0) {
          $('.card-owner-container').append('<div class="subscribed-title"> Subscribed</div>');
        }
        $('.card-owner-container').append('<div class="subsribed-user" title="' + user.name + '" data-user-id="' + user.id + '" style="background-color:#' + user.color + ';">' + avatar_img + '<p class="member_initials">' + user.initials + '</p> </div>');
      }
    }
  }
  $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-subscribed', JSON.stringify(user_ids));
  lcard = Breeze.Card.id;
  ltoken = Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + Breeze.Card.id + '"]').attr('data-project-token');
  if (developement()) {
    url_host = "https://app.breeze.test:3006";
  } else {
    url_host = "https://breeze-node-n8.herokuapp.com";
  }
  $.ajax({
    type: "POST",
    url: url_host + '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/subscribe',
    queue: true,
    data: {
      auth_token: Breeze.current_user.token,
      ids: user_ids,
      multiple: true
    },
    success: function() {
      if (Breeze.is_socket_connected()) {
        return Breeze.socket.emit("message", {
          project: ltoken,
          method: "card-subscribe",
          card: lcard,
          ids: user_ids
        });
      }
    }
  });
  Breeze.Card.subscribed = user_ids;
  if (ref1 = Breeze.current_user.id, indexOf.call(user_ids, ref1) >= 0) {
    if ($('.card[data-card-id="' + Breeze.Card.id + '"] .badges .subs-bubble').length <= 0) {
      $('.card[data-card-id="' + Breeze.Card.id + '"] .badges .card-subs-bubble-container').append('<span class="subs-bubble"><i class="icon-eye" title="subscribed to email notifications"></i> </span>');
    }
  } else {
    $('.card[data-card-id="' + Breeze.Card.id + '"] .badges .subs-bubble').remove();
  }
  update_project_list_view_columns("subs");
  return false;
};

export {card_subscribers_add};
