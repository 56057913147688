import {chooseDateFormat} from 'mobile/utils.js';
import {developement} from 'mobile/utils.js';

var fetch_page, observe_scroll, old_results, projects, query, search, search_loading, search_next_page, search_next_page_archived_items, search_page, search_paging, update,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$(document).ready(function() {
  if ($('.workspace-search-empty').length > 0) {
    fetch_page();
  }
  if ($('.container.search').length > 0) {
    // Breeze.init_search_select();
  }
  $('#search-query-top').on("keyup", function(e) {
    var val;
    val = $('#search-query-top').val();
    if (e.keyCode === 27) {
      $('.main-search').removeClass("open");
    }
    if (val.length > 0) {
      $('.main-search .section.items').show();
      return $('.main-search .section.items strong').text(val);
    } else {
      $('.main-search .section.items').hide();
      return $('.main-search .section.project-result').hide();
    }
  });
  $('#search-query-top').on("keydown", function(e) {
    var n, t;
    if (e.keyCode === 13) {
      t = $('.main-search .section li.active');
      if (t.length > 0) {
        t.find("a").trigger("click");
        return false;
      }
    }
    if (e.keyCode === 40) {
      if ($('.main-search .section li.active').length <= 0) {
        e = $('.main-search .section:visible:first li:first');
        if (e) {
          e.addClass("active");
        }
      } else {
        t = $('.main-search .section li.active');
        if (t.next().is("li")) {
          t.removeClass("active");
          $('.main-search .section li').removeClass("active");
          t.next().addClass("active");
        } else {
          n = t.closest('.section').nextAll(".section:visible").first();
          $('.main-search .section li').removeClass("active");
          if (n.length > 0) {
            n.find("li:first").addClass("active");
          } else {
            e = $('.main-search .section:visible:first li:first');
            if (e) {
              e.addClass("active");
            }
          }
        }
      }
      return false;
    }
    if (e.keyCode === 38) {
      if ($('.main-search .section li.active').length <= 0) {
        e = $('.main-search .section:visible:last li:last');
        if (e) {
          e.addClass("active");
        }
      } else {
        t = $('.main-search .section li.active');
        if (t.prev().is("li")) {
          t.removeClass("active");
          $('.main-search .section li').removeClass("active");
          t.prev().addClass("active");
        } else {
          n = t.closest('.section').prevAll(".section:visible").first();
          $('.main-search .section li').removeClass("active");
          if (n.length > 0) {
            n.find("li:last").addClass("active");
          } else {
            e = $('.main-search .section:visible:last li:last');
            if (e) {
              e.addClass("active");
            }
          }
        }
      }
      return false;
    }
  });
  $('.main-search').on("click", '.section a', function() {
    return $('.main-search').removeClass("open");
  });
  $('.main-search').on("click", '.section.items', function() {
    return $('#search-form-top').submit();
  });
  $(document).on("click", '.main [data-menu="search"]', function(evt, xhr, settings) {
    return Breeze.init_top_search();
  });
  $(document).on("ajax:beforeSend", "#search-form-top", function(evt, xhr, settings) {
    if ($('#search-query-top').val().length <= 0) {
      return false;
    }
    $('.main-search').removeClass("open");
    $("#main_header .nav li").removeClass("bold-font");
    $('body').css("background", "").removeClass('custom-bg');
    $(".workspace").hide();
    return $('.contain').append(Breeze.global_loader);
  });
  return $(document).on("ajax:complete", "#search-form-top", function(evt, xhr, status) {
    var text;
    $(window).unbind('.jscroll').removeData('jscroll');
    text = xhr.responseText;
    $(".workspace").replaceWith(xhr.responseText);
    $('.main-search .section li').removeClass("active");
    $('#search-query').val($('#search-query-top').val());
    $('#search-query').attr("value", $('#search-query-top').val());
    $("#main_header .main-links .main.bold-font").removeClass("bold-font");
    $(".contain .application + .m-loader").remove();
    $('body, html').scrollTop(0);
    $('#harvest-messaging').remove();
    $(".workspace").show();
    History.pushState({
      workspace: $(".workspace").prop('outerHTML'),
      "static": true,
      searchcache: true,
      url: "/search"
    }, $("title").html(), "/search");
    observe_scroll();
    $('body').css("background", "").removeClass('custom-bg');
    Breeze.init_search_select();
    $(document).trigger("breeze:page", "search");
    return $("#search-form .search-submit .btn").trigger("click");
  });
});

search_next_page = [];

search_next_page_archived_items = false;

query = "";

projects = null;

search_loading = true;

search_paging = false;

search_page = 1;

old_results = false;

$.xhrPool = [];

Breeze.init_top_search = function() {
  var el, i, j, len, p, p_icon, r, ref, ref1, url;
  $("#search-form-top input:text").focus();
  $('.main-search .section li').removeClass("active");
  p_icon = '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" class="p-icon" viewBox="0 0 24 24"><path fill="currentColor" d="M9 2h-5c-1.103 0-2 .896-2 2v5c0 1.104.897 2 2 2h5c1.103 0 2-.896 2-2v-5c0-1.104-.897-2-2-2zm0 7h-5v-5h5v5zM20 2h-5c-1.104 0-2 .896-2 2v5c0 1.104.896 2 2 2h5c1.104 0 2-.896 2-2v-5c0-1.104-.896-2-2-2zm0 7h-5v-5h5v5zM9 13h-5c-1.103 0-2 .896-2 2v5c0 1.104.897 2 2 2h5c1.103 0 2-.896 2-2v-5c0-1.104-.897-2-2-2zm0 7h-5v-5h5v5zM20 13h-5c-1.104 0-2 .896-2 2v5c0 1.104.896 2 2 2h5c1.104 0 2-.896 2-2v-5c0-1.104-.896-2-2-2zm0 7h-5v-5h5v5z"/></svg>';
  if (((ref = Breeze.current_user.recent_projects) != null ? ref.length : void 0) > 0) {
    $('.main-search .section.recent').show();
    $('.main-search .section.recent ul li').remove();
    el = $('.main-search .section.recent ul');
    i = 0;
    ref1 = Breeze.current_user.recent_projects;
    for (j = 0, len = ref1.length; j < len; j++) {
      p = ref1[j];
      if (i > 6) {
        break;
      }
      r = _.find(Breeze.projects_stages, function(e) {
        return e.name === String(p);
      });
      if (r) {
        el.append('<li>' + p_icon + '<a href="/projects/' + r.name + '" data-partial="true">' + r.label + '</a></li>');
        i = i + 1;
      }
    }
  } else {
    $('.main-search .section.recent').hide();
  }
  if ($('.main-search .inline-typeahead').length <= 0) {
    $("#search-form-top input:text").typeahead({
      inline: true,
      menu: '<ul class="inline-typeahead"></ul>',
      items: 5,
      item: '<li>' + p_icon + '<a href="#"></a></li>',
      appendTo: ".project-result .body",
      source: Breeze.projects_stages
    });
    if (developement()) {
      url = "https://app.breeze.test:3006/projects/cards/search.json?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n2.herokuapp.com/projects/cards/search.json?auth_token=" + Breeze.current_user.token;
    }
    return $('#search-form-top input:text').typeahead({
      menu: '<ul class="inline-typeahead"></ul>',
      items: 5,
      appendTo: ".card-result .body",
      inline: true,
      item: '<li><svg aria-hidden="true" data-prefix="far" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class=""><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" class=""></path></svg><a href="#"></a></li>',
      source: function(query, process) {
        var that;
        that = this;
        that.loading = true;
        if (query.length >= 3) {
          return $.get(url, {
            query: query,
            top_search: true
          }, function(data) {
            if (!data) {
              data = [];
            }
            that.loading = false;
            return process(data);
          });
        } else {
          that.loading = false;
          return process([]);
        }
      }
    });
  }
};

fetch_page = function() {
  var url;
  if ($('.workspace-search-empty').length > 0) {
    if (developement()) {
      url = ("https://app.breeze.test:3006" + location.pathname + "?&auth_token=") + Breeze.current_user.token;
    } else {
      url = ("https://breeze-node-n2.herokuapp.com" + location.pathname + "?&auth_token=") + Breeze.current_user.token;
    }
    return $.ajax({
      type: 'GET',
      url: url,
      data: {
        partial: true
      },
      beforeSend: function() {
        return $('.workspace').append(Breeze.global_loader);
      },
      success: function(data) {
        $(".workspace").removeClass('workspace-search-empty');
        $(".workspace").html($(data).html());
        $(document).trigger("breeze:page", "search");
        $(document).trigger("breeze:page", "update-global-counters");
        return Breeze.init_search_select();
      }
    });
  }
};

$.xhrPool.abortAll = function() {
  return $(this).each(function(i, jqXHR) {
    jqXHR.abort();
    return $.xhrPool.splice(i, 1);
  });
};

update = function(data, e, last_item, first_item) {
  var elm, text;
  text = $(data).find(".search-result");
  if (e === "task" && text.length >= 10) {
    search_next_page.push(e);
  } else if (e === "comment" && text.length >= 10) {
    search_next_page.push(e);
  } else if (e === "project" && text.length >= 10) {
    search_next_page.push(e);
  } else if (e === "task_ids" && text.length >= 10) {
    search_next_page.push(e);
  } else if (e === "todo" && text.length >= 5) {
    search_next_page.push(e);
  } else if (e === "tag" && text.length >= 5) {
    search_next_page.push(e);
  } else if (e === "file" && text.length >= 5) {
    search_next_page.push(e);
  } else if (e === "messages" && text.length >= 5) {
    search_next_page.push(e);
  }
  if (text.length > 0) {
    if (old_results) {
      $('.search .body .search-result').remove();
      old_results = false;
    }
    $(".search .body .empty-search").hide();
    $(".search .body .search-block").append(text);
  }
  if (e === last_item) {
    if (old_results) {
      $('.search .body .search-result').remove();
      $('.search .search-body .m-loader-top').css("visibility", "hidden");
      old_results = false;
    }
    if ($('.search .body .search-result').length <= 0) {
      $('.search .body .empty-search').remove();
      elm = '<div class="row empty-search"><h3>Nothing found !</h3><p>Try searching something else. </p></div>';
      $(".search .body .search-block").append(elm);
    }
    search_loading = false;
    search_page += 1;
    // History.pushState({
    //   workspace: $(".workspace").prop('outerHTML'),
    //   "static": true,
    //   searchcache: true,
    //   url: "/search"
    // }, $("title").html(), "/search");
    observe_scroll();
    $(".search .m-loader").css("visibility", "hidden");
  }
};

search = function(e, last_item, first_item) {
  var end_date, start_date, url;
  if (developement()) {
    url = "https://app.breeze.test:3006/search?auth_token=" + Breeze.current_user.token;
  } else {
    url = "https://breeze-node-n2.herokuapp.com/search?auth_token=" + Breeze.current_user.token;
  }
  start_date = $('.calendar-selection input[name="start_date"]').val();
  end_date = $('.calendar-selection input[name="end_date"]').val();
  return $.ajax({
    type: 'POST',
    url: url,
    queue: true,
    data: {
			phone: true,
      items: e,
      query: query,
      projects: $('.fields-projects-select').val(),
      page: search_page,
      start_date: start_date,
      end_date: end_date,
      status: $('.fields-statuses-select').val(),
      users: $('.fields-users-select').val(),
      stages: $('.fields-stages-select').val(),
      tags: $('.fields-tags-select').val(),
      swimlanes: $('.fields-swimlanes-select').val(),
      custom_fields: $('.custom-fields-button').attr("data-fields")
    },
    beforeSend: function(jqXHR) {
      if (!old_results) {
        $('.search .search-body .m-loader-top').css("visibility", "hidden");
      }
      if (!old_results) {
        $(".search .m-loader-bottom").css("visibility", "visible");
      }
      return $.xhrPool.push(jqXHR);
    },
    complete: function(jqXHR) {
      var i;
      i = $.xhrPool.indexOf(jqXHR);
      if (i > -1) {
        return $.xhrPool.splice(i, 1);
      }
    },
    success: function(data) {
      return update(data, e[0], last_item, first_item);
    }
  });
};

observe_scroll = function() {
  return $(window).unbind('.myscroll').bind('scroll.myscroll', function() {
    var $e, $inner, borderTopWidth, borderTopWidthInt, e, first_item, iContainerTop, iTopHeight, iTotalHeight, innerTop, item, items, j, last_item, len, results;
    $e = $('.search .search-body');
    $inner = $('.search .search-body .search-block');
    borderTopWidth = parseInt($e.css('borderTopWidth'), 10);
    borderTopWidthInt = isNaN(borderTopWidth) ? 0 : borderTopWidth;
    iContainerTop = parseInt($e.css('paddingTop'), 10) + borderTopWidthInt;
    iTopHeight = $(window).scrollTop();
    innerTop = $inner.length ? $inner.offset().top : 0;
    iTotalHeight = Math.ceil(iTopHeight - innerTop + $(window).height() + iContainerTop);
    if (!search_loading && iTotalHeight >= $inner.outerHeight()) {
      if ((query != null) && query.length > 0) {
        last_item = search_next_page[search_next_page.length - 1];
        first_item = search_next_page[0];
        old_results = false;
        items = search_next_page;
        search_next_page = [];
        results = [];
        for (j = 0, len = items.length; j < len; j++) {
          item = items[j];
          e = [item];
          if (search_next_page_archived_items) {
            e.push("archived");
          }
          results.push(search(e, last_item, first_item, old_results));
        }
        return results;
      }
    }
  });
};

Breeze.init_search_select = function() {
  var update_search_date;
  $('.search .search-body').addClass("panel");
	
  $('.app').css('overflow', '');
  observe_scroll();
  $('.search .search-block').on("click", '[data-partial="true"]', function() {
    return $.xhrPool.abortAll();
  });
  $(".search").on("click", "#search-form .search-submit input", function(evt, xhr, settings) {
    var archived_items, e, first_item, item, items, j, last_item, len;
		
    query = $('#search-query').val();
    if ((query != null) && query.length <= 0) {
      $('#search-query').focus();
      return false;
    }
    $.xhrPool.abortAll();
    $('.search .search-body').addClass("panel");
    $('.search .search-body .m-loader-top').css("visibility", "visible");
    search_next_page = [];
    archived_items = false;
    items = $('.search-item-select').val();
    projects = $('.search-project-select').val();
    old_results = $('.search .body .search-result').length > 0;
		
    $(".search .body .empty-search").hide();
		
		// return false
		
    if  (typeof items !== "undefined" && items !== null && items.length > 0){
			
      archived_items = indexOf.call(items, "archived") >= 0;
      if (archived_items) {
				
        items = _.without(items, "archived");
        if (items.length <= 0) {
          items = ["task_ids", "task", "project", "comment", "todo", "file", "tag", "messages"];
        }
      }
    } else {
      items = ["task_ids", "task", "project", "comment", "todo", "file", "tag", "messages"];
    }
		
    if (indexOf.call(items, "task_ids") < 0) {
      items.unshift("task_ids");
    }
		
		
    $('#search-query').attr("value", $('#search-query').prop("value"));
    // $('.search .calendar-selection').data('daterangepicker').startDate.format();
    // $('.search .calendar-selection').data('daterangepicker').endDate.format();
    search_next_page_archived_items = archived_items;
    last_item = items[items.length - 1];
    first_item = items[0];
    search_page = 1;
		
    if ((query != null) && query.length > 0) {
      for (j = 0, len = items.length; j < len; j++) {
        item = items[j];
        e = [item];
        if (archived_items) {
          e.push("archived");
        }
        search(e, last_item, first_item);
      }
    }
		
    return evt.preventDefault();
  });
  $('.fields-projects-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    allSelectedText: "Projects",
    nonSelectedText: "Projects",
    buttonClass: 'btn btn-link project-btn',
    nSelectedText: '',
    nSelectedText1: 'Projects',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    selectAllNumber: false,
    selectAllBadge: true,
    enableClickableOptGroups: true,
    onInitialized: function(select, container) {
      var selected_values;
      selected_values = $(this.$select).data('selected-values');
      if (selected_values && selected_values.length > 0) {
        $(container).find('.multiselect-badge').text(selected_values.length);
      }
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      var select_el, selected_values, url, wrapper_el;
      $(".popover").remove();
      $('.custom-fields-button').removeData("popover");
      select_el = $(this.$select);
      wrapper_el = $(this.$container);
      if (select_el.find('option').length <= 0) {
        selected_values = select_el.attr('data-selected-values');
        wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
        if (developement()) {
          url = "https://app.breeze.test:3006/projects/select_field?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n2.herokuapp.com/projects/select_field?auth_token=" + Breeze.current_user.token;
        }
        return $.ajax({
          type: "POST",
          url: url,
          data: {
            selected_values: selected_values
          },
          success: function(data) {
            select_el.append($(data).find("select").html());
            select_el.multiselect('rebuild');
            wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
            return select_el.parent().find("input[type='text'].multiselect-search").focus();
          }
        });
      }
    }
  });
  $('.fields-stages-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Lists",
    buttonClass: 'btn btn-link',
    nSelectedText: '',
    nSelectedText1: 'Lists',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      var j, len, project, project_ids, stage_names;
      $('.fields-stages-wrapper .options-wrapper li').removeClass("hidden");
      project_ids = $('.fields-projects-select').val();
      if (project_ids && project_ids.length > 0) {
        projects = _.filter(Breeze.projects_stages, function(item, index) {
          return _.contains(project_ids, item.name);
        });
        stage_names = [];
        for (j = 0, len = projects.length; j < len; j++) {
          project = projects[j];
          stage_names = stage_names.concat(_.pluck(project.stages, "label"));
        }
        stage_names = _.uniq(stage_names);
        if (stage_names.length > 0) {
          $('.fields-stages-wrapper .options-wrapper li').each(function() {
            var ref;
            if (ref = $(this).find("input").val(), indexOf.call(stage_names, ref) < 0) {
              return $(this).addClass("hidden");
            }
          });
        }
      }
      $(".popover").remove();
      return $('.custom-fields-button').removeData("popover");
    }
  });
  $('.fields-tags-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Tags",
    buttonClass: 'btn btn-link',
    nSelectedText: '',
    nSelectedText1: 'Tags',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      $(".popover").remove();
      return $('.custom-fields-button').removeData("popover");
    }
  });
  $('.fields-users-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Users",
    buttonClass: 'btn btn-link',
    nSelectedText: '',
    nSelectedText1: 'Users',
    enableFiltering: true,
    enableHTML: true,
    enableCaseInsensitiveFiltering: true,
    enableClickableOptGroups: true,
    onInitialized: function(select, container) {
      var selected_values;
      selected_values = $(this.$select).data('selected-values');
      if (selected_values && selected_values.length > 0) {
        $(container).find('.multiselect-badge').text(selected_values.length);
      }
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      var select_el, selected_values, url, wrapper_el;
      $(".popover").remove();
      $('.custom-fields-button').removeData("popover");
      select_el = $(this.$select);
      wrapper_el = $(this.$container);
      if (select_el.find('option').length <= 0) {
        selected_values = select_el.attr('data-selected-values');
        wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
        if (developement()) {
          url = "https://app.breeze.test:3006/users/select_field?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n2.herokuapp.com/users/select_field?auth_token=" + Breeze.current_user.token;
        }
        return $.ajax({
          type: "POST",
          url: url,
          data: {
            selected_values: selected_values
          },
          success: function(data) {
            select_el.append($(data).find("select").html());
            select_el.multiselect('rebuild');
            wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
            return select_el.parent().find("input[type='text'].multiselect-search").focus();
          }
        });
      }
    }
  });
  $('.fields-swimlanes-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Swimlanes",
    buttonClass: 'btn btn-link',
    nSelectedText: '',
    nSelectedText1: 'Swimlanes',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      var j, len, project, project_ids, swimlane_names;
      $(".popover").remove();
      $('.custom-fields-button').removeData("popover");
      $('.fields-swimlanes-wrapper .options-wrapper li').removeClass("hidden");
      project_ids = $('.fields-projects-select').val();
      if (project_ids && project_ids.length > 0) {
        projects = _.filter(Breeze.projects_stages, function(item, index) {
          return _.contains(project_ids, item.name);
        });
        swimlane_names = [];
        for (j = 0, len = projects.length; j < len; j++) {
          project = projects[j];
          swimlane_names = swimlane_names.concat(_.pluck(project.swimlanes, "name"));
        }
        swimlane_names = _.uniq(swimlane_names);
        if (swimlane_names.length > 0) {
          $('.fields-swimlanes-wrapper .options-wrapper li').each(function() {
            var ref;
            if (ref = $(this).find("input").val(), indexOf.call(swimlane_names, ref) < 0) {
              return $(this).addClass("hidden");
            }
          });
        } else {
          $('.fields-swimlanes-wrapper .options-wrapper li').addClass("hidden");
        }
      }
      $('.fields-swimlanes-wrapper .options-wrapper li input[value="w/o swimlane"]').closest('li').removeClass("hidden");
      $(".popover").remove();
      return $('.custom-fields-button').removeData("popover");
    }
  });
  $('.fields-statuses-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: false,
    includeSelectAllIfMoreThan: 3,
    nonSelectedText: "Statuses",
    buttonClass: 'btn btn-link',
    nSelectedText: '',
    nSelectedText1: 'Statuses',
    enableFiltering: false,
    enableCaseInsensitiveFiltering: false,
    allSelectedText: "Statuses",
    selectAllNumber: false,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      $(".popover").remove();
      return $('.custom-fields-button').removeData("popover");
    }
  });
  $('.fields-extra-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 400,
    includeSelectAllOption: false,
    nonSelectedText: '<i class="fa fa-filter" aria-hidden="true"></i>',
    allSelectedText: '<i class="fa fa-filter" aria-hidden="true"></i>',
    nSelectedText1: '<i class="fa fa-filter" aria-hidden="true"></i>',
    buttonClass: 'btn btn-link',
    nSelectedText: '',
    enableHTML: true,
    onDropdownShown: function(e, i) {
      $(".popover").remove();
      return $('.custom-fields-button').removeData("popover");
    }
  });
  $('.search-project-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 400,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Projects",
    buttonClass: 'btn btn-link project-btn',
    nSelectedText: '',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    searchSelect: true,
    onInitialized: function(select, container) {
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    }
  });
  $('.search-item-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 400,
    includeSelectAllOption: false,
    nonSelectedText: '<i class="fa fa-filter" aria-hidden="true"></i>',
    allSelectedText: '<i class="fa fa-filter" aria-hidden="true"></i>',
    nSelectedText1: '<i class="fa fa-filter" aria-hidden="true"></i>',
    buttonClass: 'btn btn-link item-btn',
    nSelectedText: '',
    enableHTML: true,
    searchSelect: true
  });
  update_search_date = function(start, end) {
    var endDate, endDate_short, startDate, startDate_short;
    startDate = $('.search .calendar-selection').data('daterangepicker').startDate.format();
    endDate = $('.search .calendar-selection').data('daterangepicker').endDate.format();
    startDate_short = $('.search .calendar-selection').data('daterangepicker').startDate.format("MMM D");
    endDate_short = $('.search .calendar-selection').data('daterangepicker').endDate.format("MMM D");
    $('.calendar-selection input[name="start_date"]').val(startDate);
    $('.calendar-selection input[name="end_date"]').val(endDate);
    return $('.calendar-selection .multiselect-badge').text(startDate_short + " - " + endDate_short);
  };
  // $('.search .calendar-selection').daterangepicker({
  //   linkedCalendars: false,
  //   opens: 'left',
  //   autoApply: true,
  //   locale: {
  //     firstDay: Breeze.first_day,
  //     cancelLabel: 'Clear'
  //   }
  // }, update_search_date);
  $('.search .calendar-selection').on("show.daterangepicker", function(ev, picker) {
    $('.search .calendar-selection').addClass("open");
    return $('.daterangepicker').addClass("search-daterange");
  });
  $('.search .calendar-selection').on("hide.daterangepicker", function(ev, picker) {
    return $('.search .calendar-selection').removeClass("open");
  });
  return $('.search .calendar-selection').on("cancel.daterangepicker", function(ev, picker) {
    $('.search .calendar-selection').val("");
    $('.calendar-selection input[name="start_date"]').val("");
    $('.calendar-selection input[name="end_date"]').val("");
    $('.calendar-selection .multiselect-badge').text("");
    $('.search .calendar-selection').data('daterangepicker').setStartDate(new Date());
    return $('.search .calendar-selection').data('daterangepicker').setEndDate(new Date());
  });
};

Breeze.reset_multiselect = function(root) {
  return $(root).find('.multiselect-native-select').each(function(e) {
    var ids, j, len, select;
    ids = $(this).find('.multiselect-container li.active input').map(function() {
      return $(this).attr("value");
    });
    select = $(this).find("select");
    for (j = 0, len = ids.length; j < len; j++) {
      e = ids[j];
      select.find('option[value="' + e + '"]').attr("selected", "");
    }
    $(this).find('.btn-group').remove();
    select.unwrap();
    return select.data('multiselect', null);
  });
};

// ---
// generated by coffee-script 1.9.2