import PullToRefresh from 'mobile/utils/pullrefresh.js';
import {init_pull_to_refresh} from 'mobile/utils/pull_to_refresh.js';

document.addEventListener("turbolinks:load", function(e) {
	
	
	$('html').removeClass("home-overflow")
	
	if ($('.container.activities').length > 0) {
	  $(document).trigger("breeze:page", "activities");
	} 
	else if ($('.workspace.project-board').length > 0) {
	  $(document).trigger("breeze:page", "project");
	  $(document).trigger("breeze:page", "comments");
	  $(document).trigger("breeze:page", "time_entries");
	  $(document).trigger("breeze:page", "todo_lists");
	  $(document).trigger("breeze:page", "todos");
	  $(document).trigger("breeze:page", "load-swimlanes");
	  $(document).trigger("breeze:page", "card_move_short");
		// if ($(".timer.on").length	<= 0) $.init_timer()
		
	} 
	else if ($('.workspace.projects-list').length > 0) {
	  $(document).trigger("breeze:page", "projects_list");
	}
	else if ($('.workspace.dashboard').length > 0) {
		// if ($(".timer.on").length	<= 0) $.init_timer()
	  $(document).trigger("breeze:page", "project");
		
		$(document).trigger("breeze:page","dashboard")
		$(document).trigger("breeze:page","comments")
		$(document).trigger("breeze:page","time_entries")
		$(document).trigger("breeze:page","todo_lists")
		$(document).trigger("breeze:page","todos")
		$(document).trigger("breeze:page","card_move_short")
	}
	
	else if ($('.container.notifications').length > 0) {
		$(document).trigger("breeze:page","notifications")
	}		
	
  else if ($('.container.search').length > 0) {
    Breeze.init_search_select();
  }
	
	else if ($('.workspace.home').length > 0) {
	$('html').addClass("home-overflow")
		
	  $(document).trigger("breeze:page", "project");
		
		$(document).trigger("breeze:page:home")
		
		$(document).trigger("breeze:page","comments")
		$(document).trigger("breeze:page","time_entries")
		$(document).trigger("breeze:page","todo_lists")
		$(document).trigger("breeze:page","todos")
		$(document).trigger("breeze:page","card_move_short")
		
		
	}
	if ($('.latest-notifications').data('notifications-count') > 0) {
	  $('#main-notify .number-circle').html($('.latest-notifications').data('notifications-count')).show();
	} else {
	  $('#main-notify .number-circle').hide();
	}


	if ($('#card_modal').length > 0) {
		$(document).trigger("breeze:page","card_page")
	}
	
	init_pull_to_refresh()
	
})
