function unembed_images(element) {
  return element.find('a').each(function() {
    if ($(this).hasClass('card-link-ref')) {
      return true;
    }
    if ($(this).find('video').length > 0) {
      return $(this).html($(this).text());
    } else if ($(this).find('iframe').length > 0 || $(this).find('video').length > 0) {
      return $(this).html($(this).attr('href'));
    }
  });
};

function embed_images(element) {
  var dropbox, img_error, img_link, video_link, vimeo, wistia, youtube;
  img_error = function(url) {
    return "onerror=\"$(this).replaceWith('" + encodeURI(url).replace(/'/g, "%27") + "')\"";
  };
  youtube = function(e) {
    var n, r, t;
    if (e.text().length <= 0) {
      return false;
    }
    t = /(?:youtu\.be\/|youtube\.com)(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=)?([\w-]{10,11})/;
    if (n = ((r = e.attr('href').match(t)) != null ? r[1] : void 0)) {
      return e.html("<iframe  src=\"//www.youtube.com/embed/" + n + "\" frameborder=\"0\" allowfullscreen id=\"ytplayer\" type=\"text/html\"></iframe>");
    }
  };
  vimeo = function(e) {
    var n, r, t;
    t = /vimeo\.com(?:\/(?!album)|(?!review)[^\/]+)?\/(\d+)/;
    if (n = ((r = e.attr('href').match(t)) != null ? r[1] : void 0)) {
      return e.html("<iframe src=\"//player.vimeo.com/video/" + n + "\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>");
    }
  };
  wistia = function(e) {
    var i, n, r, t;
    t = /(?:wistia\.com|wi\.st)\/(?:medias|embed)\/(.+)/;
    if (r = ((i = e.attr('href').match(t)) != null ? i[1] : void 0)) {
      n = encodeURI("https://fast.wistia.net/embed/iframe/" + decodeURIComponent(r));
      return e.html("<iframe  src=\"" + n + "\" allowtransparency=\"true\" frameborder=\"0\" scrolling=\"no\" class=\"wistia_embed\" name=\"wistia_embed\" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen></iframe>");
    }
  };
  dropbox = function(e) {
    var n, t;
    if (e.find("img").length > 0) {
      return true;
    }
    if (t = ((n = e.attr("href").match(/dropbox\.com\/(s\/.+?(?:\.png|\.jpg|\.gif|\.jpeg))(?:\?.+)?\b/i)) != null ? n[1] : void 0)) {
      return e.html("<img src=\"" + encodeURI('https://dl.dropboxusercontent.com/' + decodeURIComponent(t)) + "\" class=\"embed-img\" " + img_error(e.attr('href')) + ">");
    }
  };
  img_link = function(e) {
    var n, t;
    if (e.find("img").length > 0) {
      return true;
    }
    if (e.parent().is("em") || e.parent().is("pre") || e.parent().is("quote")) {
      return true;
    }
    if (t = ((n = e.attr("href").match(/(.+?(?:\.png|\.jpg|\.gif|\.jpeg))(?:\?.+)?\b/i)) != null ? n[1] : void 0)) {
      return e.html("<img src=\"" + t + "\" class=\"embed-img\" " + img_error(e.attr('href')) + ">");
    }
  };
  video_link = function(e) {
    var n, t;
    if (e.find("video").length > 0) {
      return true;
    }
    if (t = ((n = e.attr("href").match(/(.+?(?:\.mp4|\.ogg|\.webm))(?:\?.+)?\b/i)) != null ? n[1] : void 0)) {
      return e.html("<video controls muted playsinline src=\"" + t + "\" class=\"embed-img\" " + img_error(e.attr('href')) + "></video>" + e.text());
    }
  };
  return element.find('a').each(function() {
    if (typeof $(this).attr('href') !== typeof void 0 && $(this).attr('href') !== false) {
      if ((img_link($(this))) == null) {
        if ((youtube($(this))) == null) {
          if ((vimeo($(this))) == null) {
            if ((wistia($(this))) == null) {
              if ((dropbox($(this))) == null) {
                if ((video_link($(this))) == null) {

                }
              }
            }
          }
        }
      }
    }
  });
};

export {unembed_images,embed_images};
