function hide_first_swimlane_init() {
  var stage;
  if ($('.swimlane').length > 0) {
    stage = $('.sortable_stage:first');
    if ($('.project-board').hasClass("project-list-view")) {
      if (stage.find('.card').length <= 0) {
        return $('.sortable_stage:first').hide();
      } else {
        return $('.sortable_stage:first').show();
      }
    } else {
      if (stage.find('.card').length <= 0) {
        stage.css('min-height', '25px').css('height', '25px');
        stage.find('.stage_class').each(function() {
          return $(this).css('min-height', '32px').css('height', '32px');
        });
        stage.addClass('empty-stage');
        stage.find('.task-new-stage').css('display', '');
        return stage.find('.stage_class').removeClass('hover');
      }
    }
  }
};

function hide_first_swimlane() {
  var stage;
  if ($('.swimlane').length > 0) {
    stage = $('.sortable_stage:first');
    if ($('.project-board').hasClass("project-list-view")) {
      if (stage.find('.card').length <= 0) {
        return $('.sortable_stage:first').hide();
      } else {
        return $('.sortable_stage:first').show();
      }
    } else {
      if (stage.find('.card').length <= 0) {
        stage.css('min-height', '25px').css('height', '25px');
        stage.find('.stage_class').each(function() {
          return $(this).css('min-height', '32px').css('height', '32px');
        });
        stage.addClass('empty-stage');
        stage.find('.task-new-stage').css('display', '');
        return stage.find('.stage_class').removeClass('hover');
      } else {
        stage.css('min-height', '').css('height', '');
        stage.find('.stage_class').removeClass('hover');
        stage.find('.task-new-stage').css('display', '');
        stage.find('.stage_class').each(function() {
          return $(this).css('min-height', '').css('height', '');
        });
        return stage.removeClass("empty-stage");
      }
    }
  }
};

function hide_first_swimlane_clear() {
  var stage;
  if ($('.swimlane').length > 0) {
    stage = $('.sortable_stage:first');
    if ($('.project-board').hasClass("project-list-view")) {
      if (stage.find('.card').length <= 0) {
        return $('.sortable_stage:first').hide();
      } else {
        return $('.sortable_stage:first').show();
      }
    } else {
      if (stage.find('.card').length <= 0) {
        stage.css('min-height', '25px').css('height', '25px');
        stage.find('.stage_class').each(function() {
          return $(this).css('min-height', '32px').css('height', '32px');
        });
        stage.addClass('empty-stage');
        stage.find('.task-new-stage').css('display', '');
        return stage.find('.stage_class').removeClass('hover');
      } else {
        stage.css('min-height', '').css('height', '');
        stage.find('.stage_class').removeClass('hover');
        stage.find('.task-new-stage').css('display', '');
        stage.find('.stage_class').each(function() {
          return $(this).css('min-height', '').css('height', '');
        });
        stage.find('.stage_class .sortable').each(function() {
          return $(this).css('min-height', '').css('height', '');
        });
        return stage.removeClass("empty-stage");
      }
    }
  }
};

export {hide_first_swimlane,hide_first_swimlane_clear,hide_first_swimlane_init};