require("mobile/jquery.jscroll")
import {chooseDateFormat} from 'mobile/utils.js';

var activity_parse, fetch_activities, fetch_page, init_page;


$(document).ready(function() {
	
  $(document).on("breeze:page", function(e, page) {
    if (page === "activities") {
     init_page();
    }
  });
  if ($('.container.activities').length > 0) {
		
		init_page();

  }
});

init_page = function() {
  activity_parse();


  if ($(".entry-container").length > 0) {
	  $(".entry-container").jscroll({
      nextSelector: '.next-page:last-child',
      loadingHtml: Breeze.global_loader,
      callback: function() {
	      activity_parse();
      }
    });
  }
};


fetch_activities = function() {
  var url;
  if (Breeze.developement()) {
    url = "https://app.breeze.test:3006/activities?auth_token=" + Breeze.current_user.token;
  } else {
    url = "https://breeze-node-n2.herokuapp.com/activities?auth_token=" + Breeze.current_user.token;
  }
  $.ajax({
    type: 'GET',
    url: "/activities?auth_token=" + Breeze.current_user.token,
    data: {
      projects: $('.fields-projects-select').val(),
      users: $('.fields-users-select').val(),
      partial: true
    },
    beforeSend: function() {
      $(".activities .loading-dots.filter-dots").css("visibility", "visible");
      $(window).unbind('.jscroll').removeData('jscroll');
      (".entry-container").unbind('.jscroll').removeData('jscroll');
    },
    success: function(data) {
      $(".workspace .entry-container").replaceWith($(data).find('.entry-container'));
      $(".activities .loading-dots.filter-dots").css("visibility", "hidden");
      activity_parse();

      $(".entry-container").jscroll({
        nextSelector: '.next-page:last-child',
        loadingHtml: '<div class="loading-dots"></div>',
        callback: function() {
          activity_parse();
        }
      });
    }
  });
  return false;
};

activity_parse = function() {
  var el;
	var dateFormat = require('dateformat');
	
  el = $(".activities .entry");
  if ($(".jscroll-added").lenght > 0) {
    el = $(".jscroll-added:last .entry");
  }
  el.each(function(e) {
    var date, name, now, pdate, time_format, yesterday;
    date = $(this).find(".day");
    pdate = Date.parse(date.data("date"));
    $(this).find(".day .day-number").html(dateFormat(pdate, "mmmm"));
    $(this).find(".day .day-name").html(dateFormat(pdate, "d"));
    name = dateFormat(pdate, "dddd");
    now = new Date();
    yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);
    if (now.setHours(0, 0, 0, 0) === new Date(pdate).setHours(0, 0, 0, 0)) {
      name = "Today";
    } else if (yesterday.setHours(0, 0, 0, 0) === new Date(pdate).setHours(0, 0, 0, 0)) {
      name = "Yesterday";
    }
    $(this).find(".day .day-month").html(name + ",");
    time_format = "h:MMtt";
    if (Breeze.clock24 === true) {
      time_format = "HH:MM";
    }
    return $(this).find(".log").each(function(l) {
      return $(this).find(".localtime").html(dateFormat(Date.parse($(this).data("date")), time_format));
    });
  });
  $('.activities [data-assdate]').each(function(e) {
		
    var ddate, dformat, dtime;
    ddate = $(this).attr("data-assdate");
    try {
      dtime = $(this).data("asstime");
      dformat = chooseDateFormat(Breeze.clock24,ddate, dtime);
      $(this).html(dateFormat(ddate, dformat));
    } catch (_error) {
      $(this).html(ddate);
    }
  });
};


