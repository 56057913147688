import dateFormat from 'dateformat';
import moment from "mobile/moment.min.js";

var init_estimates;

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.public-card').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_estimates();
  }
  return $(document).on("breeze:page", function(e, page) {
    if (page === "time_entries") {
      return init_estimates();
    }
  });
});

init_estimates = function() {
  var init_time_estimates;
  $('#card_modal .estimates-hold').on("click", '.hide-estimates', function(e) {
    var hidden;
    hidden = $(this).hasClass('active');
    if (hidden) {
      $('#card_modal .time-estimates .tracker').removeClass("hide");
      Breeze.current_user.hide_estimates = false;
    } else {
      $('#card_modal .time-estimates .tracker').addClass("hide");
      Breeze.current_user.hide_estimates = true;
    }
    $('#card_modal .time-estimates .hide-estimates').toggleClass("active");
    $.ajax({
      type: 'POST',
      url: '/profile',
      data: {
        user: {
          hide_estimates: Breeze.current_user.hide_estimates
        }
      }
    });
  });
  $('#card_modal ').on("mouseenter", '.time-estimates .creator[data-type="container"][data-readonly="false"]', function() {
    return $(this).find('.icon-remove').addClass("show-item");
  });
  $('#card_modal ').on("mouseleave", '.time-estimates .creator[data-type="container"][data-readonly="false"]', function() {
    return $(this).find('.icon-remove').removeClass("show-item");
  });
  $('#card_modal ').on("click", '.time-estimates [data-action="add-estimate"]', function() {
    var el, selected, time_entry_user, u, users_options, users_options_select;
    Breeze.new_time_entry = true;
    $('#card_modal .time-estimates .actions .cancel').trigger("click");
    users_options_select = "";
    if (Breeze.current_user.admin_owner && $('.card[data-card-id="' + Breeze.Card.id + '"]').data('project-owner-team')) {
      users_options = (function() {
        var j, len, ref, results;
        ref = Breeze.project_users;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          u = ref[j];
          selected = u.email === Breeze.current_user.email ? 'selected="selected"' : ' ';
          results.push('<option value="' + u.id + '" data-email="' + u.email + '" ' + selected + '>' + u.name + '</option>');
        }
        return results;
      })();
      users_options_select = '<div class="single-select"><select name="timeentry_user" class="">' + users_options.join("") + '</select></div>';
      time_entry_user = "time-entry-user";
    }
    el = $('<div class="tracker row" data-type="container" data-action="new" data-billable="true"> <span data-action="time-entry" class="' + time_entry_user + '" ><span class="border"><input type="text" readonly class="dateinput" spellcheck="false" placeholder="Today" data-type="date"><input type="text" readonly class="dateinput-display" spellcheck="false" placeholder="Today"> </span><span class="border"> <input type="text" name="workef" class="" spellcheck="false" placeholder="Estimate" data-type="estimate"> </span>' + users_options_select + '<span class="border textarea-border"> <textarea name="comment" cols="1" rows="1" placeholder="Comment or description" data-type="comment"></textarea> </span> <span class="actions"> <button class="c-button c-button--blue c-button-med c-button-save update" data-action="create" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Add <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Cancel </button> </span> </span></div>');
    el.insertBefore($('#card_modal .time-estimates [data-action="add-estimate"]').first());
    el.find('textarea').autosize().trigger('autosize.resize');
    el.find('[name="timeentry_user"]').multiselect({
      numberDisplayed: 1,
      maxHeight: 300,
      nonSelectedText: "default",
      buttonClass: 'btn-material',
      nSelectedText: 'selected',
      multiple: false
    });
    init_time_estimates();
    el.on("click", ".dateinput-display", function(e) {
      $(this).parent().find(".dateinput").datepicker("show");
      return $(this).focus();
    });
    $(this).hide();
    return false;
  });
  $('#card_modal ').on("click", '.time-estimates [data-action="create"]', function() {
    var box, card, container, desc, el, entry_user, entry_user_email, ldate, socket_params, sum, tid, tracked, tracked_minutes, tracked_parsed, tracked_user_id;
    container = $(this).closest('[data-type="container"]');
    box = $(this).closest('[data-action="time-entry"]');
    desc = box.find('[data-type="comment"]').val();
    card = container.attr('data-time-card-id');
    ldate = box.find('[data-type="date"]').val();
    if ((ldate != null ? ldate.length : void 0) <= 0) {
      ldate = moment().format("YYYY-MM-DD");
    }
    tracked = box.find('[data-type="estimate"]').val();
    tracked_parsed = Breeze.Time.parse(tracked, 1);
    tracked_minutes = Breeze.Time.parseMinutes(tracked, 1);
    if (tracked_parsed === null) {
      $(this).tooltip("show");
      $('#card_modal .time-estimates input[data-type="estimate"]').tooltip("show");
      return false;
    }
    tid = "time_temp_" + Breeze.current_user.id + new Date().getTime();
    if (Breeze.current_user.admin_owner) {
      entry_user = box.find('select[name="timeentry_user"] option:selected').text() || Breeze.current_user.name;
      entry_user_email = box.find('select[name="timeentry_user"] option:selected').attr('data-email') || Breeze.current_user.email;
    } else {
      entry_user = Breeze.current_user.name;
      entry_user_email = Breeze.current_user.email;
    }
    el = '<div class="row tracker creator" data-user-email="' + entry_user_email + '" data-time-estimate="' + tracked_minutes + '" data-type="container" data-readonly="false" data-id="' + tid + '" data-tid="' + tid + '" data-date="' + dateFormat(ldate, "UTC:yyyy-mm-dd") + '" data-time-card-id="' + Breeze.Card.id + '" data-project-id="' + Breeze.Card.project + '"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg><div data-type="item"><span data-type="date">' + dateFormat(ldate, "UTC:mmm d") + '</span><span data-type="time">' + Breeze.Time.formatPretty(tracked_minutes) + '</span><span data-type="user">' + entry_user + '</span><span data-type="comment">' + desc + '</span></div> </div>';
    $('#card_modal .time-estimates .tracker[data-action="new"]').remove();
    $(el).insertBefore('#card_modal .time-estimates [data-action="add-estimate"]');
    $('#card_modal .time-estimates [data-action="add-estimate"]').show();
    tracked_user_id = box.find('select[name="timeentry_user"]').val();
    sum = Breeze.sum_estimated_time();
    Breeze.update_card_time(Breeze.Card.tracked_time, card, sum);
    Breeze.sum_tracked_time(sum, 0);
    socket_params = {
      card: Breeze.Card.id,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-estimate-user",
      message: container.prop('outerHTML'),
      entry: container.attr('data-id'),
      tracked: Breeze.Card.tracked_time
    };
    $.ajax({
      type: 'POST',
      queue: true,
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/estimates',
      data: {
        estimate: {
          comment: desc,
          amount: tracked_minutes,
          user_id: tracked_user_id,
          logged_date: ldate
        }
      },
      success: function(data) {
        if (data) {
					$('#card_modal .estimates-hold [data-id="'+tid+'"]').attr("data-id",data);
					$('.card[data-card-id="'+socket_params.card+'"] .people-estimates [data-id="'+tid+'"]').attr("data-id",data); 
					
          if ($('#card_modal .estimates-hold [data-tid="' + data + '"]').length > 0) {
            socket_params.message = $('#card_modal .estimates-hold [data-tid="' + data + '"]').prop('outerHTML');
          } else if ($('.card[data-card-id="' + socket_params.card + '"] .people-tracked [data-tid="' + data + '"]').length > 0) {
            socket_params.message = $('.card[data-card-id="' + socket_params.card + '"] .people-tracked [data-tid="' + data + '"]').prop('outerHTML');
          }
          if (socket_params.message) {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        } else {
          return $.flashMessage("<span class='red-text'>We couldn't create the entry. Please refresh your browser window.</span>", true);
        }
      }
    });
    return false;
  });
  $('#card_modal ').on("click", '.time-estimates .actions .cancel', function() {
    var box, container;
    container = $(this).closest('[data-type="container"]');
    if (container.data("action")) {
      container.remove();
      $('#card_modal .time-estimates [data-action="add-estimate"]').show();
    } else {
      box = $(this).closest('[data-action="time-entry"]');
      box.remove();
      container.find('[data-type="item"]').show();
      container.find("i").removeClass("hide show-item");
    }
    return false;
  });
  $('#card_modal ').on("click", '.time-estimates .creator[data-readonly="false"] [data-type="item"]', function() {
    var box, el, icon, selected, selected_user, time_entry_user, u, users_options, users_options_select;
    Breeze.new_time_entry = false;
    $('#card_modal .time-estimates .actions .cancel').trigger("click");
    users_options_select = "";
    time_entry_user = "";
    if (Breeze.current_user.admin_owner && $('.card[data-card-id="' + Breeze.Card.id + '"]').data('project-owner-team')) {
      selected_user = $(this).parent().attr('data-user-email');
      users_options = (function() {
        var j, len, ref, results;
        ref = Breeze.project_users;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          u = ref[j];
          selected = u.email === selected_user ? 'selected="selected"' : ' ';
          results.push('<option value="' + u.id + '" data-email="' + u.email + '" ' + selected + '>' + u.name + '</option>');
        }
        return results;
      })();
      users_options_select = '<div class="single-select"><select name="timeentry_user" class="">' + users_options.join("") + '</select></div>';
      time_entry_user = "time-entry-user";
    }
    el = $('<span data-action="time-entry" class="' + time_entry_user + '"><span class="border"><input type="text" class="dateinput" readonly spellcheck="false" placeholder="Today" data-type="date"><input type="text"  readonly class="dateinput-display" spellcheck="false" placeholder="Today"> </span><span class="border"><input type="text" name="workef" class="" spellcheck="false" placeholder="Estimate" data-type="estimate" value="' + $(this).find('[data-type="time"]').html() + '"> </span>' + users_options_select + '<span class="border textarea-border"><textarea name="comment" cols="1" rows="1" placeholder="Comment or description" data-type="comment"></textarea></span> <span class="actions"> <button class="c-button c-button--blue c-button-med c-button-save update" data-action="update" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Save <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div>Cancel </button> </span> </span>');
    $(this).hide();
    box = $(this).parent();
    icon = box.find("i");
    box.find('.timeentry-pill').removeClass('show').hide();
    el.find('[name="timeentry_user"]').multiselect({
      numberDisplayed: 1,
      maxHeight: 300,
      nonSelectedText: "default",
      buttonClass: 'btn-material',
      nSelectedText: 'selected',
      multiple: false
    });
    icon.addClass("hide");
    box.append(el);
    box.find('textarea').autosize().trigger('autosize.resize');
    box.find('textarea').autosize().trigger('autosize.resize');
    init_time_estimates(box.attr("data-date"));
    el.on("click", ".dateinput-display", function(e) {
      $(this).parent().find(".dateinput").datepicker("show");
      return $(this).focus();
    });
    el.find('[data-type="comment"]').val($(this).find('[data-type="comment"]').text());
    if ($('body').hasClass('ie')) {
      $('#card_modal .time-estimates input').placeholder();
    }
    return false;
  });
  $('#card_modal ').on("click", '.time-estimates [data-action="update"]', function() {
    var box, card, container, desc, entry_user, entry_user_email, ldate, project, socket_params, sum, tracked, tracked_minutes, tracked_user_id;
    container = $(this).closest('[data-type="container"]');
    box = $(this).closest('[data-action="time-entry"]');
    desc = box.find('[data-type="comment"]').val();
    tracked = box.find('[data-type="estimate"]').val();
    card = container.attr('data-time-card-id');
    project = container.attr('data-project-id');
    ldate = box.find('[data-type="date"]').val();
    tracked_minutes = Breeze.Time.parseMinutes(tracked, 1);
    tracked_user_id = box.find('select[name="timeentry_user"]').val();
    if (Breeze.Time.parse(tracked) === null) {
      $(this).tooltip("show");
      $('#card_modal .time-estimates input[data-type="estimate"]').tooltip("show");
      return false;
    }
    container.find('[data-type="item"] [data-type="time"]').html(Breeze.Time.formatPretty(tracked_minutes));
    container.find('[data-type="item"] [data-type="comment"]').html(desc);
    container.find('[data-type="item"] [data-type="date"]').html(dateFormat(ldate, "UTC:mmm d"));
    container.attr('data-date', dateFormat(ldate, "UTC:yyyy-mm-dd"));
    if (Breeze.current_user.admin_owner) {
      entry_user = box.find('select[name="timeentry_user"] option:selected').text() || Breeze.current_user.name;
      entry_user_email = box.find('select[name="timeentry_user"] option:selected').attr('data-email') || Breeze.current_user.email;
      container.find('[data-type="item"] [data-type="user"]').html(entry_user);
      container.attr('data-user-email', entry_user_email);
    }
    container.attr('data-time-estimate', tracked_minutes);
    box.remove();
    container.find('[data-type="item"]').show();
    container.find("i").removeClass("hide show-item");
    sum = Breeze.sum_estimated_time();
    Breeze.update_card_time(Breeze.Card.tracked_time, card, sum);
    Breeze.sum_tracked_time(sum, 0);
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-estimate-user-update",
      message: container.prop('outerHTML'),
      entry: container.attr('data-id'),
      tracked: Breeze.Card.tracked_time
    };
    $.ajax({
      type: 'PUT',
      queue: true,
      url: '/projects/' + project + '/cards/' + card + '/estimates/' + container.attr('data-id'),
      data: {
        estimate: {
          comment: desc,
          amount: tracked_minutes,
          user_id: tracked_user_id,
          logged_date: ldate
        }
      },
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    return false;
  });
  $('#card_modal ').on('click', '.time-estimates .tracker.creator .icon-remove', function() {
    var card, el, project, socket_params;
    el = $(this).closest('.tracker');
    card = el.attr('data-time-card-id');
    project = el.attr('data-project-id');
    el.fadeOut(function() {
      var sum;
      el.remove();
      sum = Breeze.sum_estimated_time();
      Breeze.update_card_time(Breeze.Card.tracked_time, card, sum);
      return Breeze.sum_tracked_time(sum, 0);
    });
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-estimate-user-delete",
      message: "delete",
      entry: el.attr('data-id'),
      tracked: Breeze.Card.tracked_time
    };
    $.ajax({
      type: "DELETE",
      queue: true,
      url: '/projects/' + project + '/cards/' + card + '/estimates/' + el.attr('data-id'),
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    return false;
  });
  init_time_estimates = function(logged_date) {
    var udate;
    $('#card_modal .time-estimates input[data-type="date"]').datepicker('destroy');
    $('#card_modal .time-estimates input[data-type="date"]').datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      dateFormat: "yy-mm-dd",
      showAnim: "",
      constrainInput: true,
      prevText: "←",
      nextText: '→',
      firstDay: Breeze.first_day,
      onSelect: function(dateText, inst) {
        var day, month;
        month = inst.selectedMonth + 1 <= 9 ? "0" + (inst.selectedMonth + 1).toString() : (inst.selectedMonth + 1).toString();
        day = inst.selectedDay <= 9 ? "0" + inst.selectedDay.toString() : inst.selectedDay.toString();
        Breeze.Card.logged_date = inst.selectedYear.toString() + "-" + month + "-" + day + "T00:00:00+00:00";
        return $('#card_modal .time-estimates .dateinput-display').val(dateFormat(Breeze.Card.logged_date, "UTC:mmm d"));
      }
    });
    if (logged_date) {
      udate = logged_date.match(/(\d+)/g);
      $('#card_modal .time-estimates .dateinput').datepicker('setDate', new Date(udate[0], parseInt(udate[1], 10) - 1, udate[2]));
      $('#card_modal .time-estimates .dateinput-display').val(dateFormat(new Date(udate[0], parseInt(udate[1], 10) - 1, udate[2]), "mmm d"));
    } else {
      $('#card_modal .time-estimates .dateinput').datepicker('setDate', null);
      $('#card_modal .time-estimates .dateinput-display').val("");
    }
    $('#card_modal .time-estimates input[data-type="estimate"]').tooltip({
      animation: false,
      placement: "bottomleft",
      trigger: "manual",
      title: "This isn't a correct format. Valid time formats are 45, 1:45, 1h, 1h45m, 1day"
    });
    return $('#card_modal .estimates-hold').on("keydown", '.time-estimates input[data-type="work"],.time-estimates textarea[name="comment"]', function(e, item) {
      $(this).tooltip("hide");
      if (e.keyCode === 13 && !e.shiftKey) {
        return $(this).closest('.tracker').find('[type="submit"]').trigger("click");
      }
    });
  };
  return Breeze.sum_estimated_time = function() {
    var planned, planned_pill, sum, trackers;
    planned_pill = $('.card-content .time-estimates span[data="planned"]');
    trackers = $(".card-content .time-estimates .tracker");
    planned = parseInt(planned) || 0;
    sum = 0;
    trackers.each(function(i) {
      return sum = sum + parseInt($(this).attr("data-time-estimate")) || 0;
    });
    sum = Math.round(sum);
    planned_pill.html(Breeze.Time.formatPretty(sum));
    if (sum > 0) {
      planned_pill.attr("class", "pill");
    } else {
      planned_pill.attr("class", "pill hide");
    }
    return sum;
  };
};

// ---
// generated by coffee-script 1.9.2